import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import './competence-checklist-item.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//
import PersonAvatar from '@components/person-avatar/person-avatar';
import { getEmployeePreviewPath } from '@utils/routes.utils';

import { competencesToggle } from '@actions/competences.actions';

import { Link } from 'react-router-dom';
import DEFAULT_BG from '../../../assets/images/default_customer.jpg';

const createSubtitleContent = content => ({
  __html: content,
});

const CompetenceChecklistItem = ({ course, size, type, competencesToggle, roleId, removeRequirement }) => {
  const { competence_title: title, passed } = course;
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (clicked) {
      return;
    }
    setClicked(true);

    let data = null;
    if (size === 'big') {
      data = {
        pid: course.person_id,
        type,
        size,
        cid: course.competence_id,
      };
    } else {
      data = {
        pid: course.person_id,
        type,
        size,
        cid:
          (course.pending_checklists &&
            course.pending_checklists.competence_id) ||
          course.competence_id,
      };
    }

    competencesToggle(data);

    /*
    onClick(course);
     */
  };

  const removePersonalCompetence = (id) => {
    if (removeRequirement) {
      removeRequirement(id);
    }
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      handleClick();
    }
  };
  console.log('sie');
  console.log(size);
  return (
    <>
      {size === 'employee' && (
        <div className={`competence-card ${size}`}>
          <div className="grid-x">
            <div className="small-12 cell">
              <div
                className="roles-list__competence"
                key={course.competence_id}>
                <div className="roles-list__competence-title">
                  <div
                    className={classNames([
                      'roles-list__competence-status',
                      {
                        'roles-list__competence-status--not-started':
                          course.passed === 0,
                      },
                      {
                        'roles-list__competence-status--in-progress':
                          course.passed > 0 && course.passed < 100,
                      },
                      {
                        'roles-list__competence-status--completed':
                          course.passed === 100,
                      },
                    ])}
                  />
                  <>
                    <div className="grid-x">
                      <div className="cell small-12 medium-8">
                        <div className="small-header">
                          {course.competence_type.title}
                          {roleId === -2 && course.passed !== 100 && removeRequirement && (
                            <div className="removeThis">
                              <button
                                type="button"
                                onClick={() => removePersonalCompetence(course.competence_id)}
                              >
                                <i className="far fa-trash-alt"/>
                              </button>

                            </div>
                          )}
                        </div>
                        {course.competence_title}
                        <div className="signed-info">
                          {course.verified && course.verified.date && (
                            <div className="signed-date">
                              <FontAwesomeIcon icon="calendar-alt" />
                              &nbsp;&nbsp;{course.verified.date}
                            </div>
                          )}
                          {course.checklist &&
                            course.checklist.is_check_user && (
                              <div className="signed-by">
                                Avhuk av brukeren:{' '}
                                {course.checklist.is_check_user}
                              </div>
                            )}
                          {course.checklist &&
                            course.checklist.is_check_manager && (
                              <div className="signed-by">
                                Avhuket av leder (
                                {course.checklist.is_check_manager_name}):{' '}
                                {course.checklist.is_check_user}
                              </div>
                            )}
                          {course.verified && course.verified.fullname && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon="user-check" />
                              &nbsp;&nbsp;{course.verified.fullname}
                            </div>
                          )}
                          {course.valid_until && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon="hourglass" />
                              &nbsp;&nbsp;{course.valid_until}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cell small-12 medium-4">
                        {course.passed === 100 &&
                          !!course.grade &&
                          course.grade !== 0 && (
                            <div className="course-grade">
                              Karakter: {course.grade}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                </div>
                <div className="roles-list__competence-description" />
              </div>
            </div>
          </div>
        </div>
      )}
      {size === 'big' && (
        <>
          <div className="cell small-4 medium-4">
            <div
              className="img"
              style={{
                backgroundImage: `url(${(course.cover && course.cover.url) ||
                  DEFAULT_BG})`,
              }}
            />
          </div>
          <div className="cell small-8 medium-7 content">
            <div className="employees-checklist__text">
              <h2>{course.competence.title}</h2>
            </div>
            {course.competence.competence_type.competence_type && (
              <div>{course.competence_type.title}</div>
            )}

            <div className="employees-checklist__item">
              {(course.passed === 50 && !course.manager_check_user_id && (
                <div>
                  <div className="employees-checklist__card">
                    <div className="employees-checklist__dott-done">
                      <i className="fas fa-check" />
                    </div>
                    <div className="employees-checklist__text">
                      <div>Du er ferdig, venter på godkjenning av leder</div>
                    </div>
                  </div>
                </div>
              )) || (
                <div className="employees-checklist__card">
                  <div
                    className={classNames('employees-checklist__dott', {
                      'employees-checklist__dott-disabled': clicked,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                  />

                  <div className="employees-checklist__text">
                    {course.competence.title}
                  </div>
                </div>
              )}
            </div>
            <div
              className="desc"
              dangerouslySetInnerHTML={createSubtitleContent(
                course.competence.short_description
              )}
            />
          </div>
        </>
      )}
      {size === 'dashboard' && (
        <>
          {course.pending_checklists.map(checklist => (

            <div className="employees-checklist__item">
              <div className="employees-checklist__card">
                {type !== 'employee' && (
                  <div className="employees-checklist__avatar">
                    <PersonAvatar person={course} link/>
                  </div>
                )}

                <div
                  className={classNames('employees-checklist__dott', {
                    'employees-checklist__dott-disabled': clicked,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                />
                <div className="employees-checklist__text">
                  {checklist.competence_title}
                  {type !== 'employee' && (
                    <Link
                      key={course.id}
                      to={getEmployeePreviewPath(course.user_name)}>
                      <div className="employees-checklist__text__name">
                        {course.fullname}
                      </div>
                    </Link>
                  )}


                </div>
              </div>
            </div>

          ))}
        </>
      )}
      {size === 'children' && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={classNames([
            'checklist-item',
            { 'checklist-item--passed': passed },
          ])}>
          <div className="checklist-item__status">
            {passed === 100 && <i className="fas fa-check" />}
          </div>
          <div className="checklist-item__title">{title}</div>
        </div>
      )}
      {size === 'normal' && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={classNames([
            'checklist-item',
            { 'checklist-item--passed': passed },
          ])}>
          <div className="checklist-item__status">
            {passed === 100 && <i className="fas fa-check" />}
          </div>
          <div className="checklist-item__title">{title}</div>
        </div>
      )}
    </>
  );
};

CompetenceChecklistItem.defaultProps = {
  size: 'normal',
  typs: '',
  roleId: -2,
  removeRequirement: undefined,
};

CompetenceChecklistItem.propTypes = {
  course: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  removeRequirement: PropTypes.func,
  type: PropTypes.string,
  roleId: PropTypes.number,
  size: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      competencesToggle,
    },
    dispatch
  );

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(CompetenceChecklistItem)
);
