import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContentLoader from 'react-content-loader';

import Progress from '@components/progress/progress';
import CompetenceList from '@components/competences/competence-list';
import CompetenceCourse from '@components/competences/competence-course';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';

import { Link } from 'react-router-dom';
import { getRouteWithParams } from '@utils/routes.utils';
import { employees as employeesRoutes } from '@routes/routes.manifest';
import { getOrganisationId } from '@selectors/profile.selectors';

import '@routes/employees/styles/roles-list.scss';

import CompetenceVerification from '../../../common/components/competences/competence-verification';

class Role extends Component {
  state = {
    expanded: false,
    fetchingRoleId: null,
  };

  constructor(props) {
    super(props);
    this.handleRoleClicked = this.handleRoleClicked.bind(this);
  }

  componentDidMount() {}

  handleRoleClicked() {
    const { role, orgId, personId, type, fetchSubItems } = this.props;
    const { expanded } = this.state;

    let roleId = role.id;
    if (roleId === 0) {
      if (type === 'mandatory') {
        roleId = -2;
      } else {
        roleId = -1;
      }
    }

    if (role.children.length === 0) {
      const { expanded } = role;
      this.setState({ expanded: !expanded });
      this.setState({ fetchingRoleId: roleId });
      fetchSubItems({ roleId, personId, type, orgId });
    } else {
      this.setState({ expanded: !expanded });
    }
  }

  render() {
    const { expanded, fetchingRoleId } = this.state;
    const {
      type,
      isFetching,
      role,
      isSubFetching,
      removeRequirement,
    } = this.props;
    if (!role) return null;

    const { title, passed_count: tasksDone, missing_count: tasksToDo } = role;
    const progress = tasksDone
      ? Math.round((tasksDone / (tasksDone + tasksToDo)) * 100)
      : 0;

    if (!isFetching && fetchingRoleId) {
      /*
       * reset it.
       * */
      this.setState({ fetchingRoleId: null });
    }

    return (
      <div className="roles-list__role">
        <div
          className="roles-list__role-header"
          role="button"
          onClick={() => this.handleRoleClicked()}
          onKeyPress={() => this.handleRoleClicked()}
          tabIndex="0">
          <div className="roles-list__role-title">{title}</div>
          <div className="roles-list__role-progress">
            {type !== 'other' && (
              <Progress
                progress={progress}
                tasksDone={tasksDone}
                tasks={tasksDone + tasksToDo}
                fullWidth
              />
            )}
          </div>
        </div>
        {expanded && (
          <>
            {(isFetching && fetchingRoleId === role.id && (
              <div className="roles-list__loading">henter data...</div>
            )) || (
              <div>
                {role && role.children && role.children.length !== 0 && (
                  <>
                    {role.children.map(course => (
                      <>
                        {(course.competence_type.competence_type ===
                          'ecourse' && (
                          <CompetenceEcourse
                            type="ecourse"
                            size="employee"
                            isFetching={isSubFetching}
                            removeRequirement={removeRequirement}
                            course={course}
                            root="show-employee"
                            roleId={role.id}
                          />
                        )) ||
                          (course.competence_type.competence_type ===
                            'course' && (
                            <CompetenceCourse
                              type="course"
                              size="employee"
                              removeRequirement={removeRequirement}
                              course={course}
                              root="show-employee"
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'verification' && (
                            <CompetenceVerification
                              type="verification"
                              size="employee"
                              removeRequirement={removeRequirement}
                              course={course}
                              root="show-employee"
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'digital_signature' && (
                            <CompetenceSignature
                              type="signature"
                              size="employee"
                              removeRequirement={removeRequirement}
                              course={course}
                              root="show-employee"
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'complex' && (
                            <CompetenceComplex
                              isFetching={isSubFetching}
                              type="complex"
                              size="employee"
                              removeRequirement={removeRequirement}
                              course={course}
                              root="show-employee"
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'equivalents' && (
                            <CompetenceEquivalents
                              isFetching={isSubFetching}
                              type="equivalents"
                              size="employee"
                              course={course}
                              removeRequirement={removeRequirement}
                              root="show-employee"
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'checklist' && (
                            <CompetenceChecklist
                              isFetching={isSubFetching}
                              type="checklist"
                              size="employee"
                              removeRequirement={removeRequirement}
                              root="show-employee"
                              course={course}
                              roleId={role.id}
                            />
                          )) ||
                          (course.competence_type.competence_type ===
                            'checklist_item' && (
                            <CompetenceChecklistItem
                              isFetching={isSubFetching}
                              type="checklist"
                              size="employee"
                              removeRequirement={removeRequirement}
                              root="show-employee"
                              course={course}
                              roleId={role.id}
                            />
                          ))}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

Role.propTypes = {
  role: PropTypes.shape({}).isRequired,
  orgId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  removeRequirement: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchSubItems: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
};

const RoleLoader = props => (
  <div className="roles-list__role__loader">
    <ContentLoader
      title="Laster inn..."
      height="42"
      width="500"
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}>
      <rect x="7" y="11" rx="4" ry="4" width="450" height="20" />
    </ContentLoader>
  </div>
);

const RolesList = ({
  isFetching,
  summary,
  orgId,
  userName,
  personId,
  tabChange,
  tabActive,
  fetchSubItems,
  removeRequirement,
  isSubFetching,
}) => (
  <div className="roles-list">
    {(isFetching && !summary && (
      <div>
        <RoleLoader />
        <RoleLoader />
        <RoleLoader />
      </div>
    )) ||
      (summary && (
        <div>
          {summary.requirement && summary.requirement.length !== 0 && (
            <div className="roles-list__role-section">
              <div className="roles-list__role-section__header">
                <h2>OBLIGATORISK KOMPETANSE</h2>
                <ul className="tabs">
                  <li className={tabActive === 'role' ? 'active' : ''}>
                    <button
                      onClick={() => tabChange('role')}
                      onKeyPress={() => tabChange('role')}
                      tabIndex="0"
                      type="button">
                      Rolle
                    </button>
                  </li>
                  <li className={tabActive !== 'role' ? 'active' : ''}>
                    <button
                      onClick={() => tabChange('activities')}
                      onKeyPress={() => tabChange('activities')}
                      tabIndex="0"
                      type="button">
                      Oppgaver som gjenstår
                    </button>
                  </li>
                </ul>
              </div>
              {(tabActive === 'role' && (
                <>
                  {summary.requirement.map(role => (
                    <Role
                      isSubFetching={isSubFetching}
                      type="mandatory"
                      removeRequirement={removeRequirement}
                      fetchSubItems={fetchSubItems}
                      orgId={orgId}
                      isFetching={summary.isFetching}
                      personId={personId}
                      role={role}
                      key={role.id}
                    />
                  ))}
                </>
              )) || (
                <div>
                  {(summary.activities.isFetching && (
                    <div>Henter data..</div>
                  )) || (
                    <div className="roles-list__role-section">
                      <div className="roles-list__role">
                        <CompetenceList
                          isSubFetching={isSubFetching}
                          size="employee"
                          root="employee-activites"
                          courses={summary.activities.children}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {summary.optional && summary.optional.length !== 0 && (
            <div className="roles-list__role-section">
              <h2>ANBEFALT</h2>
              {summary.optional.map(role => (
                <Role
                  type="optional"
                  orgId={orgId}
                  personId={personId}
                  isFetching={isFetching}
                  fetchSubItems={fetchSubItems}
                  role={role}
                  key={role.id}
                />
              ))}
            </div>
          )}
          {summary.other && Array.isArray(summary.other) !== 0 && (
            <div className="roles-list__role-section">
              <h2>ANNEN GJENNOMFØRT KOMPETANSE</h2>
              {summary.other.map(role => (
                <Role
                  type="other"
                  orgId={orgId}
                  personId={personId}
                  fetchSubItems={fetchSubItems}
                  role={role}
                  key={role.id}
                />
              ))}
            </div>
          )}
          <div className="roles-list__report-link">
            <Link
              to={getRouteWithParams(employeesRoutes.employeeReport, {
                userName,
              })}>
              {' '}
              Full kompetanserapport
            </Link>
          </div>
        </div>
      ))}
  </div>
);

RolesList.propTypes = {
  personId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  removeRequirement: PropTypes.func.isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  fetchSubItems: PropTypes.func.isRequired,
  tabChange: PropTypes.func.isRequired,
  tabActive: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  summary: PropTypes.arrayOf(Object).isRequired,
};

const mapStateToProps = state => ({
  orgId: getOrganisationId(state),
});

export default connect(mapStateToProps)(RolesList);
