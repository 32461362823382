import { employees, courseCatalog } from "@routes/routes.manifest";

export const getRouteWithParams = (url, params) =>
  Object.entries(params).reduce(
    (createdUrl, [paramKey, paramValue]) =>
      createdUrl.replace(`:${paramKey}`, paramValue),
    url
  );

export const getRoutesBreadcrumbsArray = moduleRoutes =>
  Object.values(moduleRoutes)
    .sort(({ index: indexA }, { index: indexB }) => {
      if (indexA === indexB) {
        throw new Error('You must define different indexes for each route');
      }
      return indexA < indexB ? -1 : 1;
    })
    .map(({ path, breadcrumb }) => ({ path, breadcrumb }));

export const getEmployeePreviewPath = userName =>
  getRouteWithParams(employees.employeePreview, {
    userName,
  });

export const getCoursePath = cid =>
  getRouteWithParams(courseCatalog.coursePreview.path, {
    cid,
  });
