export const getAllNews = ({
  news: {
    list: { data },
  },
}) => data;

export const getFetchedNews = ({
  news: {
    fetched: { data },
  },
}) => data;

export const getActiveId = ({ news: { active } }) => active;

export const getActiveNews = ({
  news: {
    fetched: { data },
    active,
  },
}) => active && data.find(({ id }) => id === active);

export const getIsFetchingSelected = ({
  news: {
    fetched: { isFetching },
  },
}) => isFetching;
