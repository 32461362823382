/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

const base = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '1em',
  margin: '0 auto',
  maxWidth: 1280,
};

export const SplitPane = styled('div')(base, {
  label: 'HorizontalSplitPane',
});

const column = {
  position: 'relative',
};

const leftPaneVariants = props => {
  const common = {
    display: 'flex',
  };
  switch (props.variant) {
    case 'end':
      return {
        ...common,
        display: 'flex',
        justifyContent: 'end',
      };

    default:
      return null;
  }
};

export const LeftPane = styled('div')(
  column,
  {
    label: 'LeftPane',
  },
  props => leftPaneVariants(props)
);
export const RightPane = styled('div')(column, {
  label: 'RightPane',
});
