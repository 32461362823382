import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';
import { getPassed, getPassedIds } from './profile.selectors';

export const getCompetences = ({ courses: { competences } }) => ({
  ...competences,
  data:
    Array.isArray(competences.data) &&
    competences.data.map((competence) => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.cmsData && competence.cmsData.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const [cover] = competence.files.filter((file) => file.title === 'cover');
      const durations =
        (competence.durations &&
          competence.durations.length &&
          competence.durations[0].duration) ||
        null; // ToDo: Get dynamic value from API
      const durationType =
        (competence.durations &&
          competence.durations.length &&
          competence.durations[0].type) ||
        null;
      return {
        ...competence,
        cover,
        image:
          (cover && cover.url) ||
          (competence.cmsData && competence.cmsData.image),
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        durationType,
      };
    }),
});

export const getFeeaturedCompetences = (state) => {
  const featuredCompetences = getCompetences({
    courses: { competences: state.courses.featuredCompetences },
  });

  return featuredCompetences;
};

export const getCourseEvents = ({ courses: { courseEvents } }) => courseEvents;

export const getCompetencegroups = ({ courses: { competencegroups } }) =>
  competencegroups;

export const getCompetencetypes = ({ courses: { competencetypes } }) =>
  competencetypes;

export const getSelectedCatalogView = ({
  courses: {
    filters: { catalogView },
  },
}) => catalogView;

export const getSelectedCompetencegroupId = ({
  courses: { selectedCompetencegroupId },
}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({
  courses: { selectedSubcompetencegroupId },
}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({
  courses: { selectedSubSubcompetencegroupId },
}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({ courses: { courseSignOn } }) =>
  courseSignOn;

export const getSigningOffCourse = ({ courses: { courseSignOff } }) =>
  courseSignOff;

export const getCompetencesSearchTerm = ({
  courses: {
    competences: { searchTerm },
  },
}) => searchTerm;

export const getCoursesSorting = ({ courses: { sorting } }) => sorting;

export const getCourseFilters = ({ courses }) => courses.filters;

export const getSelectedCompetencetypes = ({
  courses: {
    filters: { selectedCompetencetypes },
  },
}) =>
  Object.keys(selectedCompetencetypes).reduce(
    (ids, id) => (selectedCompetencetypes[id] && [...ids, id]) || ids,
    []
  );

export const getSelectedCompetencetypesNames = ({
  courses: { competencetypes, selectedCompetencetypes },
}) => {
  return competencetypes.data
    ? new Set(
        competencetypes.data
          .filter(({ id }) => selectedCompetencetypes.has(id))
          .map(({ competence_type }) => competence_type)
      )
    : new Set();
};

export const getCompetenceDetails = ({ courses: { competenceDetails } }) => ({
  ...competenceDetails,
  data:
    competenceDetails.data &&
    ((competence) => {
      let cover;
      let duration;
      if (
        Array.isArray(competence.files) &&
        competence.files.filter((file) => file.title === 'cover').length
      ) {
        [cover] = competence.files.filter((file) => file.title === 'cover');
      }
      if (Array.isArray(competence.durations) && competence.durations.length) {
        [duration] = competence.durations;
      }
      return {
        ...competence,
        cover,
        duration: duration && {
          value: duration.duration,
          metric: duration.type,
        },
      };
    })(competenceDetails.data),
});

export const getActiveCourse = ({ courses: { activeCourse } }) => activeCourse;

export const getSignOnCourseResults = ({
  courses: {
    courseSignOn: { results, isFetching },
  },
}) => ({ results, isFetching });

export const getCurrentSignature = ({ courses: { currentSignature } }) =>
  currentSignature;

export const getSignCourse = ({ courses: { courseSignCourse } }) =>
  courseSignCourse;

export const getMapCoursesData = ({
  courses: {
    mapCourses: { data },
  },
}) => data;

export const getSelectedMapCourse = (state) =>
  state.courses.selectedMapCourse.data;

export const getMapCourses = (state) => state.courses.mapCourses;

export const getMapCoursesInfo = ({
  courses: {
    mapCoursesInfo: { data },
  },
}) => data;

export const getIsAllMapDotsCompleted = (state) => {
  const tracks =
    state.courses.mapCourses.data && state.courses.mapCourses.data.tracks;

  return (
    tracks &&
    tracks.every((track) => track.dotts.every((dott) => dott.status === 'DONE'))
  );
};

export const getIsMapVerified = (state) => {
  const configObject = getConfigObject(state);
  const configForCurrLangAndMap = getPropertiesForCurrLangAndTrack(state);
  if (!configObject.data) {
    return null;
  }

  const verifyCourseId = configForCurrLangAndMap.atlas.verifyId;
  if (!verifyCourseId) {
    return null;
  }

  if (
    state.courses &&
    state.courses.mapCourses &&
    state.courses.mapCourses.mapIsVerified === true
  ) {
    return true;
  }

  return false;
};

export const getIsFirstMapDotCompleted = (state) => {
  const tracks =
    state.courses.mapCourses.data && state.courses.mapCourses.data.tracks;

  return tracks && tracks[0].dotts[0].status === 'DONE';
};

export const getOutroIsCompleted = (state) => {
  const configForCurrLangAndMap = getPropertiesForCurrLangAndTrack(state);
  if (!configForCurrLangAndMap) {
    return null;
  }

  const outroCompetenceId = configForCurrLangAndMap.atlas.outroId;
  if (!outroCompetenceId) {
    return null;
  }

  if (
    state.courses &&
    state.courses.mapCourses &&
    state.courses.mapCourses.outroIsDone === true
  ) {
    return true;
  }

  return false;
};

export const getShouldShowMapInsteadOfCourseCatalog = (state) => {
  const mapIsVerified = getIsMapVerified(state);
  const outroIsDone = getOutroIsCompleted(state);

  const passed = getPassed(state);
  if (!passed.data) {
    return null;
  }

  if (mapIsVerified === null || outroIsDone === null) {
    return null;
  }

  return mapIsVerified === false || outroIsDone === false;
};

export const getInitializeMyCoursesView = (state) => {
  return state.courses.initializeMyCoursesView;
};
