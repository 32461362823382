import React from 'react';
import PropTypes from 'prop-types';

import { PersonShape } from '@types/person';

import '@routes/employees/styles/employees-list.scss';

import EmployeeCard, {
  EmployeeCardLoading,
} from '@routes/employees/components/employee-card';

const EmployeesList = ({
  employees,
  hasMore,
  onEmployeeSelect,
  onEmployeeMore,
  isFetching,
}) => (
  <div className="employees-list">
    {employees.map(employee => (
      <div
        className={`employees-list__card-wrapper${
          employee.is_absent ? ' absent' : ''
        }`}
        key={employee.user_name}>
        <EmployeeCard
          employee={employee}
          onAvatarClick={() => onEmployeeSelect(employee)}
        />
      </div>
    ))}
    {hasMore && (
      <>
        {(isFetching && (
          <div className="info__chip">
            <div className="chip show-more chip--loading">
              <i className="fas fa-spin fa-spinner" />
            </div>
          </div>
        )) || (
          <div className="info__chip">
            <button
              type="button"
              className="chip show-more"
              onClick={onEmployeeMore}>
              ...
            </button>
          </div>
        )}
      </>
    )}
  </div>
);

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(PersonShape()).isRequired,
  onEmployeeSelect: PropTypes.func.isRequired,
  onEmployeeMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default EmployeesList;

export const EmployeesListLoading = () => (
  <div className="employees-list">
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
  </div>
);
