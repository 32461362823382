import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  profileFetchPersonSummary,
  fetchExpiring,
} from '@actions/profile.actions';
import { getPassedCompetencesGroupedByType } from '@selectors/profile.selectors';

import { routerMyEducationMainViewDidMount } from '@actions/router.actions';

import '../styles/my-education-container.scss';
import { messagesGetMessages } from '@actions/messages.actions';

import { ProfileBaseInformation, Trail } from '@routes/my-education';
import NewsList from '@components/news-list/news-list';
import { employeesFetchSelectedPersonExpiring } from '@actions/employees.actions';

class MyEducationContainer extends Component {
  componentDidMount() {
    const {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      fetchExpiring,
      messagesGetMessages,
    } = this.props;
    routerMyEducationMainViewDidMount();
    profileFetchPersonSummary();
    fetchExpiring();
    messagesGetMessages();
  }

  render() {
    return (
      <div className="my-education">
        <div className="my-education__section">
          <Trail />
          <NewsList />
          <ProfileBaseInformation />
        </div>
      </div>
    );
  }
}

MyEducationContainer.propTypes = {
  passedCompetencesGroupedByType: PropTypes.shape({}).isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  routerMyEducationMainViewDidMount: PropTypes.func.isRequired,
  profileFetchPersonSummary: PropTypes.func.isRequired,
  messagesGetMessages: PropTypes.func.isRequired,
  fetchExpiring: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      fetchExpiring,
      messagesGetMessages,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyEducationContainer
);
