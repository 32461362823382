import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import reports from '@routes/reports.manifest';

import '@routes/employees/styles/employees-report.scss';
import { getMainOrganisations } from '@selectors/profile.selectors';

import { backendUrl } from '@config';

const getReportTitle = reportId => !!reportId && reportId in reports && reports[reportId].name;
const getReportCti = reportId => !!reportId && reportId in reports && reports[reportId].cti;

class EmployeesReport extends Component {
  state = {
    doPrint: false,
    iframeIndex: 0,
  };

  reportPrint = React.createRef();

  componentDidMount() {
    const { onInit } = this.props;
    if (onInit) {
      onInit();
    }
  }

  print = () => {
    const { iframeIndex } = this.state;

    this.setState({ doPrint: true, iframeIndex: iframeIndex + 1 });
  };

  render() {
    const { report, mainOrganisation } = this.props;
    const { doPrint, iframeIndex } = this.state;
    return (
      <div className="employees-report">
        {(report.isFetching && <div>Laster rapport...</div>) ||
          (report.data && (
            <>
              <h1 className="employees-report__title">
                {mainOrganisation && mainOrganisation.title}
                {' - '}
                {report.data.reportTitle}
              </h1>
              <div className="employees-report__buttons">
                <a
                  href={`${backendUrl}/custom/custompdf/pdf.mak?organisation_id=${
                    mainOrganisation.id
                  }&competence_ids=${
                    report.data.reportCids
                  }&title=${window.encodeURIComponent(
                    report.data.reportTitle
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="far fa-file-pdf" /> PDF
                </a>

              </div>
              <div className="courseReport">
                <table border="1" id="ts_tbl">
                  <tr>
                    <th className="legendcell">
                      <div className="legend">
                        <div className="line">
                          <span className="label">Mangler</span>
                          <span className="mandatory">&nbsp;</span>
                        </div>
                        <div className="line">
                          <span className="label">Fullført</span>
                          <span className="mandatory ok">OK</span>
                        </div>
                      </div>
                    </th>
                    {report.data.courses.map(course => (
                      <th>
                        <div className="box_rotate_col">{course.name}</div>
                      </th>
                    ))}
                  </tr>
                  {report.data.persons.map(person => (
                    <tr key={person.id}>
                      <td className="col_name">{person.fullname}</td>
                      {person.courses.map(personCourse => (
                        <td
                          className={personCourse.class}
                          key={personCourse.id}>
                          <div className="dott">
                            {personCourse.passed ? personCourse.score : ''}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </table>
              </div>
            </>
          ))}
        {doPrint && (
          <iframe
            key={iframeIndex}
            ref={this.reportPrint}
            src={`${backendUrl}/templates/widgets/pdf?organisation_id=${
              mainOrganisation.id
            }&competence_ids=${getReportCti(
              report.data.reportId
            )}&title=${window.encodeURIComponent(
              getReportTitle(report.data.reportId)
            )}&print=1`}
            title="reportPrint"
            style={{
              float: 'left',
              width: '1px',
              height: '1px',
              visibility: 'hidden',
              display: 'block',
              margin: '-1px',
            }}
          />
        )}
      </div>
    );
  }
}

EmployeesReport.propTypes = {
  report: PropTypes.shape({}).isRequired,
  mainOrganisation: PropTypes.shape({}).isRequired,
  onInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  mainOrganisation: getMainOrganisations(state),
});

export default connect(mapStateToProps)(EmployeesReport);
