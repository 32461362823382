/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { mq, or } from '@styles/facepaint';
import { highlightOnKeyboardFocusMixinExtra } from '@styles/mixins';
import { motion } from 'framer-motion';

const wrapperBorderRadius = '5px';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
  height: 100%;
  flex: 50% 1 1;

  background: ${(props) => props.background};
  border-radius: 5px;
  border-radius: ${wrapperBorderRadius};

  ${(props) =>
    props.fullwidth
      ? css`
          width: 100%;
          grid-column: 1 / -1;
        `
      : ''}

  :hover {
    cursor: pointer;
  }
`;

export const ImageBgOverlay = styled.div`
  opacity: ${(props) => props.opacity};
  background: ${(props) => props.background};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  flex: 50% 1 1;
  height: 100%;
  position: relative;
`;
export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  object-position: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;
export const TextWrapper = styled.div`
  padding: 0.5em;
  color: ${(props) => (props.colorScheme === 'light' ? 'white' : 'black')};
  flex: 50% 1 1;
  height: 100%;
`;
export const PrimaryText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.95em;
`;
export const SecondaryText = styled.p`
  background: rgba(230, 230, 231, 0.56);
  border-radius: 20px;
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 0.7em;
  margin: 0;
  margin-top: 0.3em;
`;

const grid = css({
  display: 'grid',
  gridAutoFlow: 'dense',
});

export const MasonryGrid = ({
  className,
  minItemWidth = '200px',
  minItemHeight = '190px',
  minItemWidthSmall = '150px',
  minItemWidthTiny = '130px',
  gap = 17,
  padding = 10,
  children,
}) => {
  return (
    <div
      className={className}
      css={[
        grid,
        {
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            typeof minItemWidth === 'number'
              ? `${minItemWidth}px`
              : minItemWidth
          }, 1fr))`,
          gridAutoRows: `minmax(${
            typeof minItemHeight === 'number'
              ? `${minItemHeight}px`
              : minItemHeight
          }, auto)`,
          gridGap: gap,
          padding,
        },
        {
          '@media (max-width: 500px)': {
            gridTemplateColumns: `repeat(auto-fill, minmax(${
              typeof minItemWidthSmall === 'number'
                ? `${minItemWidthSmall}px`
                : minItemWidthSmall
            }, 1fr))`,
          },
        },
        {
          '@media (max-width: 365px)': {
            gridTemplateColumns: `repeat(2, 1fr)`,
          },
        },
      ]}>
      {children}
    </div>
  );
};

export const Item = ({
  className,
  h = 1,
  v = 1,
  children,
  fullwidth,
  onlydDisplayIfSmaller = null,
  mediaQueryGridElementSizeOverride,
  mediaQueryStyleOverride,
  ...props
}) => {
  const style = {
    gridColumnEnd: `span ${h}`,
    gridRowEnd: `span ${v}`,
    '&:hover': { cursor: 'pointer' },
  };
  if (fullwidth) {
    style.gridColumnEnd = 'span 2';
    style.display = 'none';
    style[`@media (max-width: 653px)`] = {
      display: 'flex',
    };
  }
  if (mediaQueryStyleOverride) {
    style[
      `@media (min-width: ${mediaQueryStyleOverride.minWidth}px) and (max-width: ${mediaQueryStyleOverride.maxWidth}px)`
    ] = {
      ...mediaQueryStyleOverride.style,
    };
  }
  if (mediaQueryGridElementSizeOverride) {
    style[
      `@media (min-width: ${mediaQueryGridElementSizeOverride.minWidth}px) and (max-width: ${mediaQueryGridElementSizeOverride.maxWidth}px)`
    ] = {
      gridColumnEnd: `span ${mediaQueryGridElementSizeOverride.h}`,
      gridRowEnd: `span ${mediaQueryGridElementSizeOverride.v}`,
    };
  }
  return (
    <div
      role="button"
      tabIndex={0}
      className={className}
      onKeyDown={(evt) => {
        if (evt.key === 'Enter' && props.onClick) {
          props.onClick(evt);
        }
      }}
      css={[style, highlightOnKeyboardFocusMixinExtra]}
      {...props}>
      {children}
    </div>
  );
};
