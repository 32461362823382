const prefix = '[NEWS]';

export const NEWS_GET_ALL = `${prefix} news get all`;
export const NEWS_GET_ALL_REQUEST = `${prefix} news get all request`;
export const NEWS_GET_ALL_SUCCESS = `${prefix} news get all success`;
export const NEWS_GET_ALL_FAILURE = `${prefix} news get all failure`;

export const newsGetAll = () => ({
  type: NEWS_GET_ALL,
});
export const newsGetAllRequest = () => ({
  type: NEWS_GET_ALL_REQUEST,
});
export const newsGetAllSuccess = payload => ({
  type: NEWS_GET_ALL_SUCCESS,
  payload,
});
export const newsGetAllFailure = payload => ({
  type: NEWS_GET_ALL_FAILURE,
  payload,
});

export const NEWS_GET = `${prefix} news get`;
export const NEWS_GET_REQUEST = `${prefix} news get request`;
export const NEWS_GET_SUCCESS = `${prefix} news get success`;
export const NEWS_GET_FAILURE = `${prefix} news get failure`;

export const newsGet = payload => ({
  type: NEWS_GET,
  payload,
});
export const newsGetRequest = () => ({
  type: NEWS_GET_REQUEST,
});
export const newsGetSuccess = payload => ({
  type: NEWS_GET_SUCCESS,
  payload,
});
export const newsGetFailure = payload => ({
  type: NEWS_GET_FAILURE,
  payload,
});

export const NEWS_SET_ACTIVE = `${prefix} news set active`;
export const NEWS_CLEAR_ACTIVE = `${prefix} news clear active`;

export const newsSetActive = payload => ({
  type: NEWS_SET_ACTIVE,
  payload,
});
export const newsClearActive = () => ({
  type: NEWS_CLEAR_ACTIVE,
});
