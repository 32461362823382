import React from 'react';
import PropTypes from 'prop-types';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './form-ckeditor.scss';

const CKEditorField = ({ label, input, meta }) => (
  <div className="form-ckeditor">
    <div className="form-ckeditor__label">{label}</div>
    <CKEditor
      className="form-ckeditor__editor"
      editor={ClassicEditor}
      data={input.value}
      onChange={(_, editor) => {
        const message = editor.getData();
        input.onChange(message);
      }}
    />
    {meta.touched && meta.error && <div className="form-input__error">{meta.error}</div>}
  </div>
);

CKEditorField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
};

export default CKEditorField;
