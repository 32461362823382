import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';

import Modal from '@components/modal/modal';
import FormInput from '@components/form-input';
import EditPasswordForm from '@routes/employees/components/edit-password-form';

import './person-form.scss';

const validateRequired = input => {
  if (!input || !input.trim()) {
    return 'Må fylles ut';
  }

  return undefined;
};

const validateMobile = input => {
  if (!input || !input.trim()) {
    return undefined;
  }
  const number = input
    .replace(/^\s*(?:\+|00)(?:45|46|47|358|354)\s*/g, '')
    .trim();
  const digits = number.match(/\d/g);
  if (
    !digits ||
    !/^[\s\d]+$/g.test(number) ||
    digits.length < 5 ||
    digits.length > 12
  ) {
    return 'Ugyldig telefonnummer';
  }

  return undefined;
};

export const PersonFormLoading = () => (
  <div className="person-form">
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="email"
        input={{}}
        meta={{}}
        disabled
      />
    </div>

    <div className="person-form__input-wrapper">
      <FormInput
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>

    <div className="person-form__submit-button-wrapper">
      <button className="btn btn--flat" type="button" disabled>
        Avbryt
      </button>
      <button className="btn" type="button" disabled>
        Lagre
      </button>
    </div>
  </div>
);

const PersonForm = ({ handleSubmit, history, manager, onEditPassword, isMobile }) => {
  const [setResetPasswordModalVisible] = useState(false);
  const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(
    false
  );

  const escapeListener = ({ key }) => {
    if (key === 'Escape') {
      setEditPasswordModalVisible(false);
    }
  };

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'reset') {
      setResetPasswordModalVisible(visibility);
    } else if (type === 'edit') {
      setEditPasswordModalVisible(visibility);
    }

    toggleEscapeListener(visibility);
  };

  const handleEditPassword = data => {
    onEditPassword({ ...data, user: data.user_name });
    setModalVisibility('edit', false);
  };

  return (
    <form onSubmit={handleSubmit} className="person-form">
      <div className="person-form__input-wrapper">
        <Field
          autoComplete="name"
          className="person-form__input"
          component={FormInput}
          label="Fornavn"
          name="firstname"
          type="text"
          validate={[validateRequired]}
        />
      </div>
      <div className="person-form__input-wrapper">
        <Field
          autoComplete="surname"
          className="person-form__input"
          component={FormInput}
          label="Etternavn"
          name="lastname"
          type="text"
          validate={[validateRequired]}
        />
      </div>
      <div className="person-form__input-wrapper">
        <Field
          autoComplete="email"
          className="person-form__input"
          component={FormInput}
          label="E-post"
          name="email"
          type="email"
          validate={[validateRequired]}
        />
      </div>
      <div className="person-form__input-wrapper">
        <Field
          className="person-form__input"
          component={FormInput}
          label="Telefon"
          name="mobile"
          type="text"
          validate={[validateMobile]}
        />
      </div>

      {!manager && (
        <div>
          <button
            className="employee-preview__add-menu-item"
            type="button"
            onClick={() => setModalVisibility('edit', true)}>
            <i className="fas fa-pencil-alt task-list__item-icon" />
            Endre passord
          </button>
        </div>
      )}
      {editPasswordModalVisible && (
        <Modal onBackdropClick={() => setModalVisibility('edit', false)}>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">Endre passord</h2>
            <EditPasswordForm
              isMobile={isMobile}
              managerForm={false}
              onSubmit={handleEditPassword}
              onCancel={() => setModalVisibility('edit', false)}
            />
          </div>
        </Modal>
      )}
      <div className="person-form__submit-button-wrapper">
        <button
          className="btn btn--flat"
          type="button"
          onClick={history.goBack}>
          Avbryt
        </button>
        <button className="btn" type="submit">
          Lagre
        </button>
      </div>
    </form>
  );
};

PersonForm.defaultProps = {
  manager: false,
  isMobile: false,
};

PersonForm.propTypes = {
  manager: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onEditPassword: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default compose(
  withRouter,
  reduxForm({
    form: 'person',
    enableReinitialize: true,
    updateUnregisteredFields: true,
  })
)(PersonForm);
