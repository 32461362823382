import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '@routes/employees/styles/events-list.scss';

import PersonAvatar from '@components/person-avatar/person-avatar';
import { getCoursePath, getEmployeePreviewPath } from '@utils/routes.utils';

import Moment from 'react-moment';
import moment from 'moment';
import classNames from 'classnames';
import CourseSignButton from '@components/course-sign-button/course-sign-button';

class EventsList extends Component {
  renderEvent = ({ event }) => {
    const { type } = this.props;

    return (
      <>
      <div className="events-list__text">
        <Link to={getCoursePath(event.competence_id)}>
          {(event.title && (
            <div className="events-list__text__title">{event.title}</div>
          )) || (
            <div className="events-list__text__title">
              {event.competence_title}
            </div>
          )}
        </Link>
        {type !== 'employee' && (
          <Link to={getEmployeePreviewPath(event.person.user_name)}>
            <div className="events-list__person-name">
              {event.person.fullname}
            </div>
          </Link>
        )}
        <div className="events-list__text__description">
          {event.locationTitle}
        </div>
        {event.location && event.location.title && (
          <div className="events-list__text__location">
            {event.location.title}
          </div>
        )}
        <div className="events-list__text__description">
          {moment(event.startdate).format('HH:mm')}-
          {moment(event.enddate).format('HH:mm')}
        </div>

      </div>
        <CourseSignButton
          courseEvent={event}
          signOff
          isBusy
          usersCourseEvent={event}
        />
      </>
    );
  };

  renderEvents = events => {
    const { type } = this.props;

    let currentUser = null;
    let currentDate = null;
    let lastEvent = null;
    let cache = [];

    const renderNode = children => (
      <div className="events-list__item">
        <div className="events-list__date">
          <Moment
            className="events-list__date__day"
            locale="nb"
            interval={0}
            format="DD">
            {lastEvent.startdate}
          </Moment>
          <Moment
            className="events-list__date__month"
            locale="nb"
            interval={0}
            format="MMM">
            {lastEvent.startdate}
          </Moment>
        </div>
        <div className="events-list__card">
          {type !== 'employee' && (
            <div className="events-list__avatar">
              <PersonAvatar person={lastEvent.person} link />
            </div>
          )}
          <div className="events-list__card__contents">{children}</div>
        </div>
      </div>
    );

    return events.reduce((list, event, index) => {
      const { person } = event;

      const date = moment(event.startdate).format('DD.MM.YYYY');

      let node = null;
      if (
        cache.length &&
        (currentUser !== (person && person.user_name) || currentDate !== date)
      ) {
        node = renderNode(cache);
        cache = [];
      }
      cache.push(this.renderEvent({ event }));
      lastEvent = event;
      currentUser = person && person.user_name;
      currentDate = date;
      const next = list;
      if (node) {
        next.push(node);
      }
      if (index === events.length - 1) {
        next.push(renderNode(cache));
      }
      return next;
    }, []);
  };

  render() {
    const { events } = this.props;
    return <div className="events-list">{this.renderEvents(events)}</div>;
  }
}

EventsList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string,
};

EventsList.defaultProps = {
  type: '',
};

export default EventsList;
