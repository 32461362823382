import React from 'react';
/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { base, Background, wrapper } from '@routes/atlas/components/Map/styles';
import { getIsMobile } from '@selectors/global.selectors';
import { useSelector } from 'react-redux';

export const MapBounding = ({ children, ...props }) => {
  const isMobile = useSelector(getIsMobile);

  return (
    <Background isMobile={isMobile} {...props}>
      <div css={wrapper}>
        <div css={base}>{children}</div>
      </div>
    </Background>
  );
};
