import defaultTheme from '@styles/themes/default.scss';
import blueTheme from '@styles/themes/blue.scss';
import greenTheme from '@styles/themes/green.scss';
import yellowTheme from '@styles/themes/yellow.scss';
import redTheme from '@styles/themes/red.scss';
import lightGreenTheme from '@styles/themes/lightGreen.scss';

const styleNode = document.createElement('style');

export const themes = {
  Kontor: 6,
  Matkroken: 10,
  CoopPrix: 11,
  CoopMega: 12,
  Extra: 13,
  CoopMarked: 14,
  Annetformat: 15,
  ObsHypermarked: 16,
  ObsBygg: 17,
  Byggmix: 18,
  ExtraBygg: 19,
  Samvirkelag: 20,
  Faghandel: 21,
  Medlemsvalgtportal: 22,
  Elektro: null, // ?
};

export const parseTheme = theme =>
  Object.keys(theme).reduce(
    (obj, prop) => Object.assign({}, obj, { [`--${prop}`]: theme[prop] }),
    {}
  );

export const getDefaultTheme = () => parseTheme(defaultTheme);

export const getTheme = id => {
  let selectedTheme;

  switch (Number(id)) {
    default:
    case themes.ObsHypermarked:
    case themes.ObsBygg:
    case themes.ExtraBygg:
    case themes.Faghandel:
    case themes.Medlemsvalgtportal:
      selectedTheme = blueTheme;
      break;
    case themes.Extra:
    case themes.Matkroken:
    case themes.CoopMarked:
      selectedTheme = redTheme;
      break;
    case themes.CoopPrix:
    case themes.Elektro:
      selectedTheme = yellowTheme;
      break;
    case themes.CoopMega:
      selectedTheme = greenTheme;
      break;
    case themes.Byggmix:
      selectedTheme = lightGreenTheme;
      break;
  }

  return {
    ...parseTheme(defaultTheme),
    ...parseTheme(selectedTheme),
  };
};

export const createStyles = themeVariables => {
  return `
    :root {
      ${Object.keys(themeVariables)
        .map(propName => `${propName}: ${themeVariables[propName]};`)
        .join(' ')}
    }`;
};

export const appendThemeStyles = themeVariables => {
  const styles = createStyles(themeVariables);

  styleNode.type = 'text/css';
  styleNode.innerHTML = styles;
  document.head.appendChild(styleNode);
};

appendThemeStyles(getDefaultTheme());
