import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Progress from '@components/progress/progress';
import PersonAvatar from '@components/person-avatar/person-avatar';

const PersonInfo = ({ profileData }) => (
  <div className="person">
    <div className="person__top-section">
      <div className="person__avatar-wrapper">
        <div style={{width: 68, position: 'absolute', top:-4, left:-4}}>
        <CircularProgressbar value={profileData.competencelevel} strokeWidth={3} />
      </div>
        <PersonAvatar
          person={profileData}
          className="person-avatar__avatar"
          editable
          size="60px"
        />
      </div>
    </div>
  </div>
);

PersonInfo.propTypes = {
  profileData: PropTypes.object.isRequired,
};

export default PersonInfo;
