const prefix = 'auth';
export const AUTH_LOGIN_REQUEST = `${prefix} LOGIN_REQUEST`;
export const AUTH_LOGIN_SUCCESS = `${prefix} LOGIN_SUCCESS`;
export const AUTH_LOGIN_SUCCESS_CHOICE = `${prefix} LOGIN_SUCCESS/BUT/SELECT`;
export const AUTH_LOGIN_FAILURE = `${prefix} LOGIN_FAILURE`;
export const AUTH_LOGOUT_REQUEST = `${prefix} LOGOUT_REQUEST`;
export const AUTH_LOGOUT_SUCCESS = `${prefix} LOGOUT_SUCCESS`;
export const AUTH_UNAUTHORIZED = `${prefix} AUTH_UNAUTHORIZED`;

export const AUTH_CHECK_LOGIN_METHOD = `${prefix}/AUTH/CHECK-LOGIN-METHOD/REQUEST`;
export const AUTH_CHECK_LOGIN_METHOD_REQUEST = `${prefix}/AUTH/CHECK-LOGIN-METHOD/REQUEST`;
export const AUTH_CHECK_LOGIN_METHOD_SUCCESS = `${prefix}/AUTH/CHECK-LOGIN-METHOD/SUCCESS`;
export const AUTH_CHECK_LOGIN_METHOD_FAILURE = `${prefix}/AUTH/CHECK-LOGIN-METHOD/FAILURE`;

export const authVerifyUser = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD,
  payload,
});

export const authVerifyUserRequest = () => ({
  type: AUTH_CHECK_LOGIN_METHOD_REQUEST,
});

export const authVerifyUserSuccess = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  payload,
});

export const authVerifyUserFailure = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD_FAILURE,
  payload,
});

export const AUTH_GOTO_NEXT = `${prefix} AUTH_GOTO_NEXT`;

export const AUTH_LOGIN_GOTO_ON = `${prefix}/AUTH_LOGIN_GOTO_ON`;

export const AUTH_LOGIN_NEW_PASSWORD_REQUEST = `${prefix}/NEWPASSWORD/REQUEST`;
export const AUTH_LOGIN_NEW_PASSWORD_SUCCESS = `${prefix}/NEWPASSWORD/SUCCESS`;
export const AUTH_LOGIN_NEW_PASSWORD_FAILURE = `${prefix}/NEWPASSWORD/FAILURE`;

export const authGotoOn = () => ({
  type: AUTH_LOGIN_GOTO_ON,
});

export const authLoginRequest = payload => ({
  type: AUTH_LOGIN_REQUEST,
  payload,
});

export const authLoginNewPasswordSuccess = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_SUCCESS,
  payload,
});

export const authLoginNewPasswordFailure = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_FAILURE,
  payload,
});

export const authLoginNewPasswordRequest = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_REQUEST,
  payload,
});

export const authGotoNext = payload => ({
  type: AUTH_GOTO_NEXT,
  payload,
});

export const authLoginChoice = payload => ({
  type: AUTH_LOGIN_SUCCESS_CHOICE,
  payload,
});

export const authLoginSuccess = payload => ({
  type: AUTH_LOGIN_SUCCESS,
  payload,
});

export const authLoginFailure = payload => ({
  type: AUTH_LOGIN_FAILURE,
  payload,
});

export const authLogoutRequest = () => ({
  type: AUTH_LOGOUT_REQUEST,
});

export const authLogoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
});

export const authUnauthorized = payload => ({
  type: AUTH_UNAUTHORIZED,
  payload,
});
