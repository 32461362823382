export const strs = {
  OPEN: 'OPEN',
  START_TRAINING: 'START_TRAINING',
  PART: 'PART',
  GOTO_HOME: 'GOTO_HOME',
  LOCKED: 'LOCKED',
  GO_TO_TRAINERCOURSES: 'GO_TO_TRAINERCOURSES',
  VERIFICATION_AGREEMENT: 'VERIFICATION_AGREEMENT',
  LOADING_WITH_ELIPSIS: 'LOADING_WITH_ELIPSIS',
  FINISH: 'FINISH',
  COURSE_CATALOG: 'COURSE_CATALOG',
  TRAINER_CERTIFICATE: 'TRAINER_CERTIFICATE',
  ATLAS: 'ATLAS',
  VERIFICATION_PROGRESS_BAR_ARIA_LABEL: 'VERIFICATION_PROGRESS_BAR_ARIA_LABEL',
};

const strMap = {
  OPEN: {
    no: 'åpne',
    en: 'open',
  },
  START_TRAINING: {
    no: 'Start',
    en: 'Start',
  },
  PART: {
    no: 'del',
    en: 'part',
  },
  GO_TO_TRAINERCOURSES: {
    no: 'gå til Trenerattest',
    en: 'Go to Course',
  },
  LOG_OUT: {
    no: 'logg ut',
    en: 'log out',
  },
  GOTO_HOME: {
    no: 'Gå til Ressursside',
    en: 'Go to Resource Page',
  },
  LOCKED: {
    no: 'låst',
    en: 'locked',
  },
  VERIFICATION_AGREEMENT: {
    no: 'jeg bekrefter å ha lest, gjennomført og er kjent med innholdet.',
    en: 'I confirm to have read, completed and am familiar with the content.',
  },
  LOADING_WITH_ELIPSIS: {
    no: 'Laster ...',
    en: 'Loading...',
  },
  FINISH: {
    no: 'fullfør',
    en: 'Complete',
  },
  COURSE_CATALOG: {
    no: 'kurskatalog',
    en: 'course catalog',
  },
  TRAINER_CERTIFICATE: {
    no: 'Trenerattest',
    en: 'Trainer Certificate',
  },
  ATLAS: {
    no: 'Kart',
    en: 'Map',
  },
  VERIFICATION_PROGRESS_BAR_ARIA_LABEL: {
    no: 'Fremdrift',
    en: 'Progres',
  },
};

const capitalize = (str) => str.slice(0, 1).toUpperCase() + str.slice(1);

export const MISSING_TRANSLATION = '[missing translation]';

export const i18n = (key, capitalized = true) => {
  const lang = localStorage.getItem('language') || 'no';

  const target = strMap[key] && strMap[key][lang];
  if (!target) {
    console.error(`Missing i8n for key ${key} and lang ${lang}`);
    return MISSING_TRANSLATION;
  }

  if (capitalized) {
    return capitalize(target);
  }
  return target;
};
