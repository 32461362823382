import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import { backendUrl } from '@config';

import { authUnauthorized } from '@actions/auth.actions';
import {
  MESSAGES_GET_MESSAGES,
  messagesGetRequest,
  messagesGetSuccess,
  messagesGetFailure,
  MESSAGES_SEND_MESSAGE,
  messagesSendMessagePostRequest,
  messagesSendMessagePostSuccess,
  messagesSendMessagePostFailure,
} from '@actions/messages.actions';
import { notificationsAdd } from '@actions/notifications.actions';

import retry from '@utils/sagas.utils';

export function* getMessages() {
  yield put(messagesGetRequest());

  try {
    const {
      data: { messages },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {
          fields: 'ts,message_id',
        },
        url: `${backendUrl}/api/messages`,
        withCredentials: true,
      })
    );
    yield put(
      messagesGetSuccess({
        messages,
      })
    );
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(messagesGetFailure({ error }));
  }
}

function* sendMessage(action) {
  const { username: user_name, title, emailBody, callback } = action.payload;
  yield put(messagesSendMessagePostRequest());

  try {
    yield all({
      email: call(() =>
        axios.request({
          method: 'POST',
          url: `${backendUrl}/api/messages`,
          params: {
            user_name,
            title,
            text: emailBody,
            send_medium: 'email',
          },
          withCredentials: true,
        })
      ),
    });

    yield callback({ employee: { user_name } });

    yield put(messagesSendMessagePostSuccess());
    yield put(
      notificationsAdd({
        notification: {
          text: 'Melding sendt',
          color: 'green',
        },
      })
    );
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(messagesSendMessagePostFailure({ error }));
    yield put(
      notificationsAdd({
        notification: {
          text: 'Error',
          color: 'red',
        },
      })
    );
    yield callback({ employee: { user_name } });
  }
}

export default [
  takeLatest(MESSAGES_GET_MESSAGES, getMessages),
  takeLatest(MESSAGES_SEND_MESSAGE, sendMessage),
];
