import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './progress.scss';

const Progress = ({
  progress,
  tasksDone,
  tasks,
  taskName,
  fullWidth,
  barOnly,
  className,
  label,
  loading,
}) => (
  <div className={`progress ${className}`}>
    {!barOnly &&
      ((!!tasks &&
        ((loading && <div className="progress__progress">- av -</div>) || (
          <div className="progress__progress">
            {taskName}:&nbsp;&nbsp;
            {tasksDone}
            &nbsp;av&nbsp;
            {tasks}
          </div>
        ))) ||
        ((loading && <div className="progress__progress">-</div>) || (
          <div className="progress__progress">{`${Math.round(progress)}%`}</div>
        )))}
    {label && <div className="progress__label">{label}</div>}
    <div
      className={classNames({
        'progress__bar-overall': true,
        'progress__bar-overall--full-width': fullWidth,
      })}>
      <div
        className="progress__bar-complete"
        style={{ width: `${progress}%` }}
      />
    </div>
  </div>
);

Progress.propTypes = {
  progress: PropTypes.number,
  tasksDone: PropTypes.number,
  tasks: PropTypes.number,
  taskName: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  barOnly: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

Progress.defaultProps = {
  progress: 0,
  tasksDone: null,
  tasks: null,
  taskName: 'Oppgaver',
  fullWidth: false,
  className: '',
  barOnly: false,
  label: '',
  loading: false,
};

export default Progress;
