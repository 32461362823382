/* Returns a config-object with data and methods
for accessing that data.
*/

export const getConfigObject = ({ config }) => ({
  data: config.config.data,
  error: config.config.error,
  isFetching: config.config.isFetching,

  /* Get a property using dot syntax, eg a.b.c.d
    If there is no data or the final property or any properties along the way dosent exist,
    undefined is returned

    Internally, all properties that exists in the config-object is computed beforehand,
    so that acessing a property is always O(1)

    */
  getProperty: (key) => {
    if (!config.config.data) {
      return undefined;
    }

    const { data } = config.config;
    return data[key];
  },

  getRoutes: () => Object.getOwnPropertyNames(config.routes),
  hasRoute: (route) => config.routes[route] !== undefined,
});

export const getConfig = ({ config }) => config;
export const getHasLoadedConfig = ({ config }) =>
  !!(!config.config.isFetching && !config.config.error && config.config.data);

export const getCourseCatalogFeatures = (state) => {
  const configObject = getConfigObject(state);

  return {
    searchbar: configObject.getProperty(
      'routes.course-catalog.showSearchField'
    ),
    titleOfCategory: configObject.getProperty(
      'routes.course-catalog.showTitleOfCategory'
    ),
    courseTypeFilter: configObject.getProperty(
      'routes.course-catalog.showourseTypeFilter'
    ),
  };
};

export const getPropertiesForCurrLangAndTrack = (state) => {
  const configObject = getConfigObject(state);

  if (!configObject.data) {
    return null;
  }
  const track =
    localStorage.getItem('track') ||
    configObject.getProperty('params.default-track');

  const language =
    localStorage.getItem('language') ||
    configObject.getProperty('params.default-lang');

  try {
    const defaultTrack = configObject.getProperty('params.default-track');
    const defaultLanguage = configObject.getProperty('params.default-lang');

    const atlasConfigByMapIdAndLang = configObject.getProperty(
      'routes.atlas.configByTrackAndLang'
    );
    const menuConfigByTrack = configObject.getProperty(
      'params.menu.configByTrack'
    );

    const atlasConfigForCurrTrack =
      atlasConfigByMapIdAndLang[track] ||
      atlasConfigByMapIdAndLang[defaultTrack];

    const atlasConfigForCurrTrackAndLang = atlasConfigForCurrTrack[language];

    const menuConfigForMapId =
      menuConfigByTrack[track] || menuConfigByTrack[defaultTrack];

    const menuConfigForMapIdAndLanguage =
      menuConfigForMapId.configByLang[language] ||
      menuConfigForMapId.configByLang[defaultLanguage];

    return {
      atlas: {
        mapId: atlasConfigForCurrTrackAndLang.mapId,
        verifyId: atlasConfigForCurrTrackAndLang.verify.courseId,
        outroId: atlasConfigForCurrTrackAndLang.outro.courseId,
        redirectAfterOutroFinished:
          atlasConfigForCurrTrackAndLang.outro.redirectAfterFinished,
        sticker: {
          ...atlasConfigForCurrTrackAndLang.verify.sticker,
        },
      },
      menu: {
        ...menuConfigForMapId,
        ...menuConfigForMapIdAndLanguage,
      },
      courseCatalog: {
        ...configObject.getProperty('routes.course-catalog.configByTrack')[
          track
        ],
        featuredTiles:
          configObject.getProperty(
            'routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack'
          )[track] &&
          configObject.getProperty(
            'routes.course-catalog.customToplevelSelector.featuredTiles.configByTrack'
          )[track].featuredTiles,
      },
    };
  } catch (error) {
    alert(`Manglende eller feil konfigurering for løype med navn ${track}`);
    console.error(`Manglende konfigurering for løype med id ${track}`);
    console.error(error);
    return {};
  }
};
