import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import '../styles/employee-report.scss';
import { backendUrl } from '@config';

class EmployeeReport extends Component {
  componentDidMount() {
    const { onInit } = this.props;
    if (onInit) {
      onInit();
    }
  }

  render() {
    const { report } = this.props;
    return (
      <div className="employee-report">
        <div className="employee-report__card">
          {(report && report.data && (
            <>
              <h2 className="employee-report__title">{report.data.title}</h2>
              <table className="employee-report__table" width="100%">
                <tr>
                  {report.data.headers.map(h => (
                    <th>{h[0]}</th>
                  ))}
                </tr>
                {report.data.competences.map(c => (
                  <tr>
                    <td>{c.date}</td>
                    <td>{c.competence_title}</td>
                    <td>{c.competence.competence_type}</td>
                    <td>{c.coursevenue}</td>
                    <td width={100}>{c.duration_string}</td>
                    <td width={100}>{c.valid_until}</td>
                    <td>
                      <>
                        {c.certificate_url && (
                          <>
                            <a
                              href={`${backendUrl}${c.certificate_url}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              Kurs bevis
                            </a>
                            <br />
                          </>
                        )}

                        {c.competence.files && (
                          <div>
                            {c.competence.files.map(f => (
                              <div>
                                <a
                                  href={`${backendUrl}/files/image/${
                                    f.file_id
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  {f.title}
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )) || <h2>FULL KOMPETANSE RAPPORT</h2>}
        </div>
      </div>
    );
  }
}

EmployeeReport.propTypes = {
  onInit: PropTypes.func,
  history: PropTypes.shape({}).isRequired,
  report: PropTypes.shape({}).isRequired,
};

EmployeeReport.defaultProps = {
  onInit: null,
};

export default compose(withRouter)(EmployeeReport);
