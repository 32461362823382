import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { mq } from '@facepaint';

export const mt = css({
  marginTop: 12,
});

export const baseSize = css({
  width: 260,
  maxWidth: '100%',
});

const fontFamily = css({
  fontFamily: 'Helvetica Neue,Helvetica,Roboto,Arial,sans-serif',
});

export const Base = styled('div')(
  {
    border: '1px solid rgb(235, 235, 235)',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    transitionDuration: '100ms',
    transitionTimingFunction: 'ease-in',
    transitionProperty: 'all',
    ':focus': {
      border: '1px solid black',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      span: {
        display: 'inline-flex',
        marginRight: 14,
        marginLeft: 14,
      },
    },
  },
  fontFamily,
  baseSize
);

export const Input = styled('input')({
  backgroundColor: 'white',
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
  borderBottomLeftRadius: 'inherit',
  height: 40,
  fontSize: 16,
  width: '100%',
  border: 'none',
});

export const Button = styled('button')({
  backgroundColor: 'black',
  color: 'white',
  height: 40,
  width: '100%',
  transition: 'all 0.2 ease',
  ':hover': {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
  },
});

export const box = css({
  display: 'flex',
  flexDirection: 'column',
});

export const ButtonLink = styled('button')({
  color: '#0070f3',
  fontSize: 16,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const FormFooter = styled('div')({
  display: 'flex',
  borderTop: '1px solid #eaeaea',
  width: '100%',
  height: 100,
  alignItems: 'center',
  justifyContent: 'center',
});

export const stack = css({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'inherit',
});

export const height = css({
  [mq.small]: {
    height: 300,
  },
  [mq.medium]: {
    height: 400,
  },
  [mq.large]: {
    height: 400,
  },
});
