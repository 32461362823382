/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { backendUrl } from '@config';
import Highlighter from 'react-highlight-words';
import SearchBox, { DropdownItem } from './search-box';
import LoadingIndicator from './loading-indicator';

const localeSort = ({ name, sort }, { name: name2, sort: sort2 }) =>
  (sort || 0) - (sort2 || 0) || name.localeCompare(name2);

export const searchFilter = (dataset, query, keys) => {
  if (!query || !query.trim()) {
    return dataset;
  }

  const simpleStr = str =>
    str
      .toString()
      .toLowerCase()
      .replace(/[^a-z0-9æøåäö]/g, '');

  return keys.reduce((results, key) => {
    const elements =
      (key && dataset.map(item => item[key])) ||
      (Array.isArray(dataset) && dataset) ||
      Object.keys(dataset);
    return results.concat(
      dataset.filter((item, index) =>
        simpleStr(elements[index]).includes(simpleStr(query))
      )
    );
  }, []);
};

const InputSearchMultiple = forwardRef(({ type, onChange, ...props }, ref) => {
  const [filters, setFilters] = useState(() => {
    if (type === 'competence') {
      return [
        {
          text: 'E-kurs',
          types: ['ecourse'],
          enabled: false,
        },
        {
          text: 'Kurskategori',
          types: ['complex'],
          enabled: false,
        },
        {
          text: 'Ekvivalenter',
          types: ['equivalents'],
          enabled: false,
        },
        {
          text: 'Innehold',
          types: ['content'],
          enabled: false,
        },
      ];
    }
    return [
      {
        text: 'Format',
        types: ['brand'],
        enabled: false,
      },
      {
        text: 'Samvirkelag',
        types: ['samvirkelag'],
        enabled: false,
      },
      {
        text: 'Distrikt',
        types: ['district'],
        enabled: false,
      },
      {
        text: 'Butikker',
        types: ['store'],
        enabled: false,
      },
    ];
  });

  const [items, setItems] = useState({
    isFetching: false,
    data: [],
  });
  const [term, setTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [value, setValue] = useState([]);
  const [requestSelectIds, setRequestSelectIds] = useState(null);

  const noFiltersEnabled = filters
    .map(({ enabled }) => enabled)
    .every(enabled => enabled === false);

  // Get comma delimitered string of types that are enabled, or get all if none are enabled
  const selectedFilterTypes = filters.reduce(
    (result, { types, enabled }) =>
      enabled || noFiltersEnabled ? result.concat(types) : result,
    []
  );
  const filterString = selectedFilterTypes.join(',');

  const toggleFilter = key => {
    const newFilters = [...filters];
    newFilters[key].enabled = !newFilters[key].enabled;
    setFilters(newFilters);
    return newFilters;
  };

  const onItemSelected = item => {
    setValue(values => [...values, item]);
    setTerm('');
  };

  const removeAtIndex = index => {
    setValue(values => {
      values.splice(index, 1);
      return [...values];
    });
  };

  useImperativeHandle(ref, () => ({
    setSelectedIds: setRequestSelectIds,
  }));

  useEffect(() => {
    setItems(({ ...items }) => ({
      ...items,
      isFetching: true,
    }));
    if (type === 'org') {
      const flattenOrgStructure = data => {
        let result = Object.values(data.samvirkelag)
          .reduce(
            (samvirkelag, { districts, type, id, name }) => [
              ...samvirkelag,
              {
                sort: 1,
                typeName: 'Samvirkelag',
                type,
                id,
                name,
              },
              ...Object.values(districts).reduce(
                (districts, { stores, type, id, name }) => [
                  ...districts,
                  {
                    sort: 2,
                    typeName: 'Distrikt',
                    type,
                    id,
                    name,
                  },
                  ...Object.values(stores).reduce(
                    (stores, { type, id, name }) => [
                      ...stores,
                      {
                        sort: 3,
                        typeName: 'Butikk',
                        type,
                        id,
                        name,
                      },
                    ],
                    []
                  ),
                ],
                []
              ),
            ],
            []
          )
          .concat(
            Object.values(data.brands).map(brand => ({
              ...brand,
              sort: 0,
              typeName: 'Format',
              type: 'brand',
            }))
          )
          .filter(({ id }) => id !== 70084);
        if (result.find(({ id }) => id === 69664)) {
          result = result.filter(({ id }) => id !== 69664);
          result.push({
            sort: 1,
            typeName: 'Samvirkelag',
            type: 'samvirkelag',
            id: 69664,
            name: 'Norsk Butikkdrift',
          });
        }
        result.sort(localeSort);
        return result;
      };
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/plugin/dashboard_org_structure`,
          withCredentials: true,
        })
        .then(({ data }) =>
          setItems(({ ...items }) => ({
            ...items,
            data: flattenOrgStructure(data),
            isFetching: false,
          }))
        );
    } else {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/plugin/dashboard_competences`,
          withCredentials: true,
        })
        .then(({ data: { courses } }) =>
          setItems(({ ...items }) => ({
            ...items,
            data: courses
              .map(course => ({
                ...course,
                type: course.course_type,
                sort: {
                  ecourse: 1,
                  complex: 2,
                  equivalents: 3,
                }[course.course_type],
                typeName: {
                  ecourse: 'E-kurs',
                  complex: 'Kurskategori',
                  equivalents: 'Ekvivalent',
                }[course.course_type],
              }))
              .sort(localeSort),
            isFetching: false,
          }))
        );
    }
  }, []);

  useEffect(() => {
    setFilteredItems(
      searchFilter(
        items.data.filter(
          item =>
            value.indexOf(item) === -1 &&
            selectedFilterTypes.includes(item.type)
        ),
        term,
        ['name', 'id']
      )
    );
  }, [value, term, items, filterString]);

  useEffect(() => {
    if (requestSelectIds) {
      if (!requestSelectIds.length) {
        setValue([]);
        setRequestSelectIds(null);
      } else if (items) {
        const value = items.data.filter(
          ({ id }) => requestSelectIds.indexOf(id) !== -1
        );
        if (value.length) {
          setValue(value);
          setRequestSelectIds(null);
        }
      }
    }
  }, [items, requestSelectIds]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <>
      <SearchBox
        {...props}
        loading={items.isFetching}
        onSearch={term => setTerm(term)}
        value={term}
        onChange={onItemSelected}
        items={filteredItems}
        rowHeight={42}
        renderItemText={item => (
          <>
            <DropdownItemText
              as={Highlighter}
              searchWords={[term]}
              autoEscape
              textToHighlight={item.name}
            />
            {(type === 'competence' && (
              <DropdownItemDescription>
                {item.typeName} / #{item.id}
              </DropdownItemDescription>
            )) || (
              <DropdownItemDescription>{item.typeName}</DropdownItemDescription>
            )}
          </>
        )}
        renderDropdown={render => (
          <DropdownRow>
            <DropdownFilters>
              {items.data &&
                filters.map(
                  ({ text, types }, key) =>
                    items.data.find(
                      ({ type }) => types.indexOf(type) !== -1
                    ) && (
                      <DropdownFilterCheckbox key={key}>
                        <input
                          type="checkbox"
                          checked={filters[key].enabled}
                          onChange={() => toggleFilter(key)}
                        />
                        {text}
                      </DropdownFilterCheckbox>
                    )
                )}
            </DropdownFilters>
            <DropdownItems>
              {!filteredItems.length &&
                ((items.isFetching && (
                  <DropdownItem>
                    <Loading />
                  </DropdownItem>
                )) || <DropdownItem>Fant ingen resultater</DropdownItem>)}
              {render()}
            </DropdownItems>
          </DropdownRow>
        )}
      />
      <ItemRow>
        {value.map((item, index) => (
          <Fragment key={index}>
            {!!index && (
              <ItemPlus>
                <i className="fa fa-plus" />
              </ItemPlus>
            )}
            <ItemLabel>
              {(type === 'org' &&
                item.type !== 'store' &&
                `${item.typeName}: ${item.name}`) ||
                item.name}
              <ItemRemoveButton
                onClick={() => removeAtIndex(index)}
                onKeyPress={e => e.key === 'Enter' && removeAtIndex(index)}
                tabIndex="0"
                role="button">
                <i className="fa fa-times" />
              </ItemRemoveButton>
            </ItemLabel>
          </Fragment>
        ))}
        {/* {!value.length &&
          ((type === 'competence' && (
            <ItemRowText>Ingen kompetanser valgt</ItemRowText>
          )) || <ItemRowText>Ingen organisasjoner valgt</ItemRowText>)} */}
      </ItemRow>
    </>
  );
});

const Loading = styled(LoadingIndicator)`
  margin-top: 260px;
`;

const DropdownItemText = styled.div`
  flex: 1 1 auto;

  mark {
    font-weight: 600;
    background: none;
    color: inherit;
  }
`;
const DropdownItemDescription = styled.div`
  flex: 0 1 auto;
  color: #9d9d9d;
`;

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const DropdownFilters = styled.div`
  display: flex;
  flex: 1 1 auto;
  max-width: 200px;
  padding: 10px 0;
  flex-direction: column;
  border-right: 1px solid #efeeea;
`;
const DropdownItems = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;
const DropdownFilterCheckbox = styled.label`
  flex: 0 1 auto;
  margin: 0;
  padding: 4px 30px;
  input {
    margin-right: 1em;
  }
`;

const ItemRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 0.9em;
`;
// const ItemRowText = styled.div`
//   flex: 0 0 auto;
//   display: flex;
//   flex-direction: row;
//   border-radius: 8px;
//   color: #7d7e7e;
//   padding: 2px 8px;
//   margin: 0 8px;
//   margin-bottom: 10px;
// `;
const ItemLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #618da7;
  color: #fff;
  padding: 2px 8px;
  margin: 0 8px;
  margin-bottom: 10px;
`;
const ItemPlus = styled.div`
  flex: 0 0 auto;
  color: #6a6868;
  padding: 2px 0;
  margin: 0 8px;
`;
const ItemRemoveButton = styled.div`
  flex: 0 0 auto;
  margin-left: 6px;
  font-size: 0.6em;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -8px;
  border-radius: 8px;
  width: 28px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.115s ease-out;
  font-size: 0.8em;

  :hover {
    background-color: #517c95;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 0;
  }
`;

InputSearchMultiple.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['org', 'competence']),
};

export default InputSearchMultiple;
