import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import './notifications.scss';

import Notification from './notification';

const Notifications = ({ notifications, onHideNotification }) =>
  createPortal(
    <div className="notifications">
      <div className="notifications__wrapper">
        {notifications.map(({ title, text, redirectTo, color, id }, idx) => (
          <Notification
            title={title}
            color={color}
            onClose={() =>
              onHideNotification({ notification: notifications[idx] })
            }
            key={`notification-${id}`}
            redirectTo={redirectTo}>
            {text}
          </Notification>
        ))}
      </div>
    </div>,
    document.getElementById('notifications')
  );

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHideNotification: PropTypes.func.isRequired,
};
export default Notifications;
