import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Box, Base } from './styles';

export const CourseInformationPane = ({ children }) => {
  return (
    <Box>
      <Stack>
        {React.Children.map(children, child => (
          <Base>{child}</Base>
        ))}
      </Stack>
    </Box>
  );
};

CourseInformationPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
