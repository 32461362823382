import React from 'react';

import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { screenreaderOnlyMinxin } from '@styles/mixins';
import { getImageUrl } from '@utils/misc.utils';
import AspectRatio from '@components/common/aspect-ratio';
import {
  Wrapper,
  MainImage,
  MainText,
  SecondaryText,
  TextAndButtonsWrapper,
  ImageContentWrapper,
  Icon,
  MetaInformationLine,
  MetaInformationWrapper,
  MetaIcon,
  MetaText,
  SecondaryImage,
  ReadMore,
  SecondaryTextWrapper,
  IconsWrapper,
  IconWrapper,
  ActionButton,
  TextWrapper,
} from './styles';

const ScreenreaderImgText = ({ text }) => (
  <span
    role="img"
    aria-label={text}
    css={css`
      ${screenreaderOnlyMinxin}
    `}
  />
);

const renderMetaInformation = ({ metaInformation, location }) => (
  <MetaInformationWrapper location={location}>
    {metaInformation
      .filter((mi) => mi.location === location)
      .map((mi, i) => (
        <MetaInformationLine location={location} key={i}>
          <MetaIcon icon={mi.icon} color={mi.color} />
          <MetaText>{mi.text || mi.element}</MetaText>
        </MetaInformationLine>
      ))}
  </MetaInformationWrapper>
);

const CourseCardV2 = ({
  mainText,
  secondaryText,
  metaInformation,
  image,
  showActionButton,
}) => {
  const hasTextOrActionButtons =
    (mainText && mainText.content) ||
    (secondaryText && secondaryText.content) ||
    showActionButton;
  const hasIcon = image.icon || image.icons;
  const hasSecondaryImage = image.secondaryImages || image.secondaryImage;
  const hasContentForImageCont =
    image ||
    metaInformation.filter((mi) => mi.location === 'image-part').length > 0;

  return (
    <Wrapper>
      {hasContentForImageCont && (
        <ImageContentWrapper>
          <AspectRatio
            ratio={image.aspectRatio || 2}
            style={{
              borderTopLeftRadius: 'inherit',
              borderTopRightRadius: 'inherit',
            }}>
            <MainImage src={getImageUrl(image.src)} />
            <ScreenreaderImgText text={image.imageAltText} />
          </AspectRatio>

          {hasIcon && (
            <IconsWrapper>
              {(image.icons || [image.icon]).map((icon, i) => (
                <IconWrapper
                  key={`icon${i}`}
                  placement={icon.placement}
                  displayAsCircle={icon.surroundByCircle}>
                  <Icon
                    icon={icon.name}
                    color={icon.color}
                    fontSize={icon.fontSize}
                  />
                </IconWrapper>
              ))}
            </IconsWrapper>
          )}
          {hasSecondaryImage &&
            (image.secondaryImages || [image.secondaryImage]).map(
              (image, i) => (
                <>
                  <SecondaryImage
                    key={`image${i}`}
                    placement={image.placement}
                    src={getImageUrl(image.src)}
                    color={image.color}
                  />
                  <ScreenreaderImgText text={image.imageAltText} />
                </>
              )
            )}

          {metaInformation &&
            renderMetaInformation({ metaInformation, location: 'image-part' })}
        </ImageContentWrapper>
      )}

      {hasTextOrActionButtons && (
        <TextAndButtonsWrapper>
          <TextWrapper>
            {mainText && mainText.content && (
              <MainText css={mainText.css}>{mainText.content}</MainText>
            )}

            {secondaryText && secondaryText.content && (
              <SecondaryTextWrapper>
                <SecondaryText
                  css={secondaryText.css}
                  dangerouslySetInnerHTML={{
                    __html: secondaryText.content,
                  }}
                />
                {secondaryText.showReadMore && <ReadMore>Les mer</ReadMore>}
              </SecondaryTextWrapper>
            )}
          </TextWrapper>

          {metaInformation &&
            renderMetaInformation({ metaInformation, location: 'text-part' })}

          {showActionButton && (
            <ActionButton onClick={showActionButton.onClick}>
              {showActionButton.text}
            </ActionButton>
          )}
        </TextAndButtonsWrapper>
      )}
    </Wrapper>
  );
};

const iconShape = PropTypes.shape({
  // font-awesome icons, first elem in array is kind second is icon ['fal','player']
  name: PropTypes.arrayOf(PropTypes.string),

  placement: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),

  color: PropTypes.string,
  fontSize: PropTypes.string,

  surroundByCircle: PropTypes.shape({
    background: PropTypes.string,
    padding: PropTypes.string,
  }),
});

CourseCardV2.propTypes = {
  mainText: PropTypes.shape({
    content: PropTypes.string,
    css: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  secondaryText: PropTypes.shape({
    content: PropTypes.string,
    css: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    showReadMore: PropTypes.bool,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
    icons: PropTypes.arrayOf(iconShape),
    icon: iconShape,
    secondaryImage: PropTypes.shape({
      src: PropTypes.string,
      placement: PropTypes.oneOf([
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ]),
    }),
  }),
  showActionButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  metaInformation: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.oneOf(['image-part', 'text-part']),
      icon: PropTypes.arrayOf(PropTypes.string), // se iconShape -> name
      element: PropTypes.node,
    })
  ),
};

CourseCardV2.defaultProps = {
  image: null,
  mainText: null,
  secondaryText: null,
  showActionButton: null,
  metaInformation: null,
};

export default CourseCardV2;
