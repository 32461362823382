/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios, { CancelToken } from 'axios';
import moment from 'moment';
import { backendUrl } from '@config';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import classNames from 'classnames';
import ExtraChart from '@routes/camp-extra/extra-chart';
import PersonAvatar from '@components/person-avatar/person-avatar';

import posed from 'react-pose';

import { easing } from 'popmotion';
import { connect } from 'react-redux';
import { notificationsAdd } from '@actions/notifications.actions';
import { withRouter, Link } from 'react-router-dom';
import { getRouteWithParams } from '@utils/routes.utils';

import InputSearchMultiple from './input-search-multiple';

import BadgeCard from './components/badge-card/badge-card';

import './competence-report.scss';
import ProgressCard from './progress-card';
import ReportDatepicker from './report-datepicker';
import DropdownSelect from '../../common/components/dropdown-select/dropdown-select';
import LoadingIndicator from './loading-indicator';
import Modal from '../../common/components/modal/modal';
import { employees as routes } from '../routes.manifest';
import {
  getOrganisationId,
  getHelptexts,
  getProfileId,
} from '../../store/selectors/profile.selectors';
import { stringifyUrlParams } from '../../common/utils/requests.utils';
import DonutChart from '../../common/components/chart/chart';
import InfoBubble from './info-bubble';

const Report = ({
  organisationId,
  helptexts,
  profileId,
  dispatch,
  match: { params },
  location: { state },
  history: { push },
}) => {
  const [hasError, setHasError] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [showLoadReportModal, setShowLoadReportModal] = useState(false);
  const [savedReports, setSavedReports] = useState({
    loading: false,
    data: [],
  });
  const [shouldFetchReport, setShouldFetchReport] = useState(false);
  const [topExpanded, setTopExpanded] = useState(false);
  const topSectionRef = useRef();

  const [searchedCompetenceGroup, setSearchedCompetenceGroup] = useState(null);
  const [selectedCompetenceGroup, setSelectedCompetenceGroup] = useState(null);
  const [searchedCourses, setSearchedCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [searchedOrgs, setSearchedOrgs] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [searchedDate, setSearchedDate] = useState(moment());
  const [date, setDate] = useState(moment());

  const [competenceGroups] = useState([
    {
      name: 'Kjedeskolen',
      cids: [],
      id: 1,
    },
    {
      name: 'Salg av produkter med aldersgrense',
      cids: [866],
      id: 2,
    },
    {
      name: 'Trygg vare',
      cids: [550, 750],
      id: 3,
    },
  ]);

  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [reportTitle, setReportTitle] = useState('');
  const [saveReportLoading, setSaveReportLoading] = useState(false);

  const [expandedInputs, setExpandedInputs] = useState([]);
  const anyInputsExpanded = expandedInputs.some(expanded => expanded);

  const [courseCardsHover, setCourseCardsHover] = useState(false);
  const [orgSort, setOrgSort] = useState({
    key: 'completion_grade',
    text: 'Gjennomføringsgrad',
    reverse: false,
  });
  const [employeeSort, setEmployeeSort] = useState({
    key: 'completion_grade',
    text: 'Gjennomføringsgrad',
    type: 'number',
    reverse: false,
  });

  const setExpandedInput = index =>
    setExpandedInputs(list => {
      const [...expanded] = list;
      expanded[index] = true;
      return expanded;
    });

  const [expandedOrgs, setExpandedOrgs] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [authorId, setAuthorId] = useState(null);

  const [requestedOrgIds, setRequestedOrgIds] = useState(null);
  const [requestedCourseIds, setRequestedCourseIds] = useState(null);

  const getRequestParams = useCallback(() => {
    const distriktIds = [];
    const orgIds = [];
    const samlIds = [];
    const brandIds = [];
    selectedOrgs.map(({ type, id }) => {
      if (type === 'district') {
        // return distriktIds.push(id);
        return orgIds.push(id);
      }
      if (type === 'brand') {
        return brandIds.push(id);
      }
      if (type === 'samvirkelag') {
        return samlIds.push(id);
      }
      return orgIds.push(id);
    });
    const courseIds = selectedCourses.map(({ id }) => id);
    return {
      distrikt_ids: distriktIds.join(','),
      brand_ids: brandIds.join(','),
      slag_ids: samlIds.join(','),
      org_ids: orgIds.join(','),
      cids: [
        ...(selectedCompetenceGroup ? selectedCompetenceGroup.cids : []),
        ...courseIds,
      ].join(','),
      week: date ? date.isoWeek() : moment().isoWeek(),
      year: date ? date.year() : moment().year(),
      brandschool:
        selectedCompetenceGroup && selectedCompetenceGroup.id === 1
          ? '1'
          : null,
    };
  }, [selectedCourses, selectedOrgs, date, selectedCompetenceGroup]);

  const [init, setInit] = useState(false);

  const resetReport = () => {
    setInit(false);
    setTopExpanded(true);
    setShowDashboard(true);
    setLoading(false);
    setSelectedCompetenceGroup(null);
    setSelectedOrgs([]);
    setSelectedCourses([]);
    setDate(moment());
    setData(null);
    setReportTitle('');
    setExpandedInputs([]);
    setHasError(false);
  };

  const fetchDataToken = useRef(null);

  const fetchData = useCallback(() => {
    const params = getRequestParams();
    setData(null);
    setLoading(true);
    setHasError(null);

    const cancel = 'Operation canceled by the user.';

    if (fetchDataToken.current) {
      fetchDataToken.current.cancel(cancel);
    }

    fetchDataToken.current = CancelToken.source();

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/plugin/dashboard_report`,
        params,
        withCredentials: true,
        cancelToken: fetchDataToken.current.token,
      })
      .then(({ data }) => {
        if (data.error || !data.week) {
          throw new Error('Unexpected response');
        } else {
          setData(data);
          setSearchedCompetenceGroup(selectedCompetenceGroup);
          setSearchedCourses([...selectedCourses]);
          setSearchedOrgs([...selectedOrgs]);
          setSearchedDate(date);
          setLoading(false);
          setHasError(null);
        }
      })
      .catch(e => {
        if (e.message !== cancel) {
          setData(null);
          setSearchedCompetenceGroup(selectedCompetenceGroup);
          setSearchedCourses([...selectedCourses]);
          setSearchedOrgs([...selectedOrgs]);
          setSearchedDate(date);
          setLoading(false);
          setHasError(e);
        }
      });
  }, [
    getRequestParams,
    setData,
    setSearchedCompetenceGroup,
    setSearchedCourses,
    selectedCompetenceGroup,
    selectedCourses,
    setSearchedOrgs,
    selectedOrgs,
    setSearchedDate,
    date,
    setLoading,
  ]);

  let orgs = data
    ? data.stores
        .sort((org1, org2) =>
          orgSort.reverse
            ? org1[orgSort.key] - org2[orgSort.key]
            : org2[orgSort.key] - org1[orgSort.key]
        )
        .map(store => ({
          ...store,
          typeName: 'Butikk',
          type: 'store',
        }))
    : [];

  const showExpandOrgs = !expandedOrgs && orgs.length > 10;

  if (!expandedOrgs) {
    orgs = (!expandedOrgs && orgs.slice(0, 10)) || orgs;
  }

  const onScroll = useCallback(() => {
    if (topSectionRef.current) {
      const { style } = topSectionRef.current;
      style.maxHeight = `${topSectionRef.current.scrollHeight}px`;
    }
  }, [
    getRequestParams,
    topSectionRef.current,
    selectedOrgs,
    selectedCourses,
    expandedInputs,
    helptexts,
    topExpanded,
    showDashboard,
    params,
    init,
  ]);

  const saveReport = () => {
    setSaveReportLoading(true);
    axios
      .request({
        method: 'POST',
        url: `${backendUrl}/search/save/dashboard`,
        params: {
          criteria: JSON.stringify({
            competenceGroup:
              (selectedCompetenceGroup && selectedCompetenceGroup.cids) || null,
            orgs: selectedOrgs,
            competences: selectedCourses,
            date: (expandedInputs[2] && date && date.toJSON()) || null,
            name: reportTitle,
            authorId: profileId,
            version: 1,
          }),
          access_level: 'self',
          name: reportTitle,
        },
        withCredentials: true,
      })
      .then(() => {
        setSaveReportLoading(false);
        setShowSaveReportModal(false);
        dispatch(
          notificationsAdd({
            notification: {
              color: 'green',
              text: 'Rapporten er lagret',
            },
          })
        );
      })
      .catch(() => {
        setSaveReportLoading(false);
        dispatch(
          notificationsAdd({
            notification: {
              color: 'red',
              text: 'Det oppstod en feil ved lagring av rapporten',
            },
          })
        );
      });
  };

  const deleteSavedReport = reportId => {
    axios
      .request({
        method: 'POST',
        url: `${backendUrl}/search/deletesavedsearch/${reportId}`,
        withCredentials: true,
      })
      .catch(() => {
        dispatch(
          notificationsAdd({
            notification: {
              color: 'red',
              text: 'Det oppstod en feil ved sletting av rapporten',
            },
          })
        );
        setSavedReports(savedReports);
      });
    setSavedReports(({ data, ...reports }) => ({
      ...reports,
      data: data.filter(({ id }) => id !== reportId),
    }));
  };

  const loadReport = ({
    competenceGroup,
    orgs,
    competences,
    date,
    name,
    authorId,
  }) => {
    setSelectedCompetenceGroup(
      (competenceGroup &&
        competenceGroup.length &&
        competenceGroups.find(
          ({ cids }) => JSON.stringify(cids) === JSON.stringify(competenceGroup)
        )) ||
        null
    );
    setDate(date && moment(date));
    setReportTitle(name || '');
    setExpandedInputs([!!competences, !!orgs, !!date]);
    setSelectedOrgs(orgs || []);
    setRequestedOrgIds((orgs && orgs.map(({ id }) => id)) || null);
    setSelectedCourses(competences || []);
    setRequestedCourseIds(
      (competences && competences.map(({ id }) => id)) || null
    );
    setAuthorId(authorId);
    setShouldFetchReport(true);
  };

  const anyInputsEdited =
    JSON.stringify(searchedCompetenceGroup) !==
      JSON.stringify(selectedCompetenceGroup) ||
    JSON.stringify(searchedCourses) !== JSON.stringify(selectedCourses) ||
    JSON.stringify(searchedOrgs) !== JSON.stringify(selectedOrgs) ||
    JSON.stringify(searchedDate) !== JSON.stringify(date);

  const shouldAutoFetch =
    !anyInputsExpanded && showDashboard && anyInputsEdited;

  const isEmptyReport = !params.reportId || params.reportId === 'totalt';

  useEffect(() => {
    if (
      (!init && isEmptyReport) ||
      (init && shouldAutoFetch && params.reportId !== 'search')
    ) {
      setShouldFetchReport(true);
      setTopExpanded(true);
      setShowDashboard(true);
    }
  }, [params, getRequestParams, shouldAutoFetch]);

  useEffect(() => {
    document
      .getElementsByClassName('app-wrapper__content')[0]
      .addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);
    onScroll();

    return () => {
      document
        .getElementsByClassName('app-wrapper__content')[0]
        .removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [onScroll]);

  const onOrgClick = org => () => {
    setExpandedInput(1);
    setSelectedOrgs([org]);
    setRequestedOrgIds([org.id]);
    setShouldFetchReport(true);
  };

  useEffect(() => {
    if (shouldFetchReport) {
      setShouldFetchReport(false);
      setInit(true);
      fetchData();
    }
  }, [shouldFetchReport]);

  const fetchSavedReports = () => {
    setSavedReports(({ ...saved }) => ({ ...saved, loading: true }));
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/search/savedsearches/dashboard?json_out=1`,
        withCredentials: true,
      })
      .then(({ data: { self } }) => {
        setSavedReports(() => ({ loading: false, data: self }));
      });
  };

  useEffect(() => {
    if (params.reportId === 'search') {
      setExpandedInputs([true]);
    } else if (params.reportId === 'totalt') {
      setSelectedCompetenceGroup(competenceGroups[0]);
    } else if (params.reportId) {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/search/getsavedsearch/${params.reportId}`,
          withCredentials: true,
        })
        .then(({ data }) => {
          loadReport(data);
        })
        .catch(() => {
          setData(null);
          setHasError('loadReport');
          dispatch(
            notificationsAdd({
              notification: {
                color: 'red',
                text: `Fant ikke rapport med ID ${params.reportId}`,
              },
            })
          );
        });
    } else if (state && state.roleId) {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/organisationalfunctions`,
          params: {
            organisation_id: organisationId,
            fields: 'title,required_competences(competence_id)',
          },
          withCredentials: true,
        })
        .then(({ data: { roles } }) => {
          const id_data = roles.find(r => r.id === parseInt(state.roleId, 10));
          if (id_data) {
            resetReport();
            const cti = id_data.required_competences.map(({ id }) => id);
            setSelectedCompetenceGroup(
              competenceGroups.find(
                ({ cids }) => JSON.stringify(cids) === JSON.stringify(cti)
              )
            );
          }
        });
    }
  }, [params.reportId, state]);

  useEffect(() => {
    if (authorId && profileId && authorId === profileId) {
      setShowDashboard(true);
    }
  }, [authorId, profileId]);

  useEffect(() => {
    if (showLoadReportModal) {
      fetchSavedReports();
    }
  }, [showLoadReportModal]);

  useEffect(() => {
    if (selectedCourses.length) {
      setSelectedCompetenceGroup(null);
    }
  }, [selectedCourses]);

  useEffect(() => {
    if (selectedCompetenceGroup) {
      setRequestedCourseIds([]);
    }
  }, [selectedCompetenceGroup]);

  useEffect(() => {
    if (confirmDelete !== null) {
      const timeout = setTimeout(() => setConfirmDelete(null), 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [confirmDelete]);

  const courseCardsHoverRef = useCallback(
    current => {
      if (!current) {
        return;
      }

      const onMouseEnter = () => {
        setCourseCardsHover(true);
      };
      const onMouseLeave = () => {
        setCourseCardsHover(false);
      };

      current.addEventListener('mouseenter', onMouseEnter);
      current.addEventListener('mouseleave', onMouseLeave);
    },
    [setCourseCardsHover]
  );

  return (
    <>
      <TopSectionBg
        ref={topSectionRef}
        collapsed={!topExpanded}
        loading={loading || requestedCourseIds || requestedOrgIds}>
        <TopSection>
          <ReportSectionNarrowInner collapsed={!topExpanded}>
            {(!showDashboard && (
              <ReportSectionHeader light>{reportTitle}</ReportSectionHeader>
            )) || (
              <>
                <div css="margin-bottom: 20px;">
                  <ReportSectionHeader light>
                    {reportTitle || 'RAPPORT'}
                  </ReportSectionHeader>

                  <ReportDescription
                    hasDescription={
                      helptexts && helptexts.report && helptexts.report.toptext
                    }>
                    {helptexts && helptexts.report && helptexts.report.toptext}
                  </ReportDescription>
                  {params.reportId !== 'search' && (
                    <>
                      <ReportSectionHeader light css="margin-bottom: 0.5em;">
                        Kompetansegrupper
                      </ReportSectionHeader>
                      <BadgeRow>
                        {competenceGroups.map(competencegroup => (
                          <ClickyBadge
                            onClick={() => {
                              if (selectedCompetenceGroup === competencegroup) {
                                setSelectedCompetenceGroup(null);
                              } else {
                                setSelectedCompetenceGroup(competencegroup);
                              }
                            }}
                            active={
                              selectedCompetenceGroup &&
                              selectedCompetenceGroup.id === competencegroup.id
                            }>
                            {competencegroup.name}
                          </ClickyBadge>
                        ))}
                      </BadgeRow>
                    </>
                  )}
                </div>
                {(!expandedInputs[0] && (
                  <TopFilterRow>
                    <FlexRow>
                      <FlexRow>
                        <ReportSectionHeader light>
                          Velg kompetanser
                        </ReportSectionHeader>
                      </FlexRow>
                      <FlexRow shrink>
                        <LinkButton onClick={() => setExpandedInput(0)}>
                          velg kompetanser
                        </LinkButton>
                      </FlexRow>
                    </FlexRow>
                  </TopFilterRow>
                )) || (
                  <TopFilterRow expanded>
                    <ReportSectionHeader light>
                      Velg kompetanser
                    </ReportSectionHeader>
                    <InputSearchMultiple
                      ref={current => {
                        if (current && requestedCourseIds) {
                          current.setSelectedIds(requestedCourseIds);
                          setRequestedCourseIds(null);
                        }
                      }}
                      onChange={competences => setSelectedCourses(competences)}
                      type="competence"
                      placeholder="Finn kompetanse"
                      closeOnSelect
                    />
                  </TopFilterRow>
                )}
                {(!expandedInputs[1] && (
                  <TopFilterRow>
                    <FlexRow>
                      <FlexRow>
                        <ReportSectionHeader light>
                          Samvirkelag / Kjede / Butikk
                        </ReportSectionHeader>
                      </FlexRow>
                      <FlexRow shrink>
                        <LinkButton onClick={() => setExpandedInput(1)}>
                          filtrer på organisasjon
                        </LinkButton>
                      </FlexRow>
                    </FlexRow>
                  </TopFilterRow>
                )) || (
                  <TopFilterRow expanded>
                    <ReportSectionHeader light>
                      Samvirkelag / Kjede / Butikk
                    </ReportSectionHeader>
                    <InputSearchMultiple
                      ref={current => {
                        if (current && requestedOrgIds) {
                          current.setSelectedIds(requestedOrgIds);
                          setRequestedOrgIds(null);
                        }
                      }}
                      onChange={orgs => setSelectedOrgs(orgs)}
                      type="org"
                      placeholder="Samvirkelag / Kjede / Butikk"
                      closeOnSelect
                    />
                  </TopFilterRow>
                )}
                {(!expandedInputs[2] && (
                  <TopFilterRow>
                    <FlexRow>
                      <FlexRow>
                        <ReportSectionHeader light>
                          Historiske tall
                        </ReportSectionHeader>
                      </FlexRow>
                      <FlexRow shrink>
                        <LinkButton onClick={() => setExpandedInput(2)}>
                          velg uke
                        </LinkButton>
                      </FlexRow>
                    </FlexRow>
                  </TopFilterRow>
                )) || (
                  <TopFilterRow expanded>
                    <ReportSectionHeader light>
                      Historiske tall
                    </ReportSectionHeader>
                    <ReportDatepicker
                      value={date}
                      onChange={date => setDate(date)}
                    />
                  </TopFilterRow>
                )}
                {anyInputsExpanded && (
                  <Button
                    disabled={loading || !anyInputsEdited}
                    onClick={() => setShouldFetchReport(true)}
                    css={css`
                      ${(expandedInputs[2] && 'margin-top: -40px;') ||
                        'margin-top: 40px;'}
                    `}>
                    {loading && (
                      <i
                        className="fa fa-spin fa-spinner"
                        css="margin-right: 1em;"
                      />
                    )}
                    Lag rapport
                  </Button>
                )}
              </>
            )}
          </ReportSectionNarrowInner>
        </TopSection>
      </TopSectionBg>
      {(params.reportId !== 'search' || data || loading || hasError) && (
        <ReportBody>
          {showDashboard && (
            <TopSectionToggleButton
              onClick={() => setTopExpanded(toggle => !toggle)}
              onKeyPress={e =>
                e.key === 'Enter' && setTopExpanded(toggle => !toggle)
              }
              role="button"
              tabIndex="0">
              <i
                className={classNames('fa', {
                  'fa-angle-double-up': topExpanded,
                  'fa-angle-double-down': !topExpanded,
                })}
              />
            </TopSectionToggleButton>
          )}
          <ReportSection pose={(data && 'visible') || 'hidden'}>
            {data &&
              ((!data.total_count && (
                <FlexRow>
                  <FlexColumn>
                    <ReportHeader>Fant ingen data</ReportHeader>
                  </FlexColumn>
                </FlexRow>
              )) || (
                <>
                  <FlexRow>
                    <FlexColumn>
                      <ReportHeader>
                        Fant {data.total_count} person
                        {data.total_count !== 1 && 'er'} i {data.stores.length}{' '}
                        butikk
                        {data.stores.length !== 1 && 'er'}
                      </ReportHeader>
                      {(searchedCompetenceGroup ||
                        !!searchedCourses.length) && (
                        <ReportSubHeader>
                          som har{' '}
                          {[
                            ...(searchedCompetenceGroup
                              ? [searchedCompetenceGroup.name]
                              : []),
                            ...searchedCourses.map(({ name }) => name),
                          ].map((name, index) => (
                            <>
                              {!!index && <>&nbsp;+&nbsp;</>}
                              <b key={index}>{name}</b>
                            </>
                          ))}{' '}
                          som krav
                        </ReportSubHeader>
                      )}
                    </FlexColumn>
                    <FlexRow shrink css="margin-left: auto;">
                      <DropdownSelect
                        css="margin-top: 20px; :hover, :focus, :active { background-color: #52788e !important; }"
                        color="#fff"
                        backgroundColor="#618da7"
                        label={
                          <i
                            className="fa fa-cog"
                            css="font-size: 1.2em; margin-left: -0.3em;"
                          />
                        }
                        options={[
                          {
                            index: 0,
                            text: (
                              <>
                                <i className="far fa-file-excel" />
                                &nbsp; Last ned rapport for Person
                              </>
                            ),
                          },
                          {
                            index: 1,
                            text: (
                              <>
                                <i className="far fa-file-excel" />
                                &nbsp; Last ned rapport for Organisasjon
                              </>
                            ),
                          },
                          {
                            index: 2,
                            text: (
                              <>
                                <i className="far fa-save" />
                                &nbsp; Lagre rapport
                              </>
                            ),
                          },
                          {
                            index: 3,
                            text: (
                              <>
                                <i className="far fa-folder-open" />
                                &nbsp; Lagrede rapporter
                              </>
                            ),
                          },
                        ]}
                        renderItem={(option, { text }, props) => (
                          <div {...props}>{text}</div>
                        )}
                        selected={() => false}
                        onSelect={({ index }) => {
                          if (index === 0) {
                            window.location.href = `${backendUrl}/plugin/dashboard_report_excel/persons?${stringifyUrlParams(
                              getRequestParams()
                            )}`;
                          } else if (index === 1) {
                            window.location.href = `${backendUrl}/plugin/dashboard_report_excel/organisations?${stringifyUrlParams(
                              getRequestParams()
                            )}`;
                          } else if (index === 2) {
                            setShowSaveReportModal(true);
                          } else if (index === 3) {
                            setShowLoadReportModal(true);
                          }
                        }}
                        dropdownAlignRight
                      />
                    </FlexRow>
                  </FlexRow>
                </>
              ))}
          </ReportSection>
          {data && data.summary && !!data.total_count && (
            <>
              <ReportBody css="background-color: #f7f6f4; min-height: auto; margin-bottom: 80px; z-index: 90;">
                <ReportSection pose="visible">
                  {(searchedCompetenceGroup &&
                    searchedCompetenceGroup.id === 1 && (
                      <BrandschoolRow>
                        <BrandschoolStatsCell>
                          <ReportSectionHeader center>
                            Kjedeskole
                            {helptexts &&
                              helptexts.report &&
                              helptexts.report.helptextBrandschool && (
                                <InfoBubble
                                  title="Kjedeskole"
                                  width={400}
                                  height={300}
                                  alignRight
                                  anchor
                                  offsetX={100}
                                  offsetY={-10}>
                                  {helptexts.report.helptextBrandschool}
                                </InfoBubble>
                              )}
                          </ReportSectionHeader>
                          <ChartWrapper>
                            <ExtraChart
                              data={{
                                labels: [
                                  'Har fullført alle krav',
                                  'Har ikke fullført alle krav',
                                ],
                                data: [
                                  data.summary.persons_completed,
                                  data.summary.persons_missing,
                                ],
                                // progress: `${Math.round(
                                //   data.summary.completion
                                // )}%`,
                                colors: ['#4ab7ed', '#e84a39'],
                              }}
                              holeScale={0.76}
                            />
                          </ChartWrapper>
                          <InfoBoxRow>
                            <ProgressCard
                              mainText={`${Math.round(
                                data.summary.completion
                              )}%`}
                              subText="Har fullført alle krav"
                            />
                            <ProgressCard
                              mainText={`${Math.round(
                                data.summary.completion_grade
                              )}%`}
                              subText="Gjennomføringsgrad"
                            />
                          </InfoBoxRow>
                        </BrandschoolStatsCell>
                        <BrandschoolStatsCell>
                          <ReportSectionHeader center>
                            Kjedeskole og annen obl. kompetanse
                          </ReportSectionHeader>
                          <ChartWrapper>
                            <ExtraChart
                              data={{
                                labels: [
                                  'Har fullført alle krav',
                                  'Har ikke fullført alle krav',
                                ],
                                data: [
                                  data.other.persons_completed,
                                  data.other.persons_missing,
                                ],
                                // progress: `${Math.round(
                                //   data.other.completion
                                // )}%`,
                                colors: ['#4ab7ed', '#e84a39'],
                              }}
                              holeScale={0.76}
                            />
                          </ChartWrapper>
                          <InfoBoxRow>
                            <ProgressCard
                              mainText={`${Math.round(data.other.completion)}%`}
                              subText="Har fullført alle krav"
                            />
                            <ProgressCard
                              mainText={`${Math.round(
                                data.other.completion_grade
                              )}%`}
                              subText="Gjennomføringsgrad"
                            />
                          </InfoBoxRow>
                        </BrandschoolStatsCell>
                      </BrandschoolRow>
                    )) || (
                    <>
                      <ChartStats>
                        <ChartStatsMain>
                          <ChartWrapper>
                            <ExtraChart
                              data={{
                                labels: [
                                  `Har fullført alle krav`,
                                  'Har ikke fullført alle krav',
                                ],
                                data: [
                                  data.summary.persons_completed,
                                  data.summary.persons_missing,
                                ],
                                // progress: `${Math.round(
                                //   data.summary.completion
                                // )}%`,
                                colors: ['#4ab7ed', '#e84a39'],
                              }}
                              holeScale={0.76}
                            />
                          </ChartWrapper>
                        </ChartStatsMain>
                        <ChartStatsSidebar>
                          <ProgressCard
                            mainText={`${Math.round(
                              data.summary.completion_grade
                            )}%`}
                            subText="Gjennomføringsgrad"
                          />
                          <ProgressCard
                            ref={courseCardsHoverRef}
                            mainText={data.summary.persons_count}
                            subText={`Person${
                              data.summary.persons_count !== 1 ? 'er' : ''
                            } som har krav${
                              data.courses.length === 1 ? 'et' : 'ene'
                            }`}
                          />
                          <CourseCards
                            pose={
                              courseCardsHover && data.courses.length > 1
                                ? 'visible'
                                : 'hidden'
                            }>
                            {courseCardsHover &&
                              data.courses.length > 1 &&
                              data.courses.map(
                                ({ persons_count, name }, key) => (
                                  <ProgressCard
                                    key={key}
                                    mainText={persons_count}
                                    subText={name}
                                    courseCard
                                  />
                                )
                              )}
                          </CourseCards>
                        </ChartStatsSidebar>
                      </ChartStats>
                    </>
                  )}
                </ReportSection>
              </ReportBody>
              {searchedCompetenceGroup &&
                ((searchedCompetenceGroup.id === 1 &&
                  data.courses &&
                  !!data.courses.length && (
                    <ReportSection pose="visible">
                      <ReportSectionHeader css="margin-bottom: 1em;">
                        Kjedeskole krav
                      </ReportSectionHeader>
                      <BrandschoolTable>
                        <thead>
                          <tr>
                            <th width="35%">Krav</th>
                            <th width="25%">Gjennomføringsgrad</th>
                            <th width="20%">Personer som har kravet</th>
                            <th width="20%">Personer som har fullført</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.courses.map(
                            ({
                              completion,
                              persons_completed,
                              name,
                              persons_count,
                              id,
                            }) => (
                              <tr key={id}>
                                <td>{name}</td>
                                <td>
                                  <BrandschoolCompletion>
                                    <CompletionChart stretch>
                                      <CompletionBar completion={completion}>
                                        <CompletionDot small>
                                          <CompletionText>
                                            {Math.round(completion)}%
                                          </CompletionText>
                                        </CompletionDot>
                                      </CompletionBar>
                                    </CompletionChart>
                                  </BrandschoolCompletion>
                                </td>
                                <td>{persons_count}</td>
                                <td>{persons_completed}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </BrandschoolTable>
                    </ReportSection>
                  )) ||
                  (data.courses.length > 1 && (
                    <ReportSection pose="visible">
                      <NarrowColumn>
                        {data.courses.map(
                          ({
                            completion,
                            name,
                            persons_completed,
                            persons_count,
                            persons_missing,
                            id,
                          }) => (
                            <FlexColumn key={id} css="margin-bottom: 50px;">
                              <FlexRow css="color: #888988; margin-left: 130px; margin-bottom: 1em;">
                                <span>
                                  <span css="font-weight: bold;">{name}</span>
                                  &nbsp;- totalt {persons_count} person
                                  {persons_count.length !== 1 && 'er'}
                                </span>
                              </FlexRow>
                              <FlexRow>
                                <FlexColumn
                                  shrink
                                  css="justify-content: center; align-items: center; margin-right: 40px;">
                                  <DonutChart
                                    width={80}
                                    height={80}
                                    data={[
                                      {
                                        value: persons_completed,
                                        color: '#4ab7ed',
                                      },
                                      {
                                        value: persons_missing,
                                        color: '#e84a39',
                                      },
                                    ]}
                                    padding={0}
                                    holeScale={0.4}
                                  />
                                </FlexColumn>
                                <FlexColumn>
                                  <ProgressCard
                                    columns={[
                                      {
                                        mainText: `${Math.round(completion)}%`,
                                        subText: 'Gjennomføringsgrad',
                                      },
                                      {
                                        mainText: persons_completed,
                                        subText: 'Har fullført alle krav',
                                        small: true,
                                      },
                                      {
                                        mainText: persons_missing,
                                        subText: 'Har ikke fullført alle krav',
                                        small: true,
                                      },
                                    ]}
                                  />
                                </FlexColumn>
                              </FlexRow>
                            </FlexColumn>
                          )
                        )}
                      </NarrowColumn>
                    </ReportSection>
                  )))}
            </>
          )}
          <ReportSection
            pose={
              (data && orgs && !data.employees.length && 'visible') || 'hidden'
            }>
            {data && orgs && !!orgs.length && !data.employees.length && (
              <>
                <Row>
                  <ReportSectionHeader css="margin-bottom: 1em;">
                    Butikk oversikt
                  </ReportSectionHeader>
                  <SortRow>
                    sorter på&nbsp;
                    <SortToggleButton
                      onClick={() =>
                        setOrgSort(sort => ({
                          ...sort,
                          reverse: !sort.reverse,
                        }))
                      }>
                      {orgSort.reverse ? (
                        <>
                          stigende &nbsp;
                          <i className="fa fa-sort-amount-up" />
                        </>
                      ) : (
                        <>
                          synkende &nbsp;
                          <i className="fa fa-sort-amount-down" />
                        </>
                      )}
                    </SortToggleButton>
                    <SortSelect
                      label={orgSort.text.toLowerCase()}
                      options={[
                        {
                          key: 'completion',
                          text: 'Gjennomføringsgrad',
                        },
                        {
                          key: 'persons_count',
                          text: 'Antall ansatte',
                        },
                      ]}
                      renderItem={(option, { text }, props) => (
                        <div {...props}>{text}</div>
                      )}
                      selected={val => val && val.key === orgSort.key}
                      onSelect={({ key, text }) =>
                        setOrgSort(sort => ({ ...sort, key, text }))
                      }
                      dropdownAlignRight
                    />
                  </SortRow>
                </Row>
                <OrgsBadges>
                  {orgs.map(org => (
                    <OrgCardWrapper>
                      <BadgeCard
                        title={org.name}
                        onClick={onOrgClick({
                          type: 'store',
                          id: org.id,
                        })}
                        text={
                          <>
                            <ClickableText
                              onClick={onOrgClick({
                                type: 'samvirkelag',
                                id: org.samvirkelag_id,
                              })}>
                              {org.samvirkelag}
                            </ClickableText>
                            {' / '}
                            <ClickableText
                              onClick={onOrgClick({
                                type: 'brand',
                                id: org.brand_id,
                              })}>
                              {org.brand}
                            </ClickableText>
                            <br />
                            <span css="font-style: italic;">
                              {org.persons_count} ansatt
                              {org.persons_count !== 1 && 'e'}
                            </span>
                          </>
                        }
                        percentage={`${Math.round(org.completion_grade)}%`}
                      />
                    </OrgCardWrapper>
                  ))}
                </OrgsBadges>
                {showExpandOrgs && (
                  <div
                    className="expand-button"
                    css="margin-bottom: 80px;"
                    role="button"
                    onClick={() => setExpandedOrgs(true)}
                    onKeyPress={() => setExpandedOrgs(true)}
                    tabIndex="0"
                    style={{ marginTop: '-40px' }}>
                    &#8226;&#8226;&#8226;
                  </div>
                )}
              </>
            )}
          </ReportSection>
          <ReportSection
            pose={(data && !!data.employees.length && 'visible') || 'hidden'}>
            {data && !!data.employees.length && (
              <EmployeesColumn>
                <SortRow css="margin-bottom: 20px;">
                  sorter på&nbsp;
                  <SortToggleButton
                    onClick={() =>
                      setEmployeeSort(sort => ({
                        ...sort,
                        reverse: !sort.reverse,
                      }))
                    }>
                    {(employeeSort.type === 'string' &&
                      ((employeeSort.reverse && (
                        <>
                          Å til A &nbsp;
                          <i className="fa fa-sort-alpha-up" />
                        </>
                      )) || (
                        <>
                          A til Å &nbsp;
                          <i className="fa fa-sort-alpha-down" />
                        </>
                      ))) ||
                      (employeeSort.reverse && (
                        <>
                          stigende &nbsp;
                          <i className="fa fa-sort-amount-up" />
                        </>
                      )) || (
                        <>
                          synkende &nbsp;
                          <i className="fa fa-sort-amount-down" />
                        </>
                      )}
                  </SortToggleButton>
                  <SortSelect
                    label={employeeSort.text.toLowerCase()}
                    options={[
                      {
                        key: 'completion_grade',
                        text: 'Gjennomføringsgrad',
                        type: 'number',
                      },
                      {
                        key: 'has',
                        text: 'Gjennomførte krav',
                        type: 'number',
                      },
                      {
                        key: 'firstname',
                        text: 'Fornavn',
                        type: 'string',
                      },
                      {
                        key: 'lastname',
                        text: 'Etternavn',
                        type: 'string',
                      },
                    ]}
                    renderItem={(option, { text }, props) => (
                      <div {...props}>{text}</div>
                    )}
                    selected={val => val && val.key === employeeSort.key}
                    onSelect={({ key, text, type }) =>
                      setEmployeeSort(sort => ({ ...sort, key, text, type }))
                    }
                    dropdownAlignRight
                  />
                </SortRow>

                {data.employees
                  .sort((employee1, employee2) =>
                    employeeSort.reverse
                      ? employee1[employeeSort.key] -
                          employee2[employeeSort.key] ||
                        (typeof employee2[employeeSort.key] === 'string' &&
                          employee2[employeeSort.key].localeCompare(
                            employee1[employeeSort.key]
                          ))
                      : employee2[employeeSort.key] -
                          employee1[employeeSort.key] ||
                        (typeof employee1[employeeSort.key] === 'string' &&
                          employee1[employeeSort.key].localeCompare(
                            employee2[employeeSort.key]
                          ))
                  )
                  .map(
                    (
                      {
                        name,
                        firstname,
                        lastname,
                        completion_grade,
                        has,
                        should_have,
                        position,
                        profile_image_link,
                      },
                      key
                    ) => (
                      <EmployeeRow key={key}>
                        <EmployeeAvatar>
                          <PersonAvatar
                            size="50px"
                            person={{
                              firstname,
                              lastname,
                            }}
                            imageUrl={profile_image_link}
                          />
                        </EmployeeAvatar>
                        <EmployeeRowCard>
                          <EmployeesNameColumn>
                            <EmployeesName>{name}</EmployeesName>
                            <EmployeesPosition>
                              {position || <>&nbsp;</>}
                            </EmployeesPosition>
                          </EmployeesNameColumn>
                          <EmployeesCompletion>
                            <CompletionChart>
                              <CompletionBar completion={completion_grade}>
                                <CompletionDot>
                                  <CompletionText>
                                    {Math.round(completion_grade)}%
                                  </CompletionText>
                                </CompletionDot>
                              </CompletionBar>
                            </CompletionChart>
                          </EmployeesCompletion>
                          <EmployeesDetails>
                            {has} av {should_have} krav
                          </EmployeesDetails>
                        </EmployeeRowCard>
                      </EmployeeRow>
                    )
                  )}
              </EmployeesColumn>
            )}
          </ReportSection>
          {(loading && (
            <ReportSection pose="visible">
              <LoadingIndicator />
            </ReportSection>
          )) ||
            (hasError && (
              <ReportSection pose="visible">
                <FlexColumn css="text-align: center; margin: auto; justify-content: center;">
                  <ReportHeader css="margin: 0 auto;">
                    <i className="fas fa-exclamation-triangle" />
                    <div css="font-size: 0.9rem; margin-bottom: 20px;">
                      Kunne ikke laste inn rapport
                    </div>
                  </ReportHeader>
                  {(hasError === 'loadReport' && (
                    <LinkButton
                      css="margin: 0 auto;"
                      onClick={() => {
                        push(routes.reportNew);
                      }}>
                      Ny rapport
                    </LinkButton>
                  )) || (
                    <LinkButton
                      css="margin: 0 auto;"
                      onClick={() => setShouldFetchReport(true)}>
                      Prøv igjen
                    </LinkButton>
                  )}
                </FlexColumn>
              </ReportSection>
            ))}
        </ReportBody>
      )}
      {showSaveReportModal && (
        <Modal onBackdropClick={() => setShowSaveReportModal(false)}>
          <ModalBackground>
            <ModalTitle>Lagre rapport</ModalTitle>
            <p>
              {helptexts && helptexts.report && helptexts.report.saveReport}
            </p>
            <Label>
              <ReportTextInputLabel>Tittel</ReportTextInputLabel>
              <ReportTextInput
                type="text"
                autoComplete="off"
                value={reportTitle}
                onChange={e => setReportTitle(e.target.value)}
                onKeyUp={e =>
                  e.key === 'Enter' &&
                  reportTitle.length &&
                  !saveReportLoading &&
                  saveReport()
                }
              />
            </Label>
            <Row>
              <Button
                secondary
                css="margin-left: auto;"
                onClick={() => setShowSaveReportModal(false)}>
                Avbryt
              </Button>
              <Button
                css="margin-left: 12px;"
                disabled={!reportTitle.length || saveReportLoading}
                onClick={() => saveReport()}>
                {saveReportLoading && (
                  <i
                    className="fa fa-spin fa-spinner"
                    css="margin-right: 1em;"
                  />
                )}
                Lagre
              </Button>
            </Row>
          </ModalBackground>
        </Modal>
      )}
      {showLoadReportModal && (
        <Modal onBackdropClick={() => setShowLoadReportModal(false)}>
          <ModalBackground>
            <ModalTitle>Lagrede rapporter</ModalTitle>
            <p>
              {helptexts && helptexts.report && helptexts.report.loadReport}
            </p>
            <SavedReportsList css={savedReports.loading && 'display: flex;'}>
              {(savedReports.loading && <LoadingIndicator />) ||
                savedReports.data.map(({ name, id }) => (
                  <SavedReportsEntry
                    as={Link}
                    to={{
                      pathname: getRouteWithParams(routes.report, {
                        reportId: id,
                      }),
                    }}
                    from="*"
                    onClick={() => setShowLoadReportModal(false)}
                    key={id}>
                    <span>{name}</span>
                    <GarbagoButton
                      confirm={confirmDelete === id}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (confirmDelete === id) {
                          deleteSavedReport(id);
                        } else {
                          setConfirmDelete(id);
                        }
                      }}>
                      <i className="fa fa-trash-alt" />
                      {confirmDelete === id && <>&nbsp;Slett?</>}
                    </GarbagoButton>
                  </SavedReportsEntry>
                ))}
            </SavedReportsList>
            <Row>
              <Button
                secondary
                css="margin-left: auto;"
                onClick={() => setShowLoadReportModal(false)}>
                Avbryt
              </Button>
            </Row>
          </ModalBackground>
        </Modal>
      )}
    </>
  );
};

const OrgCardWrapper = styled.div`
  display: flex;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 20px;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const OrgsBadges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: 60px;
`;

const SortRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin: auto;
  margin-right: 0;
  align-items: center;
  justify-content: flex-end;
  color: #6a6868;
`;

const StealthButton = css`
  position: relative;
  display: flex;
  flex: 0 1 auto !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-self: flex-start;
  border: 1px solid transparent !important;
  border-radius: 5px;
  padding: 10px 12px;
  user-select: none;
  cursor: pointer;
  color: #6a6868;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  background: none !important;
  box-shadow: none !important;
  line-height: 1.5;

  .dropdown-select__label {
    padding: 0 12px;
  }
`;

const StealthButtonHover = css`
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.1) !important;
  border: 1px solid #e5e3e1 !important;
  background-color: #fff !important;
`;

const SortToggleButton = styled.button`
  ${StealthButton} :hover,
  :active {
    ${StealthButtonHover}
  }
`;

const SortSelect = styled(DropdownSelect)`
  ${StealthButton} :hover,
  :active,
  :focus {
    ${StealthButtonHover}
  }
`;

const CourseCards = styled(
  posed.div({
    visible: {
      opacity: 1,
      transition: {
        ease: easing.easeOut,
        duration: 115,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        ease: easing.easeOut,
        duration: 115,
      },
    },
  })
)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -14px;
  z-index: 100;
`;

const ClickableText = styled.span`
  display: inline;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.3em;
  }
`;

const BrandschoolTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  color: #7d7e7e;

  th {
    padding-left: 16px;
    padding-bottom: 8px;
    text-align: left;
    font-size: 0.9em;
  }

  tbody {
    tr {
      background: #fff;

      &:nth-child(2) {
        background: #ececeb;
      }
    }
    td {
      padding: 20px;
      border: 1px solid #cdcdcd;
      border-bottom: 1px solid #dededc;
    }
    tr:last-child td {
      border-bottom: 1px solid #cdcdcd;
    }
  }
`;

const ReportDescription = styled.div`
  color: #6a6868;
  padding-top: 10px;
  padding-bottom: 20px;

  ${({ hasDescription }) =>
    (hasDescription &&
      css`
        margin-bottom: 60px;
        border-bottom: 1px solid #6a6868;
      `) ||
    css`
      margin-bottom: 20px;
    `}
`;

const SavedReportsList = styled.div`
  display: block;
  flex: 1 1 auto;
  margin: 20px -24px;
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
`;

const SavedReportsEntry = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  border-bottom: 1px solid #e7e7e7;
  line-height: 2;
  padding: 4px;
  padding-left: 24px;
  padding-right: 8px;
  color: #474747;
  background-color: #fff;
  cursor: pointer;
  transition: background 0.115s ease-out;
  span {
    transition: color 0.115s ease-out;
  }

  :hover {
    background-color: #f0f0f0;

    span {
      color: #000;
    }
  }
`;

const GarbagoButton = styled.div`
  background: none;
  border: none;
  display: inline-block;
  color: #868686;
  cursor: pointer;
  line-height: 2;
  min-width: 2em;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  transition: background 0.115s ease-out, color 0.115s ease-out;
  border-radius: 1em;

  :hover {
    background-color: #e00d2e;
    color: #fff;
  }

  ${({ confirm }) =>
    confirm &&
    css`
      padding: 0 1em;
      background-color: #e00d2e;
      color: #fff;
    `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ModalBackground = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  border-radius: 8px;
  z-index: 100;
  overflow: hidden;
  padding: 24px;
  width: 100vw;
  max-width: 600px;

  p {
    color: #868686;
  }
`;

const ModalTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #474747;
`;

const Label = styled.label`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const ReportTextInput = styled.input`
  border: 2px solid #e7e7e7;
  padding: 12px 8px;
  margin: 4px 0;
`;
const ReportTextInputLabel = styled.div`
  color: #474747;
`;

const CompletionChart = styled.div`
  position: relative;
  ${({ stretch }) =>
    stretch
      ? css`
          flex: 1 1 auto;
          max-width: 200px;
        `
      : css`
          width: 200px;
        `}
  height: 1px;
  background-color: #9b9b9b;
  margin-top: 10px;
`;

const CompletionBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ completion }) => completion}%;
  background-color: #e77f1e;
`;

const CompletionDot = styled.div`
  position: absolute;
  right: -2px;
  bottom: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e77f1e;

  ${({ small }) =>
    small
      ? css`
          bottom: -2px;
        `
      : css`
          bottom: 4px;

          :after {
            content: '';
            display: block;
            position: absolute;
            right: 2px;
            top: 4px;
            width: 2px;
            height: 6px;
            background-color: #e77f1e;
          }
        `}
`;

const CompletionText = styled.div`
  position: absolute;
  bottom: 6px;
  font-size: 14px;
  overflow: visible;
  white-space: nowrap;
  transform: translateX(-50%);
  color: #e77f1e;
  font-weight: bold;
`;

const NarrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const EmployeesColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 80px;
`;

const EmployeeRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 14px 0;
`;

const EmployeeAvatar = styled.div`
  display: block;
  flex: 0 0 auto;
  align-self: center;

  .person-avatar__image {
    background-color: #fff !important;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  }
  .person-avatar__initials {
    color: #aaa;
    font-size: 1.2em;
  }
`;
const EmployeeRowCard = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-left: 20px;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  color: #6a6868;
  padding: 10px 14px;
`;

const EmployeesNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
`;

const EmployeesName = styled.div`
  display: flex;
  flex: 1 1 auto;
  font-weight: bold;
  font-size: 1.2em;
`;

const EmployeesPosition = styled.div`
  display: flex;
  flex: 1 1 auto;
  color: #aaa;
`;

const BrandschoolCompletion = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
`;

const EmployeesCompletion = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
`;

const EmployeesDetails = styled.div`
  display: block;
  flex: 0 0 100%;
  max-width: 150px;
  text-align: right;
  align-self: center;
  padding-right: 20px;
  color: #aaa;
`;

const TopFilterRow = styled.div`
  & + & {
    border-top: 1px solid #d0cfcf;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      & + & {
        border-top: none;
      }
    `};

  margin-bottom: ${({ expanded }) => (expanded && '0') || '12px'};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${({ shrink }) => (shrink ? '0 1 auto' : '1 1 auto')};
  flex-wrap: wrap;
`;

const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

const BadgeRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px -8px;
`;
const ClickyBadge = styled.button`
  border: none;
  flex: 0 1 auto;
  margin: 4px 8px;
  margin-bottom: 8px;
  background-color: #c9c9c9;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 24px;
  font-size: 1.1em;

  ${({ active }) =>
    active &&
    css`
      background-color: #618da7;
      color: #fff;
    `}
`;
const LinkButton = styled.button`
  background: none;
  border: none;
  display: inline-block;
  color: #1468b2;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.3em;
  cursor: pointer;
  align-self: flex-end;
  line-height: 2.5;
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  background-color: #618da7;
  cursor: pointer;
  padding: 16px 40px;
  flex: 0 1 auto;
  margin: 0;
  align-self: flex-end;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.115s ease-out, color 0.115s ease-out;

  :hover,
  :active {
    background-color: #4f748a;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      padding: 16px;
      background-color: transparent;
      color: #6590a9;

      :hover,
      :active {
        color: #6590a9;
        background-color: #f0f0f0;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #dededc;
      color: #b6bac5;
      cursor: default;

      :hover,
      :active {
        background-color: #dededc;
      }
    `}
`;

const TopSectionBg = styled.div`
  border-bottom: 1px solid #d2d2d1;
  background-color: #e3e3e0;
  overflow: hidden;
  max-height: max-content;
  box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);
  will-change: contents;

  ${({ loading }) =>
    !loading &&
    css`
      transition: max-height 0.4s ease-out;
    `}

  ${({ collapsed }) =>
    collapsed &&
    css`
      max-height: 70px !important;
    `}
`;

const TopSection = styled.div`
  padding-bottom: 0 !important;
`;

const ReportBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 600px;
  will-change: contents;
`;

const ReportSection = styled(
  posed.div({
    visible: {
      opacity: 1,
      transition: {
        ease: easing.easeOut,
        duration: 800,
      },
    },
    hidden: {
      opacity: 0,
    },
  })
)`
  display: ${({ pose }) => (pose === 'visible' ? 'flex' : 'none')};
  opacity: 0;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 90%;
  max-width: 1600px;
  padding: 40px 80px;
  will-change: contents;

  @media screen and (max-width: 1000px) {
    padding: 40px 20px;
  }
`;
const ReportSectionNarrowInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  padding: 80px;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;
  will-change: contents;
  transition: padding 0.4s ease-out;

  ${({ collapsed }) =>
    collapsed &&
    css`
      padding-top: 0;
    `}
`;

const TopSectionToggleButton = styled.div`
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 20px;
  background-color: #e3e3e0;
  border: 1px solid #d2d2d1;
  border-top: none;
  color: #6a6868;
  transition: background-color 0.115s ease-out;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);
  z-index: 1;

  :hover {
    background-color: #dbdbdb;
  }

  i {
    transform: scaleY(0.8);
  }
`;

const InfoBoxRow = styled.div`
  display: flex;
  margin-bottom: -100px;
  justify-content: space-evenly;

  > div {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const ReportHeader = styled.h2`
  flex: 0 1 auto;
  align-self: baseline;
  margin-right: auto;
  margin-bottom: 0;
  color: #7d7e7e;
  font-weight: 700;
  font-size: 32px;
  position: relative;
`;
const ReportSubHeader = styled(ReportHeader)`
  margin-top: 0;
  font-size: 18px;
  font-weight: normal;
`;
const ReportSectionHeader = styled(ReportHeader)`
  font-size: 24px;
  ${({ light }) =>
    (light &&
      css`
        color: #6a6868;
      `) ||
    css`
      color: #474747;
    `}

  ${({ center }) =>
    center &&
    css`
      text-align: center;
      align-self: center;
      margin: 0;
    `}

  ${({ underlined }) =>
    underlined &&
    css`
      ::after {
        content: '';
        bottom: -10px;
        height: 4px;
        width: 120px;
        background-color: $color-orange;
        display: block;
        position: absolute;
      }
    `}
`;

const ChartWrapper = styled.div`
  position: relative;
  overflow: visible;
  flex: 1 1 auto;
  height: 280px;
  justify-content: center;

  .extra-chart {
    overflow: visible;
    height: 100%;
  }
`;

const ChartStatsMain = styled.div`
  position: relative;
  flex: 1 1 600px;
  margin-right: 40px;
  margin-left: -80px;

  @media screen and (max-width: 1000px) {
    margin-right: 10px;
    margin-left: -40px;
  }

  @media screen and (max-width: 800px) {
    flex: 1 0 auto;
    margin-top: -40px;

    ${ChartWrapper} {
      height: 220px;
    }
  }
`;
const ChartStatsSidebar = styled.div`
  position: relative;
  display: flex;
  flex: 0 1 400px;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

const ChartStats = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  margin: 20px 0;

  @media screen and (max-width: 800px) {
    flex-direction: column;

    ${ChartStatsSidebar} {
      flex: 1 0 auto;
    }
  }
`;

const BrandschoolStatsCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
`;
const BrandschoolRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 0 -40px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 0 -20px;

    ${BrandschoolStatsCell} {
      flex: 1 1 auto;
      margin-bottom: 180px;

      :last-child {
        margin-bottom: 0;
      }
    }

    ${ChartWrapper} {
      height: 220px;
    }
  }
`;

const mapStateToProps = state => ({
  organisationId: getOrganisationId(state),
  helptexts: getHelptexts(state),
  profileId: getProfileId(state),
});

export default connect(mapStateToProps)(withRouter(Report));
