import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import FormInput from '@components/form-input';
import CKEditorField from '@components/form-ckeditor/form-ckeditor';

import './message-form.scss';

const requiredTitle = value =>
  (!value.length && 'Emne må fylles ut') || undefined;
const requiredMessage = value =>
  (!value.length && 'Meldingsteksten må fylles ut') || undefined;

const MessageForm = ({ handleSubmit, onCancel }) => (
  <form onSubmit={handleSubmit} className="message-form">
    <div className="message-form__input-wrapper">
      <Field
        className="message-form__input"
        component={FormInput}
        label="Emne"
        name="title"
        validate={[requiredTitle]}
        type="text"
      />
    </div>
    <div className="message-form__input-wrapper">
      <Field
        autoComplete="name"
        className="message-form__input"
        component={CKEditorField}
        label="Tekst"
        name="emailBody"
        validate={[requiredMessage]}
        type="text"
      />
    </div>
    <div className="message-form__buttons-wrapper">
      <button
        className="btn btn--flat message-form__button"
        type="button"
        onClick={onCancel}>
        Avbryt
      </button>
      <button className="btn message-form__submit-button" type="submit">
        Send
      </button>
    </div>
  </form>
);

MessageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

MessageForm.defaultProps = {
  onCancel: () => {},
};

export default reduxForm({ form: 'message' })(MessageForm);
