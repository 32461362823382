import {
  MESSAGES_GET_REQUEST,
  MESSAGES_GET_SUCCESS,
  MESSAGES_GET_FAILURE,
} from '@actions/messages.actions';

const initialState = {
  all: {
    isFetching: false,
    data: null,
    error: null,
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case MESSAGES_GET_REQUEST:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: true,
          error: null,
        },
      };
    case MESSAGES_GET_SUCCESS:
      return {
        ...state,
        all: {
          isFetching: false,
          data: action.payload.messages,
          error: null,
        },
      };
    case MESSAGES_GET_FAILURE:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default messages;
