/* eslint-disable react/prop-types */
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import React from 'react';

import { ReactComponent as HexSVG } from '@src/assets/svg/map/Hexagon.svg';

const Container = styled.div`
  height: 5em;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
`;

const Content = styled.div`
  flex: 1 1 50%;
  /* background: green; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PathBegin = styled.div`
  width: 2.5em;
  flex: 0 0 auto;
  height: 100%;
  position: relative;
`;

const Empty = styled.div`
  flex: 1 1 50%;
`;

const Path = styled.div`
  width: 2.5em;
  flex: 0 0 auto;
  height: 100%;
  position: relative;
`;

const PathEnd = styled.div`
  width: 2.5em;
  flex 0 0 auto;
  height: 100%;
  position: relative;
`;

const PathContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineTop = styled.div`
  height: 2.25em;
  width: 0.35em;
  background-color: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  margin: 0 auto;
`;

const LineBottom = styled.div`
  height: 2.75em;
  width: 0.35em;
  background-color: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  margin: 0 auto;
`;

const Circle = styled.div`
  width: 1.25em;
  height: 1.25em;
  background-color: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  border-radius: 9999px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CircleRight = styled.div`
  width: 0.5em;
  height: 1em;
  background-color: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  position: absolute;
  margin-left: 0.4em;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CircleLeft = styled.div`
  width: 0.5em;
  height: 1em;
  background-color: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
  position: absolute;
  margin-left: -0.4em;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EmptyTop = styled.div`
  height: 2.25em;
  width: 0.5em;
  margin: 0 auto;
`;

const EmptyBottom = styled.div`
  height: 2.75em;
  width: 0.5em;
  margin: 0 auto;
`;

const Hexagon = styled(HexSVG)`
  #Layer1 {
    fill: ${(props) => (props.completed ? '#1A934F' : '#C7C7C7')};
  }

  #Layer2 {
    fill: ${(props) => (props.completed ? '#21CE6C' : '#F2F2F2')};
  }
`;

const HexContainer = styled.div`
  position: relative;
  width: 4em;
  /* background-color: turquoise; */
`;

const HexText = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 1.5em;
  font-weight: bold;
`;

const Box = styled.div`
  color: white;
  /* background-color: orchid; */
  word-wrap: break-word;
  width: 9em;
  text-align: center;
  font-weight: bold;
`;

const HexBox = ({ index, courseName, completed }) => {
  return (
    <Content>
      <HexContainer>
        <Hexagon completed={completed} />
        <HexText>{index + 1}</HexText>
      </HexContainer>
      <Box>{courseName}</Box>
    </Content>
  );
};

const CreateCourseTrail = () => {
  const courses = [
    'Introduksjon',
    'Trygge Rammer',
    'Vennskap & Trivsel',
    'Mestrings-opplevelser',
    'Medvirkning',
    'Barnas Premisser',
    'Alle med på leken',
  ];
  const coursesLength = courses.length;
  const completedCourses = 2;

  return courses.map((course, index) => (
    <Container key={`Container-${index}`}>
      {(index % 2 === 0 && (
        <HexBox
          key={course.toString()}
          index={index}
          courseName={course}
          completed={completedCourses >= index}
        />
      )) || <Empty />}
      <PathBegin>
        <PathContainer>
          {(index === 0 && <EmptyTop key={course.toString()} />) || (
            <LineTop
              completed={completedCourses >= index}
              key={`LineTop-${index}`}
            />
          )}
          {(index === coursesLength - 1 && (
            <EmptyTop key={`EmptyTop-${index}`} />
          )) || (
            <LineBottom
              completed={completedCourses >= index + 1}
              key={`LineBottom-${index}`}
            />
          )}
        </PathContainer>
        {((index === 0 || index === coursesLength - 1) && (
          <Circle
            completed={completedCourses >= index}
            key={`Circle-${index}`}
          />
        )) ||
          (index % 2 === 0 && (
            <CircleLeft
              completed={completedCourses >= index}
              key={`CircleLeft-${index}`}
            />
          )) || (
            <CircleRight
              completed={completedCourses >= index}
              key={`CircleRight-${index}`}
            />
          )}
      </PathBegin>
      {(index % 2 === 1 && (
        <HexBox
          index={index}
          courseName={course}
          completed={completedCourses >= index}
          key={`HexBox-${index}`}
        />
      )) || <Empty key={`Empty-${index}`} />}
    </Container>
  ));
};

const CourseTrail = () => {
  return <CreateCourseTrail />;
};

export default CourseTrail;
