import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SelectList from 'react-widgets/lib/SelectList';

import './form-multiselect.scss';

const FormMultiselect = ({
  className,
  label,
  data,
  defaultValue,
  input: { name, onBlur, onChange, value },
  meta,
  ...props
}) => (
  <div className="form-multiselect">
    {label && (
      <label className="form-multiselect__label" htmlFor={name}>
        {label}
      </label>
    )}

    <SelectList
      {...props}
      multiple
      className={className}
      containerClassName={classnames(className, 'form-multiselect__input', {
        'form-multiselect__input--error': meta.touched && meta.error,
      })}
      data={data}
      valueField="id"
      textField="title"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={() => onBlur()}
    />
    {meta.touched && meta.error && (
      <div className="form-multiselect__error">{meta.error}</div>
    )}
  </div>
);

FormMultiselect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormMultiselect.defaultProps = {
  className: '',
  label: '',
};

export default FormMultiselect;
