import React from 'react';
import { backendUrl } from '@config';
import PropTypes from 'prop-types';
import '../styles/certificate.scss';

const Certificate = ({ certificate_url }) => {
  return (
    <>
      <a
        className="certificate"
        href={`${backendUrl}${certificate_url}`}
        onClick={e => e.stopPropagation()}
        alt="Last ned kursbevis">
        <i className="fas fa-file-pdf" />
        <div
          className="certificate__tooltip"
          href={`${backendUrl}${certificate_url}`}>
          Last ned kursbevis
        </div>
      </a>
    </>
  );
};

Certificate.propTypes = {
  certificate_url: PropTypes.string.isRequired,
};

export default Certificate;
