/* eslint-disable no-debugger */
/** @jsx jsx */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { jsx } from '@emotion/core';
import { nanoLearningUrl } from '@config';
import { Wrapper, Player } from './styles';

const NanoLearningPlayer = ({ courseId, hasLoaded }) => {
  const playerRef = useRef();
  useEffect(() => {
    if (playerRef.current && playerRef.current.focus) {
      playerRef.current.focus();
    }
  });
  console.log(
    'NANO_PLAYER: starting nano course with url: ',
    `${nanoLearningUrl}?id=${courseId}`
  );
  return (
    <Wrapper
      variants={{
        loaded: { scale: 1, opacity: 1 },
        loading: { scale: 0.7, opacity: 0 },
      }}
      initial={{ scale: 0.7, opacity: 0 }}
      animate={hasLoaded ? 'loaded' : 'loading'}
      transition={{ duration: 0.3, ease: 'easeIn' }}>
      <Player
        ref={playerRef}
        title="Nano-Læring Player"
        src={`${nanoLearningUrl}?id=${courseId}`}
      />
    </Wrapper>
  );
};

NanoLearningPlayer.propTypes = {
  courseId: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool,
};

NanoLearningPlayer.defaultProps = {
  hasLoaded: true,
};

export default withRouter(NanoLearningPlayer);
