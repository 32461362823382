import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../styles/employee-preview.scss';
import '../styles/employee-edit.scss';

import { PersonShape } from '@types/person';
import PersonForm, {
  PersonFormLoading,
} from '@components/person-form/person-form';
import Modal from '@components/modal/modal';
import { getIsMobile } from '@selectors/global.selectors';
import EditPasswordForm from './edit-password-form';

const EmployeeEdit = ({
  person: { isFetching, data },
  onInit,
  onEmployeeFormSubmit,
  onResetPassword,
  onEditPassword,
  forwardRef,
  isMobile,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(
    false
  );
  const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(
    false
  );

  const escapeListener = ({ key }) => {
    if (key === 'Escape') {
      setResetPasswordModalVisible(false);
      setEditPasswordModalVisible(false);
    }
  };

  useEffect(() => {
    if (onInit) {
      onInit();
    }
    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, []);

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'reset') {
      setResetPasswordModalVisible(visibility);
    } else if (type === 'edit') {
      setEditPasswordModalVisible(visibility);
    }

    toggleEscapeListener(visibility);
  };

  const handleEmployeeFormSubmit = employee => {
    setLoading(true);
    onEmployeeFormSubmit(employee);
  };

  const handleResetPassword = () => {
    const identifier = data.user_name || data.email || data.phone;
    onResetPassword(identifier);
    setModalVisibility('reset', false);
  };

  const handleEditPassword = data => {
    onEditPassword({ ...data, user: data.user_name });
    setModalVisibility('edit', false);
  };

  const TasksList = ({ disabled, onClick }) => {
    return (
      <ul className="task-list">
        <li className="task-list__item">
          <button
            className="employee-preview__add-menu-item"
            type="button"
            disabled={disabled}
            onMouseDown={() => {
              setModalVisibility('reset', true);
              onClick();
            }}>
            <i className="far fa-envelope task-list__item-icon" />
            Send nytt passord
          </button>
        </li>
        <li className="task-list__item">
          <button
            className="employee-preview__add-menu-item"
            type="button"
            disabled={disabled}
            onMouseDown={() => {
              setModalVisibility('edit', true);
              onClick();
            }}>
            <i className="fas fa-pencil-alt task-list__item-icon" />
            Endre brukernavn/passord
          </button>
        </li>
      </ul>
    );
  };

  TasksList.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  useImperativeHandle(forwardRef, () => ({
    TasksList,
  }));

  const isButtonDisabled = isFetching || loading;

  return (
    <>
      {!isMobile && (
        <div className="employee-edit__left-column">
          <div className="employee-edit__actions">
            <h3 className="employee-edit__actions-title">Oppgaver</h3>
            <div className="employee-edit__actions-card">
              <div className="employee-edit__actions-item">
                <button
                  type="button"
                  disabled={isButtonDisabled}
                  className="employee-edit__actions-button"
                  onClick={() => setModalVisibility('reset', true)}>
                  <i className="far fa-envelope" />
                  Send nytt passord
                </button>
              </div>
              <div className="employee-edit__actions-item">
                <button
                  type="button"
                  disabled={isButtonDisabled}
                  className="employee-edit__actions-button"
                  onClick={() => setModalVisibility('edit', true)}>
                  <i className="fas fa-pencil-alt" />
                  Endre brukernavn/passord
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="employee-edit__right-column">
        <div className="employee-edit__card">
          <div className="employee-edit__form">
            {(data && (
              <PersonForm
                {...props}
                manager
                onEditPassword={onEditPassword}
                disabled={isButtonDisabled}
                initialValues={{
                  user_name: data.user_name,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  email: data.email,
                  mobile: data.mobile,
                }}
                onSubmit={handleEmployeeFormSubmit}
              />
            )) || <PersonFormLoading />}
          </div>
        </div>
      </div>
      {resetPasswordModalVisible && (
        <Modal onBackdropClick={() => setModalVisibility('reset', false)}>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">Send nytt passord</h2>
            <p className="employee-edit__modal-paragraph">
              Et nytt engangspassord blir opprettet og sendt til brukeren på
              e-post.
            </p>
            <div className="employee-edit__modal-button-wrapper">
              <button
                type="button"
                className="btn btn--flat"
                onClick={() => setModalVisibility('reset', false)}>
                AVBRYT
              </button>
              <button
                type="button"
                className="btn employee-edit__modal-button"
                onClick={handleResetPassword}>
                SEND
              </button>
            </div>
          </div>
        </Modal>
      )}
      {editPasswordModalVisible && (
        <Modal onBackdropClick={() => setModalVisibility('edit', false)}>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">
              Endre brukernavn/passord
            </h2>
            <EditPasswordForm
              managerForm
              initialValues={{
                user_name: data.user_name,
              }}
              onSubmit={handleEditPassword}
              onCancel={() => setModalVisibility('edit', false)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

EmployeeEdit.propTypes = {
  person: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PersonShape(),
    error: PropTypes.shape({}),
  }),
  onInit: PropTypes.func,
  onEmployeeFormSubmit: PropTypes.func.isRequired,
  employeesEditPassword: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  forwardRef: PropTypes.any.isRequired,
};

EmployeeEdit.defaultProps = {
  person: {
    isFetching: false,
    data: {
      user_name: '',
      firstname: '',
      lastname: '',
      email: '',
      organisations: [],
      roles: [],
    },
  },
  onInit: null,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
});

const ConnectedEmployeeEdit = connect(mapStateToProps)(EmployeeEdit);

export default forwardRef((props, ref) => (
  <ConnectedEmployeeEdit {...props} forwardRef={ref} />
));
