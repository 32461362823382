/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

export const Title = styled('h1')({
  label: 'Title',
  color: 'black',
  margin: 0,
  marginBottom: 10,
  padding: 0,
  fontWeight: 300,
});
