import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import '../styles/profile-base-information.scss';
import Progress from '@components/progress/progress';
// import ProfileInformation from '@routes/my-education/components/profile-base-information-loading';
import EventsList from '@routes/employees/components/events-list';

import {
  getProfile,
  getRoles,
  getExpiring,
  getEvents,
  getTab,
} from '@selectors/profile.selectors';

import {
  switchTab,
  profileFetchPersonRequirements,
} from '@actions/profile.actions';

import { myEducation as myEducationRoutes } from '@routes/routes.manifest';
import { getRouteWithParams } from '@utils/routes.utils';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompetenceList from '@components/competences/competence-list';
import ExpiringList from '@components/expiring-competences';

import PersonInfo from './person-info';
import PersonRoleInfo from './person-role-info';
import {
  getMissingCompetences,
  getMessageUnreadCount,
} from '../../../store/selectors/profile.selectors';

class ProfileBaseInformation extends Component {
  state = {
    isRolesContainerExpanded: false,
  };

  handleTabSelect = tab => {
    // check keys if you want
    const {
      switchTab,
      requiredCompetences,
      profileFetchPersonRequirements,
    } = this.props;

    switchTab(tab);
    if (tab === 'activites' && requiredCompetences.keys.length === 0) {
      profileFetchPersonRequirements();
    }
  };

  createRoleSections(rolesData) {
    return rolesData
      ? rolesData.map(position => (
          <div className="section cell small-12 medium-6" key={position.id}>
            <div className="profile-base-info small">
              <Link
                key={position.id}
                to={getRouteWithParams(myEducationRoutes.roleView.path, {
                  roleId: position.id,
                })}>
                <div className="profile-base-info__position" key={position.id}>
                  <div className="content">
                    <div className="profile-base-info__position-title">
                      {position.title}
                    </div>
                  </div>
                  <div className="progress">
                    <div className="profile-base-info__competencelevel">
                      {(position.passed_count !== 0 && (
                        <Progress
                          progress={Math.round(
                            (position.passed_count /
                              (position.passed_count +
                                position.missing_count)) *
                              100
                          )}
                        />
                      )) || <Progress progress="0" />}
                    </div>
                  </div>
                  <div className="bottom-task-bar">
                    {(position.missing_count > 0 && (
                      <div>
                        {position.missing_count} oppgaver gjenstår &nbsp;
                        <FontAwesomeIcon icon="chevron-right" />
                      </div>
                    )) || (
                      <div>
                        Ferdig&nbsp;
                        <FontAwesomeIcon icon="thumbs-up" />
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))
      : null;
  }

  render() {
    const {
      profile: { data: profileData },
      isFetching,
      isSubFetching,
      summary,
      events,
      expiring,
      requiredCompetences,
      tabSelected,
      unreadCount,
    } = this.props;
    const { isRolesContainerExpanded } = this.state;

    let hiddenSubRoles = [];
    let visibleSubRoles = [];
    if (summary.data && summary.data.requirement.length !== 0) {
      const sortedSubRoles = summary.data.requirement.slice(1).sort((a, b) => {
        if (a.missing_count === b.missing_count) {
          return a.title > b.title ? 1 : -1;
        }
        return b.missing_count - a.missing_count;
      });
      visibleSubRoles = sortedSubRoles.slice(0, 2);
      hiddenSubRoles = sortedSubRoles.slice(2);
    }

    // if (!isFetching && profileData) {
    return (
      <div>
        {profileData && (
          <div className="profile-top">
            <div className="grid-x profile-top__person-info">
              <div className="cell auto">
                <PersonInfo profileData={profileData} />
              </div>
              <div className="cell">
                <div className="profile-top__person-info__cv">
                  <Link
                    className="active"
                    key={`profile-main-${profileData.id}`}
                    to={getRouteWithParams(myEducationRoutes.main.path, {})}
                  >
                    Min opplæring
                  </Link>

                  <Link
                    key={`profile-cv-${profileData.id}`}
                    to={getRouteWithParams(myEducationRoutes.cvView.path, {
                      personId: profileData.id,
                    })}>
                    Gjennomført opplæring
                  </Link>

                  <Link
                    key={`profile-message-${profileData.id}`}
                    to={getRouteWithParams(
                      myEducationRoutes.messagesView.path,
                      {
                        personId: profileData.id,
                      }
                    )}>
                    Meldinger <div className="badge">{unreadCount}</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="my-education__section__competences">
          {expiring && expiring.data && expiring.data.length !== 0 && (
            <div className="profile-base-info__positions">
              <div className="grid-x heading">
                <div className="cell small-12">
                  <h2>Kompetanser som utløper</h2>
                  <ExpiringList
                    expiring={expiring.data}
                    isPerson
                  />
                </div>

              </div>
            </div>
          )}
          {events.data && events.data.length !== 0 && (
            <div className="profile-base-info__positions">
              <div className="grid-x heading">
                <div className="cell small-12">
                  <h2>Mine kurspåmeldinger</h2>
                </div>
                <EventsList type="employee" events={events.data} />
              </div>
            </div>
          )}
          {(!isFetching &&
            profileData &&
            profileData.competencelevel === 100 && (
              <div className="profile-base-info__positions">
                <div className="allok">
                  <p>
                    «Gratulerer! Du har fullført all din obligatoriske opplæring
                    så langt!
                    <br />
                    Visste du at det finnes flere kurs innenfor ulike temaer
                    <br />i kurskatalogen? Ta en kikk da vel!
                  </p>
                  <h2>Du lever så lenge du lærer!»</h2>
                </div>
              </div>
            )) || (
            <>
              <div className="profile-base-info__positions">
                <div className="grid-x heading">
                  <div className="cell shrink">
                    <ul className="tabs">
                      <li className={tabSelected === 'role' ? 'active' : ''}>
                        <button
                          type="button"
                          onClick={() => this.handleTabSelect('role')}>
                          Rolle
                        </button>
                      </li>
                      <li
                        className={tabSelected === 'activites' ? 'active' : ''}>
                        <button
                          type="button"
                          onClick={() => this.handleTabSelect('activites')}>
                          Oppgaver
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {tabSelected === 'activites' && (
                  <CompetenceList
                    root
                    isSubFetching={isSubFetching}
                    courses={requiredCompetences}
                  />
                )}

                {tabSelected === 'role' && (
                  <>
                    <div className="grid-x">
                      {(summary.isFetching && (
                        <div className="profile-base-info__loaders profile-base-info__loaders--competences">
                          <div className="profile-base-info">
                            <div className="profile-base-info__loaders__title" />
                            <div className="bottom-task-bar" />
                          </div>
                          <div className="profile-base-info profile-base-info--small">
                            <div className="profile-base-info__loaders__title" />
                            <div className="bottom-task-bar" />
                          </div>
                          <div className="profile-base-info profile-base-info--small">
                            <div className="profile-base-info__loaders__title" />
                            <div className="bottom-task-bar" />
                          </div>
                        </div>
                      )) || (
                        <>
                          {summary.data &&
                            summary.data.requirement.length !== 0 && (
                              <PersonRoleInfo
                                role={summary.data.requirement[0]}
                                description={
                                  summary.data.requirement[0].description
                                }
                              />
                            )}
                        </>
                      )}
                    </div>

                    <div className="grid-x">
                      {this.createRoleSections(visibleSubRoles)}
                      {isRolesContainerExpanded &&
                        this.createRoleSections(hiddenSubRoles)}
                    </div>

                    {hiddenSubRoles.length !== 0 && (
                      <div className="info__chip">
                        <button
                          type="button"
                          className={classnames('chip', 'show-more', {
                            'chip--active': isRolesContainerExpanded,
                          })}
                          onClick={() =>
                            this.setState(({ isRolesContainerExpanded }) => ({
                              isRolesContainerExpanded: !isRolesContainerExpanded,
                            }))
                          }>
                          ...
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
    // }
    // return <div>{isFetching ? <ProfileInformation /> : 'Feil!'}</div>;
  }
}

ProfileBaseInformation.propTypes = {
  profile: PropTypes.shape({
    data: PropTypes.shape({}),
    error: PropTypes.shape({}),
  }).isRequired,
  summary: PropTypes.shape({
    data: PropTypes.shape({}),
    isFetching: PropTypes.shape({}),
    error: PropTypes.shape({}),
  }).isRequired,
  switchTab: PropTypes.func.isRequired,
  roles: PropTypes.shape({}).isRequired,
  events: PropTypes.shape({}).isRequired,
  expiring: PropTypes.shape({}).isRequired,
  tabSelected: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSubFetching: PropTypes.bool.isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  profileFetchPersonRequirements: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  summary: state.profile.summary,
  roles: getRoles(state),
  events: getEvents(state),
  expiring: getExpiring(state),
  tabSelected: getTab(state),
  isFetching: state.profile.isFetching,
  isSubFetching: state.profile.competences.isSubFetching,
  requiredCompetences: getMissingCompetences(state),
  unreadCount: getMessageUnreadCount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTab,
      profileFetchPersonRequirements,
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfileBaseInformation);
