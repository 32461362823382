/* eslint-disable no-debugger */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css, jsx } from '@emotion/core';

import { Wrapper } from '@routes/content/components/contentViewer/styles';
import AspectRatio from '@components/common/aspect-ratio';
import ContentLoader from 'react-content-loader';

/* Displays a skeleton for an article */
const ArticleLoader = ({
  isMobile,
  wrapperstyle,
  wrapperstyleMobile,
  imagePartStyle,
  imagePartStyleMobile,
}) => (
  <Wrapper
    aria-hidden
    css={css`
      padding: 2em;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;

      margin-top: ${isMobile ? '-7.5em' : '0em'};
      ${wrapperstyle};
      ${isMobile ? wrapperstyleMobile : ''}
    `}>
    <div>
      <AspectRatio
        ratio={1.7}
        css={css`
          margin-top: 7em;
          height: 550px;
          margin-bottom: ${isMobile ? '0.5em' : 'unset'};
          ${imagePartStyle}
          ${isMobile ? imagePartStyleMobile : ''}
        `}>
        <div
          className="course-card__cover--image"
          style={{
            backgroundColor: '#f3f3f3',
          }}
        />
      </AspectRatio>
    </div>
    <ContentLoader
      height={2000}
      width={1500}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb">
      <rect
        x="15"
        y={isMobile ? '0' : '10'}
        rx="4"
        ry="4"
        width="1250"
        height="25"
      />

      <rect x="100" y="180" rx="4" ry="4" width="1250" height="30" />
      <rect x="100" y="250" rx="4" ry="4" width="1050" height="30" />
      <rect x="100" y="320" rx="4" ry="4" width="1150" height="30" />

      <rect x="100" y="450" rx="4" ry="4" width="1300" height="30" />
      <rect x="100" y="520" rx="4" ry="4" width="1250" height="30" />
      <rect x="100" y="590" rx="4" ry="4" width="1150" height="30" />
      <rect x="100" y="660" rx="4" ry="4" width="1200" height="30" />
      <rect x="100" y="730" rx="4" ry="4" width="1200" height="30" />

      {isMobile && (
        <>
          <rect x="100" y="800" rx="4" ry="4" width="1300" height="30" />
        </>
      )}
    </ContentLoader>
  </Wrapper>
);

ArticleLoader.propTypes = {
  isMobile: PropTypes.bool,
  wrapperstyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperstyleMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
ArticleLoader.defaultProps = {
  isMobile: false,
  wrapperstyle: '',
  wrapperstyleMobile: '',
};

export default ArticleLoader;

export const FullscreenArticleLoader = () => {
  return (
    <div
      css={css`
        position: fixed;
        background: white;
        top: 0;
        left: 0;
        margin: auto;
        width: 100vw;
        height: 100wh;
        z-index: 3000;
      `}>
      <ArticleLoader
        wrapperstyle={css`
          height: 100vh;
          position: relative;
          top: -4.5em;
        `}
      />
    </div>
  );
};
