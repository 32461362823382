/* eslint-disable no-debugger */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import DashboardTile from '@components/dashboard-tile/dashboardTile';
import { css, jsx } from '@emotion/core';

import { TrainertipsCard } from '@routes/course-catalog/containers/course-catalog/components/trainertips-card/trainertips-card';
import {
  Item,
  MasonryGrid,
  mediaQuerySizeOverride,
} from '@components/dashboard-tile/styles/styles';
import { getWindowSize, getIsTablet } from '@selectors/global.selectors';
import { size } from '@styles/device';
import { CourseCardLoader } from '@routes/course-catalog/containers/course-catalog/components/course-card/course-card';
import { Wrapper as TrainertipsCardWrapper } from '@routes/course-catalog/containers/course-catalog/components/trainertips-card/styles';
import { useLocation } from 'react-router-dom';
import { getSelectedCompetencegroupId } from '@selectors/courses.selectors';
import { Wrapper } from './styles/index';

/* A collection of icons/tiles
 */
const TileGrid = ({
  isMobile,
  isTablet,
  tiles,
  courseKindMap,
  fullwidthTile,
  featuredTiles,
  featuredTilesIsLoading,
  featuredTilesConfig,
  onClickCourseGroupSelectorTile,
  onClickCourseTile,
  onClickLinkTile,
  wrapperStyle,
  contentWrapperStyle,
  wrapperStyleMobile,
}) => {
  const handleClickTile = (tile) => {
    if (tile.route) {
      onClickLinkTile(tile.route);
    } else if (tile.cid) {
      onClickCourseTile(tile.cid, 24);
    } else {
      onClickCourseGroupSelectorTile(tile['course-mapping_key']);
    }
  };
  const activeCategory = useSelector(getSelectedCompetencegroupId);

  const mobileLayoutBreakpoint = size.mobileCourseCatalog;

  const renderTile = (tileConfig, fullwidth, key) => (
    <Item
      fullwidth={fullwidth}
      onClick={() => handleClickTile(tileConfig)}
      key={key}>
      <DashboardTile
        active={
          !isMobile &&
          courseKindMap[tileConfig['course-mapping_key']] &&
          courseKindMap[tileConfig['course-mapping_key']].competenceGroupId ===
            activeCategory
        }
        className={`tile ${fullwidth ? ' fullwidth' : ''}`}
        mobileBreakpoint={mobileLayoutBreakpoint}
        fullwidth={fullwidth}
        textConfig={{
          primaryText: tileConfig.text.primaryText,
          secondaryText: tileConfig.text.secondaryText,
          background: tileConfig.text.background,
        }}
        imageConfig={{
          src: tileConfig.image.src,
          overlay: tileConfig.image.overlay,
          position: tileConfig.image.position,
        }}
      />
    </Item>
  );

  return (
    <Wrapper
      className="tile-grid-wrapper"
      css={css`
        ${wrapperStyle};
        ${isMobile ? wrapperStyleMobile : ''}
      `}>
      <MasonryGrid
        className="grid"
        css={css`
          height: min-content;
          ${contentWrapperStyle};
        `}>
        <Item
          css={
            isMobile
              ? css`
                  font-size: 0.9em;
                `
              : ''
          }
          h={2}
          v={2}
          name="featuredTile"
          mediaQueryGridElementSizeOverride={{
            minWidth: 667,
            maxWidth: 1100,
            h: 3,
            v: 2,
          }}>
          <Carousel
            swipeable
            className="featured-articles-carousel"
            autoPlay={!!featuredTilesConfig.autoplayDuration}
            infiniteLoop
            interval={featuredTilesConfig.autoplayDuration * 100}>
            {(featuredTiles.data &&
              featuredTiles.data.map((t, i) => (
                <>
                  {/* This invisible image fixes a bug where the carousel swiping acts wierd */}
                  <img
                    css={css`
                      height: 1px;
                      width: 1px !important;
                      overflow: hidden;
                      clip: rect(0 0 0 0);
                      clip-path: inset(50%);
                      height: 1px;
                      overflow: hidden;
                      position: absolute;
                    `}
                    src="/favicon.ico"
                    alt=""
                  />
                  <TrainertipsCard
                    key={i}
                    isMobile={isMobile}
                    category={t.authorName ? null : 'Trenertips'}
                    authorName={t.authorName}
                    authorImage={t.authorImage}
                    onClick={() => handleClickTile(t)}
                    image={t.image}
                    imageAltText=""
                    text={t.title}
                    variant="large"
                    mobileBreakpoint={mobileLayoutBreakpoint}
                    mobileWrapperStyle={css``}
                  />
                </>
              ))) || (
              <TrainertipsCardWrapper variant="large" key="loader">
                <CourseCardLoader
                  ascpectRatioImagePart={2.2}
                  customCssImagePartContent={{ maxHeight: '200px' }}
                  customCssWrapper={css`
                    overflow: hidden;
                    border-radius: inherit;
                    height: 0; /*ensures this dosent make the wrapepr any larger*/
                  `}
                  customCssTextPart={css`
                    filter: brightness(97%);
                  `}
                  customCssImagePart={css`
                    max-height: 200px;
                  `}
                />
              </TrainertipsCardWrapper>
            )}
          </Carousel>
        </Item>
        {fullwidthTile &&
          fullwidthTile.placement === 'top' &&
          renderTile(fullwidthTile, true, 'fullwidth-top')}

        {tiles.map((tile, i) => renderTile(tile, false, i))}

        {fullwidthTile &&
          fullwidthTile.placement === 'bottom' &&
          renderTile(fullwidthTile, true, 'fullwidth-bottom')}
      </MasonryGrid>
    </Wrapper>
  );
};

export default TileGrid;
