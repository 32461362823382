/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css, keyframes } from '@emotion/core';
import { ReactComponent as HexagonLock } from '@src/assets/svg/map/HexagonLock.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const colors = {
  DONE: {
    border: 'none',
    shadow: '#21CE6C',
    primary: '#1A934F',
  },
  DEFAULT_ACTIVE: {
    border: '#4E4300',
    shadow: '#9a8b19',
    primary: '#dec821',
  },
  ACTIVE: {
    border: '#043C75',
    shadow: '#3080D5',
    primary: '#5AB3FF',
  },
  DEFAULT: {
    border: 'none',
    shadow: '#C7C7C7',
    primary: '#F2F2F2',
  },
};

const shapeHoverFill = css({
  '&:hover #Layer1': {
    fill: colors.ACTIVE.shadow,
  },
  '&:hover #Layer3': {
    fill: colors.ACTIVE.shadow,
  },
  '&:hover #Layer2': {
    fill: colors.ACTIVE.primary,
  },
});

const shapeHoverOrFocus = css({
  '&:hover #Border1': {
    fill: colors.ACTIVE.border,
  },
  '&:hover #Border2': {
    fill: colors.ACTIVE.border,
  },
  '&:focus #Border1': {
    fill: colors.ACTIVE.border,
  },
  '&:focus #Border2': {
    fill: colors.ACTIVE.border,
  },
});

const shapeStyles = css`
  #Layer1 {
    fill: none;
    transition: fill 0.4s ease-out;
  }
  #Layer3 {
    fill: none;
    transition: fill 0.4s ease-out;
  }
  #Layer2 {
    fill: none;
    transition: fill 0.4s ease-out;
  }
  #Border1 {
    fill: none;
    transition: fill 0.4s ease-out;
  }
  #Border2 {
    fill: none;
    transition: fill 0.4s ease-out;
  }
`;
// const shapeVariantOpenActive = css`
//   #Layer1 {
//     fill: ${colors.DEFAULT_ACTIVE.shadow};
//   }
//   #Layer3 {
//     fill: ${colors.DEFAULT_ACTIVE.shadow};
//   }
//   #Layer2 {
//     fill: ${colors.DEFAULT_ACTIVE.primary};
//   }
//   #Border1 {
//     fill: ${colors.DEFAULT_ACTIVE.border};
//   }
//   #Border2 {
//     fill: ${colors.DEFAULT_ACTIVE.border};
//   }
// `;
const shapeVariantActive = css`
  #Layer1 {
    fill: ${colors.ACTIVE.shadow};
  }
  #Layer3 {
    fill: ${colors.ACTIVE.shadow};
  }
  #Layer2 {
    fill: ${colors.ACTIVE.primary};
  }
  #Border1 {
    fill: ${colors.ACTIVE.border};
  }
  #Border2 {
    fill: ${colors.ACTIVE.border};
  }
`;
const shapeVariantDone = css`
  #Layer1 {
    fill: ${colors.DONE.primary};
  }
  #Layer2 {
    fill: ${colors.DONE.shadow};
  }
`;
const shapeVariantDefault = css`
  #Layer1 {
    fill: ${colors.DEFAULT.shadow};
  }
  #Layer2 {
    fill: ${colors.DEFAULT.primary};
  }
`;

const hexagonCompleteAnimation = keyframes`
from {
  transform: scale(1);
}
60% {
  transform: scale(1.2);
}
to {
  transform: scale(1);
}
`;

const layer1CompleteAnimation = keyframes`
from {
  fill: ${colors.DEFAULT.shadow};
}
to {
  fill: ${colors.DONE.primary};
}
`;

const layer2CompleteAnimation = keyframes`
from {
  fill: ${colors.DEFAULT.primary};
}
to {
  fill: ${colors.DONE.shadow};
}
`;

const hexagonUnlockAnimation = keyframes`
from {
  transform: scale(1);
}
40% {
  transform: scale(1.2);
}
to {
  transform: scale(1);
}
`;

const lockShakeAnimation = keyframes`
from {
  transform: translateX(0);
}
20% {
  transform: translateX(-20%);
}
40% {
  transform: translateX(20%);
}
60% {
  transform: translateX(-10%);
}
80% {
  transform: translateX(10%);
}
to {
  transform: translateX(0);
}
`;

const textUnlockAnimation = keyframes`
from {
  opacity: 0;
  transform: translateX(-50%) scale(0.6);
}
60% {
  transform: translateX(-50%) scale(1.4);
}
to {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}
`;

const lockUnlockAnimation = keyframes`
from {
  opacity: 1;
  transform: scale(1);
}
to {
  opacity: 0;
  transform: scale(1.5);
}
`;

export const Lock = styled(HexagonLock)(
  css`
    position: absolute;
    bottom: 0.6em;
    right: -0.6em;
    opacity: 1;
    color: ${colors.DEFAULT.shadow};
    font-size: 1.3em;
    width: 1.7em;
    height: 1.7em;

    #Layer2 {
      fill: #dfe0df;
    }
  `,
  ({ animateUnlocked }) =>
    animateUnlocked &&
    css`
      animation: ${lockUnlockAnimation} 1.5s ease forwards;
    `
);

export const CourseNumber = styled.span(
  css`
    position: absolute;
    top: -0.5em;
    line-height: 1;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  `,
  ({ animateUnlocked }) =>
    animateUnlocked &&
    css`
      animation: ${textUnlockAnimation} 1.5s ease forwards;
    `
);

export const Base = styled.div(
  css`
    position: relative;
  `,
  shapeStyles,
  ({ active, status }) =>
    (active && shapeVariantActive) ||
    (status === 'DONE' && shapeVariantDone) ||
    shapeVariantDefault,
  ({ locked, lockedOpacity, disableInteraction }) =>
    !locked
      ? css(
          !disableInteraction ? shapeHoverFill : '',
          shapeHoverOrFocus,
          css`
            &:not(.disableInteraction) {
              cursor: pointer;
            }

            &:hover:not(.disableInteraction) {
              #Layer1 {
                transition: none;
              }
              #Layer3 {
                transition: none;
              }
              #Layer2 {
                transition: none;
              }
              #Border1 {
                transition: none;
              }
              #Border2 {
                transition: none;
              }
            }
          `
        )
      : css(
          css`
            transition: translation 0.8s ease-out;

            &,
            * {
              cursor: default !important;
            }

            &:hover {
              ${Lock} {
                animation: ${lockShakeAnimation} 0.6s ease-out forwards;
              }
            }
          `,
          lockedOpacity &&
            css`
              opacity: 0.6;
            `
        ),
  ({ animateCompleted, animateUnlocked, status, active }) =>
    (animateCompleted &&
      !active &&
      css`
        svg {
          animation: ${hexagonCompleteAnimation} 1.5s ease-out forwards;
          #Layer1 {
            animation: ${layer1CompleteAnimation} 1.5s ease-out forwards;
          }
          #Layer2 {
            animation: ${layer2CompleteAnimation} 1.5s ease-out forwards;
          }
        }
      `) ||
    (animateUnlocked &&
      status !== 'DONE' &&
      css`
        animation: ${hexagonUnlockAnimation} 1.5s ease forwards;
      `)
);

export const stack = css({
  flex: '1 1 50%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  '&.focus-visible': {
    border: `3px solid gray`,
  },
});

export const text = css({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'black',
  fontSize: '1.5em',
  fontWeight: 'bold',
});

const titleVariant = (props) => {
  switch (props.variant) {
    case 'a11y':
      return {
        backgroundColor: 'rgb(0,0,0,0.5)',
        padding: '0 1em',
        borderRadius: 4,
        border: '1px solid black',
      };
    default:
      return null;
  }
};

export const Title = styled('div')(
  {
    color: 'white',
    wordWrap: 'break-word',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  (props) => titleVariant(props)
);
