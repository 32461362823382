/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { highlightOnKeyboardFocusMixin } from '@styles/mixins';
import { useDispatch, useSelector } from 'react-redux';
import { coursesEndCourse } from '@actions/courses.actions';
import { getShouldShowMapInsteadOfCourseCatalog } from '@selectors/courses.selectors';
import { linearGradientWithAdjustableColorStop } from '@facepaint';
import { lightOrDark } from '@utils/misc.utils';
import { i18n, strs } from '@src/i18n';
import {
  Base,
  Stack,
  Logo,
  HamburgerMenuToggle,
  HamburgerMenuItemsWrapper,
  Button,
  row,
  separate,
  display,
  HamburgerMenuBackdrop,
} from './styles';

export const MenuTwoButton = ({
  icon,
  text,
  allowMotion,
  onLogoutClick,
  allowLogout,
  variant,
  routeHomeWhenActive,
  lastItem,
  onClick,
  ...props
}) => {
  const [flip, triggerFlip] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const { to } = props;

  const courseCatalogLocked = useSelector(
    getShouldShowMapInsteadOfCourseCatalog
  );

  // console.log(allowLogout, onLogoutClick);

  const shouldNavigateHome = () => {
    return routeHomeWhenActive && location.pathname === to;
  };

  const onClickMotion = () => {
    if (allowMotion) triggerFlip(!flip);
  };

  const onClickLogout = () => {
    if (allowLogout) onLogoutClick();
  };

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      onClickMotion();
      onClickLogout();
      if (shouldNavigateHome()) {
        dispatch(coursesEndCourse());
      }
    }
  };

  if (
    shouldNavigateHome() &&
    (courseCatalogLocked || courseCatalogLocked === null)
  ) {
    return null;
  }

  const disableIcons = variant === 'hamburger';

  return (
    <Button
      onClick={() => onClickHandler()}
      {...props}
      variant={variant}
      lastItem={lastItem}>
      {Boolean(icon) && allowMotion ? (
        <motion.div
          variants={{ down: { rotate: 0 }, up: { rotate: 180 } }}
          animate={flip ? 'up' : 'down'}
          transition={{ duration: 0 }}
          initial="down">
          {!disableIcons && <FontAwesomeIcon icon={icon} />}
        </motion.div>
      ) : shouldNavigateHome() && !disableIcons ? (
        <FontAwesomeIcon icon="home" />
      ) : (
        !disableIcons && <FontAwesomeIcon icon={icon} />
      )}
      {Boolean(icon) && <span>&nbsp;&nbsp;</span>}
      <span>{shouldNavigateHome() ? i18n(strs.GOTO_HOME) : text}</span>
    </Button>
  );
};

export const MenuTwo = (props) => {
  const {
    buttons,
    logo,
    onLogoutClick,
    baseColor,
    createGradient,
    variant,
    showSwitchTrackVersionButton,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const [hamburgerMenuState, setHamburgerMenuState] = useState('closed');

  function pathSelector(obj) {
    if (obj.routeHomeWhenActive && location.pathname === obj.to) {
      return '/';
    }

    return obj.to;
  }

  const gradient = linearGradientWithAdjustableColorStop(
    baseColor,
    0.4,
    1,
    0.1
  );

  const lightOrdarkBg = lightOrDark(baseColor);

  return (
    <>
      <Base
        css={[
          createGradient ? gradient : { backgroundColor: baseColor },
          { borderBottom: 'none' },
        ]}>
        <Stack as="nav">
          {/* home button */}
          <Link
            to="/"
            tabIndex={0}
            onClick={() => {
              setHamburgerMenuState('closedByNavigation');
              dispatch(coursesEndCourse());
            }}
            css={css`
              ${highlightOnKeyboardFocusMixin};
            `}>
            <Logo src={logo} />
          </Link>

          {variant === 'hamburger' && (
            <HamburgerMenuToggle
              variants={{ closed: { rotate: 0 }, open: { rotate: 90 } }}
              animate={hamburgerMenuState === 'open' ? 'open' : 'closed'}
              transition={{
                type: 'tween',
                stiffness: 9999999, // disable spring effect
                duration: 0.25,
              }}
              initial="closed"
              onClick={() =>
                setHamburgerMenuState((old) =>
                  old === 'open' ? 'closedByToggleButton' : 'open'
                )
              }
              tabIndex="0"
              role="button"
              aria-label="Open menu">
              <FontAwesomeIcon
                icon={
                  hamburgerMenuState === 'open'
                    ? ['far', 'times']
                    : ['far', 'bars']
                }
                color={lightOrdarkBg === 'light' ? 'black' : 'white'}
              />
            </HamburgerMenuToggle>
          )}

          {/* items for horizontal */}
          {variant === 'horizontal' && (
            <div css={[row, separate, display]}>
              {showSwitchTrackVersionButton && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    @media (max-width: 900px) {
                      flex-direction: column;
                      button {
                        padding: 0.3em;
                      }
                      button:first-child {
                        margin-bottom: 0.2em;
                      }
                    }
                  `}>
                  <MenuTwoButton
                    css={css`
                      font-size: 0.8em;
                      background: gainsboro;
                      margin-right: 8px;
                      @media (max-width: 900px) {
                        font-size: 0.75em;
                      }
                    `}
                    icon={['fas', 'code-branch']}
                    variant="horizontal"
                    onClick={() => {
                      window.location.href = '/set-atlas/barn';
                    }}
                    text="Barn"
                  />
                  <MenuTwoButton
                    css={css`
                      font-size: 0.8em;
                      background: gainsboro;
                    `}
                    icon={['fas', 'code-branch']}
                    variant="horizontal"
                    onClick={() => {
                      window.location.href = '/set-atlas/ungdom';
                    }}
                    text="Ungdom"
                  />
                </div>
              )}
              {Object.keys(buttons).map((key, i, arr) => {
                return (
                  <Link
                    to={pathSelector(buttons[key])}
                    tabIndex={0}
                    aria-label={i18n(buttons[key].textKey)}
                    css={css`
                      ${highlightOnKeyboardFocusMixin};
                    `}>
                    <MenuTwoButton
                      lastItem={i === arr.length - 1}
                      variant="horizontal"
                      tabIndex={-1}
                      onLogoutClick={onLogoutClick}
                      {...buttons[key]}
                      text={i18n(buttons[key].textKey)}
                    />
                  </Link>
                );
              })}
            </div>
          )}

          {variant === 'hamburger' && hamburgerMenuState === 'open' && (
            <HamburgerMenuBackdrop
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeOut', duration: 0.2 }}
            />
          )}

          {/* items for hamburger 
          
          if the hamburger-menu is clsoed by the toggle-button, we want this lsit to animte closing
          if it is closed by navigation we want to hide this lsit right away and skip the animated closing
          */}
          {variant === 'hamburger' &&
            hamburgerMenuState !== 'closedByNavigation' && (
              <HamburgerMenuItemsWrapper
                state={hamburgerMenuState}
                aria-hidden={hamburgerMenuState !== 'open'}
                variants={{
                  closed: { height: 0, overflow: 'hidden' },
                  open: { height: '100vh', overflow: 'visible' },
                }}
                animate={hamburgerMenuState === 'open' ? 'open' : 'closed'}
                transition={{
                  ease: 'easeOut',
                  duration: hamburgerMenuState === 'open' ? 0.37 : 0.25,
                }}
                initial="closed">
                {showSwitchTrackVersionButton && (
                  <>
                    <MenuTwoButton
                      icon={['fas', 'code-branch']}
                      variant="hamburger"
                      onClick={() => {
                        window.location.href = '/set-atlas/barn';
                      }}
                      text="Til barneversjon"
                    />
                    <MenuTwoButton
                      icon={['fas', 'code-branch']}
                      variant="hamburger"
                      onClick={() => {
                        window.location.href = '/set-atlas/ungdom';
                      }}
                      text="Til ungdomsversjon"
                    />
                  </>
                )}
                {Object.keys(buttons).map((key, i, arr) => {
                  return (
                    <Link
                      to={pathSelector(buttons[key])}
                      tabIndex={0}
                      onClick={() =>
                        setHamburgerMenuState('closedByNavigation')
                      }
                      css={css`
                        ${highlightOnKeyboardFocusMixin};
                      `}>
                      <MenuTwoButton
                        lastItem={i === arr.length - 1}
                        variant="hamburger"
                        tabIndex={-1}
                        onLogoutClick={onLogoutClick}
                        {...buttons[key]}
                        text={i18n(buttons[key].textKey)}
                        icon={null}
                      />
                    </Link>
                  );
                })}
              </HamburgerMenuItemsWrapper>
            )}
        </Stack>
      </Base>
    </>
  );
};

MenuTwoButton.propTypes = {
  icon: PropTypes.array,
  text: PropTypes.string.isRequired,
  allowMotion: PropTypes.bool,
  onLogoutClick: PropTypes.func,
  allowLogout: PropTypes.bool,
};

MenuTwoButton.defaultProps = {
  icon: false,
  allowMotion: false,
  onLogoutClick: false,
  allowLogout: false,
};
