import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';

import './indicator-dots.scss';

const IndicatorDots = ({ numOfItems, activeItem, onItemClicked, isCarousel }) =>
  isCarousel ? (
    <DotGroup>
      <div className="indicator-dots">
        <div className="indicator-dots__arrows">
          <ButtonBack>
            <div
              role="button"
              tabIndex={0}
              className="indicator-dots__dot__arrow">
              <i className="fa fa-angle-left" />
            </div>
          </ButtonBack>
          <ButtonNext>
            <div
              role="button"
              tabIndex={0}
              className="indicator-dots__dot__arrow indicator-dots__dot__arrow">
              <i className="fa fa-angle-right" />
            </div>
          </ButtonNext>
        </div>
      </div>
    </DotGroup>
  ) : (
    <div className="indicator-dots">
      <div className="indicator-dots__wrapper">
        {Array(numOfItems)
          .fill()
          .map((_, id) => (
            <div
              role="button"
              tabIndex={0}
              key={`indicator-dot-${id}`}
              onClick={() => onItemClicked(id)}
              onKeyPress={() => {}}
              className={classNames([
                'indicator-dots__dot',
                { 'indicator-dots__dot--active': id === activeItem },
              ])}
            />
          ))}
      </div>
    </div>
  );

IndicatorDots.propTypes = {
  numOfItems: PropTypes.number.isRequired,
  activeItem: PropTypes.number,
  onItemClicked: PropTypes.func,
  isCarousel: PropTypes.bool,
};

IndicatorDots.defaultProps = {
  activeItem: null,
  onItemClicked: () => {},
  isCarousel: false,
};

export default IndicatorDots;
