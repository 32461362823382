import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fetchedDataWrapperShape } from '@types/fetched-data';

import './courses-menu-chipses.scss';

const CoursesMenuChipses = ({
  className,
  competencegroups,
  handleCompetenceChipsClick,
  isMobile,
  selectedCompetencegroupId,
  selectedSubcompetencegroupId,
}) => (
  <>
    {(selectedCompetencegroupId &&
      Array.isArray(competencegroups.data) &&
      competencegroups.data.find(
        competencegroup => competencegroup.id === selectedCompetencegroupId
      ).children.length && (
        <div
          className={classNames([
            'chipses',
            { 'chipses--mobile': isMobile },
            { [className]: !!className },
          ])}>
          <div className="chipses__container">
            {selectedCompetencegroupId &&
              Array.isArray(competencegroups.data) &&
              competencegroups.data
                .find(
                  competencegroup =>
                    competencegroup.id === selectedCompetencegroupId
                )
                .children.map((competencegroup, i) => (
                  <button
                    key={i}
                    className={classNames('chipses__chips', {
                      'chipses__chips--active':
                        selectedSubcompetencegroupId === competencegroup.id,
                    })}
                    type="button"
                    onClick={() =>
                      handleCompetenceChipsClick(competencegroup.id)
                    }>
                    {competencegroup.title}
                  </button>
                ))}
          </div>
        </div>
      )) ||
      (!isMobile && (
        <div
          style={{
            display: 'block',
            height: '32px',
          }}
        />
      ))}
  </>
);

CoursesMenuChipses.propTypes = {
  className: PropTypes.string,
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  handleCompetenceChipsClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  selectedCompetencegroupId: PropTypes.number,
  selectedSubcompetencegroupId: PropTypes.object.isRequired,
};

CoursesMenuChipses.defaultProps = {
  className: null,
  isMobile: false,
  selectedCompetencegroupId: null,
};

export default CoursesMenuChipses;
