import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './styles';

export const CourseInformationButton = ({ text, disabled, ...props }) => {
  return (
    <Button
      type="button"
      value={text}
      name={text}
      disabled={disabled}
      {...props}>
      {text}
    </Button>
  );
};

CourseInformationButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CourseInformationButton.defaultProps = {
  disabled: null,
};
