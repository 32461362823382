/* eslint-disable react/jsx-fragments */
import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { i18n, strs } from '@src/i18n';

import { connect, useSelector } from 'react-redux';
import {
  getProfileId,
  getOrganisationId,
  getMessageUnreadCount,
} from '@selectors/profile.selectors';
import { getRouteWithParams } from '@utils/routes.utils';
import {
  employees as employeeRoutes,
  myEducation,
} from '@routes/routes.manifest';
import { withRouter, matchPath } from 'react-router';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import { getSelectedCompetencegroupId } from '@selectors/courses.selectors';
import MobileMenu from './mobile-menu/mobile-menu';
import DesktopMenu from './desktop-menu/desktop-menu';

import { rotor } from './rotor';

export const getColorConfigForGroup = ({
  courseGroupId,
  pathname,
  isMobile,
  configObject,
  forMenu,
}) => {
  const getTopPartColorFromCourseGroup = configObject.getProperty(
    `params.menu.getTopPartColorFromCourseGroup`
  );
  const defaultColor = configObject.getProperty(
    `params.menu.${isMobile ? 'baseColorMobile' : 'baseColorDesktop'}`
  );

  if (forMenu && !getTopPartColorFromCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
      getTopPartColorFromCourseGroup,
    };
  }

  if (!isMobile) {
    return {
      color: configObject.getProperty(`params.menu.baseColorDesktop`),
      createGradient: false,
      getTopPartColorFromCourseGroup,
    };
  }
  const noActiveCourseGroup =
    (isMobile && pathname === '/course-catalog') ||
    (!isMobile && !courseGroupId) ||
    pathname === '/atlas';

  if (noActiveCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
      getTopPartColorFromCourseGroup,
    };
  }

  return {
    color:
      configObject.getProperty(
        `params.menu.configByCourseGroupId.${courseGroupId}.baseColor`
      ) || defaultColor,
    createGradient:
      configObject.getProperty(
        `params.menu.configByCourseGroupId.${courseGroupId}.gradient`
      ) || false,
    getTopPartColorFromCourseGroup,
  };
};

const Menu = ({
  onLogoutClick,
  isManager,
  isMobile,
  orgId,
  personId,
  profileData,
  messagesCount,
  location,
}) => {
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  const configObject = useSelector(getConfigObject);
  const config = configObject.getProperty('params.menu');
  const selectedCourseGroupId = useSelector(getSelectedCompetencegroupId);
  const isMobileCourseCatalog = useSelector(getIsMobileCourseCatalog);

  const toggleSidebarMenu = () => {
    setMobileMenuOpened(!isMobileMenuOpened);
  };

  const messagesCountString = (messagesCount && ` (${messagesCount})`) || '';
  const baseTitle =
    configObject.getProperty('params.default-title-key') &&
    i18n(configObject.getProperty('params.default-title-key'));
  const menuLinksManager = [
    {
      name: 'Min side',
      title: 'Min Side',
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
      children: [
        {
          name: `Meldinger${messagesCountString}`,
          title: 'Meldinger - Min Side',
          path: getRouteWithParams(myEducation.messagesView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.messagesView.active,
            exact: false,
          }),
        },
        {
          name: `Gjennomført opplæring`,
          title: 'Gjennomført opplæring - Min Side',
          path: getRouteWithParams(myEducation.cvView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.cvView.active,
            exact: false,
          }),
        },
      ],
    },
    {
      name: 'Kurskatalog',
      title: `${i18n(strs.COURSE_CATALOG)}${
        (baseTitle && ` - ${baseTitle}`) || ''
      }`,
      path: '/course-catalog',
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
    {
      name: 'Dashboard',
      title: 'Dashboard - Min Side',
      path: getRouteWithParams(employeeRoutes.dashboard, { orgId }),
      active: matchPath(location.pathname, {
        path: '/dashboard',
        exact: false,
      }),
    },
    {
      name: 'Atlas',
      title: `${i18n(strs.ATLAS)}${(baseTitle && ` - ${baseTitle}`) || ''}`,
      path: '/atlas',
      active: matchPath(location.pathname, {
        path: '/atlas',
        exact: false,
      }),
    },
    {
      name: 'Ansatte',
      title: 'Ansatte - Min Side',
      path: getRouteWithParams(employeeRoutes.employees, { orgId }),
      active: matchPath(location.pathname, {
        path: '/employees',
        exact: false,
      }),
    },
  ];

  const menuLinksEmployee = [
    {
      name: 'Min side',
      path: '/my-education',
      active: matchPath(location.pathname, {
        path: '/my-education',
        exact: false,
      }),
      children: [
        {
          name: `Meldinger${messagesCountString}`,
          path: getRouteWithParams(myEducation.messagesView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.messagesView.active,
            exact: false,
          }),
        },
        {
          name: `Gjennomført opplæring`,
          path: getRouteWithParams(myEducation.cvView.path, { personId }),
          active: matchPath(location.pathname, {
            path: myEducation.cvView.active,
            exact: false,
          }),
        },
      ],
    },
    {
      name: 'Kurskatalog',
      path: '/course-catalog',
      active: matchPath(location.pathname, {
        path: '/course-catalog',
        exact: false,
      }),
    },
  ];

  const menuLinks = isManager ? menuLinksManager : menuLinksEmployee;

  const setDocumentTitle = (links) => {
    return (
      links &&
      links.map(({ title, active, children }) => {
        if (active && title) {
          document.title = title;
        }
        return setDocumentTitle(children);
      })
    );
  };

  setDocumentTitle(menuLinksManager);

  const colorConfigForGroup = getColorConfigForGroup({
    courseGroupId: selectedCourseGroupId,
    pathname: location.pathname,
    isMobile: isMobileCourseCatalog,
    configObject,
    forMenu: true,
  });

  const useMap = configObject.getProperty('params.show-map');
  const configForCurrentMap = useSelector(getPropertiesForCurrLangAndTrack);

  return (
    <React.Fragment>
      {config && config.type === 'menu-two'
        ? rotor({
            onLogoutClick,
            ...config,
            ...(useMap ? configForCurrentMap.menu : {}),
            variant: isMobile ? 'hamburger' : 'horizontal',
            createGradient: colorConfigForGroup.createGradient,
            baseColor: colorConfigForGroup.color,
          }).menuTwo
        : (isMobile && (
            <MobileMenu
              menuLinks={menuLinks}
              orgId={orgId}
              isSidebarOpen={isMobileMenuOpened}
              onLogoutClick={onLogoutClick}
              onToggleSidebarClick={toggleSidebarMenu}
              onMenuItemClick={toggleSidebarMenu}
              profile={profileData}
            />
          )) || (
            <DesktopMenu
              orgId={orgId}
              menuLinks={menuLinks}
              onLogoutClick={onLogoutClick}
            />
          )}
    </React.Fragment>
  );
};

Menu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  profileData: PropTypes.shape({}),
  orgId: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  messagesCount: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

Menu.defaultProps = {
  profileData: null,
};

const mapStateToProps = (state) => ({
  orgId: getOrganisationId(state),
  personId: getProfileId(state),
  messagesCount: getMessageUnreadCount(state),
  isMobile: getIsMobile(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
