import { eventChannel, END } from 'redux-saga';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import { backendUrl, nanoLearningUrl } from '@config';

import { stringifyUrlParams } from '@utils/requests.utils';

import { ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT } from '@actions/router.actions';
import { notificationsAdd } from '@actions/notifications.actions';
import * as alertActions from '@actions/alert.actions';

import retry from '@utils/sagas.utils';

import * as coursesActions from '@actions/courses.actions';
import * as configActions from '@actions/config.actions';
import {
  profileFetchPersonEvents,
  profileUpdateOneCompetences,
} from '@actions/profile.actions';

import {
  getEmployeesEvents,
  getSelectedPersonUsername,
} from '@selectors/employees.selectors';

import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';

import {
  employeesFetchEvents,
  employeesFetchSelectedPersonEvents,
} from '@actions/employees.actions';

import {
  getCompetencesSearchTerm,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  getSelectedCompetencetypes,
  getCompetencegroups as getCompetencegroupsSelector,
  getCompetences as getCompetencesSelector,
  getInitializeMyCoursesView,
} from '@selectors/courses.selectors';

import {
  getProfile,
  isManager,
  getProfileUserName,
} from '@selectors/profile.selectors';

const defaultFieldsCompetences = [
  'short_description',
  'files',
  'title',
  'modified',
  'course_type',
  'competence_type_id',
  'durations',
  'has_parents',
  'competence_type',
  'url',
  'category',
  'description',
];

const defaultFieldsCmsContent = [
  'title',
  'image',
  'video',
  'imageCaption',
  'imageAltText',
  'authorText',
  'authorImage',
  'files',
  'teaserForListView',
  'category',
  'short_description',
  'description',
];

function* getShouldGetCmsDataForCompetenceGroup(competenceGroupId) {
  const configObject = yield select(getConfigObject);
  const groupsToGetDataFromCms = configObject.getProperty(
    'routes.course-catalog.getCourseInfoFromCmsForGroups'
  );
  return (
    groupsToGetDataFromCms && groupsToGetDataFromCms.includes(competenceGroupId)
  );
}

function* getCompetenceById(action) {
  const { cid } = action.payload;
  const selectedCompetencegroupId = [
    yield select(getSelectedCompetencegroupId),
  ];

  const fields = defaultFieldsCompetences.join(',');

  const params = encodeURI(
    stringifyUrlParams({
      fields,
      view: 'full',
    })
  );

  const competence = yield retry(() =>
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
      .then((response) => response.data.competences)
  );

  const alsoGetDataFromCms = yield call(
    getShouldGetCmsDataForCompetenceGroup,
    selectedCompetencegroupId[0]
  );

  let cmsData = {};
  const cmsUrl = competence[0].url;
  if (alsoGetDataFromCms && cmsUrl) {
    const cmsParams = encodeURI(
      stringifyUrlParams({
        fields: defaultFieldsCmsContent.join(','),
        view: 'full',
      })
    );

    cmsData = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/cms/${cmsUrl}?${cmsParams}`,
          withCredentials: true,
        })
        .then((response) => response.data.pages[0])
    );
  }

  return { ...competence[0], cid: competence[0].id, cmsData };
}

function* waitForInitializeMyCoursesViewToFinish() {
  const initializeMyCoursesView = yield select(getInitializeMyCoursesView);
  if (initializeMyCoursesView.isFetching) {
    yield take([
      coursesActions.coursesInitializeMyCoursesViewSuccess,
      coursesActions.coursesInitializeMyCoursesViewFailure,
    ]);
  }
}

function* getCompetences(action) {
  const searchTerm = yield select(getCompetencesSearchTerm) || null;

  const selectedSubcompetencegroupId = yield select(
    getSelectedSubcompetencegroupId
  );

  const selectedSubSubcompetencegroupId = yield select(
    getSelectedSubSubcompetencegroupId
  );

  const selectedCompetencetypes = [
    ...(yield select(getSelectedCompetencetypes)),
  ];
  const selectedCompetencegroupId = [
    yield select(getSelectedCompetencegroupId),
  ];

  const notStartedByInitializeMyCoursesView =
    action && action.type !== coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW;
  if (notStartedByInitializeMyCoursesView) {
    // to prevent race conditions between this request and the one initialized by initialzie mycourses view

    yield waitForInitializeMyCoursesViewToFinish(action);
  }

  const configObject = yield select(getConfigObject);

  let types =
    (selectedCompetencetypes.length && selectedCompetencetypes.join(',')) ||
    'complex,ecourse,equivalents,course,content';

  // for these groups, get cms info in addition to some course info and merge them together afterwards
  const alsoGetCourseDataFromCms = yield call(
    getShouldGetCmsDataForCompetenceGroup,
    selectedCompetencegroupId[0]
  );

  const fields = (
    (action && action.payload && action.payload.fields) ||
    defaultFieldsCompetences
  ).join(',');

  // if we have an override for which types that are allowed
  // for certain competencegroups, set the types to this override
  const typesConfigOverrideForCompetencegroupId = configObject.getProperty(
    'routes.course-catalog.courseTypesAllowedForCompetenceGroupId'
  );

  if (typesConfigOverrideForCompetencegroupId) {
    const overrideForGroup =
      typesConfigOverrideForCompetencegroupId[selectedCompetencegroupId[0]];
    const overrideForSubgroup =
      typesConfigOverrideForCompetencegroupId[selectedSubcompetencegroupId];

    if (overrideForGroup) {
      types = overrideForGroup.join(',');
    }
    if (overrideForSubgroup) {
      types = overrideForSubgroup.join(',');
    }
  }

  // if defined, get courses that are children of this group in the competence tree
  const configForCurrLangAndTrack = yield select(
    getPropertiesForCurrLangAndTrack
  );

  const baseCompetenceGroupId =
    configForCurrLangAndTrack.courseCatalog.startAtGroupId;

  let params = encodeURI(
    stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
        selectedSubSubcompetencegroupId ||
        selectedSubcompetencegroupId ||
        selectedCompetencegroupId ||
        baseCompetenceGroupId ||
        '',
      'types[]': types,
    })
  );

  const disalowwedCompetenceGroupIds = configObject.getProperty(
    'routes.course-catalog.disalowwedCompetenceGroupIds'
  );
  const allowChildCompetenceForGroupIds = configObject.getProperty(
    'routes.course-catalog.allowChildCompetenceForGroupIds'
  );

  if (params) {
    params = `?${params}`;
  }

  yield put(coursesActions.coursesGetCompetencesRequest());

  try {
    let competences;
    const limit = 100;
    if (searchTerm) {
      competences = yield retry(() =>
        axios
          .request({
            method: 'GET',
            params: {
              term: searchTerm,
              course_types: types,
              not_course_groups: disalowwedCompetenceGroupIds.join(','),
            },
            url: `${backendUrl}/plugin/search_courses`,
            withCredentials: true,
          })
          .then((response) => response.data.courses)
      );
    } else {
      competences = yield retry(() =>
        axios
          .request({
            method: 'GET',
            params: {
              children: '1',
              limit: limit + 1,
            },
            url: `${backendUrl}/api/competences/${params}`,
            withCredentials: true,
          })
          .then((response) => response.data.competences)
      );
    }

    let filter_out = null;
    if (
      selectedCompetencegroupId &&
      !allowChildCompetenceForGroupIds.includes(selectedCompetencegroupId[0])
    ) {
      filter_out = competences.filter((c) => !c.has_parents);
    } else {
      filter_out = competences;
    }

    let hasMore = false;
    if (filter_out.length >= limit) {
      hasMore = true;
      filter_out = filter_out.slice(0, limit - 1);
    }

    let cmsData = [];
    // get info such as cover image, title and similar from cms
    // for each competence and merge info from the cms with the course info
    if (alsoGetCourseDataFromCms) {
      const cmsParams = encodeURI(
        stringifyUrlParams({
          fields: defaultFieldsCmsContent.join(','),
          view: 'full',
        })
      );
      cmsData = yield all(
        filter_out
          .filter((c) => c.url)
          .map((c) =>
            call(() => {
              return axios
                .request({
                  method: 'GET',
                  url: `${backendUrl}/api/cms/${c.url}?${cmsParams}`,
                  withCredentials: true,
                })
                .then((response) => ({
                  ...response.data.pages[0],
                  cid: c.id,
                }));
            })
          )
      );

      // If we got cms data for a course, merge that together with the course data
      filter_out = filter_out.map((c, i) => {
        const cmsDataForCourse = cmsData.find((cms) => cms.cid === c.id) || {};

        const merged = { ...c, cid: c.id, cmsData: cmsDataForCourse };
        return merged;
      });
    }

    yield put(
      coursesActions.coursesGetCompetencesSuccess({
        competences: filter_out,
        hasMore,
      })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCompetencesFailure({ error }));
  }
}

function* getFeaturedCompetences(action) {
  const featuredCompetenceIdsToGet =
    action && action.payload && action.payload.cids;

  yield put(coursesActions.coursesGetFeaturedCompetencesRequest());
  try {
    const featuredCompetences = yield all(
      featuredCompetenceIdsToGet.map((cid) =>
        call(getCompetenceById, { payload: { cid } })
      )
    );
    yield put(
      coursesActions.coursesGetFeaturedCompetencesSuccess({
        featuredCompetences,
      })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetFeaturedCompetencesFailure({ error }));
  }
}

export function getCourseEventsAPI(payload) {
  const fields = [
    'short_description',
    'title',
    'startdate',
    'competence_id',
    'enddate',
    'description',
    'location',
  ].join(',');

  let params = '';
  if (payload.userName) {
    params = encodeURI(
      stringifyUrlParams({
        fields,
        view: 'full',
        user_name: payload.userName,
      })
    );
  } else {
    params = encodeURI(
      stringifyUrlParams({
        fields,
        view: 'full',
      })
    );
  }

  if (params) {
    params = `?${params}`;
  }

  let gourl = `${backendUrl}/api/events${params}`;
  if (payload.userName) {
    gourl = `${backendUrl}/api/personevents${params}`;
  }

  return axios
    .request({
      method: 'get',
      url: gourl,
      params: {
        limit: 100,
      },
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    });
}

function* getCourseEvents() {
  yield put(coursesActions.coursesGetCourseEventsRequest());
  try {
    const events = yield call(getCourseEventsAPI, {});
    yield put(
      coursesActions.coursesGetCourseEventsSuccess({
        courseEvents: events.events,
      })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCourseEventsFailure({ error }));
  }
}

function* getCompetencegroups(action) {
  yield put(coursesActions.coursesGetCompetencegroupsRequest());
  const selectedCompetencegroupId = yield select(getSelectedCompetencegroupId);
  const currCompetencegroups = yield select(getCompetencegroupsSelector);

  const configObjet = yield select(getConfigObject);

  // get groups bellow this group
  const configForCurrLangAndTrack = yield select(
    getPropertiesForCurrLangAndTrack
  );

  const baseCompetenceGroupId =
    configForCurrLangAndTrack.courseCatalog.startAtGroupId;

  const disalowwedCompetenceGroupIds = configObjet.getProperty(
    'routes.course-catalog.disalowwedCompetenceGroupIds'
  );

  try {
    let competencegroups = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencegroups${
            (!selectedCompetencegroupId ||
              currCompetencegroups.data === null) &&
            baseCompetenceGroupId
              ? `/${baseCompetenceGroupId}`
              : ''
          }`,
          params: {
            fields: 'title,children(title)',
            ...((action && action.payload && action.payload.params) || {}),
          },
          withCredentials: true,
        })
        .then((response) => response.data.competencegroups)
    );

    if (baseCompetenceGroupId) {
      competencegroups = competencegroups[0].children;
    }

    competencegroups =
      competencegroups &&
      competencegroups.filter(
        ({ id }) => disalowwedCompetenceGroupIds.indexOf(id) === -1
      );

    const configDefaultCompetenceGroup =
      configForCurrLangAndTrack.courseCatalog.defaultSelectedCompetenceGroupId;

    const defaultSelectedCompetenceGroup = competencegroups.find(
      (c) => c.id === configDefaultCompetenceGroup
    );

    yield put(
      coursesActions.coursesGetCompetencegroupsSuccess({
        competencegroups,
        defaultSelectedCompetenceGroup: defaultSelectedCompetenceGroup
          ? defaultSelectedCompetenceGroup.id
          : null,
      })
    );
    if (action && action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(competencegroups);
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencegroupsFailure({ error }));
  }
}

function* getCompetencetypes() {
  yield put(coursesActions.coursesGetCompetencetypesRequest());
  try {
    const competencetypes = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencetypes/`,
          withCredentials: true,
        })
        .then((response) => response.data.competencetypes)
    );
    yield put(
      coursesActions.coursesGetCompetencetypesSuccess({ competencetypes })
    );
  } catch (error) {
    yield put(coursesActions.coursesGetCompetencetypesFailure({ error }));
  }
}

function* fetchMyCoursesViewData(action) {
  yield put(coursesActions.coursesInitializeMyCoursesViewRequest());

  try {
    yield all([
      yield call(getCompetencegroups),
      yield call(getCompetencetypes),
    ]);
    yield call(getCompetences, action);

    const featuredCompetencesToGet =
      action && action.payload && action.payload.alsoGetFeaturedCompetences;
    if (featuredCompetencesToGet) {
      yield call(getFeaturedCompetences, { payload: featuredCompetencesToGet });
    }

    yield put(coursesActions.coursesInitializeMyCoursesViewSuccess());
  } catch {
    yield put(coursesActions.coursesInitializeMyCoursesViewFailure());
  }
}

function* fetchCompetenceDetails(action) {
  const { cid } = action.payload;
  yield put(coursesActions.coursesEndCourse());

  const fields = [
    'description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'durations',
    'children(competence_type,description,competence_type_id,title,competence_id,files)',
  ].join(',');

  const fields_events = ['title', 'location'].join(',');

  const params = encodeURI(
    stringifyUrlParams({
      fields,
      view: 'full',
    })
  );

  const params_events = encodeURI(
    stringifyUrlParams({
      fields_events,
      view: 'full',
    })
  );

  yield put(coursesActions.coursesFetchCompetenceDetailsGetRequest());
  try {
    const {
      data: {
        competences: [competenceDetails],
      },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {
          children: '1',
        },
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
    );

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
       */
      const {
        data: { events: eventsDetails },
      } = yield retry(() =>
        axios.request({
          method: 'GET',
          params: {
            children: '1',
          },
          url: `${backendUrl}/api/competences/${cid}/events?${params_events}`,
          withCredentials: true,
        })
      );
      competenceDetails.events = eventsDetails;
      /*
      # if manager, and course details

       */
      let userName = yield select(getProfileUserName);
      while (userName === null) {
        yield take();
        userName = yield select(getProfileUserName);
      }
      const isUserManager = yield select(isManager);
      console.log('isUserManager');
      console.log(isUserManager);
      if (isUserManager) {
        const userEvents = yield select(getEmployeesEvents);
        if (!userEvents) {
          console.log('FETCH THE EMPLYEES EVENTs');
          yield put(employeesFetchEvents({}));
        }
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (let i = 0; i < competenceDetails.children.length; i += 1) {
        competenceDetails.children[i].competence_type = {
          competence_type: competenceDetails.children[i].competence_type,
          competence_type_id: competenceDetails.children[i].competence_type_id,
        };
      }
    }

    yield put(
      coursesActions.coursesFetchCompetenceDetailsGetSuccess({
        competenceDetails,
      })
    );
  } catch (error) {
    yield put(
      coursesActions.coursesFetchCompetenceDetailsGetFailure({ error })
    );
  }
}

function* courseSignOn(action) {
  const { courseEventId, employees } = action.payload;

  const { data: person } = yield select(getProfile);
  const signedEmployees = employees || [person];

  yield put(coursesActions.courseSignOnRequest({ ceid: courseEventId }));
  try {
    const results = {
      correct: [],
      errors: [],
    };
    console.log('sign me up.....');
    console.log(signedEmployees);
    yield all(
      signedEmployees.map(({ fullname, user_name }) => {
        const user = fullname && fullname.trim() !== '' ? fullname : user_name;
        return call(() =>
          axios
            .request({
              method: 'POST',
              url: `${backendUrl}/api/personevents/${courseEventId}`,
              params: {
                user_name,
              },
              withCredentials: true,
            })
            .then((response) => {
              results.correct.push({
                user,
                response,
              });
              return response;
            })
            .catch((error) => {
              results.errors.push({
                user,
                error,
              });
              return error;
            })
        );
      })
    );

    if (!employees) {
      let notification;
      if (results.correct && results.correct.length > 0) {
        notification = {
          text: results.correct[0].response.data.message,
          color: 'green',
        };
      } else if (results.errors && results.errors.length > 0) {
        notification = {
          title: 'Feil',
          text: results.errors[0].error.message,
          color: 'red',
        };
      }
      yield put(profileFetchPersonEvents({}));
      yield put(notificationsAdd({ notification }));
    } else {
      yield put(employeesFetchEvents({}));
    }

    yield put(coursesActions.courseSignOnResults({ results }));
    yield put(coursesActions.courseSignOnSuccess());
  } catch (error) {
    yield put(coursesActions.courseSignOnFailure({ error }));
  }
}

function* courseSignOff(action) {
  const { courseEventId, employees } = action.payload;
  const { data: person } = yield select(getProfile);
  const selectedUserName = yield select(getSelectedPersonUsername);
  console.log('selectedUserName', selectedUserName);
  console.log(employees);
  const signedEmployees = employees ? [employees] : [person];

  yield put(coursesActions.courseSignOffRequest({ ceid: courseEventId }));
  try {
    const results = {
      correct: [],
      errors: [],
    };
    console.log('sign me down.....');
    console.log(signedEmployees);
    yield all(
      signedEmployees.map(({ fullname, user_name }) => {
        const user = fullname && fullname.trim() !== '' ? fullname : user_name;
        return call(() =>
          axios
            .request({
              method: 'PUT',
              url: `${backendUrl}/api/personevents/${courseEventId}`,
              params: {
                action: 'off',
                user_name,
              },
              withCredentials: true,
            })
            .then((response) => {
              results.correct.push({
                user,
                response,
              });
              return response;
            })
            .catch((error) => {
              results.errors.push({
                user,
                error,
              });
              return error;
            })
        );
      })
    );

    let notification;
    if (results.correct && results.correct.length > 0) {
      notification = {
        text: results.correct[0].response.data.message,
        color: 'green',
      };
    } else if (results.errors && results.errors.length > 0) {
      notification = {
        title: 'Feil',
        text: results.errors[0].error.message,
        color: 'red',
      };
    }

    if (employees) {
      console.log('employees', employees);
      console.log(employees.user_name);
      if (employees.user_name === selectedUserName) {
        console.log('WE SHOULD UPDATE THE PERSON');
        yield put(employeesFetchSelectedPersonEvents(employees));
      }
      yield put(employeesFetchEvents(employees));
    }

    yield put(profileFetchPersonEvents({}));
    yield put(notificationsAdd({ notification }));

    yield put(coursesActions.courseSignOffResults({ results }));
    yield put(coursesActions.courseSignOffSuccess());
  } catch (error) {
    yield put(coursesActions.courseSignOffFailure({ error }));
  }
}

function* coursePreviewLoaded(action) {
  try {
    yield call(fetchCompetenceDetails, action);
  } catch (e) {
    console.error(e);
  }
  yield put(alertActions.actionClear());
}

function* onCourseStart(action) {
  try {
    const { cid, type } = action.payload;
    if (type === 10) {
      const lmsStartUrl = `${backendUrl}/ecourse/start?cid=${cid}&ref_id=-1&subpath=/grape`;
      // CLEAR THE INFO BOX.
      const win = window.open(lmsStartUrl, '_blank');
      yield put(
        coursesActions.coursesLmsRunning({ url: lmsStartUrl, opened: !!win })
      );
    } else if (type === 24) {
      const nanoCourseStartUrl = `${nanoLearningUrl}?id=${cid}`;
      console.log('run nano course', action.payload);
      yield put(
        coursesActions.coursesRunNanoCourse({
          url: nanoCourseStartUrl,
          cid,
          type,
        })
      );
    } else {
      const iframeMessageChannel = yield call(() =>
        eventChannel((emmiter) => {
          window.addEventListener('message', ({ data }) => {
            if (
              data === 'IFRAME_COURSE_FINISHED' ||
              data === 'TAB_COURSE_FINISHED'
            ) {
              emmiter();
              emmiter(END);
            }
          });
          return () => {
            window.removeEventListener('message');
          };
        })
      );
      yield take(iframeMessageChannel);
      yield put(coursesActions.coursesCourseFinished({ cid }));
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesCourseFailure({ error }));
  }
}

function* onCourseSign(action) {
  const { courseId, password } = action.payload;
  const bodyFormData = new FormData();
  bodyFormData.set('formIndex', '0');
  bodyFormData.set('password', password);
  let status;
  try {
    const data = yield call(() =>
      axios
        .request({
          method: 'POST',
          url: `${backendUrl}/courses/sign_digitally/${courseId}`,
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } },
          withCredentials: true,
        })
        .then((response) => response.data)
    );
    ({ status } = data);
    if (data.statuscode === -1) {
      yield put(
        coursesActions.coursesSignCourseSuccess({
          status: data.status,
          courseId,
        })
      );
      yield put(
        notificationsAdd({
          notification: {
            text: data.status,
            color: 'green',
          },
        })
      );
      yield put(profileUpdateOneCompetences());
      yield put(coursesActions.coursesBeginSignature(null));
    } else {
      if (status) {
        yield put(
          notificationsAdd({
            notification: {
              text: status,
              color: 'red',
            },
          })
        );
      }
      coursesActions.coursesSignCourseError();
    }
  } catch (error) {
    if (status) {
      yield put(
        notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        })
      );
    }
    coursesActions.coursesSignCourseError({ error });
  }
}

export function* fetchMapCourses() {
  const conf = yield select(getPropertiesForCurrLangAndTrack);

  yield put(coursesActions.fetchMapCoursesRequest());
  try {
    const mapCourses = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/player/track`,
          params: {
            id: conf.atlas.mapId,
          },
          withCredentials: true,
        })
        .then((res) => {
          return res.data;
        })
    );
    yield put(coursesActions.fetchMapCoursesSucceeded({ data: mapCourses }));
  } catch (error) {
    console.log(error);
  }
}

function* fetchMapCourseInfo(action) {
  console.log(
    'fetchMapCourseInfofetchMapCourseInfofetchMapCourseInfofetchMapCourseInfofetchMapCourseInfofetchMapCourseInfofetchMapCourseInfo'
  );
  console.log(action);
  const fields = ['children(title,short_description),files'].join(',');

  const params = encodeURI(
    stringifyUrlParams({
      fields,
      competence_group_ids: [30],
    })
  );

  yield put(coursesActions.fetchMapCoursesInfoRequest());
  try {
    const mapCoursesInfo = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${action.payload.trackId}?${params}`,
          withCredentials: true,
        })
        .then((res) => {
          return res.data;
        })
    );

    yield put(
      coursesActions.fetchMapCoursesInfoSucceeded({ data: mapCoursesInfo })
    );
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(coursesActions.COURSE_SIGNON, courseSignOn),
  takeLatest(coursesActions.COURSE_SIGNOFF, courseSignOff),
  takeLatest(coursesActions.COURSES_GET_COMPETENCES, getCompetences),
  takeLatest(
    coursesActions.COURSES_GET_FEATURED_COMPETENCES,
    getFeaturedCompetences
  ),
  takeLatest(coursesActions.COURSES_GET_COMPETENCEGROUPS, getCompetencegroups),
  takeLatest(coursesActions.COURSES_GET_COMPETENCETYPES, getCompetencetypes),
  takeLatest(
    coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW,
    fetchMyCoursesViewData
  ),
  takeLatest(coursesActions.COURSES_FILTERS_SET_FILTERS, getCompetences),
  throttle(700, coursesActions.COURSES_SET_SEARCHTERM, getCompetences),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP,
    getCompetences
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE,
    getCompetences
  ),
  takeLatest(coursesActions.COURSES_GET_COURSEEVENTS, getCourseEvents),
  takeLatest(
    ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT,
    coursePreviewLoaded
  ),
  takeLatest(coursesActions.COURSES_START_COURSE, onCourseStart),
  takeEvery(coursesActions.COURSES_SIGN_COURSE, onCourseSign),
  takeLatest(coursesActions.FETCH_MAP_COURSES_INFO, fetchMapCourseInfo),
  takeLatest(configActions.CONFIG_GET_CONFIG_SUCCESS, fetchMapCourses),
  takeLatest(coursesActions.FETCH_MAP_COURSES, fetchMapCourses),
];
