const prefix = 'config';

export const CMS_GET_CONTENT = `${prefix} get cms content`;
export const CMS_GET_CONTENT_REQUEST = `${prefix} get cms content request`;
export const CMS_GET_CONTENT_SUCCESS = `${prefix} get cms content success`;
export const CMS_GET_CONTENT_FAILURE = `${prefix} get cms content failure`;

export const cmsGetContent = (payload) => ({
  type: CMS_GET_CONTENT,
  payload,
});

export const cmsgGetContentRequest = () => ({
  type: CMS_GET_CONTENT_REQUEST,
});

export const cmsGetContentSuccess = (payload) => ({
  type: CMS_GET_CONTENT_SUCCESS,
  payload,
});

export const cmsGetContentFailure = () => ({
  type: CMS_GET_CONTENT_FAILURE,
});

export const CMS_GET_CHILDREN = `${prefix} get cms children`;
export const CMS_GET_CHILDREN_REQUEST = `${prefix} get cms children request`;
export const CMS_GET_CHILDREN_SUCCESS = `${prefix} get cms children success`;
export const CMS_GET_CHILDREN_FAILURE = `${prefix} get cms children failure`;

export const cmsGetchildren = (payload) => ({
  type: CMS_GET_CHILDREN,
  payload,
});

export const cmsGetchildrenRequest = () => ({
  type: CMS_GET_CHILDREN_REQUEST,
});

export const cmsGetchildrenSuccess = (payload) => ({
  type: CMS_GET_CHILDREN_SUCCESS,
  payload,
});

export const cmsGetchildrenFailure = (payload) => ({
  type: CMS_GET_CHILDREN_FAILURE,
  payload,
});

export const CMS_SET_SELECTED_PARENT_PAGE = `${prefix} get set selected parent page`;
export const cmsSetSelectedParentPage = (payload) => ({
  type: CMS_SET_SELECTED_PARENT_PAGE,
  payload,
});
