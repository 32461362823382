import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import classNames from 'classnames';
import {
  Button,
  box,
  baseSize,
  mt,
  ButtonLink,
  FormFooter,
  stack,
  height,
} from './styles';
import { FormInput } from './FormInput';

const LoginForm = ({
  handleSubmit,
  handleCancel,
  stage,
  invalidCredentials,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit} css={[box, stack, height]}>
      <div css={baseSize}>
        <Field
          aria-label="Brukernavn"
          autoComplete="username"
          component={FormInput}
          validate={() => undefined}
          name="username"
          type="text"
          placeholder="kari@nordmann.no"
          icon={['far', 'envelope']}
        />

        {/** <Field
        autoComplete="username"
        component={FormInput}
        validate={() => undefined}
        name="stage"
        type="hidden"
      /> */}
        <div css={mt}>
          {stage === 'valid' && (
            <Field
              aria-label="Passord"
              autoComplete="password"
              component={FormInput}
              placeholde="Password"
              validate={() => undefined}
              name="password"
              type="password"
              icon={['far', 'key']}
            />
          )}
        </div>
        <p
          css={[mt]}
          className={classNames(
            'login-form__invalid-credentials',
            stage === 'no-user' && !submitting
              ? 'login-form__invalid-credentials__visible'
              : 'login-form__invalid-credentials__hidden'
          )}>
          Brukernavn eller e-post er ikke gyldig.
        </p>
        <p
          className={classNames(
            'login-form__invalid-credentials',
            invalidCredentials && !submitting
              ? 'login-form__invalid-credentials__visible'
              : 'login-form__invalid-credentials__hidden'
          )}>
          Brukernavn eller passord stemmer ikke.
        </p>

        <Button type="submit">{stage === 'valid' ? 'Login' : 'Neste'}</Button>
      </div>
      <FormFooter>
        <ButtonLink type="button" onClick={handleCancel} css={mt}>
          Husker ikke brukernavn eller passord?
        </ButtonLink>
      </FormFooter>
    </form>
  );
};

LoginForm.defaultProps = {
  invalidCredentials: false,
  submitting: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  invalidCredentials: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'login',
})(LoginForm);
