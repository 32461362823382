/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { lighten, darken } from 'polished';

export const Button = styled('button')(
  {
    label: 'Button',
    backgroundColor: 'black',
    color: 'white',
    minWidth: '10em',
    minHeight: '3em',
    padding: 12,
    borderRadius: 32,
    '&:hover': {
      backgroundColor: lighten(0.2, '#000'),
      cursor: 'pointer',
    },
  },
  props =>
    props.disabled === true
      ? {
          backgroundColor: '#FF3B30',
          '&:hover': {
            backgroundColor: darken(0.1, '#FF3B30'),
            cursor: 'not-allowed',
          },
        }
      : null
);
