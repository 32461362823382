import React from 'react';
import PropTypes from 'prop-types';

import './course-preview-container.scss';

import CourseDetails from '@components/course/course-details';

const CoursePreviewContainer = ({ match }) => (
    <CourseDetails
      roleId={match.params.roleId}
      courseId={match.params.cid}
      rootId={match.params.cid}
    />
);

CoursePreviewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      roleId: PropTypes.shape.isRequired,
      courseId: PropTypes.shape.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CoursePreviewContainer;
