import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  withRouter,
  matchPath,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import { myEducation as myEducationRoutes } from '@routes/routes.manifest';

import { getRoutesBreadcrumbsArray } from '@utils/routes.utils';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

import MyEducationContainer from './containers/my-education-container';
import MyEducationRoleContainer from './containers/my-education-role-container';
import MyEducationCourseContainer from './containers/my-education-course-container';
import MyEducationMessagesContainer from './containers/my-education-messages';
import MyEducationCompetenceContainer from './containers/my-education-competences';
import ProfileContainer from './containers/profile-container';

const MyEducationSwitch = ({ location, breadcrumbs }) => (
  <>
    <div
      className={`my-education-switch ${
        location.pathname.indexOf('/my-education/role') !== -1 ? 'narrow' : ''
      }`}>
      {!matchPath(location.pathname, {
        path: myEducationRoutes.main.path,
        exact: true,
      }) && (
        <div className="my-education-switch__section">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      )}
      <div className="my-education-switch__section">
        <Switch>
          <Route
            path={myEducationRoutes.main.path}
            exact
            component={MyEducationContainer}
          />
          <Route
            path={myEducationRoutes.profileView.path}
            component={ProfileContainer}
          />
          <Route
            path={myEducationRoutes.cvView.path}
            component={MyEducationCompetenceContainer}
          />
          <Route
            path={myEducationRoutes.messagesView.path}
            component={MyEducationMessagesContainer}
          />
          <Route
            path={myEducationRoutes.courseView.path}
            component={MyEducationCourseContainer}
          />
          <Route
            path={myEducationRoutes.roleCourseView.path}
            component={MyEducationCourseContainer}
          />
          <Route
            path={myEducationRoutes.roleView.path}
            component={MyEducationRoleContainer}
          />
          <Redirect to={myEducationRoutes.main.path} />
        </Switch>
      </div>
    </div>
  </>
);

MyEducationSwitch.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default compose(
  withRouter,
  withBreadcrumbs(getRoutesBreadcrumbsArray(myEducationRoutes), {
    disableDefaults: true,
  })
)(MyEducationSwitch);
