export const getIsMobile = (state) => {
  return state.browser.is.extraSmall || state.browser.is.small;
};

export const getIsMobileCourseCatalog = (state) => {
  return (
    state.browser.is.mobileCourseCatalog ||
    state.browser.lessThan.mobileCourseCatalog
  );
};

export const getWindowSize = (state) => state.browser.lessThan;
