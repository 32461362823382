/* eslint-disable no-debugger */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsMobile } from '@selectors/global.selectors';

import { css, jsx } from '@emotion/core';
import { useHistory, withRouter } from 'react-router-dom';

import NanoLearningPlayer from '@routes/nano-learning/components/nano-learning-player/nano-learning-player';
import { nanoLearningUrl } from '@config';
import ArticleLoader from '@routes/content/components/loader/loader';
import {
  coursesCourseFinished,
  coursesEndCourse,
  coursesSetActiveCourse,
  setOutroIsCompleted,
} from '@actions/courses.actions';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';

// eslint-disable-next-line react/prop-types
const NanoLearningPlayerContainer = ({ courseId }) => {
  console.log('COURSE:ID', courseId);
  const [hasLoadedPlayer, setHasLoadedPlayer] = useState(false);
  const [failedToLoadCourse, setFailedToLoadCourse] = useState(false);

  const configObject = useSelector(getConfigObject);
  const configForCurrMapAndLang = useSelector(getPropertiesForCurrLangAndTrack);

  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();

  const history = useHistory();

  const redirectAfterOutroIfRelevant = () => {
    const useMap = configObject.getProperty('params.show-map');

    if (
      useMap &&
      configForCurrMapAndLang.atlas.outroId === courseId &&
      configForCurrMapAndLang.atlas.redirectAfterOutroFinished
    ) {
      console.log(
        'redirect to',
        configForCurrMapAndLang.atlas.redirectAfterOutroFinished
      );
      history.push(configForCurrMapAndLang.atlas.redirectAfterOutroFinished);
    }
  };

  const onRecieveIframeMessage = (evt) => {
    if (evt.data === 'IFRAME_COURSE_LOADED') {
      console.log('COURSE:LOADED');
      setHasLoadedPlayer(true);
    }
    if (evt.data === 'IFRAME_COURSE_FINISHED') {
      if (configForCurrMapAndLang.atlas.outroId === courseId) {
        dispatch(setOutroIsCompleted());
      }
      dispatch(coursesCourseFinished({ cid: courseId }));
      dispatch(coursesSetActiveCourse({}));
      redirectAfterOutroIfRelevant();
    }
    if (evt.data === 'IFRAME_COURSE_CANCELLED') {
      console.log('COURSE:CANCELLED');
      dispatch(coursesEndCourse());
      dispatch(coursesSetActiveCourse({}));
      redirectAfterOutroIfRelevant();

      if (evt.data === 'IFRAME_COURSE_FAILED_TO_LOAD') {
        console.log('COURSE:FAILED TO LOAD');
        setFailedToLoadCourse(true);
        redirectAfterOutroIfRelevant();
      }
    }
  };
  useEffect(() => {
    window.addEventListener('message', onRecieveIframeMessage);
    return () => {
      window.removeEventListener('message', onRecieveIframeMessage);
    };
  });

  return (
    <div
      css={css`
        height: 100%;
      `}>
      {!hasLoadedPlayer && !failedToLoadCourse && (
        <ArticleLoader
          isMobile={isMobile}
          wrapperstyle={css`
            margin-top: -4.5em;
          `}
        />
      )}
      {failedToLoadCourse && (
        <>
          <p
            css={css`
              text-align: center;
              margin-top: 3em;
              font-style: italic;
            `}>
            Enten finnes ikke kurset, eller så oppsto det en feil ved henting av
            det.
          </p>
          <button
            type="button"
            css={css`
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              margin-top: 1em;
            `}
            className="btn course-preview__action-button"
            onClick={() => {
              dispatch(coursesEndCourse());
            }}>
            Tilbake til kurskatalogen
          </button>
        </>
      )}
      <NanoLearningPlayer hasLoaded={hasLoadedPlayer} courseId={courseId} />
    </div>
  );
};

NanoLearningPlayerContainer.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

NanoLearningPlayerContainer.defaultProps = {};

export default withRouter(NanoLearningPlayerContainer);
