import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ChartComponent from '@components/chart/chart';
import classNames from 'classnames';

import './extra-chart.scss';

const ExtraChart = ({
  className,
  title,
  holeScale,
  data: { labels, data, progress, subtitle, colors },
  ...props
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(
      data.map((data, i) => ({
        title: (data === 1 && `${data} person`) || `${data} personer`,
        subtitle: labels[i],
        value: data,
        color: colors[i],
      }))
    );
  }, [labels, data, colors]);

  const [dimensions, setDimensions] = useState({
    width: null,
    height: null,
    labelScale: 1,
  });
  const wrapperRef = useRef();

  const setSize = useCallback(() => {
    if (wrapperRef.current) {
      const { width, height } = wrapperRef.current.getBoundingClientRect();
      const labelScale = Math.min(width / 420 + 0.2, 1);
      setDimensions({
        width: Math.min(width, height * 2.8),
        height,
        labelScale,
      });
    }
  }, [wrapperRef.current]);

  useEffect(() => {
    window.addEventListener('resize', setSize);
    window.addEventListener('orientationchange', setSize);
    setSize();

    return () => {
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  }, [setSize]);

  return (
    <div className={classNames('extra-chart', className)} {...props}>
      {title && <div className="extra-chart__title">{title}</div>}
      <span className="extra-chart__progress">
        <div ref={wrapperRef} className="extra-chart__canvas-wrapper">
          <ChartComponent
            width={dimensions.width}
            height={dimensions.height}
            data={chartData}
            padding={58}
            holeScale={holeScale}
            labelScale={dimensions.labelScale}
          />
        </div>
        {progress && (
          <div className="extra-chart__progress-main">{progress}</div>
        )}
      </span>
      {subtitle && <div className="extra-chart__subtitle">{subtitle}</div>}
    </div>
  );
};

ExtraChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  holeScale: PropTypes.number,
};

ExtraChart.defaultProps = {
  className: null,
  title: null,
  holeScale: 0.82,
};

export default ExtraChart;
