import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './notification.scss';
import { Redirect } from 'react-router-dom';

const Notification = ({
  title,
  children,
  onClose,
  disableAutoClose,
  color,
  className,
  redirectTo,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  let closeTimeout;

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    });

    if (!disableAutoClose) {
      closeTimeout = setTimeout(() => {
        handleClose();
      }, 5300);
    }

    return () => clearTimeout(closeTimeout);
  }, []);

  return (
    <div
      className={classNames([
        { notification: true },
        { [`notification--${color}`]: !!color },
        { [className]: !!className },
        { 'notification--visible': visible },
      ])}
      {...rest}>
      <div className="notification__content">
        {title && <div className="notification__title">{title}</div>}
        <div className="notification__text">{children}</div>
      </div>
      <button
        type="button"
        className="notification__close-button"
        onClick={handleClose}>
        <i className="fas fa-times" />
      </button>
      {redirectTo && <Redirect from="*" to={redirectTo} />}
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disableAutoClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  redirectTo: PropTypes.string,
};

Notification.defaultProps = {
  className: null,
  color: null,
  disableAutoClose: false,
  title: null,
  redirectTo: null,
};

export default Notification;
