import React from 'react';
import PropTypes from 'prop-types';
import CourseDetails from '@components/course/course-details';
import '../styles/my-education-role-container.scss';

const MyEducationCourseContainer = ({ match }) => (
  <div className="my-education">
    <div className="my-education__section">
      <CourseDetails
        roleId={match.params.roleId}
        courseId={match.params.courseId}
        rootId={match.params.courseId}
      />
    </div>
  </div>
);

MyEducationCourseContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      roleId: PropTypes.shape.isRequired,
      courseId: PropTypes.shape.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MyEducationCourseContainer;
