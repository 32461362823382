import React from 'react';
import PropTypes from 'prop-types';

import { Description } from './styles';

export const CourseInformationDescription = ({ children }) => {
  return <Description>{children}</Description>;
};

CourseInformationDescription.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
