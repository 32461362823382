/* eslint-disable no-debugger */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import { css, jsx } from '@emotion/core';

import { motion } from 'framer-motion';

import AnimatedDropdownArrow from '@components/animated-dropdown-arrow/animatedDropdownArrow';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Wrapper,
  SelectOptionContent,
  SelectOptionWrapper,
  SelectItemText,
  SelectChildren,
  SelectItemSecondaryText,
  ToplevelListWrapper,
} from './styles';

const optionShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSelected: PropTypes.bool,
  text: PropTypes.string,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
optionShape.children = PropTypes.arrayOf(PropTypes.shape(optionShape));

/*
A element to show items to select at a airbitrary depth.

The options is defined as optionShape, where children defines the children of an option.
Children can be recursive, so a child can have children, which in turn can have children and so on.
the isSelected-property determines if the node is to be shown as expanded or collapsed.
The hightest isCollapsed-propperty in the hierchy decides, so if a parent is closed but its child is
open, the child is not shown, since the parent is higher up.

Upon selection a callback is run with these parameters:
id: the id of the selected option
level: which depth the clicked element is in. The top-level depth is 1
isLeafNode: if the clicked element dosent have any childs bellow itself

Customisation:
wrapperStyle: a css-string  or object to style the wrapper
itemStyle: a css-string to style each item

isLoadingMode allows to display the sidebar with an invisible placeholder item,
ideal to show while the options is still fetching.
If this prop is given, no other props are necessary
*/
const NestedSelect = ({
  options,
  onSelected,
  wrapperStyle,
  itemStyle,
  isLoadingMode,
  theme,
  isMobile,
}) => {
  return (
    <Wrapper
      aria-hidden={isLoadingMode}
      className="select-sidebar-wrapper normal-mode">
      <ToplevelListWrapper
        css={css`
          ${wrapperStyle};
        `}>
        {(isLoadingMode && (
          <SelectItems
            options={[{ id: 1, text: 'PLACEHOLDER' }]}
            className="select-items"
            itemStyle={css`
              ${itemStyle};
              visibility: hidden;
            `}
          />
        )) || (
          <SelectItems
            options={options}
            theme={theme}
            isMobile={isMobile}
            onSelected={onSelected}
            className="select-items"
            itemStyle={itemStyle}
          />
        )}
      </ToplevelListWrapper>
    </Wrapper>
  );
};

NestedSelect.propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
  onSelected: PropTypes.func.isRequired,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  itemStyle: PropTypes.shape({}),
  isLoadingMode: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  isMobile: PropTypes.bool,
};

NestedSelect.defaultProps = {
  wrapperStyle: {},
  itemStyle: {},
  isLoadingMode: false,
  theme: 'dark',
  isMobile: false,
};

export default NestedSelect;

const SelectItems = ({
  isLoadingMode,
  options,
  onSelected,
  itemStyle,
  theme,
  isMobile,
}) =>
  !isLoadingMode &&
  options &&
  options.map((option, i) => (
    <SelectOption
      theme={theme}
      isMobile={isMobile}
      zIndex={0}
      itemStyle={itemStyle}
      key={i}
      option={option}
      onSelected={onSelected}
    />
  ));

const SelectOption = ({
  option,
  isMobile,
  theme,
  onSelected,
  level,
  itemStyle,
}) => {
  const isLeafNode = !option.children || option.children.length === 0;
  return (
    <SelectOptionWrapper
      tabIndex={-1}
      className="select-items-parent"
      css={css`
        ${itemStyle}
      `}>
      <SelectOptionContent
        isMobile={isMobile}
        theme={theme}
        zIndex={0}
        isLeafNode={isLeafNode}
        highlight={option.isSelected && isLeafNode}
        highlightOnHover
        role="button"
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            e.stopPropagation();
            onSelected(option, level, isLeafNode);
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
          onSelected(option, level, isLeafNode);
        }}>
        {option.children && option.children.length > 1 && (
          <AnimatedDropdownArrow
            hideIcon={isLeafNode}
            state={option.isSelected ? 'collapsed' : 'expanded'}
          />
        )}
        <SelectItemText className="primary-text">{option.text}</SelectItemText>
        {isMobile && option.isSelected && (
          <div
            css={css`
              display: flex;
              align-items: center;
              font-size: 1.2em;
              opacity: 0.6;
            `}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}
      </SelectOptionContent>

      {[undefined, null].indexOf(option.secondaryText) === -1 && (
        <SelectItemSecondaryText className="secondary-text">
          {option.secondaryText}
        </SelectItemSecondaryText>
      )}

      <SelectChildren aria-label="subelementer">
        <ul css={{ padding: 0 }}>
          {option.children && option.isSelected && (
            <motion.div
              exit={{ height: 0 }}
              animate={{ height: 'auto' }}
              transition="tween"
              initial={{ height: 0 }}
              css={{ overflow: 'hidden', zIndex: 10000, position: 'relative' }}>
              {option.children.map((childOption) => (
                /* Recursively render each child */
                <SelectOption
                  theme={theme}
                  isMobile={isMobile}
                  key={childOption.id}
                  isChild
                  level={level + 1}
                  onSelected={onSelected}
                  option={childOption}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelected(
                      childOption,
                      level + 1,
                      !childOption.children || childOption.children.length === 0
                    );
                  }}
                />
              ))}
            </motion.div>
          )}
        </ul>
      </SelectChildren>
    </SelectOptionWrapper>
  );
};

SelectOption.propTypes = {
  option: optionShape.isRequired,
  level: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  itemStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.oneOf(['dark', 'light']),
  isMobile: PropTypes.bool.isRequired,
};

SelectOption.defaultProps = {
  level: 1,
  itemStyle: '',
  theme: 'dark',
};
