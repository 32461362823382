import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DEFAULT_BG from '../../../assets/images/default_customer.jpg';

const createSubtitleContent = content => ({
  __html: content,
});

class CompetenceVerification extends Component {
  constructor(props) {
    super(props);
    this.handleRolesOnChange = this.handleRolesOnChange.bind(this);
  }

  componentDidMount() {
    console.log('did maout');
  }

  handleRolesOnChange({ values }) {
    console.log(values);
  }

  removePersonalCompetence(id) {
    const { removeRequirement } = this.props;
    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const { size, course, roleId } = this.props;
    return (
      <>
        {(size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}>
                  <div className="roles-list__competence-title">
                    <div
                      className={classNames([
                        'roles-list__competence-status',
                        {
                          'roles-list__competence-status--not-started':
                            course.passed === 0,
                        },
                        {
                          'roles-list__competence-status--in-progress':
                            course.passed > 0 && course.passed < 100,
                        },
                        {
                          'roles-list__competence-status--completed':
                            course.passed === 100,
                        },
                      ])}
                    />
                    <div>
                      <div className="small-header">
                        {course.competence_type.title}
                        {roleId === -2 && course.passed !== 100 && (
                          <div className="removeThis">
                            <button
                              type="button"
                              onClick={() =>
                                this.removePersonalCompetence(
                                  course.competence_id
                                )
                              }>
                              <i className="far fa-trash-alt" />
                            </button>
                          </div>
                        )}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon="calendar-alt" />
                            &nbsp;&nbsp;{course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon="user-check" />
                            &nbsp;&nbsp;{course.verified.fullname}
                          </div>
                        )}
                        {course.valid_until && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon="hourglass" />
                            &nbsp;&nbsp;{course.valid_until}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        )) ||
          (size === 'big' && (
            <>
              <div className="cell small-4 medium-5">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${(course.cover &&
                      course.cover.url) ||
                      DEFAULT_BG})`,
                  }}
                />
              </div>
              <div className="content cell small-8 medium-7">
                <div className="__title">
                  <h2>{course.competence_title}</h2>
                </div>
                {course.competence_type.competence_type && (
                  <div>
                    {course.competence_type.title}
                    <br /><br /><em>Venter på godkjenning fra Leder</em><br /><br />
                  </div>

                )}
                <div
                  className="desc"
                  dangerouslySetInnerHTML={createSubtitleContent(
                    course.short_description
                  )}
                />
                {course.files && !!course.files.length && (
                  <div className="course-preview__files">
                    {course.files.map(
                      ({ title, url }) =>
                        title !== 'cover' && (
                          <>
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn course-preview__file-button">
                              <i className="fas fa-file-alt" /> {title}
                            </a>
                          </>
                        )
                    )}
                  </div>
                )}
              </div>
            </>
          ))}
      </>
    );
  }
}

CompetenceVerification.defaultProps = {
  size: 'normal',
  roleId: -2,
  removeRequirement: undefined,
};

CompetenceVerification.propTypes = {
  course: PropTypes.shape({}).isRequired,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
  size: PropTypes.string,
};

export default CompetenceVerification;
