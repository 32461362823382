/* eslint-disable no-debugger */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { css, jsx } from '@emotion/core';
import { getImageUrl } from '@utils/misc.utils';
import { i18n } from '@src/i18n';
import {
  Wrapper,
  Image,
  ContentWrapper,
  AuthorAvatar,
  AuthorName,
  AuthorWrapper,
  HeaderWrapper,
  Header,
  Category,
} from './styles';

export const TrainertipsCard = ({
  authorName,
  authorImage,
  category,
  text,
  image,
  imageAltText,
  isMobile,
  onClick,
  variant = 'large',
  mobileBreakpoint,
  mobileWrapperStyle,
  ...props
}) => {
  return (
    <Wrapper
      className="trainertips-card-wrapper"
      {...props}
      role="button"
      tabIndex="0"
      mobileBreakpoint={mobileBreakpoint}
      variant={variant}
      onClick={onClick}
      isMobile={isMobile}
      mobileWrapperStyle={mobileWrapperStyle}>
      {variant === 'listCard' && (
        <Category className="category" variant="listCard">
          {category}
        </Category>
      )}

      <Image
        className="cover-image"
        src={getImageUrl(image)}
        aria-label={imageAltText}
        variant={variant}>
        <AuthorAvatar
          hide={!authorImage}
          src={getImageUrl(authorImage)}
          variant={variant}
          className="avatar-img"
        />
      </Image>
      <ContentWrapper variant={variant} className="content-wrapper">
        <HeaderWrapper variant={variant} className="header-wrapper">
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <div css={{ transform: `translate(0, 16px)` }}>
              <p css={{ margin: 0, color: '#222' }}>{category}</p>
            </div>
            <Header
              variant={variant}
              className="header"
              dangerouslySetInnerHTML={{ __html: text }} />

            <AuthorName
              variant={variant}
              hide={!authorName}
              className="author-name">
              {(authorName && `Trenertips med ${authorName}`) || null}
            </AuthorName>
          </div>
        </HeaderWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
