/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { rgba } from 'polished';
import { css } from '@emotion/react';
import { container, overlay, img } from './styles';

export const ImageWithOverlay = ({
  color = '#000',
  src,
  imagePosition = 'initial',
  customCssWrapper,
  customCssImageParts,
  colorAlpha = 0.5,
}) => {
  return (
    <div css={[container, { ...customCssWrapper }]}>
      <div
        css={css(
          overlay,
          css`
            transition: background 0.2s ease-out;
          `,
          { background: color && rgba(color, colorAlpha) },
          { ...customCssImageParts }
        )}
      />
      <div
        css={[
          img,
          {
            backgroundImage: `url(${src})`,
            backgroundPosition: imagePosition,
          },
          { ...customCssImageParts },
        ]}
      />
    </div>
  );
};
