import * as cmsActions from '@actions/cms.actions';

const initialState = {
  content: {
    isFetching: false,
    data: null,
    error: null,
  },
  children: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedParentPage: {
    data: null,
  },
};

const cms = (state = initialState, action) => {
  switch (action.type) {
    case cmsActions.CMS_GET_CONTENT_REQUEST:
      return {
        ...state,
        content: {
          ...initialState.content,
          isFetching: true,
        },
      };
    case cmsActions.CMS_GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          ...initialState.content,
          isFetching: false,
          data: {
            ...state.content.data,
            [action.payload.contentId]: action.payload.content.pages[0],
          },
        },
      };
    case cmsActions.CMS_GET_CONTENT_FAILURE:
      return {
        ...state,
        content: {
          ...initialState.content,
          error: action.payload.error,
          isFetching: false,
        },
      };

    case cmsActions.CMS_GET_CHILDREN_REQUEST:
      return {
        ...state,
        children: {
          ...initialState.children,
          isFetching: true,
        },
      };
    case cmsActions.CMS_GET_CHILDREN_SUCCESS:
      return {
        ...state,
        children: {
          ...initialState.children,
          data: action.payload.children.pages,
          isFetching: false,
        },
      };
    case cmsActions.CMS_GET_CHILDREN_FAILURE:
      return {
        ...state,
        children: {
          ...initialState.children,
          error: action.payload.error,
          isFetching: false,
        },
      };

    case cmsActions.CMS_SET_SELECTED_PARENT_PAGE:
      return {
        ...state,
        selectedParentPage: {
          ...initialState.selectedParentPage,
          isFetching: true,
        },
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
};

export default cms;
