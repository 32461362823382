import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { PersonShape } from '@types/person';

import RoleForm from '@components/role-form/role-form';
import PersonCompetenceForm from '@components/person-competence-form/person-competence-form';
import PersonOnTheJobForm from '@components/person-onthejob-form/person-onthejob-form';
import PersonEmploymentForm from '@components/person-employment-form/person-employment-form';
import EmployeesChecklist from '@routes/employees/components/employees-checklist';

import {
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonActivities,
  employeesFetchSelectedPersonExpiring,
  employeesRemoveRequirements,
} from '@actions/employees.actions';
import { withRouter } from 'react-router';

import {
  getSelectedPerson,
  getPersonEvents,
  getIsSaving,
} from '@selectors/employees.selectors';

import {
  getMainOrganisations,
} from '@selectors/profile.selectors';

import ActivityList from '@routes/employees/components/activity-list';

import '../styles/employee-preview.scss';
import Modal from '@components/modal/modal';
import EmployeeRolesList from '@routes/employees/components/roles-list';
import EventsList from '@routes/employees/components/events-list';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getIsMobile } from '@selectors/global.selectors';
import moment from 'moment';
import ExpiringList from '@components/expiring-competences';

const EmployeePreview = ({
  forwardRef,
  onInit,
                           mainOrganisation,
  person: {
    data: personData,
    isFetching: isFetchingPerson,
    summary: roles,
    elements,
  },
  isSaving,
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonActivities,
  employeesFetchSelectedPersonExpiring,
  employeesRemoveRequirements,
  isMobile,
  saveRole,
  saveVerification,
  onEmploymentFormSubmit,
  saveRequirements,
  //  onRoleFormSubmit,
}) => {
  const [tab, setTab] = useState('role');

  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [rolesDataLoaded, setRolesDataLoaded] = useState(false);

  const [personalModalOpen, setPersonalModalOpen] = useState(false);
  const [personalDataLoaded, setPersonalDataLoaded] = useState(false);

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [verifyDataLoaded, setVerifyDataLoaded] = useState(false);

  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [employedDataLoaded, setEmployedDataLoaded] = useState(false);

  useEffect(() => {
    onInit();
  }, []);

  const closeAllModals = () => {
    setRolesModalOpen(false);
    setPersonalModalOpen(false);
    setVerifyModalOpen(false);
    setEmployedModalOpen(false);
  };

  const escapeListener = ({ key }) => {
    if (key === 'Escape') {
      closeAllModals();

      setRolesDataLoaded(false);
      setPersonalDataLoaded(false);
      setVerifyDataLoaded(false);
      setEmployedDataLoaded(false);
    }
  };

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'roles') {
      setRolesModalOpen(visibility);
    } else if (type === 'edit') {
      setPersonalModalOpen(visibility);
    } else if (type === 'onthejob') {
      setVerifyModalOpen(visibility);
    } else if (type === 'person-comps') {
      setPersonalModalOpen(visibility);
    } else if (type === 'employed') {
      setEmployedModalOpen(visibility);
    }
    toggleEscapeListener(visibility);
  };

  const handleTabChange = data => {
    setTab(data);
    employeesFetchSelectedPersonActivities({ userName: personData.user_name });
  };

  const handleRemoveRequirements = data => {
    if (window.confirm('Er du sikker at du vil fjerne dette kravet?')) {
      employeesRemoveRequirements({ data });
    }
  };

  const handleRoleChange = data => {
    setModalVisibility('roles', false);
    saveRole({ data });
  };

  const handleOnTheJobFormSubmit = data => {
    setModalVisibility('onthejob', false);
    saveVerification({
      data: {
        ...data,
        verifieddate:
          data.verifieddate &&
          moment(data.verifieddate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        valid_until:
          data.valid_until &&
          moment(data.valid_until, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      },
    });
  };

  const handleEmploymentFormSubmit = data => {
    setModalVisibility('employed', false);
    onEmploymentFormSubmit({ data });
  };

  const handlePersonalFormSubmit = data => {
    setModalVisibility('person-comps', false);
    saveRequirements({ data });
  };

  const handleRolesFormClose = () => {
    closeAllModals();
    setRolesDataLoaded(false);
  };

  const handlePersonalFormClose = () => {
    closeAllModals();
    setPersonalDataLoaded(false);
  };

  const handleVerifyFormClose = () => {
    closeAllModals();
    setVerifyDataLoaded(false);
  };

  const handleEmployedFormClose = () => {
    closeAllModals();
    setEmployedDataLoaded(false);
  };
  /*
  const handleRoleFormSubmit = ({ roles }) => {
    onRoleFormSubmit({ roles });
  };
*/

  const TasksList = ({ disabled, onClick }) => (
    <ul className="task-list">
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('roles', true);
            onClick();
          }}>
          <i className="fas fa-plus task-list__item-icon" />
          Endre, legg til rolle
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('person-comps', true);
            onClick();
          }}>
          <i className="fas fa-plus task-list__item-icon" />
          Personlige krav
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('onthejob', true);
            onClick();
          }}>
          <i className="fas fa-plus task-list__item-icon" />
          Verifisiere krav
        </button>
      </li>
      <li className="task-list__item">
        <button
          className="employee-preview__add-menu-item"
          type="button"
          disabled={disabled}
          onMouseDown={() => {
            setModalVisibility('employed', true);
            onClick();
          }}>
          <i className="fas fa-plus task-list__item-icon" />
          Rediger ansettelse
        </button>
      </li>
    </ul>
  );

  TasksList.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
  };

  TasksList.defaultProps = {
    onClick: () => {},
  };

  useImperativeHandle(forwardRef, () => ({
    TasksList,
  }));

  return (
    <>
      {isSaving && <div className="saving-data">Lagrer data...</div>}
      {!isMobile && (
        <div className="employee-preview__card-wrapper">
          <div className="employee-preview__tasks">
            <h3>Oppgaver</h3>
            <TasksList disabled={!personData} />
          </div>
        </div>
      )}

      <div className="employee-preview__competences-wrapper">
        {personData && personData.is_absent && (
          <div className="alert alert-info">
            <h4 className="alert-header">Fravær</h4>
            <p>
              Personen er satt med fravær.
              {personData.absent_data.absent_from && (
                <span> Fra {personData.absent_data.absent_from}</span>
              )}
              {personData.absent_data.absent_to && (
                <span> til {personData.absent_data.absent_to}</span>
              )}
            </p>
          </div>
        )}

        {personData && personData.expiring && personData.expiring.length !== 0 && (
          <div className="roles-list__role-section">
            <h2>KOMPETANSER SOM UTLØPER</h2>
            <ExpiringList
              expiring={personData.expiring}
              isPerson
            />
          </div>
        )}

        {personData && personData.events && personData.events.length !== 0 && (
          <>
            <h2>KURSPÅMELDINGER</h2>
            <EventsList type="employee" events={personData.events} />
          </>
        )}
        {personData && personData.checklists && personData.checklists.length !== 0 && (
          <>
            <h2>Sjekkliste punkter</h2>
            <div className="employees-page__activity-wrapper">
              <EmployeesChecklist
                type="employee"
                items={personData.checklists}
              />
            </div>
          </>
        )}

        {personData && personData.activities.length !== 0 && (
          <div className="roles-list__role-section">
            <h2>AKTIVITETER</h2>
            <ActivityList
              loading={elements.checklists.isFetching}
              activities={personData.activities}
              personActivities
            />
          </div>
        )}
        <EmployeeRolesList
          personId={personData && personData.id}
          userName={personData && personData.user_name}
          tabActive={tab}
          tabChange={handleTabChange}
          removeRequirement={handleRemoveRequirements}
          fetchSubItems={employeesFetchSelectedPersonCompetences}
          summary={personData && personData.summary}
          isFetching={isFetchingPerson}
        />
      </div>
      {personalModalOpen && (
        <Modal
          onBackdropClick={() => setModalVisibility('person-comps', false)}
          waitForLoadedDataWithAnimation
          dataLoaded={personalDataLoaded}>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">
              Legg til personlige kompetansekrav
            </h2>

            <PersonCompetenceForm
              userName={personData.user_name}
              personId={personData.id}
              onGotData={() => setPersonalDataLoaded(true)}
              orgId={
                personData &&
                Array.isArray(personData.organisations) &&
                mainOrganisation.id
              }
              onSubmit={handlePersonalFormSubmit}
              onCancel={() => handlePersonalFormClose()}
            />
          </div>
        </Modal>
      )}
      {verifyModalOpen && (
        <Modal onBackdropClick={() => setModalVisibility('onthejob', false)}
               waitForLoadedDataWithAnimation
               classNameContent="employee-edit__modal-content"
               dataLoaded={verifyDataLoaded}>
          <div className="employee-edit__card employee-edit__modal employee-edit__modal-onthejob">
            <div className="grid-x">
              <div className="small-12 cell">
                <h2 className="employee-edit__modal-title">Verifikasjon</h2>
              </div>
            </div>
            <PersonOnTheJobForm
              personId={personData.id}
              onSubmit={handleOnTheJobFormSubmit}
              onCancel={() => handleVerifyFormClose()}
              onGotData={() => setVerifyDataLoaded(true)}
            />
          </div>
        </Modal>
      )}
      {rolesModalOpen && (
        <Modal
          onBackdropClick={() => setModalVisibility('roles', false)}
          dataLoaded={rolesDataLoaded}
          waitForLoadedDataWithAnimation>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">Rediger Roller</h2>
            <RoleForm
              roles={roles}
              userName={personData.user_name}
              personId={personData.id}
              onGotData={() => setRolesDataLoaded(true)}
              orgId={mainOrganisation.id}
              onSubmit={handleRoleChange}
              onCancel={() => handleRolesFormClose()}
            />
          </div>
        </Modal>
      )}
      {employedModalOpen && (
        <Modal onBackdropClick={() => setModalVisibility('employed', false)}
               waitForLoadedDataWithAnimation
               classNameContent="employee-edit__modal-content"
               dataLoaded={employedDataLoaded}>
          <div className="employee-edit__card employee-edit__modal">
            <h2 className="employee-edit__modal-title">Rediger ansettelse</h2>

            <PersonEmploymentForm
              roles={roles}
              userName={personData.user_name}
              personId={personData.id}
              onSubmit={handleEmploymentFormSubmit}
              onCancel={() => handleEmployedFormClose()}
              onGotData={() => setEmployedDataLoaded(true)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

EmployeePreview.propTypes = {
  person: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    elements: PropTypes.shape({}),
    data: PersonShape(),
    error: PropTypes.shape({}),
  }).isRequired,
  isSaving: PropTypes.bool,
  onInit: PropTypes.func.isRequired,
  onRoleFormSubmit: PropTypes.func.isRequired,
  saveVerification: PropTypes.func.isRequired,
  onEmploymentFormSubmit: PropTypes.func.isRequired,
  saveRequirements: PropTypes.func.isRequired,
  saveRole: PropTypes.func.isRequired,
  employeesFetchSelectedPersonActivites: PropTypes.func.isRequired,
  employeesFetchSelectedPersonCompetences: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  forwardRef: PropTypes.any.isRequired,
};

EmployeePreview.defaultProps = {
  isSaving: false,
};

const mapStateToProps = state => ({
  person: getSelectedPerson(state),
  mainOrganisation: getMainOrganisations(state),
  personEvents: getPersonEvents(state),
  isSaving: getIsSaving(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      employeesFetchSelectedPersonCompetences,
      employeesRemoveRequirements,
      employeesFetchSelectedPersonActivities,
      employeesFetchSelectedPersonExpiring,
    },
    dispatch
  );

const ConnectedEmployeePreview = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmployeePreview)
);

export default forwardRef((props, ref) => (
  <ConnectedEmployeePreview {...props} forwardRef={ref} />
));
