/** @jsx jsx */

/* eslint-disable react/prop-types */
import React from 'react';
import { jsx } from '@emotion/core';
import { CourseInformationPane } from '@routes/atlas/components/CourseInformationPane/CourseInformationPane';
import { CourseInformationTitle } from '@routes/atlas/components/CourseInformationTitle/CourseInformationTitle';
import { CourseInformationDescription } from '@routes/atlas/components/CourseInformationDescription/CourseInformationDescription';
import { CourseInformationButton } from '@routes/atlas/components/CourseInformationButton/CourseInformationButton';
import { CourseInformationContent } from '@routes/atlas/components/CourseInformationContent/CourseInformationContent';
import { i18n, strs } from '@src/i18n';

export const CourseInformation = ({
  coursesInfo: { id, title, short_description },
  startSelectedCourse,
  selectedCourse,
  findCourseIndex,
}) => (
  <CourseInformationPane>
    <CourseInformationContent>
      {findCourseIndex(id) - 1 !== -1 && (
        <div>
          {i18n(strs.PART)} {findCourseIndex(id)}
        </div>
      )}
      <CourseInformationTitle title={title} />
      {short_description && (
        <CourseInformationDescription>
          <span dangerouslySetInnerHTML={{ __html: short_description }} />
        </CourseInformationDescription>
      )}
    </CourseInformationContent>
    <CourseInformationButton
      onClick={startSelectedCourse}
      text={
        selectedCourse.status !== 'LOCKED'
          ? i18n(strs.START_TRAINING)
          : i18n(strs.LOCKED)
      }
      disabled={selectedCourse.status === 'LOCKED'}
    />
  </CourseInformationPane>
);
