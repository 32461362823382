import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {} from 'polished';

const input = css({
  margin: '20px auto',
  width: '30%',
});

export const noFill = css({
  fill: 'none',
});

export const CircleSVG = styled('svg')({
  circle: {
    fill: 'none',
  },
});

/** Bottom Layer === 0 and counting upwards for each circle layer.
 * e.g. layer 5 would be circle4
 */
export const circle0 = css({
  stroke: '#ddd',
});

export const circle1 = css({
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
});

export const BoundingSVG = styled('div')(
  {
    position: 'relative',
  },
  () => ({})
);

export const SVGContent = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
