/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsx jsx */
import React, { useState, useRef, useEffect, useCallback } from 'react';

import { CircularProgressBar } from '@src/common/components/progressbar-circular';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';

import { i18n, strs } from '@src/i18n';
import { getPropertiesForCurrLangAndTrack } from '@selectors/config.selectors';
import {
  Checkbox,
  Button,
  focus,
  baseLabelStyle,
  ApprovedSticker,
} from './styles';

const Sticker = styled(motion.div)({
  zIndex: 999,
  height: 800,
  width: 800,
  visibility: 'hidden',
});

const variants = {
  scaleIn: () => ({
    height: 200,
    width: 200,
    visibility: 'visible',
  }),
  beginAt: () => ({
    height: 800,
    width: 800,
    visibility: 'hidden',
  }),
};

export const CompleteCourse = ({ onVerifiedAndDone }) => {
  const [percentage, setPercentage] = useState(0);
  const [checked, setChecked] = useState(false);
  const [beginStickerAnimation, setBeginStickerAnimation] = useState(false);
  const [disabledButtonState, setDisabledButtonState] = useState(true);
  const [disabledCheckbox, shouldCheckboxBeDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(i18n(strs.FINISH));

  const configForCurrLangAndTrack = useSelector(
    getPropertiesForCurrLangAndTrack
  );

  const ref = useRef();
  const submitRef = useRef();
  const labelRef = useRef();

  // console.log(labelRef);

  const delay = useCallback((duration) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), duration);
    });
  }, []);

  const increasePercentage = useCallback((current, next) => {
    let val = current;
    const interval = setInterval(() => {
      if (val <= next) {
        setPercentage(val);
        val += 1;
      }
    }, 5);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const decreasePercentage = useCallback((current, next) => {
    let val = current;
    const interval = setInterval(() => {
      if (val >= next) {
        setPercentage(val);
        val -= 1;
      }
    }, 5);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onEnter = useCallback(() => {
    Promise.resolve()
      .then(() => increasePercentage(percentage, 100))
      .then(() => shouldCheckboxBeDisabled(false))
      .then(() => labelRef.current.focus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onEnter();
  }, [onEnter]);

  const handleSubmit = useCallback((event) => {
    Promise.resolve()
      .then(() => setBeginStickerAnimation(true))
      .then(() => setDisabledButtonState(true))
      .then(() => shouldCheckboxBeDisabled(true))
      .then(() => setText(i18n(strs.LOADING_WITH_ELIPSIS)))
      .then(() => delay(3000))
      .then(() => {
        onVerifiedAndDone();
      });
  });

  const handleCheckboxChange = useCallback(
    (event) => {
      setChecked(event.target.checked);

      if (event.target.checked) {
        Promise.resolve()
          .then(() => increasePercentage(percentage, 100))
          .then(() => setDisabledButtonState(false));
      } else {
        Promise.resolve()
          .then(() => setBeginStickerAnimation(false))
          .then(() => setDisabledButtonState(true));
      }
    },
    [increasePercentage, percentage]
  );

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minHeight: 340,
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2em',
          }}>
          <CircularProgressBar
            percentage={percentage}
            ref={ref}
            strokeWidth={12}
            sqSize={140}
            facepaint={{ circle1: { stroke: '#1b9b6f' } }}
            aria-label={i18n(strs.VERIFICATION_PROGRESS_BAR_ARIA_LABEL)}>
            {percentage}%
          </CircularProgressBar>
          <Sticker
            initial={null}
            animate={beginStickerAnimation ? 'scaleIn' : 'beginAt'}
            transition={{ duration: 0.3 }}
            css={{
              position: 'absolute',
              top: '-32px',
            }}
            variants={variants}>
            <ApprovedSticker
              src={configForCurrLangAndTrack.atlas.sticker.file}
              alt={configForCurrLangAndTrack.atlas.sticker.alt_text}
            />
          </Sticker>
        </div>
        <label
          css={[baseLabelStyle, isFocused && focus]}
          ref={labelRef}
          onMouseEnter={() => setIsFocused(true)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onMouseLeave={() => setIsFocused(false)}>
          <Checkbox
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
            disabled={disabledCheckbox}
          />
          <span>{i18n(strs.VERIFICATION_AGREEMENT)}</span>
        </label>
        <Button
          type="submit"
          ref={submitRef}
          disabled={disabledButtonState}
          onClick={handleSubmit}>
          {text}
        </Button>
      </div>
    </React.Fragment>
  );
};
