import React from 'react';
import PropTypes from 'prop-types';
import ThemedLogo from '@components/themed-logo/themed-logo';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import './desktop-menu.scss';

const DesktopMenu = ({ menuLinks, onLogoutClick }) => (
  <div className="desktop-menu">
    <div className="desktop-menu__top-section">
      <div className="desktop-menu__logo-wrapper">
        <ThemedLogo className="desktop-menu__logo" />
      </div>
      <div className="desktop-menu__search-wrapper">
        <div className="desktop-menu__search" />
      </div>
      <div className="desktop-menu__buttons">
        <button
          className="desktop-menu__button"
          onClick={onLogoutClick}
          type="button">
          <i className="fas fa-sign-out-alt" />
          Logg ut
        </button>
      </div>
    </div>
    <div className="desktop-menu__bottom-section">
      {menuLinks.map(({ name, path, active, exact = false }, index) => (
        <NavLink
          className={classNames('desktop-menu__link', {
            'desktop-menu__link--active': active,
          })}
          activeClassName="desktop-menu__link--active"
          exact={exact}
          to={path}
          strict={false}
          key={index}>
          {name}
        </NavLink>
      ))}
    </div>
  </div>
);

DesktopMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default DesktopMenu;
