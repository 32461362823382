import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { backendUrl } from '@config';
import * as configActions from '@actions/config.actions';
import retry from '@utils/sagas.utils';
import { getConfigObject } from '@selectors/config.selectors';
import { i18n, MISSING_TRANSLATION } from '@src/i18n';

function convertObjectToStrDotNotation(data, currPath = '', out = {}) {
  const isObject = typeof data === 'object' && data !== null;
  const dataCpy = data;
  const outCpy = out;

  // get all properties of the object recursively
  // the keys should be the stringified dot notation
  // and the values should be the corresponding value
  if (isObject) {
    const keysForObject = Object.getOwnPropertyNames(dataCpy);
    for (let keyIdx = 0; keyIdx < keysForObject.length; keyIdx += 1) {
      const key = keysForObject[keyIdx];
      const value = dataCpy[key];
      const pathForKey = currPath === '' ? key : `${currPath}.${key}`;
      convertObjectToStrDotNotation(value, pathForKey, out);
      outCpy[pathForKey] = value;
    }
    return outCpy;
  }
  // we have reached the bottom of the recursion, just return the data

  return dataCpy;
}

function* getConfig() {
  yield put(configActions.configGetConfigRequest());
  try {
    const config = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/templates/config/learningportal.json`,
          withCredentials: true,
        })
        .then((response) => response.data)
    );

    const recievedValidJSON = typeof config === 'object' && config !== null;
    if (recievedValidJSON) {
      /* find all the keys using a stringified dot-notation and store them in this manner
      so if we have this config-object:
      {
        a: {a1: 123, a2: 456}, b: {b1: true} }
      }

      create this:
      {
        a: {a1: 123, a2: 456},
        b: {b1: {b2: true}},
        'a.a1': 123,
        'a.a2': 456,
        'b.b1': true,
      }

      in this way, we can easily and efficiently access a specific property in the config using this syntax,
      so if we want to get a -> a1 we just look up 'a.a1' in the config
      */

      const configDataInDotNotation = convertObjectToStrDotNotation(config);

      yield put(
        configActions.configGetConfigSuccess({
          config: configDataInDotNotation,
        })
      );
    } else {
      console.error('Invaldid JSON for config-file');
      if (process.env.NODE_ENV === 'development') {
        alert('Invalid JSON for config file');
      }
      yield put(configActions.configGetConfigFailure({ error: {} }));
    }
  } catch (error) {
    yield put(configActions.configGetConfigFailure({ error }));
  }
}

export default [takeLatest(configActions.CONFIG_GET_CONFIG, getConfig)];
