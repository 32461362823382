import React from 'react';

import PropTypes from 'prop-types';
import CourseSignButton from '@components/course-sign-button/course-sign-button';

import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import './course-date.scss';

moment.locale('no');
momentLocalizer(moment);

const CourseDate = ({
  courseEvent,
  courseSigningOn,
  usersCourseEvent,
  isMobile,
}) => {
  const { startdate, enddate, location, id: courseId, title } = courseEvent;
  const startDate = moment(startdate);
  const endDate = moment(enddate);

  return (
    <div className="course-event-details course-date">
      <div className="course-date__date">
        <span className="course-date__date-day">{startDate.format('DD')}</span>
        <span className="course-date__date-month">
          {startDate.format('MMM')}
        </span>
      </div>
      <div className="course-date__details">
        <div className="course-date__title">{title}</div>
        <div className="course-date__location">
          {location.title ? (
            <span>{location.title}</span>
          ) : (
            location.city && <span>{location.city}</span>
          )}
        </div>
        <div className="course-date__hours">
          {startDate.format('HH.mm')} - {endDate.format('HH.mm')}
        </div>
        {isMobile && (
          <>
            <br />
            <CourseSignButton
              courseEvent={courseEvent}
              usersCourseEvent={usersCourseEvent}
            />
          </>
        )}
      </div>
      {courseSigningOn.error &&
        courseSigningOn.results.length === 0 &&
        courseSigningOn.ceid === courseId && (
          <div>Feil i registreringen...</div>
        )}
      {!isMobile && (
        <CourseSignButton
          courseEvent={courseEvent}
          usersCourseEvent={usersCourseEvent}
        />
      )}
    </div>
  );
};

CourseDate.propTypes = {
  courseEvent: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
  courseSigningOn: PropTypes.shape({}).isRequired,
  usersCourseEvent: PropTypes.array,
  employees: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

CourseDate.defaultProps = {
  usersCourseEvent: null,
  employees: {
    isFetching: false,
    data: [],
  },
};

export default CourseDate;
