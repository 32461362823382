import moment from 'moment';

export const getEmployees = ({ employees: { list, checklists } }) => {
  if (!list.data) {
    return list;
  }

  return {
    ...list,
    data: list.data.map(employee => ({
      ...employee,
      pending_checklists:
        (checklists.data &&
          checklists.data.filter(cl => cl.id === employee.id).length) ||
        0,
    })),
  };
};

export const getFilteredEmployees = ({ employees: { filteredList } }) =>
  filteredList;

export const getReport = ({ employees: { report } }) => report;

export const getSelectedPersonReport = ({
  employees: {
    selectedPerson: { report },
  },
}) => report;

export const getSelectedPerson = ({ employees: { selectedPerson } }) => ({
  ...selectedPerson,
  data: selectedPerson.data && {
    ...selectedPerson.data,
    roles:
      Array.isArray(selectedPerson.data.roles) &&
      selectedPerson.data.roles.map(role => ({
        ...role,
        taskdone:
          (Array.isArray(role.competences) &&
            role.competences.length &&
            role.competences.reduce(
              (taskdone, { passed }) => taskdone + ((passed === 100 && 1) || 0),
              0
            )) ||
          0,
        tasks: role.competences.length,
        progress:
          (Array.isArray(role.competences) &&
            role.competences.length &&
            role.competences.reduce(
              (progress, { passed }) => progress + (passed || 0),
              0
            ) /
              role.competences.length /
              100) ||
          0,
        competences:
          Array.isArray(role.competences) &&
          role.competences.map(competence => ({
            id: competence.id,
            passed: competence.passed,
            date: competence.date ? moment(competence.date) : null,
            title: competence.competence.title,
            competenceType: competence.competence.competence_type,
          })),
      })),
  },
});

export const getSelectedPersonUsername = ({
  employees: {
    selectedPerson: {
      data: { user_name: userName },
    },
  },
}) => userName;

export const getIsFetchingEmployees = ({
  employees: {
    list: { isFetching },
    filteredList: { isFetching: isFetchingFiltered },
  },
}) => isFetching || isFetchingFiltered;

export const getSearchPhraze = ({ employees: { searchPhraze } }) =>
  searchPhraze;

export const getEmployeesStatistics = ({ employees: { statistics } }) =>
  statistics;

export const getEmployeesActivities = ({ employees: { activities } }) =>
  activities;

export const getEmployeesExpiredCompetences = ({ employees: { expiring } }) =>
  expiring;

export const getEmployeesChecklists = ({ employees: { checklists } }) =>
  checklists;

export const getIsFetchingFunctions = ({
  employees: {
    functions: { isFetching },
  },
}) => isFetching;

export const getFunctions = ({
  employees: {
    functions: { data },
  },
}) =>
  data &&
  data.reduce(
    (acc, cur) => {
      const { rolemetatype } = cur;
      const types = ['position', 'role'];

      types.forEach(type => {
        if (rolemetatype === type) {
          acc[type].push(cur);
        }
      });

      return acc;
    },
    {
      position: [],
      role: [],
    }
  );

export const getIsSaving = ({
  employees: {
    saving: { isSaving },
  },
}) => isSaving;

export const getPersonEvents = ({
  employees: {
    selectedPerson: { data },
  },
}) => data && data.events;

export const getEmployeesEvents = ({
  employees: {
    events: { data },
  },
}) => data;




export const getEvents = ({
  employees: {
    events: { data },
  },
}) => data;

export const getCampExtraData = ({ employees: { extraData } }) => extraData;
