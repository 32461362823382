import '../styles/competences-list.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import Certificate from './certificate';

const createDescriptionHTML = descriptionHTML => ({
  __html: descriptionHTML,
});

const Competence = ({ personcompetence }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      onClick={() => setIsExpanded(!isExpanded)}
      onKeyPress={() => setIsExpanded(!isExpanded)}
      className={classNames({
        competence: true,
        'competence--expanded': isExpanded,
      })}
      role="button"
      tabIndex="0">
      <div className="competence__header">
        <div className="competence__info">
          <div className="competence__name">
            {personcompetence.competence.title}
          </div>
          <div className="competence__meta">
            {personcompetence.grade && (
              <div className="competence__grade">
                {`${personcompetence.grade}%`}
              </div>
            )}
            {personcompetence.date && (
              <div className="competence__date">
                {moment(personcompetence.date).format('DD.MM.YYYY')}
              </div>
            )}
          </div>
        </div>
        {personcompetence.certificate_url && (
          <Certificate certificate_url={personcompetence.certificate_url} />
        )}
        <button
          className="competence__collapse"
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}>
          <i className={`fas fa-angle-${isExpanded ? 'up' : 'down'}`} />
        </button>
      </div>
      {isExpanded && (
        <div className="competence__description">
          <div
            dangerouslySetInnerHTML={createDescriptionHTML(
              personcompetence.competence.description
            )}
          />
        </div>
      )}
    </div>
  );
};

Competence.propTypes = {
  personcompetence: PropTypes.shape({}).isRequired,
};

const CompetencesList = ({ competencesGroupedByType }) => {
  console.log('competencesGroupedByType', competencesGroupedByType);
  return (
    <div className="competences-list">
      {competencesGroupedByType.length ? (
        competencesGroupedByType
          .filter(competenceGroup => competenceGroup.id !== 6)
          .map(competenceGroup => (
            <div
              key={competenceGroup.id}
              className="competences-list__competence-group">
              <div className="competences-list__competence-group-header">
                {competenceGroup.title}
              </div>
              {competenceGroup.personcompetences.map(personcompetence => (
                <Competence
                  key={personcompetence.id}
                  personcompetence={personcompetence}
                />
              ))}
            </div>
          ))
      ) : (
        <div className="competences-list__empty-state">
          Du har <i>ingen</i> gjennomført opplæring.
        </div>
      )}
    </div>
  );
};

CompetencesList.propTypes = {
  competencesGroupedByType: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CompetencesList;
