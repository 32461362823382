/* eslint-disable no-debugger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { css, jsx } from '@emotion/core';
import { bindActionCreators } from 'redux';
import {
  coursesGetCourseEvents,
  coursesSetCompetencegroup,
  coursesSetFilters,
  coursesSetSearchTerm,
  coursesFiltersSetSubcompetencegroup,
  coursesFiltersSetSubSubcompetencegroup,
} from '@actions/courses.actions';
import {
  getCompetences,
  getCompetencesSearchTerm,
  getCompetencegroups,
  getSelectedCatalogView,
  getSelectedCompetencegroupId,
  getSelectedCompetencetypes,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
} from '@selectors/courses.selectors';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import SearchBar from '@routes/employees/components/search-bar';
import NestedSelect from '@components/nested-select/nested-select';

import './competencegroups-sidebar.scss';
import { configGetConfig } from '@actions/config.actions';
import { getConfigObject } from '@selectors/config.selectors';
import AnimatedDropdownArrow from '@components/animated-dropdown-arrow/animatedDropdownArrow';
import { getCompeteceGroupsForMode } from '@routes/course-catalog/containers/course-catalog/course-catalog-container';
import CoursesMenu from '../courses-menu';

const catalogViews = [
  ['competences', 'Kompetanse'],
  ['calendar', 'Kurskalender'],
];

const courseTypes = [
  ['ecourse,complex', 'E-kurs'],
  ['course', 'Klassekurs'],
];

const CompetenceGroupSidebar = ({
  competences,
  catalogView,
  competencegroups,
  coursesGetCourseEvents,
  coursesSetFilters,
  coursesSetSearchTerm,
  coursesSetCompetencegroup,
  selectedCompetencegroupId,
  selectedSubCompetencegroupId,
  selectedCompetenceTypes,
  isMobile,
  searchTerm,
  coursesFiltersSetSubcompetencegroup,
  configObject,
  showSearchbar,
  showCourseTypeFilter,
  mode,
  theme,
  selectedSubSubCompetenceGroupId,
  coursesFiltersSetSubSubcompetencegroup,
}) => {
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(true);

  const handleCourseTypeChange = ({ courseType, checked }) => {
    coursesSetFilters({
      selectedCompetencetypes: {
        [courseType]: checked,
      },
    });
  };

  const handleViewTabChange = ({ catalogView }) => {
    if (catalogView === 'calendar') {
      coursesGetCourseEvents();
    }
    coursesSetFilters({
      catalogView: { tab: catalogView },
    });
  };

  const handleCourseMenuChangeForMode = ({ id, level }) => {
    // level determines the "depth of the selction", eg if the selected element is a top-level,
    // a second-level and so on
    if (mode === 'groups') {
      if (level === 1) {
        coursesSetCompetencegroup({
          competencegroupId: id,
        });
      } else if (level === 2) {
        coursesFiltersSetSubcompetencegroup({
          subcompetencegroupId: id,
        });
      } else if (level === 3) {
        coursesFiltersSetSubSubcompetencegroup({
          subSubcompetencegroupId: id,
        });
      }
    } else if (mode === 'subgroups') {
      if (level === 1) {
        coursesFiltersSetSubcompetencegroup({
          subcompetencegroupId: id,
        });
      } else if (level === 2) {
        coursesFiltersSetSubSubcompetencegroup({
          subSubcompetencegroupId: id,
        });
      }
    }
  };

  const disableButtons = catalogView.tab === 'calendar';

  const getSelectedGroupIdForMode = () => {
    if (mode === 'groups') {
      return selectedCompetencegroupId;
    }
    if (mode === 'subgroups') {
      return selectedSubCompetencegroupId;
    }

    console.error('mode not inplemented', mode);
    return null;
  };

  const getSelectedSubGroupIdForMode = () => {
    if (mode === 'groups') {
      return selectedSubCompetencegroupId;
    }
    if (mode === 'subgroups') {
      return null;
    }

    console.error('mode not inplemented', mode);
    return null;
  };

  const getSelectedSubSubGroupForMode = () => {
    if (mode === 'groups') {
      return selectedSubSubCompetenceGroupId;
    }
    if (mode === 'subgroups') {
      return null;
    }

    console.error('mode not inplemented', mode);
    return null;
  };

  const getOptionsForSelectedSidebar = (options) => {
    if (!Array.isArray(options)) {
      return null;
    }

    return options.map((o) => ({
      id: o.id,
      text: o.title,
      isSelected:
        selectedCompetencegroupId === o.id ||
        selectedSubCompetencegroupId === o.id ||
        selectedSubSubCompetenceGroupId === o.id,
      children: getOptionsForSelectedSidebar(o.children),
    }));
  };

  const groupsForMode = getCompeteceGroupsForMode(
    competencegroups,
    selectedCompetencegroupId,
    mode
  );

  return (
    <div
      className={classNames('competence-groups-sidebar', theme, {
        'competence-groups-sidebar--mobile': isMobile,
        'competence-groups-sidebar__buttons-disabled': disableButtons,
      })}>
      {!disableButtons && (
        <>
          {isMobile && groupsForMode && groupsForMode.length > 0 && (
            <div className="competence-groups-sidebar__buttons">
              <button
                className="competence-groups-sidebar--mobile__category_filter_button"
                type="button"
                onClick={() => setIsMobileFiltersOpen(!isMobileFiltersOpen)}>
                <AnimatedDropdownArrow
                  theme={theme}
                  css={css`
                    position: static;
                  `}
                  state={isMobileFiltersOpen ? 'collapsed' : 'expanded'}
                />
                <div
                  css={css`
                    margin-left: 1em;
                    float: right;
                    font-weight: bold;
                    margin-bottom: 0.75em;
                    color: ${theme === 'dark' ? 'inherit' : 'white'};
                    :hover {
                      cursor: pointer;
                    }
                  `}>
                  Kategorier
                </div>
              </button>

              <CoursesMenu isMobile={isMobile} />
            </div>
          )}

          {(showSearchbar ||
            (Array.isArray(groupsForMode) && groupsForMode.length > 0)) && (
            <div
              className={classNames([
                'competence-groups-sidebar__filters',
                {
                  'competence-groups-sidebar__filters--active': isMobileFiltersOpen,
                },
              ])}>
              {(!isMobile || isMobileFiltersOpen) && (
                <>
                  {showSearchbar && (
                    <SearchBar
                      className="competence-groups-sidebar__competences-search"
                      placeholder="Søk kurs"
                      name="competence-search"
                      value={searchTerm}
                      loading={competences.isFetching}
                      onChange={({ target: { value } }) => {
                        coursesSetSearchTerm({ searchTerm: value });
                      }}
                    />
                  )}
                  {!isMobile && (
                    <div
                      css={css`
                        padding-left: 40px;
                        color: #0a0a0a;
                        font-size: 1.2em;
                      `}>
                      Kategorier
                    </div>
                  )}
                  {(!searchTerm && groupsForMode && (
                    <div>
                      {Array.isArray(groupsForMode) && (
                        <div>
                          <NestedSelect
                            theme={theme}
                            isMobile={isMobile}
                            wrapperStyle={
                              isMobile
                                ? css`
                                    margin: 0;
                                    padding: 0;
                                    position: relative;
                                    margin-top: -1em;
                                  `
                                : ''
                            }
                            key="selected"
                            options={getOptionsForSelectedSidebar(
                              groupsForMode
                            )}
                            onSelected={(option, level, isLeafNode) =>
                              handleCourseMenuChangeForMode({
                                id: option.id,
                                level,
                                isLeafNode,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  )) ||
                    (showSearchbar && (
                      <button
                        className="competence-groups-sidebar__clear-search"
                        type="button"
                        onClick={() =>
                          coursesSetSearchTerm({ searchTerm: '' })
                        }>
                        <i className="fa fa-times" /> Fjern søk
                      </button>
                    ))}

                  {showCourseTypeFilter && (
                    <div>
                      <h3 className="competence-groups-sidebar__subtitle">
                        Kurs type
                      </h3>
                      <div className="competence-groups-sidebar__courses">
                        {courseTypes.map(
                          ([courseType, courseTypeName], key) => (
                            <CompetenceGroupsSidebarItem
                              key={key}
                              className="competence-groups-sidebar__item  competence-groups-sidebar__item-type"
                              label={courseTypeName}
                              name={courseType}
                              disabled={disableButtons}
                              onChange={() =>
                                handleCourseTypeChange({
                                  courseType,
                                  checked:
                                    selectedCompetenceTypes.indexOf(
                                      courseType
                                    ) === -1,
                                })
                              }
                              type="checkbox"
                              checked={
                                !disableButtons &&
                                selectedCompetenceTypes.indexOf(courseType) !==
                                  -1
                              }
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

CompetenceGroupSidebar.propTypes = {
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  catalogView: PropTypes.shape({}).isRequired,
  coursesGetCourseEvents: PropTypes.func.isRequired,
  coursesSetCompetencegroup: PropTypes.func.isRequired,
  selectedCompetenceTypes: PropTypes.array.isRequired,
  coursesSetFilters: PropTypes.func.isRequired,
  selectedCompetencegroupId: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  competences: fetchedDataWrapperShape(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.bool,
    ])
  ).isRequired,
  coursesSetSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['groups', 'subgroups']),
  configObject: PropTypes.shape({}).isRequired,
  selectedSubCompetencegroupId: PropTypes.number,
  selectedSubSubCompetenceGroupId: PropTypes.number,
  coursesFiltersSetSubcompetencegroup: PropTypes.func.isRequired,
  coursesFiltersSetSubSubcompetencegroup: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  showCourseTypeFilter: PropTypes.bool,
  showSearchbar: PropTypes.bool,
};

CompetenceGroupSidebar.defaultProps = {
  selectedCompetencegroupId: null,
  selectedSubCompetencegroupId: null,
  selectedSubSubCompetenceGroupId: null,
  showCourseTypeFilter: false,
  showSearchbar: false,
  mode: 'groups',
  theme: 'dark',
};

const mapStateToProps = (state) => ({
  competencegroups: getCompetencegroups(state),
  catalogView: getSelectedCatalogView(state),
  selectedCompetencegroupId: getSelectedCompetencegroupId(state),
  selectedCompetenceTypes: getSelectedCompetencetypes(state),
  competences: getCompetences(state),
  searchTerm: getCompetencesSearchTerm(state),
  selectedSubCompetencegroupId: getSelectedSubcompetencegroupId(state),
  configObject: getConfigObject(state),
  selectedSubSubCompetenceGroupId: getSelectedSubSubcompetencegroupId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      coursesGetCourseEvents,
      coursesSetCompetencegroup,
      coursesSetFilters,
      coursesSetSearchTerm,
      coursesFiltersSetSubcompetencegroup,
      coursesFiltersSetSubSubcompetencegroup,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenceGroupSidebar);

const CompetenceGroupsSidebarItem = ({
  className,
  name,
  label,
  checked,
  ...props
}) => (
  <label htmlFor={name} className={[className, checked ? ' active' : null]}>
    <input autoComplete="off" id={name} name={name} {...props} />
    <div className="dott" />
    <div className="label">{label}</div>
  </label>
);

CompetenceGroupsSidebarItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

CompetenceGroupsSidebarItem.defaultProps = {
  className: '',
  label: '',
};
