import 'react-hot-loader';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import 'focus-visible';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/core';

import ReactGA from 'react-ga';

import { routerAppComponentDidMount } from '@actions/router.actions';
import { authLogoutRequest } from '@actions/auth.actions';
import { notificationsHide } from '@actions/notifications.actions';
import Loading from '@components/loading/loading';
import { getIsMobile } from '@selectors/global.selectors';
import {
  isManager,
  getProfile,
  getPassedCompetencesIds,
  getPassedIds,
} from '@selectors/profile.selectors';
import { getShownNotifications } from '@selectors/notifications.selectors';
import { PrivateRoute, LoginContainer } from '@routes/auth';
import { coursesCourseFinished } from '@actions/courses.actions';

import { configGetConfig } from '@actions/config.actions';

import {
  getActiveCourse,
  getShouldShowMapInsteadOfCourseCatalog,
} from '@selectors/courses.selectors';

import { getConfig, getConfigObject } from '@selectors/config.selectors';

import {
  employees as employeesRoutes,
  courseCatalog as courseCatalogRoutes,
  myEducation as myEducationRoutes,
  atlas as AtlasRoutes,
  content as contentRoutes,
  nanoLearning as nanoLearningRoutes,
  atlas as atlasRoutes,
} from '@routes/routes.manifest';

import '@styles/app.scss';
import CoursePlayer from '@routes/course-catalog/components/course-player/course-player';

import { MyEducationSwitch } from '@routes/my-education';
import CourseCatalogSwitch from '@routes/course-catalog/course-catalog-switch';
import { EmployeesContainer } from '@routes/employees';
import { AtlasContainer } from '@routes/atlas';
import CampExtra from '@routes/camp-extra/camp-extra';
import Report from '@routes/reports/report';

import ContentViewerContainer from '@routes/content/containers/contentViewer/contentViewerContainer';

import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import '@styles/foundation.min.css';

import Cookies from 'js-cookie';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

import {
  faChevronRight,
  faThumbsUp,
  faPlus,
  faUser,
  faCalendar,
  faCalendarAlt,
  faHourglass,
  faChevronUp,
  faUserCheck,
  faChevronDown,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fal,
  far,
  fas,
  fad,
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel
);

import { hot } from 'react-hot-loader/root';
import { messagesGetMessages } from '@actions/messages.actions';

import { version, buildHash } from '@src/config';
import SignatureModal from '@components/course/signature-modal';

import styled from '@emotion/styled';
import ClearCache from '@components/clear-cache/clear-cache';
import NanoLearningPlayerContainer from '@routes/nano-learning/containers/nano-learning-player-container';
import ArticleLoader, {
  FullscreenArticleLoader,
} from '@routes/content/components/loader/loader';

const NoticeBanner = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 999999;
  padding: 20px 0;
  padding-bottom: 4px;
  line-height: 1.5;
  border-top-right-radius: 10px,
  border-top-left-radius: 10px,
  font-size: 1.0rem;
  font-weight: bold;
  text-align: center;

  background-color: var(--color-header-top);
  color: var(--color-header-text) !important;

  span {
    display: inline-block;
    margin-bottom: 16px;
  }

  .btn {
    display: inline-block;
    margin: -0.25em 20px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    border: 1px solid white;
    background: var(--color-main-dark);
  }
`;

class App extends Component {
  // messagesFetch;

  componentDidMount() {
    const {
      routerAppComponentDidMount,
      messagesGetMessages,
      configGetConfig,
    } = this.props;

    this.onEndCourseClick = this.onEndCourseClick.bind(this);
    routerAppComponentDidMount();

    ReactGA.initialize('UA-110810743-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    configGetConfig();
    this.messagesFetch = setInterval(() => {
      messagesGetMessages();
    }, 360000);

    if (buildHash || version) {
      // eslint-disable-next-line no-console
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }
  }

  componentWillUnmount() {
    clearInterval(this.messagesFetch);
  }

  onEndCourseClick() {
    const { coursesCourseFinished, activeCourse } = this.props;
    /*

    /my-education/role/365

    */
    let my_section = 'first-page';
    if (window.location.pathname.indexOf('/role') !== -1) {
      my_section = 'roles';
    }

    console.log('end click');
    coursesCourseFinished({ cid: activeCourse.cid, section: my_section });
  }

  render() {
    const {
      sessionId,
      isMobile,
      profile,
      isManager,
      alert,
      activeCourse,
      authLogoutRequest,
      notifications,
      secondLogin,
      hideNotification,
      configObject,
      displayMapInsteadOfCourseCatalog,
    } = this.props;

    const showMap = configObject.getProperty('params.show-map') === true;

    const waitForCourseCatalogLockStatus = configObject.data && showMap;

    const isSetMapLocation =
      window.location.pathname.indexOf('/set-atlas') !== -1;
    if (isSetMapLocation) {
      const track = window.location.pathname.split('/')[2];
      const params = new URL(document.location).searchParams;

      const langId = params.get('lang');
      localStorage.setItem('track', track);
      localStorage.setItem('language', langId || 'no');
      Object.entries(localStorage).forEach(([k, v]) => {
        if (/^track-data\/[0-9]+$/.test(k)) {
          localStorage.removeItem(k);
        }
      });
    }

    if (
      !Cookies.get('tg-visit') ||
      !sessionId ||
      secondLogin ||
      profile.error
    ) {
      return (
        <Router basename={process.env.REACT_APP_MOUNT_PATH}>
          <div className="app-wrapper menu-two coop">
            <div className="app-wrapper__content">
              <LoginContainer />
              <Redirect from="*" to="/login" />
            </div>
          </div>
        </Router>
      );
    }

    const waitToShowApp = !(
      waitForCourseCatalogLockStatus === false ||
      (waitForCourseCatalogLockStatus &&
        typeof displayMapInsteadOfCourseCatalog === 'boolean')
    );

    return (
      configObject.data && (
        <Router basename={process.env.REACT_APP_MOUNT_PATH}>
          {(activeCourse && activeCourse.type === 24 && (
            <NanoLearningPlayerContainer courseId={activeCourse.cid} />
          )) || (
            <div className="app-wrapper menu-two coop">
              <div className="app-wrapper__menu">
                <Menu
                  isManager={isManager}
                  onLogoutClick={() => {
                    authLogoutRequest();
                  }}
                  profileData={profile.data}
                />
              </div>
              <div className="app-wrapper__content">
                {activeCourse && activeCourse.status !== 0 && (
                  <CoursePlayer
                    reloading={alert.type === 'alert-reloading'}
                    onEnd={this.onEndCourseClick}
                    id={activeCourse.cid}
                    cid={activeCourse.cid}
                    type={activeCourse.type}
                    url={activeCourse.url}
                    opened={activeCourse.opened}
                    timestamp={activeCourse.timestamp}
                  />
                )}

                {!waitToShowApp && (
                  <Switch>
                    {!Cookies.get('tg-visit') ||
                      (profile.error && <LoginContainer />)}

                    <PrivateRoute
                      path={courseCatalogRoutes.main.path}
                      component={CourseCatalogSwitch}
                    />

                    {/* Things like articles, videos etc */}
                    <PrivateRoute
                      path={contentRoutes.main.path}
                      component={ContentViewerContainer}
                    />

                    <PrivateRoute
                      path={myEducationRoutes.main.path}
                      component={MyEducationSwitch}
                    />
                    <PrivateRoute
                      path={employeesRoutes.campExtra}
                      component={CampExtra}
                    />
                    <PrivateRoute
                      path={[employeesRoutes.report, employeesRoutes.reportNew]}
                      component={Report}
                    />
                    <PrivateRoute
                      path={Object.values(employeesRoutes)}
                      component={EmployeesContainer}
                    />
                    <PrivateRoute
                      path={AtlasRoutes.main.path}
                      component={AtlasContainer}
                    />
                    <Redirect
                      to={
                        displayMapInsteadOfCourseCatalog
                          ? atlasRoutes.main.path
                          : courseCatalogRoutes.main.path
                      }
                    />
                  </Switch>
                )}
                <SignatureModal />
                <Notifications
                  notifications={notifications}
                  onHideNotification={hideNotification}
                />
                {(version || buildHash) && (
                  <div
                    style={{
                      left: '10px',
                      bottom: '10px',
                      position: 'fixed',
                      opacity: '0.3',
                      padding: '10px',
                      fontSize: '0.8rem',
                    }}>
                    {`${buildHash} v. ${version}`}
                  </div>
                )}
              </div>

              {alert.type === 'alert-reloading' &&
                (configObject.getProperty(
                  'params.reloading-indicator-variant'
                ) === 'fullscreen' ? (
                  <FullscreenArticleLoader />
                ) : (
                  <Loading />
                ))}

              <ClearCache>
                {({ message, isLatestVersion, emptyCacheStorage }) =>
                  !isLatestVersion && (
                    <NoticeBanner>
                      <span>
                        {message ||
                          'Ny versjon av Læringsportalen er lansert. Klikk refresh for ny versjon.'}
                      </span>
                      <button
                        type="button"
                        className="btn"
                        zIndex="0"
                        onClick={() => {
                          emptyCacheStorage();
                        }}>
                        REFRESH
                      </button>
                    </NoticeBanner>
                  )
                }
              </ClearCache>
            </div>
          )}
        </Router>
      )
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  alert: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  authLogoutRequest: PropTypes.func.isRequired,
  messagesGetMessages: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  configGetConfig: PropTypes.func.isRequired,
  configObject: PropTypes.shape({}).isRequired,
  displayMapInsteadOfCourseCatalog: PropTypes.any,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
  displayMapInsteadOfCourseCatalog: null,
};

const mapStateToProps = (state) => ({
  sessionId: state.auth.sessionId,
  secondLogin: state.auth.secondLogin,
  alert: state.alert,
  activeCourse: getActiveCourse(state),
  profile: getProfile(state),
  notifications: getShownNotifications(state),
  isManager: isManager(state),
  isMobile: getIsMobile(state),
  configObject: getConfigObject(state),
  displayMapInsteadOfCourseCatalog: getShouldShowMapInsteadOfCourseCatalog(
    state
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      routerAppComponentDidMount,
      authLogoutRequest,
      coursesCourseFinished,
      messagesGetMessages,
      hideNotification: notificationsHide,
      configGetConfig,
    },
    dispatch
  );

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
