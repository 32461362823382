import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import axios from 'axios';
import { backendUrl } from '@config';

import {
  getProfileUserName,
  getRoles,
  getOrganisationId,
  getPassedCompetences,
  getProfile,
  getPassedIds,
  getCompetences,
  getShowRoles,
  getSelectedRoleId,
} from '@selectors/profile.selectors';

import { notificationsAdd } from '@actions/notifications.actions';

import {
  ROUTER_APP_COMPONENT_DID_MOUNT,
  ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT,
  ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
  ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT,
} from '@actions/router.actions';
import { AUTH_LOGIN_SUCCESS, authUnauthorized } from '@actions/auth.actions';
import * as alertAction from '@actions/alert.actions';
import {
  coursesEndCourse,
  COURSES_COURSE_FINISHED,
  setOutroIsCompleted,
  setMapIsVerified,
} from '@actions/courses.actions';

import { fetchOrganisations } from '@sagas/roles.sagas';

import { getMessages } from '@sagas/messages.sagas';
import { getCourseEventsAPI, fetchMapCourses } from '@sagas/courses.sagas';

import retry from '@utils/sagas.utils';

import {
  profileFetchPersonRequest,
  profileFetchPersonSuccess,
  profileFetchPersonFailure,
  profileFetchFullPerson,
  fetchRolesRequestSuccess,
  profileFetchFullPersonSuccess,
  fetchShowRoleChildrenRequest,
  fetchShowRoleChildrenSuccess,
  fetchShowRoleChildrenFailure,
  profileSetLastMessageTimestamp,
  fetchCompetencesRequest,
  fetchCompetencesSuccess,
  fetchCompetencesFailure,
  PROFILE_CHEAT_COMPETENCE,
  profileEditPersonRequest,
  profileEditPersonSuccess,
  PROFILE_EDIT_PERSON,
  PROFILE_CHANGE_PROFILE_PICTURE,
  PROFILE_GET_PERSON,
  PROFILE_UPDATE_PASSED_COMPETENCES,
  PROFILE_FETCH_COMPETENCES,
  PROFILE_FETCH_COMPETENCES_CHILDREN,
  fetchCompetencesChildrenRequest,
  fetchCompetencesChildrenSuccess,
  fetchCompetencesChildrenFailure,
  profileEditPersonFailure,
  profileSetManager,
  profileFetchPersonEventsSuccess,
  profileChangeProfilePictureRequest,
  profileChangeProfilePictureFailure,
  profileChangeProfilePictureSuccess,
  PROFILE_FETCH_SUMMARY,
  profileFetchPersonSummaryRequest,
  profileFetchPersonSummarySuccess,
  profileFetchPersonSummaryFailure,
  PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS,
  fetchPassedCompetencesRequest,
  fetchPassedCompetencesSuccess,
  fetchPassedCompetencesFailure,
  fetchPassedCompetencesFullRequest,
  PROFILE_FETCH_EVENTS,
  fetchPassedCompetencesFullSuccess,
  fetchPassedCompetencesFullFailure,
  PROFILE_EDIT_PASSWORD,
  profileEditPasswordRequest,
  profileEditPasswordSuccess,
  profileEditPasswordFailure,
  profileSetSpecialroles,
  PROFILE_FETCH_EXPIRING,
  fetchExpiring,
  fetchExpiringRequest,
  fetchExpiringSuccess,
  fetchExpiringFailure,
} from '@actions/profile.actions';

import {
  employeesFetchSelectedPersonCompetencesChildrenRequest,
  employeesFetchSelectedPersonCompetencesSuccess,
  employeesFetchSelectedPersonCompetencesChildrenFailure,
} from '@actions/employees.actions';

import { fetchRole } from '@actions/roles.actions';

import { stringifyUrlParams } from '@utils/requests.utils';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {
  PROFILE_FETCH_REQUIREMENTS,
  profileFetchHelptextsSuccess,
  profileFetchAllOrganisations,
  profileFetchAllOrganisationsFailure,
  profileFetchAllOrganisationsSuccess,
} from '../actions/profile.actions';
import { getSelectedPerson } from '../selectors/employees.selectors';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* fetchHelptexts() {
  const data = yield retry(() =>
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/templates/config/dashboard/helptexts`,
        withCredentials: true,
      })
      .then((response) => response.data)
  );
  yield put(profileFetchHelptextsSuccess({ data }));
}

export function* fetchAllOrganisations() {
  yield put(profileFetchAllOrganisations());
  try {
    const data = yield call(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/allMyOrganisations`,
          withCredentials: true,
        })
        .then((response) => response.data)
    );

    yield put(profileFetchAllOrganisationsSuccess({ data }));
  } catch (error) {
    if (error.code === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(profileFetchAllOrganisationsFailure({ error }));
  }
}

function* fetchPerson() {
  yield put(profileFetchPersonRequest());
  try {
    const { profile, competencelevel } = yield all({
      profile: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/person`,
            params: {
              fields:
                'person_id,firstname,lastname,profile_image,email,mobile,fullname,user_name,roles(title),positions(organisation_id,title),organisations(organisation_id,extern_organisation_id,id,title,brand_id),data(avatar,last_message)',
            },
            withCredentials: true,
          })
          .then((response) => response.data.persons[0])
      ),
      competencelevel: retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/competencelevel`,
            withCredentials: true,
          })
          .then((response) => response.data.competencelevel[0].level)
      ),
    });

    const cheif_names = [
      'Salgssjef',
      'Ass. varehussjef',
      'Varehussjef',
      'Administrerende direktør',
      'Salgsleder',
      'Driftssjef',
      'Driftsleder',
      'Hovedkontor - leder',
      'Ass. butikksjef',
      'Hypermarkedssjef',
      'Butikksjef franchise',
      'Franchisetaker',
      'Daglig leder',
      'Butikksjef',
      'Ferskvaresjef',
      'Ass. ferskvaresjef',
      'CNSA - Leder',
      'Enhetsadministrator',
      'Superbruker',
    ];

    const campextra_names = ['DS Extra', 'Superbruker'];

    let isCheif = false;
    let pos = null;

    if (Array.isArray(profile.positions)) {
      pos = profile.positions.some((p) => cheif_names.includes(p.title));
      isCheif = pos;
    }

    if (!isCheif) {
      if (Array.isArray(profile.roles)) {
        pos = profile.roles.some((p) => cheif_names.includes(p.title));
        isCheif = pos;
      }
    }

    if (isCheif) {
      /*
       * IF WE ARE CHEIF, CHECK ALL ORGS AND REMOVE ANY THAT ARE NOT CHEIF.
       *
       * */
      if (profile.positions.length > 1) {
        const rpos = profile.positions.filter((p) =>
          cheif_names.includes(p.title)
        );

        if (rpos.length !== 0 && !localStorage.getItem('orgId')) {
          localStorage.setItem('orgId', rpos[0].organisation_id);
        }
      }

      yield put(profileSetManager());
    }

    profile.competencelevel = competencelevel;
    yield put(
      profileFetchPersonSuccess({
        person: profile,
        positions: profile.positions,
        competencelevel,
      })
    );

    /*
    const specialroles = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/roles`,
          params: {
            user_name: profile.data.user_name,
            fields: 'id,title',
            role_meta_types: 'specialrole',
          },
          withCredentials: true,
        })
        .then(({ data }) => data.roles)
    );
    */

    const superuser =
      !!profile.roles.filter((p) => p.id === 248).length || false;
    const driftsjef =
      !!profile.organisations.filter((p) => campextra_names.includes(p.title))
        .length || false;

    yield put(profileSetSpecialroles({ data: null, superuser, driftsjef }));
  } catch (error) {
    yield put(authUnauthorized({ error }));
    yield put(profileFetchPersonFailure({ error }));
  }
}

function* fetchCompetencesChildrenAPI(payload) {
  const { courseId, root } = payload.payload;
  let profile = null;
  const orgId = yield select(getOrganisationId);

  if (root === 'show-role') {
    profile = yield select(getProfile);
    yield put(fetchShowRoleChildrenRequest());
  } else if (root === 'employee-activites') {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());
  } else if (root === 'show-employee') {
    profile = yield select(getSelectedPerson);
    yield put(employeesFetchSelectedPersonCompetencesChildrenRequest());
  } else {
    profile = yield select(getProfile);
    yield put(fetchCompetencesChildrenRequest());
  }

  const personId = profile.data.id;

  let competences = [];
  if (root === 'show-role') {
    competences = yield select(getShowRoles);
    if (competences.data) {
      competences = competences.data;
    }
  } else if (root === 'employee-activites') {
    competences = yield select(getSelectedPerson);
  } else if (root === 'show-employee') {
    competences = yield select(getSelectedPerson);
  } else {
    competences = yield select(getCompetences);
  }

  try {
    const { data } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      })
    );

    if (root === 'show-role') {
      for (let i = 0; i < competences.requirements.length; i += 1) {
        if (competences.requirements[i].competence_id === courseId) {
          competences.requirements[i].competence.children = data;
        }
      }
      yield put(fetchShowRoleChildrenSuccess({ competences }));
    } else if (root === 'employee-activites') {
      for (
        let i = 0;
        i < competences.data.summary.activities.children.length;
        i += 1
      ) {
        if (
          competences.data.summary.activities.children[i].competence_id ===
          courseId
        ) {
          competences.data.summary.activities.children[i].children = data;
          break;
        }
      }
      const new_data = competences.data;
      yield put(
        employeesFetchSelectedPersonCompetencesSuccess({ data: new_data })
      );
    } else if (root === 'show-employee') {
      for (let i = 0; i < competences.data.summary.requirement.length; i += 1) {
        for (
          let ii = 0;
          ii < competences.data.summary.requirement[i].children.length;
          ii += 1
        ) {
          if (
            competences.data.summary.requirement[i].children[ii]
              .competence_id === courseId
          ) {
            competences.data.summary.requirement[i].children[
              ii
            ].children = data;
          }
        }
      }
      const new_data = competences.data;
      yield put(
        employeesFetchSelectedPersonCompetencesSuccess({ data: new_data })
      );
    } else {
      for (let i = 0; i < competences.length; i += 1) {
        if (competences[i].competence_id === courseId) {
          if (root === 'show-role') {
            competences[i].competence.children = data;
          } else {
            competences[i].children = data;
          }
        }
      }
      yield put(fetchCompetencesChildrenSuccess({ competences }));
    }
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    console.error(error);
    if (root === 'show-role') {
      yield put(fetchShowRoleChildrenFailure({ error }));
    } else if (root === 'show-employee') {
      yield put(
        employeesFetchSelectedPersonCompetencesChildrenFailure({ error })
      );
    } else {
      yield put(fetchCompetencesChildrenFailure({ error }));
    }
  }
}

/*
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 * */

export function* fetchPersonsSummary() {
  yield put(profileFetchPersonSummaryRequest());
  try {
    let userName = yield select(getProfileUserName);
    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const summary = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/roles`,
          params: {
            fields: 'description',
            role_meta_types: 'position,role',
            summary: 1,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then((response) => response.data)
    );
    yield put(profileFetchPersonSummarySuccess({ summary }));
  } catch (error) {
    if (error.code === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(profileFetchPersonSummaryFailure({ error }));
  }
}

function* fetchPassedCompetences(action) {
  console.log(action);
  yield put(fetchPassedCompetencesRequest());

  let params = {
    state: 'passed',
    limit: 100,
    // lms: 1,
    fields: 'passed,competence_id,points',
  };
  if (action.dirty) {
    params = {
      state: 'passed',
      limit: 100,
      // lms: 1,
      dirty: 1,
      fields: 'passed,competence_id,points',
    };
  }
  try {
    const {
      data: { personcompetences: competences },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params,
        withCredentials: true,
      })
    );

    const configObject = yield select(getConfigObject);
    if (configObject.getProperty('params.show-map')) {
      const propsForCurrLangAndTrack = yield select(
        getPropertiesForCurrLangAndTrack
      );
      const hasPasssedOutro = competences.find(
        (c) => c.competence_id === propsForCurrLangAndTrack.atlas.outroId
      );
      console.log('fetch passed');
      console.log('fetch passed ccs', competences);
      const hasVerified = competences.find(
        (c) => c.competence_id === propsForCurrLangAndTrack.atlas.verifyId
      );

      if (hasPasssedOutro) {
        yield put(setOutroIsCompleted());
      }
      if (hasVerified) {
        yield put(setMapIsVerified());
      }
    }
    yield put(fetchPassedCompetencesSuccess({ competences }));
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(fetchPassedCompetencesFailure({ error }));
  }
}

function* fetchPassedCompetencesFull() {
  yield put(fetchPassedCompetencesFullRequest());
  try {
    const {
      data: { personcompetences: competences },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          state: 'passed',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      })
    );
    for (let i = 0; i < competences.length; i += 1) {
      competences[i].competence_type_id =
        competences[i].competence_type.competence_type_id;
      competences[i].id = competences[i].competence_id;
      competences[i].children = [];
      competences[i].expanded = false;
      if (competences[i].competence.files.length !== 0) {
        competences[i].competence.cover = competences[i].competence.files.find(
          (f) => f.title === 'cover'
        );
      }
    }

    yield put(fetchPassedCompetencesFullSuccess({ competences }));
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    }
    yield put(fetchPassedCompetencesFullFailure({ error }));
  }
}

function* fetchMissingCompetencesAPI() {
  console.log('RUN THIS');
  if (true !== false) {
    yield put(fetchCompetencesRequest());

    try {
      const {
        data: { personcompetences: competences },
      } = yield retry(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences`,
          params: {
            state: 'missing',
            limit: 100,
            isthins: 1,
            fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
          },
          withCredentials: true,
        })
      );

      for (let i = 0; i < competences.length; i += 1) {
        competences[i].competence_type_id =
          competences[i].competence_type.competence_type_id;
        competences[i].id = competences[i].competence_id;
        competences[i].children = [];
        competences[i].expanded = false;
        if (competences[i].competence.files.length !== 0) {
          competences[i].competence.cover = competences[
            i
          ].competence.files.find((f) => f.title === 'cover');
        }
      }

      yield put(fetchCompetencesSuccess({ competences }));
    } catch (error) {
      if (error.status === 401) {
        yield put(authUnauthorized({ error }));
      }
      yield put(fetchCompetencesFailure({ error }));
    }
  }
}

function* fetchCompetencesAPI() {
  if (true !== false) {
    yield put(fetchCompetencesRequest());

    try {
      const {
        data: { personcompetences: competences },
      } = yield retry(() =>
        axios.request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences`,
          params: {
            state: 'all',
            limit: 100,
            fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
          },
          withCredentials: true,
        })
      );

      for (let i = 0; i < competences.length; i += 1) {
        competences[i].competence_type_id =
          competences[i].competence_type.competence_type_id;
        competences[i].id = competences[i].competence_id;
        competences[i].children = [];
        competences[i].expanded = false;
        if (competences[i].competence.files.length !== 0) {
          competences[i].competence.cover = competences[
            i
          ].competence.files.find((f) => f.title === 'cover');
        }
      }

      yield put(fetchCompetencesSuccess({ competences }));
    } catch (error) {
      if (error.status === 401) {
        yield put(authUnauthorized({ error }));
      }
      yield put(fetchCompetencesFailure({ error }));
    }
  }
}

function* updateProgress() {
  const person = yield select(getProfile);
  const roles = yield select(getRoles);
  const passed_ids = yield select(getPassedCompetences);
  if (person.data && Array.isArray(roles.data) && passed_ids) {
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];
      let comp_passed_ids = 0;
      if (passed_ids.keys.length > 0) {
        for (let ii = 0; ii < pos.required_competences.length; ii += 1) {
          if (passed_ids.keys.indexOf(pos.required_competences[ii].id) !== -1) {
            comp_passed_ids += 1;
          }
        }
      }
      pos.progress = 0;
      pos.taskdone = comp_passed_ids;
      pos.tasks = pos.required_competences.length;
      if (comp_passed_ids !== 0) {
        pos.progress = Math.round((comp_passed_ids / pos.tasks) * 100);
      }
    }
    const roles_update = [];
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];
      if (pos.id === person.data.positions[0].id) {
        roles_update.push(pos);
        break;
      }
    }
    for (let i = 0; i < roles.data.length; i += 1) {
      const pos = roles.data[i];
      if (pos.required_competences.length !== 0) {
        if (pos.id !== person.data.positions[0].id) {
          roles_update.push(pos);
        }
      }
    }
    yield put(fetchRolesRequestSuccess({ roles: roles_update }));
  }
}

export function* fetchCourseEvents() {
  const userName = yield select(getProfileUserName);
  const my_events = yield call(getCourseEventsAPI, { userName });
  yield put(profileFetchPersonEventsSuccess({ events: my_events }));
}

export function* fetchFullPerson() {
  yield put(profileFetchFullPerson());
  yield all([
    yield call(fetchPerson, {}),
    yield call(fetchOrganisations, {}),
    yield call(getMessages, {}),
  ]);

  const userName = yield select(getProfileUserName);
  const my_events = yield call(getCourseEventsAPI, { userName });
  yield call(fetchPassedCompetences, { userName });
  yield put(profileFetchPersonEventsSuccess({ events: my_events }));
  yield put(profileFetchFullPersonSuccess());
  yield put(alertAction.actionClear());
}

function* updateCompetenceLevel() {
  const userName = yield select(getProfileUserName);
  const competencelevel = yield call(() =>
    axios
      .request({
        method: 'GET',
        params: {
          user_name: userName,
        },
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(
        ({
          data: {
            competencelevel: [profile],
          },
        }) => profile
      )
  );
  console.log(competencelevel);
  return competencelevel;
}

function* editPassword({
  payload: {
    data: { newPassword, oldPassword },
  },
}) {
  yield put(profileEditPasswordRequest());
  try {
    const params = {};
    params.password = newPassword;
    params.old_password = oldPassword;
    params.password_match = newPassword;
    const query = encodeURI(stringifyUrlParams({ ...params }, 'person_data'));
    const userName = yield select(getProfileUserName);
    // TODO: some sort of feedback with result for the user
    const { data: return_data } = yield call(axios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${query}`,
      withCredentials: true,
    });
    if (return_data.valid) {
      yield put(profileEditPasswordSuccess());
      yield put(
        notificationsAdd({
          notification: {
            text: 'Passordet er endret',
            color: 'green',
          },
        })
      );
    } else {
      yield put(profileEditPasswordFailure({}));
      yield put(
        notificationsAdd({
          notification: {
            text: return_data.message,
            color: 'red',
          },
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(profileEditPasswordFailure({ error }));
    yield put(
      notificationsAdd({
        notification: {
          text: 'Feil ved endring av passord',
          color: 'red',
        },
      })
    );
  }
}

function* fetchExpiringCompetences() {
  try {
    let profile = yield select(getProfile);
    while (profile.data === null) {
      yield take();
      profile = yield select(getProfile);
    }
    const userName = yield select(getProfileUserName);
    yield put(fetchExpiringRequest());
    const {
      data: { personcompetences: data },
    } = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          user_name: userName,
          fields:
            'id,passed,date,competence_title,competence_id,competence_type(title,competence_type)',
          state: 'expired',
        },
        withCredentials: true,
      })
    );
    console.log(data);
    yield put(fetchExpiringSuccess({ data }));
  } catch (error) {
    yield put(fetchExpiringFailure({ error }));
  }
}

function* setLastMessageTimestamp() {
  let profile = yield select(getProfile);
  while (profile.data === null) {
    yield take();
    profile = yield select(getProfile);
  }
  const userName = yield select(getProfileUserName);
  const lastMessage = new Date().getTime();

  const params = `person_data[data(last_message)]=${lastMessage}`;
  yield call(() =>
    axios.request({
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${params}`,
      withCredentials: true,
    })
  );

  yield put(profileSetLastMessageTimestamp({ lastMessage }));
}

/*
 *  FUNCTION ON UPDATING ALL COMPENCES, WILL CONTROLL THE REST OF THE PROCESS AS WELL.
 *
 *
 */
function* updateCompetences(payload) {
  console.log('call1');
  let section = null;
  let disableStatusUpdateCheck = null;
  if (payload && payload.payload) {
    // eslint-disable-next-line prefer-destructuring
    section = payload.payload.section;
    disableStatusUpdateCheck = payload.payload.disableStatusUpdateCheck;
  } else if (window.location.href.indexOf('/role') !== -1) {
    section = 'roles';
  }

  yield put(alertAction.actionLoading());
  const passedIds = yield select(getPassedIds);
  const userName = yield select(getProfileUserName);

  yield call(fetchPassedCompetences, { userName, dirty: 1 });

  yield call(fetchMapCourses);

  const passedIds_new = yield select(getPassedIds);

  if (
    !disableStatusUpdateCheck &&
    (passedIds.length !== passedIds_new.length || section === 'roles')
  ) {
    /*
     * check if we have a status update, if we do "reload" page.
     *
     * */
    yield updateCompetenceLevel();
    if (section === 'roles') {
      const selectedRoleId = yield select(getSelectedRoleId);
      yield put(fetchRole(selectedRoleId));
    } else {
      yield fetchCompetencesAPI();
    }
  }
  yield updateProgress();

  yield put(coursesEndCourse());
  yield put(alertAction.actionClear());
}

function* editPerson(action) {
  const {
    person: {
      profile_image_dataURL,
      firstname,
      lastname,
      email,
      mobile,
      roles,
    },
  } = action.payload;
  /*
   * TRIGGER SO WE UPDATE THE PERSON CARD AS WELL.
   * */
  yield put(profileEditPersonRequest());
  const userName = yield select(getProfileUserName);
  const params = encodeURI(
    stringifyUrlParams(
      {
        profile_image_dataURL,
        firstname,
        lastname,
        mobile,
        email,
        roles: roles.map((role) => role.role_id),
      },
      'person_data'
    )
  );

  try {
    yield call(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/persons/${userName}?${params}`,
        withCredentials: true,
      })
    );

    yield call(fetchPerson);
    yield put(profileEditPersonSuccess());
    yield put(
      notificationsAdd({
        notification: {
          text: 'Din profil er oppdatert',
          color: 'green',
        },
      })
    );
  } catch (error) {
    yield put(profileEditPersonFailure(error));
  }
}

function* changeProfilePicture(action) {
  const { person_id, file } = action.payload;
  yield put(profileChangeProfilePictureRequest());
  try {
    const formData = new FormData();
    formData.append('profile_image', file);
    const { data } = yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/persons/save_profile_image/${person_id}`,
        data: formData,
        withCredentials: true,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      })
    );
    yield put(profileChangeProfilePictureSuccess({ data }));
  } catch (error) {
    yield put(profileChangeProfilePictureFailure({ error }));
  }
}

function cheatAPI(action) {
  return axios
    .request({
      method: 'get',
      url: `${backendUrl}/templates/set-comp?username=${action.userName}&cid=${action.id}`,
    })
    .then((response) => response.data);
}

function* cheatCompetence(payload) {
  const userName = yield select(getProfileUserName);
  yield call(cheatAPI, { id: payload.payload.id, userName });
  yield delay(2000);
  yield call(() =>
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competencelevel`,
        withCredentials: true,
      })
      .then(
        ({
          data: {
            competencelevel: [profile],
          },
        }) => profile
      )
  );
  yield call(updateCompetences, { cid: payload.payload.id });
}

export default [
  takeLatest(AUTH_LOGIN_SUCCESS, fetchFullPerson),
  takeLatest(PROFILE_FETCH_EVENTS, fetchCourseEvents),
  takeLatest(PROFILE_FETCH_EXPIRING, fetchExpiringCompetences),
  takeLatest(PROFILE_EDIT_PASSWORD, editPassword),
  takeLatest(ROUTER_APP_COMPONENT_DID_MOUNT, fetchPerson),
  takeLatest(ROUTER_APP_COMPONENT_DID_MOUNT, fetchFullPerson),
  takeLatest(ROUTER_APP_COMPONENT_DID_MOUNT, fetchHelptexts),
  takeLatest(ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT, fetchAllOrganisations),
  takeLatest(AUTH_LOGIN_SUCCESS, fetchFullPerson),
  takeLatest(PROFILE_FETCH_SUMMARY, fetchPersonsSummary),
  takeLatest(PROFILE_UPDATE_PASSED_COMPETENCES, updateCompetences),
  takeLatest(PROFILE_FETCH_REQUIREMENTS, fetchMissingCompetencesAPI),
  takeLatest(PROFILE_FETCH_COMPETENCES, fetchCompetencesAPI),
  takeLatest(
    ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
    fetchPassedCompetencesFull
  ),
  takeLatest(
    ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT,
    setLastMessageTimestamp
  ),
  takeEvery(PROFILE_FETCH_COMPETENCES_CHILDREN, fetchCompetencesChildrenAPI),
  takeLatest(PROFILE_EDIT_PERSON, editPerson),
  takeLatest(PROFILE_CHANGE_PROFILE_PICTURE, changeProfilePicture),
  takeLatest(PROFILE_CHANGE_PROFILE_PICTURE_SUCCESS, fetchPerson),
  takeLatest(PROFILE_GET_PERSON, fetchPerson),
  takeLatest(PROFILE_CHEAT_COMPETENCE, cheatCompetence),
  takeLatest(COURSES_COURSE_FINISHED, updateCompetences),
];
