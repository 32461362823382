import { backendUrl } from '@config';

/* eslint-disable one-var */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */
// based on https://awik.io/determine-color-bright-dark-using-javascript/
export const lightOrDark = (color) => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.startsWith('rgb')) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 200) {
    return 'light';
  }
  return 'dark';
};

/* eslint-enable one-var */
/* eslint-enable no-param-reassign */
/* eslint-enable prefer-destructuring */
/* eslint-enable no-bitwise */

// gets the full url for an image, possibly prepending the backend-url if the url is absolute
export const getImageUrl = (src) => {
  if (!src) {
    return '';
  }
  if (src.startsWith(backendUrl)) {
    return src;
  }
  if (src.charAt(0) === '/') {
    return backendUrl + src;
  }
  return src;
};
