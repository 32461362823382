import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const CourseGroupHeader = styled.h2(
  ({ theme }) => css`
    color: ${theme === 'light' ? 'white' : 'inherit'};
    font-size: 1.5em;
    font-weight: normal;
    margin-top: 0;
  `,
  ({ breadcrumb }) =>
    breadcrumb &&
    css`
      font-size: 1.2em;
      margin-bottom: 0;
      cursor: pointer;
      margin-bottom: 0.5em;

      :hover {
        color: #010031;
      }
    `
);

export default CourseGroupHeader;
