/** @jsx jsx */

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';

import {
  Container,
  Path as StyledPath,
  Empty,
  Circle,
} from '@routes/atlas/components/Path/styles';
import { Item } from '@routes/atlas/components/Hexagon/Hexagon';
import { getIsMobile } from '../../../../store/selectors/global.selectors';

const MapStack = ({
  variant,
  index,
  course,
  activeCourseID,
  completedCourses,
  animateCompleted,
  disableInteraction,
}) => {
  const isMobile = useSelector(getIsMobile);
  const clickMode = isMobile ? 'OPEN_COURSEPLAYER' : 'SELECT_ACTIVE_COURSE';

  switch (variant) {
    case 'top':
      return (
        <>
          {(index % 2 === 0 && (
            <Item
              index={index}
              course={course}
              activeCourseID={activeCourseID}
              clickMode={clickMode}
              completedCourses={completedCourses}
              animateCompleted={animateCompleted}
              disableInteraction={disableInteraction}
            />
          )) || <Empty />}
        </>
      );
    case 'bottom':
      return (
        <>
          {(index % 2 === 1 && (
            <Item
              index={index}
              course={course}
              activeCourseID={activeCourseID}
              clickMode={clickMode}
              completedCourses={completedCourses}
              animateCompleted={animateCompleted}
              disableInteraction={disableInteraction}
            />
          )) || <Empty />}
        </>
      );
    default:
      return null;
  }
};

const CreatePath = ({ index, completedCourses, coursesLength }) => (
  <StyledPath>
    {((index === 0 || index === coursesLength - 1) && (
      <Circle completed={completedCourses >= index || index === 0} />
    )) ||
      (index % 2 === 0 && (
        <Circle variant="left" completed={completedCourses >= index} />
      )) || <Circle variant="right" completed={completedCourses >= index} />}
  </StyledPath>
);

const Path = ({
  index,
  completedCourses,
  coursesLength,
  course,
  activeCourseID,
  clickMode,
  animateCompleted,
  disableInteraction,
}) => {
  return (
    <Container key={`Container-${index}`}>
      <MapStack
        variant="top"
        index={index}
        course={course}
        activeCourseID={activeCourseID}
        clickMode={clickMode}
        completedCourses={completedCourses}
        animateCompleted={animateCompleted}
        disableInteraction={disableInteraction}
      />
      <CreatePath
        index={index}
        completedCourses={completedCourses}
        coursesLength={coursesLength}
      />
      <MapStack
        variant="bottom"
        index={index}
        course={course}
        activeCourseID={activeCourseID}
        clickMode={clickMode}
        completedCourses={completedCourses}
        animateCompleted={animateCompleted}
        disableInteraction={disableInteraction}
      />
    </Container>
  );
};

export default Path;
