import { publicUrl } from '@config';

const avatarsPath = `${publicUrl}/assets/avatar`;

const hairTabIcon = `${avatarsPath}/hair/02-Hairs.png`;
const beardTabIcon = `${avatarsPath}/beard/03-Facial-Hairs.png`;
const headTabIcon = `${avatarsPath}/head/01-Heads.png`;
const clothesTabIcon = `${avatarsPath}/body/08-Clothes.png`;
const hatTabIcon = `${avatarsPath}/hat/07-Hats.png`;
const eyeTabIcon = `${avatarsPath}/eyes/04-Eyes.png`;
const mouthTabIcon = `${avatarsPath}/mouth/05-Mouths.png`;
const eyeWearTabIcon = `${avatarsPath}/eyeWear/06-Eyewears.png`;

const hairSourceBlack = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-Black.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Black.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Black.png`,
];
const hairSourceWhite = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-White.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-White.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-White.png`,
];
const hairSourceBrown = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-Brown.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Brown.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Brown.png`,
];
const hairSourceBlond = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-Blonde.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Blonde.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Blonde.png`,
];
const hairSourceGinger = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-Ginger.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Ginger.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Ginger.png`,
];
const hairSourceRed = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Red.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Red.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Red.png`,
];
const hairSourcePurple = [
  `${avatarsPath}/hair/female/Girl-Hair-1a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-2-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-3a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-4-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-5a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-6a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-7a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-8a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-9a-Purple.png`,
  `${avatarsPath}/hair/female/Girl-Hair-10a-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-1-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-2-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-3-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-4-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-5-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-6-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-7-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-8-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-9a-Purple.png`,
  `${avatarsPath}/hair/male/Guy-Hair-10a-Purple.png`,
];

const bodyBody = [
  `${avatarsPath}/body/Body-1.png`,
  `${avatarsPath}/body/Body-2.png`,
  `${avatarsPath}/body/Body-3.png`,
  `${avatarsPath}/body/Body-4.png`,
  `${avatarsPath}/body/Body-5.png`,
];

const headWhite = [
  `${avatarsPath}/head/White-S.png`,
  `${avatarsPath}/head/White-M.png`,
  `${avatarsPath}/head/White-L.png`,
];
const headLatin = [
  `${avatarsPath}/head/Latin-S.png`,
  `${avatarsPath}/head/Latin-M.png`,
  `${avatarsPath}/head/Latin-L.png`,
];
const headAsian = [
  `${avatarsPath}/head/Asian-S.png`,
  `${avatarsPath}/head/Asian-M.png`,
  `${avatarsPath}/head/Asian-L.png`,
];
const headBlack = [
  `${avatarsPath}/head/Black-S.png`,
  `${avatarsPath}/head/Black-M.png`,
  `${avatarsPath}/head/Black-L.png`,
];

const hat1Source = `${avatarsPath}/hat/Hat-1.png`;
const hat2Source = `${avatarsPath}/hat/Hat-2.png`;
const hat3Source = `${avatarsPath}/hat/Hat-3.png`;
const hat4Source = `${avatarsPath}/hat/Hat-4.png`;
const hat6Source = `${avatarsPath}/hat/Hat-6.png`;
const hat7Source = `${avatarsPath}/hat/Hat-7.png`;
const hat8Source = `${avatarsPath}/hat/Hat-8.png`;
const hat9Source = `${avatarsPath}/hat/Hat-9.png`;
const hat10Source = `${avatarsPath}/hat/Hat-10.png`;
const hat11Source = `${avatarsPath}/hat/Hat-11.png`;
const hat12Source = `${avatarsPath}/hat/Hat-12.png`;
const hat13Source = `${avatarsPath}/hat/Hat-13.png`;
const hat14Source = `${avatarsPath}/hat/Hat-14.png`;
const hat15Source = `${avatarsPath}/hat/Hat-15.png`;

const beard1Source = `${avatarsPath}/beard/Beard-1-Brown.png`;
const beard2Source = `${avatarsPath}/beard/Beard-2-Brown.png`;
const beard3Source = `${avatarsPath}/beard/Beard-3-Brown.png`;
const beard5Source = `${avatarsPath}/beard/Beard-5-Brown.png`;
const beard6Source = `${avatarsPath}/beard/Beard-6-Brown.png`;
const beard7Source = `${avatarsPath}/beard/Beard-7-Brown.png`;
const beard8Source = `${avatarsPath}/beard/Beard-8-Brown.png`;
const beard9Source = `${avatarsPath}/beard/Beard-9-Brown.png`;
const beard10Source = `${avatarsPath}/beard/Beard-10-Brown.png`;
const beard11Source = `${avatarsPath}/beard/Beard-11-Brown.png`;

const eyesImage = [
  `${avatarsPath}/eyes/Eyes-1.png`,
  `${avatarsPath}/eyes/Eyes-2.png`,
  `${avatarsPath}/eyes/Eyes-3.png`,
  `${avatarsPath}/eyes/Eyes-4.png`,
  `${avatarsPath}/eyes/Eyes-5.png`,
  `${avatarsPath}/eyes/Eyes-6.png`,
  `${avatarsPath}/eyes/Eyes-7.png`,
  `${avatarsPath}/eyes/Eyes-8.png`,
  `${avatarsPath}/eyes/Eyes-9.png`,
  `${avatarsPath}/eyes/Eyes-10.png`,
];

const eyeWear1Source = `${avatarsPath}/eyeWear/Eye-Wear-1.png`;
const eyeWear2Source = `${avatarsPath}/eyeWear/Eye-Wear-2.png`;
const eyeWear3Source = `${avatarsPath}/eyeWear/Eye-Wear-3.png`;
const eyeWear4Source = `${avatarsPath}/eyeWear/Eye-Wear-4.png`;
const eyeWear5Source = `${avatarsPath}/eyeWear/Eye-Wear-5.png`;
const eyeWear6Source = `${avatarsPath}/eyeWear/Eye-Wear-6.png`;
const eyeWear7Source = `${avatarsPath}/eyeWear/Eye-Wear-7.png`;
const eyeWear8Source = `${avatarsPath}/eyeWear/Eye-Wear-8.png`;
const eyeWear9Source = `${avatarsPath}/eyeWear/Eye-Wear-9.png`;
const eyeWear10Source = `${avatarsPath}/eyeWear/Eye-Wear-10.png`;

const mouth1Source = `${avatarsPath}/mouth/Mouth-1.png`;
const mouth2Source = `${avatarsPath}/mouth/Mouth-2.png`;
const mouth3Source = `${avatarsPath}/mouth/Mouth-3.png`;
const mouth4Source = `${avatarsPath}/mouth/Mouth-4.png`;
const mouth5Source = `${avatarsPath}/mouth/Mouth-5.png`;
const mouth6Source = `${avatarsPath}/mouth/Mouth-6.png`;
const mouth7Source = `${avatarsPath}/mouth/Mouth-7.png`;
const mouth8Source = `${avatarsPath}/mouth/Mouth-8.png`;
const mouth9Source = `${avatarsPath}/mouth/Mouth-9.png`;
const mouth10Source = `${avatarsPath}/mouth/Mouth-10.png`;

const eyes = {
  id: 'eyes',
  title: 'Øye',
  titleImageSource: eyeTabIcon,
  hasColors: false,
  deselect: false,
  featureOptions: {
    transform: { y: 5, scale: 1.2 },
    imageSources: eyesImage,
  },
  colorOptions: {
    colors: [],
  },
};

const mouth = {
  id: 'mouth',
  title: 'Munn',
  hasColors: false,
  deselect: false,
  titleImageSource: mouthTabIcon,
  featureOptions: {
    transform: { y: 0, scale: 1.2 },
    imageSources: [
      mouth1Source,
      mouth2Source,
      mouth3Source,
      mouth4Source,
      mouth5Source,
      mouth6Source,
      mouth7Source,
      mouth8Source,
      mouth9Source,
      mouth10Source,
    ],
  },
  colorOptions: {
    colors: [],
  },
};

const eyeWear = {
  id: 'eyeWear',
  title: 'Briller',
  hasColors: false,
  deselect: true,
  titleImageSource: eyeWearTabIcon,
  featureOptions: {
    transform: { y: 5, scale: 1.2 },
    imageSources: [
      eyeWear1Source,
      eyeWear2Source,
      eyeWear3Source,
      eyeWear4Source,
      eyeWear5Source,
      eyeWear6Source,
      eyeWear7Source,
      eyeWear8Source,
      eyeWear9Source,
      eyeWear10Source,
    ],
  },
  colorOptions: {
    colors: [],
  },
};

const hair = {
  id: 'hair',
  title: 'Hår',
  hasColors: true,
  deselect: true,
  titleImageSource: hairTabIcon,
  featureOptions: {
    transform: { y: 35, scale: 1.2 },
    imageSources: [
      hairSourceBlack,
      hairSourceBlond,
      hairSourceBrown,
      hairSourceWhite,
      hairSourceGinger,
      hairSourcePurple,
      hairSourceRed,
    ],
  },
  colorOptions: {
    colors: [
      '#2E2925',
      '#FED100',
      'brown',
      '#efefef',
      '#E3671C',
      '#B566FF',
      '#FF6666',
    ],
  },
};

const body = {
  id: 'body',
  title: 'Klær',
  hasColors: false,
  deselect: false,
  titleImageSource: clothesTabIcon,
  featureOptions: {
    transform: { y: -20, scale: 1.2 },
    imageSources: bodyBody,
  },
  colorOptions: {
    colors: [],
  },
};

const beard = {
  id: 'beard',
  title: 'Skjegg',
  hasColors: false,
  deselect: true,
  titleImageSource: beardTabIcon,
  featureOptions: {
    transform: { y: 0, scale: 1.2 },
    imageSources: [
      beard1Source,
      beard2Source,
      beard3Source,
      beard5Source,
      beard6Source,
      beard7Source,
      beard8Source,
      beard9Source,
      beard10Source,
      beard11Source,
    ],
  },
  colorOptions: {
    colors: [],
  },
};

const head = {
  id: 'head',
  title: 'Hode',
  hasColors: true,
  deselect: false,
  titleImageSource: headTabIcon,
  featureOptions: {
    transform: { y: 15, scale: 1.2 },
    imageSources: [headWhite, headLatin, headAsian, headBlack],
  },
  colorOptions: {
    colors: ['#efefef', '#654F2E', '#FED100', '#000'],
  },
};

const hat = {
  id: 'hat',
  title: 'Lue',
  hasColors: false,
  deselect: true,
  titleImageSource: hatTabIcon,
  featureOptions: {
    transform: { y: 35, scale: 1.2 },
    imageSources: [
      hat1Source,
      hat2Source,
      hat3Source,
      hat4Source,
      hat6Source,
      hat7Source,
      hat8Source,
      hat9Source,
      hat10Source,
      hat11Source,
      hat12Source,
      hat13Source,
      hat14Source,
      hat15Source,
    ],
  },
  colorOptions: {
    colors: [],
  },
};

export const getFeatureSourceByIndex = ({ data, type }) => {
  const { index, color } = data[type.id] || {};
  if (index === undefined || index === null) {
    return null;
  }
  if (type.hasColors) {
    return type.featureOptions.imageSources[color || 0][index];
  }
  return type.featureOptions.imageSources[index];
};

export default { head, body, eyes, mouth, beard, eyeWear, hair, hat };
