/** @jsx jsx */
/* eslint-disable react/prop-types */
import { useEffect, useMemo, useRef, useState } from 'react';
import { jsx, css } from '@emotion/core';
import Path from '@routes/atlas/components/Path/index';
import { base, Background, wrapper } from '@routes/atlas/components/Map/styles';
import { useSelector } from 'react-redux';
import { getIsMobile } from '@selectors/global.selectors';
import { lineStyle } from '../Path/styles';
import { lerp } from '../../../../common/utils/animation.utils';
import { easingFunctions } from '../../../../common/utils/easing-functions';

const animateSpeed = 0.15;
let previousMapData = null;

export const Map = ({
  track,
  data,
  activeCourseID,
  completedCourses,
  clickPathMode,
  hide,
  backgrondPosition,
  backgroundImage,
  disableInteraction,
  finishedWithFirstMapRuntrough,
}) => {
  const coursesLength = Object.keys(data).length;

  const [lastCompletedIndex] = useState(() => {
    if (!previousMapData || previousMapData.trackId !== track.id) {
      previousMapData = { trackId: track.id, data };
      if (completedCourses === 1) {
        return 0;
      }
      return -1;
    }
    const index = [...data].reverse().findIndex(({ status }, index) => {
      const reverseIndex = coursesLength - index - 1;
      return (
        status === 'DONE' &&
        (!previousMapData[reverseIndex] ||
          previousMapData[reverseIndex].status !== 'DONE')
      );
    });
    previousMapData = { trackId: track.id, data };
    return coursesLength - index - 1;
  }, [track, completedCourses, data]);

  const initialCompletedCount = useMemo(() => {
    if (lastCompletedIndex !== -1) {
      return lastCompletedIndex;
    }
    return completedCourses;
  }, [completedCourses, lastCompletedIndex]);

  const [completedCount, setCompletedCount] = useState(initialCompletedCount);
  const [completedCoursesTarget, setCompletedCoursesTarget] = useState(
    initialCompletedCount
  );
  const [animationStarted, setAnimationStarted] = useState(
    lastCompletedIndex === -1
  );
  const animationReachedTarget = completedCount >= completedCoursesTarget;

  const isMobile = useSelector(getIsMobile);

  useEffect(() => {}, [track, data]);

  useEffect(() => {
    if (animationStarted) {
      setCompletedCoursesTarget(completedCourses);
      return undefined;
    }
    let running = true;
    const timeout = setTimeout(() => {
      if (!running) {
        return;
      }
      setCompletedCoursesTarget(completedCourses);
      setAnimationStarted(true);
    }, 1500);

    return () => {
      running = false;
      clearTimeout(timeout);
    };
  }, [animationStarted, completedCourses, coursesLength]);

  const animationProgress = useRef({
    progress:
      Math.min(completedCoursesTarget, coursesLength - 1) /
        (coursesLength - 1) || 0,
  });
  const animatedLineRef = useRef(null);

  useEffect(() => {
    const line = animatedLineRef.current;

    if (!line) {
      return undefined;
    }

    const targetProgress =
      Math.min(completedCoursesTarget, coursesLength - 1) /
        (coursesLength - 1) || 0;
    const currentProgress = animationProgress.current.progress;

    const duration = Math.abs(
      ((targetProgress - currentProgress) * 1000) / animateSpeed
    );

    let playing = true;
    const startTime = Date.now();
    const frame = () => {
      if (!playing) {
        return;
      }

      const now = Date.now();
      const seek = easingFunctions.easeOutQuad(
        Math.min(1, (now - startTime) / duration)
      );

      const progress = lerp(currentProgress, targetProgress, seek);

      animationProgress.current.progress = progress;

      const { style } = line;
      style.height = `${progress * 100}%`;

      const completedCount = Math.floor(progress * coursesLength);
      setCompletedCount(completedCount);

      if (seek < 1) {
        requestAnimationFrame(frame);
      }
    };
    requestAnimationFrame(frame);

    return () => {
      playing = false;
    };
  }, [completedCount, completedCoursesTarget, coursesLength]);

  return (
    <Background
      isMobile={isMobile}
      backgrondPosition={backgrondPosition}
      backgroundImage={backgroundImage}
      css={css`
        visibility: ${hide ? 'hidden' : 'visible'};
      `}>
      <div
        css={css(
          wrapper,
          isMobile &&
            css`
              margin-bottom: 2em;
            `
        )}>
        <div css={base}>
          <div
            css={css(lineStyle, {
              marginTop: '2.5em',
              height: `${(coursesLength - 1) * 5}em`,
            })}>
            <div
              ref={animatedLineRef}
              css={css({
                backgroundColor: '#21CE6C',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              })}
            />
          </div>
          {data.map((course, index) => (
            <Path
              clickMode={clickPathMode}
              disableInteraction={disableInteraction}
              key={course.id}
              course={course}
              index={index}
              activeCourseID={
                (animationStarted && animationReachedTarget) ||
                course.status === 'OPEN'
                  ? activeCourseID
                  : null
              }
              coursesLength={coursesLength}
              completedCourses={completedCount}
              animateCompleted={
                lastCompletedIndex === index && !finishedWithFirstMapRuntrough
              }
            />
          ))}
        </div>
      </div>
    </Background>
  );
};
