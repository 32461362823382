import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-widgets/lib/DatePicker';
import './form-datepicker.scss';

import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

moment.locale('no');
momentLocalizer(moment);

const FormDatePicker = ({ className, input, label, meta, ...props }) => (
  <div className="form-input">
    {label && (
      <label htmlFor={input.name} className="form-input__label">
        {label}
      </label>
    )}

    <DatePicker
      {...props}
      {...input}
      dateForm="DD.MM.YYYY"
      id={input.name}
      time={false}
      value={input.value ? new Date(input.value) : null}
      className={classnames('form-input__input', className, {
        'form-input__input--error': meta.touched && meta.error,
      })}
    />
    {meta.touched && meta.error && (
      <div className="form-input__error">{meta.error}</div>
    )}
  </div>
);

FormDatePicker.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormDatePicker.defaultProps = {
  className: '',
  label: '',
};

export default FormDatePicker;

// input.value ? null : new Date(input.value)
