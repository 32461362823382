/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Base, Input } from './styles';

export const FormInput = ({
  className,
  input,
  meta,
  placeholder,
  icon,
  ...props
}) => (
  <Base>
    <label>
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
      <Input
        type="text"
        id={input.name}
        placeholder={placeholder}
        value={input.value || ''}
        autoComplete="off"
        {...props}
        {...input}
      />
    </label>

    {meta.touched && meta.error && <div>{meta.error}</div>}
  </Base>
);

FormInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormInput.defaultProps = {
  className: '',
};
