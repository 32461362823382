import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import classNames from 'classnames';

import PersonAvatar from '@components/person-avatar/person-avatar';
import { employees as employeeRoutes } from '@routes/routes.manifest';
import { getRouteWithParams } from '@utils/routes.utils';

import 'moment/locale/nb';
import moment from 'moment';

class ExpiringList extends Component {
    render() {
      const { expiring, loading, isPerson } = this.props;
      if (!expiring) { return (<></>); }
      if (isPerson) {
        return (
          <div className="events-list">
            {expiring.map(item => {
              const status = moment(item.date).isBefore(new Date()) ? 'past' : 'not-past';
              return (
                <div className="events-list__item">
                  <div className="events-list__date">
                    <div className="events-list__date__day">
                      {moment(item.date).format('DD')}
                    </div>
                    <div className="events-list__date__month">
                      {moment(item.date).format('MMM')}
                    </div>
                  </div>
                  <div className="events-list__card">
                    <div className="events-list__card__contents">
                      <div className="events-list__text_2_title">
                        <Link
                          to={`/course-catalog/${item.competence_id}`}
                          className='activity-list__activity-description'
                        >
                          {`«${item.competence_title}»`}
                        </Link>
                      {status === 'past' && (
                        <div className="events-list__text__description">
                          løp ut&nbsp;
                          {moment(item.date).format('DD. MMM YY')}
                        </div>
                      ) || (
                        <div className="events-list__text__description">
                           løper ut&nbsp;
                          {moment(item.date).format('DD. MMM YYYY')}
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            }
          </div>
        );
      }
      return (
        <div className="activity-list">
          {expiring.map(item => {
            const status = moment(item.valid_until).isBefore(new Date()) ? 'past':'not-past';
            return (
              <div className={`activity-list__element ${status}`}>
                <div className="activity-list__activity-timestamp-wrapper">
                  <div className="activity-list__activity-timestamp" format="DD.MMM">
                    {moment(item.valid_until).format('DD. MMM YY')}
                  </div>
                </div>
                <div className="activity-list__circle-wrapper">
                  <div className="activity-list__circle" />
                </div>
                <PersonAvatar
                  person={item.person}
                  className="activity-list__person-avatar"
                  fontSize="18px"
                  link
                />

                <div className="activity-list__activity">
                  <div className="activity-list__activity-description">
                    <Link
                      to={getRouteWithParams(employeeRoutes.employeePreview, {
                        userName: item.person.user_name,
                      })}
                    >
                      {item.person.firstname} {item.person.lastname}
                    </Link>

                  {status === 'past' && (
                    <div>
                      {moment(item.valid_until).format('DD. MMM YY')} utløp&nbsp;
                      <Link
                        to={`/course-catalog/${item.competence_id}`}
                        className='activity-list__activity-description__link'
                      >
                        {`«${item.competence_title}»`}
                      </Link>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
  }
}

ExpiringList.defaultProps = {
  isPerson: false,
  loading: false,
};

ExpiringList.propTypes = {
  expiring: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.shape({}).isRequired,
    })
  ).isRequired,
  isPerson: PropTypes.bool,
  loading: PropTypes.bool,
};


export default ExpiringList;
