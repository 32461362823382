import React from 'react';
import PropTypes from 'prop-types';

import { highlightOnKeyboardFocusMixin } from '@styles/mixins';
import { css } from '@emotion/core';
import { Content } from './styles';

export const CourseInformationContent = ({ children }) => {
  return (
    <Content
      css={css`
        ${highlightOnKeyboardFocusMixin};
      `}>
      {children}
    </Content>
  );
};

CourseInformationContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
