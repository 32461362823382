/** @jsx jsx */
import styled from '@emotion/styled/macro';
import { jsx, css } from '@emotion/core';
import React, { useState, useLayoutEffect } from 'react';

import posed from 'react-pose';
import { easing } from 'popmotion';

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 8px;
  overflow: visible;
`;

const Dot = styled(
  posed.div({
    visible: {
      transform: 'translateY(0px)',
      transition: {
        transform: ({ delay }) => ({
          type: 'keyframes',
          // for a smooth transition, set same value for start and end
          values: ['translateY(0px)', 'translateY(-10px)', 'translateY(0px)'],
          loop: Infinity,
          ease: easing.easeOut,
          duration: 1500,
          delay,
        }),
      },
    },
  })
)`
  flex: 0 0 auto;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
  background-color: #ef591b;
`;

const LoadingIndicator = props => {
  const [visible, setVisible] = useState();
  useLayoutEffect(() => {
    setVisible('visible');
  }, []);

  return (
    <DotContainer justify="center" {...props}>
      <Dot pose={visible} />
      <Dot pose={visible} delay="300" />
      <Dot pose={visible} delay="600" />
      <Dot pose={visible} delay="900" />
    </DotContainer>
  );
};

export default LoadingIndicator;
