import './course-calendar-card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import moment from 'moment';
import Moment from 'react-moment';
import ContentLoader from 'react-content-loader';

import CourseSignButton from '@components/course-sign-button/course-sign-button';

import 'moment/locale/nb';

export const CourseCalendarCardLoading = () => (
  <div className="course-calendar-card__loading">
    <ContentLoader
      title="Laster inn..."
      height={104}
      width={450}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb">
      <circle cx="17" cy="17" r="17" />
      <rect x="0" y="45" rx="4" ry="4" width="20" height="12" />
      <rect x="95" y="0" rx="4" ry="4" width="250" height="20" />
      <circle cx="105" cy="44" r="9" />
      <rect x="120" y="38" rx="4" ry="4" width="75" height="12" />
      <circle cx="230" cy="44" r="9" />
      <rect x="245" y="38" rx="4" ry="4" width="75" height="12" />
      <rect x="95" y="80" rx="4" ry="4" width="180" height="14" />
    </ContentLoader>
  </div>
);

const createSubtitleContent = content => ({
  __html: content,
});

const CourseCalendarCard = ({ event, usersCourseEvent, href }) => (
  <div className="course-calendar-card">
    <div className="course-calendar-card__date">
      <Moment
        className="course-calendar-card__date__day"
        locale="nb"
        interval={0}
        format="DD">
        {event.startdate}
      </Moment>
      <Moment
        className="course-calendar-card__date__daytext"
        locale="nb"
        interval={0}
        format="ddd">
        {event.startdate}
      </Moment>
    </div>
    <div className="course-calendar-card__card">
      <Link to={href} className="course-calendar-card__text">
        <div className="course-calendar-card__text__title">{event.title}</div>
        <div className="course-calendar-card__text__details">
          <span className="course-calendar-card__detail">
            <i className="far fa-clock" />{' '}
            {moment(event.startdate).format('HH:mm')}-
            {moment(event.enddate).format('HH:mm')}
          </span>
          <span className="course-calendar-card__detail">
            <i className="fas fa-map-marker-alt" /> {event.location.title}
          </span>
        </div>
        <div
          className="course-calendar-card__text__description"
          dangerouslySetInnerHTML={createSubtitleContent(
            event.short_description
          )}
        />
      </Link>
      <CourseSignButton
        courseEvent={event}
        usersCourseEvent={usersCourseEvent}
      />
    </div>
  </div>
);

CourseCalendarCard.propTypes = {
  usersCourseEvent: PropTypes.array,
  event: PropTypes.shape({}),
  href: PropTypes.string.isRequired,
};

CourseCalendarCard.defaultProps = {
  usersCourseEvent: null,
  event: {},
};

export default CourseCalendarCard;
