import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css, jsx } from '@emotion/core';
import {
  courseCatalog as courseCatalogRoutes,
  atlas as atlasRoutes,
} from '@routes/routes.manifest';
import { getRouteWithParams } from '@utils/routes.utils';
import * as coursesActions from '@actions/courses.actions';
import {
  getConfigObject,
  getCourseCatalogFeatures,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import { NoCoursesAvailable } from '@src/common/components/no-courses-available/NoCoursesAvailable';
import { Divider, linearGradientWithAdjustableColorStop } from '@facepaint';
import {
  getSelectedCatalogView,
  getCompetences,
  getCourseEvents,
  getCompetencegroups,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getCoursesSorting,
  getCompetencesSearchTerm,
  getShouldShowMapInsteadOfCourseCatalog,
  getFeeaturedCompetences,
} from '@selectors/courses.selectors';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import { getEvents, getPassed } from '@selectors/profile.selectors';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import moment from 'moment';
import 'moment/locale/nb';
import './course-catalog-container.scss';
import TileGrid from '@components/tile-grid/tile-grid';
import { Redirect } from 'react-router-dom';
import { getColorConfigForGroup } from '@components/menu/menu';
import { lightOrDark, getImageUrl } from '@utils/misc.utils';
import CourseGroupHeader from '@routes/course-catalog/components/course-group-header';
import { courseCatalogFeaturesShape } from '@types/config';
import { getDurationName } from '@components/course/course-duration';
import QuizPointsCardLine from '@routes/course-catalog/components/quiz-points-card-line';
import CourseCard, {
  CourseCardLoader,
} from './components/course-card/course-card';
import CourseCalendarCard, {
  CourseCalendarCardLoading,
} from './components/course-calendar-card/course-calendar-card';
import CoursesMenu from './components/courses-menu/courses-menu';
import CompetenceGroupSidebar from './components/courses-menu/competencegroups-list/competencegroups-sidebar';
import {
  tileGridWrapperstyleMobile,
  CourseCatalogContentWrapper,
  tileGridWrapperstyle,
  courseCatalogPageElement,
} from './course-catalog-container-styles';

const getCourseType = (type) =>
  ({
    verification: 'Verifikasjon',
    courseevent: 'Klasseromskurs',
    ecourse: 'E-kurs',
    complex: 'E-kurs gruppe',
    equivalents: 'Kompeteanser',
  }[type] || type);

export const getCompeteceGroupsForMode = (
  competencegroups,
  selectedCompetencegroupId,
  mode
) => {
  if (mode === 'groups') {
    return competencegroups.data;
  }
  if (mode === 'subgroups') {
    return (
      selectedCompetencegroupId &&
      Array.isArray(competencegroups.data) &&
      competencegroups.data.find(
        (competencegroup) => competencegroup.id === selectedCompetencegroupId
      ).children
    );
  }
  console.error('mode not inplemented', mode);
  return [];
};

const getUrlForCourse = (url, id, competenceType) => {
  if (competenceType.toLowerCase() === 'content') {
    return `/content/${url || '68'}`; // DEBUG-REMOVE HARDCODED
  }
  return getRouteWithParams(courseCatalogRoutes.coursePreview.path, {
    cid: id,
  });
};

/**
 * Displays courses in a grid and provides filters to filter out which courses to display.
 * Does also show loading placeholder content
 *
 * If given in config, a custom top-part to select the top-level competenvcegroups are displayed.
 * In mobile view, this part and the bottom part will be displayed in fullscreen.
 */

class CourseCatalogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };

    this.handleSorting = this.handleSorting.bind(this);
    this.sortDuration = this.sortDuration.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.onChoosedCompetenceGroupFromCustomTopPart = this.onChoosedCompetenceGroupFromCustomTopPart.bind(
      this
    );
    this.navigateToLink = this.navigateToLink.bind(this);
    this.startCourse = this.startCourse.bind(this);
    this.getSelectedGroup = this.getSelectedGroup.bind(this);
    this.getSelectedSubGroup = this.getSelectedSubGroup.bind(this);
    this.getThemeForGroupBaseColor = this.getThemeForGroupBaseColor.bind(this);
    this.getParamsForCustomTopPart = this.getParamsForCustomTopPart.bind(this);
    this.getMetadataFromCourseCategory = this.getMetadataFromCourseCategory.bind(
      this
    );
  }

  componentDidMount() {
    const {
      coursesInitializeMyCoursesView,
      selectedCompetencegroupId,
      match,
      history,
      isMobile,
      displayMapInsteadOfCourseCatalog,
      configObject,
      featuredCompetences,
      configForCurrMapAndLang,
    } = this.props;

    if (displayMapInsteadOfCourseCatalog) {
      return;
    }

    // if the user opens a page with a specific catgory, but we have not yet loaded courses,
    // redirect to the main view
    if (match.params.groupId && !selectedCompetencegroupId) {
      history.push(courseCatalogRoutes.main.path);
    }

    const { featuredTiles } = configForCurrMapAndLang.courseCatalog;

    // if we have featured tiles, get the competences for those, in addition to the rest
    // of the competences fetched
    coursesInitializeMyCoursesView({
      alsoGetFeaturedCompetences: !featuredCompetences.data
        ? {
            cids: (featuredTiles && featuredTiles.map((ft) => ft.cid)) || [],
          }
        : null,
    });

    this.setState({ isExpanded: false });
  }

  onChoosedCompetenceGroupFromCustomTopPart(courseGroupMappingKey) {
    const { history, isMobileCourseCatalog } = this.props;
    const {
      coursesSetCompetencegroup,
      configObject,
      configForCurrMapAndLang,
    } = this.props;

    const competencegroupId =
      configForCurrMapAndLang.courseCatalog.courseKindProperties[
        courseGroupMappingKey
      ].competenceGroupId;

    coursesSetCompetencegroup({
      competencegroupId,
    });

    // only mobile uses fullscreen view and needs this routing to allow back navigation
    if (isMobileCourseCatalog) {
      history.push(`/course-catalog/category/${competencegroupId}`);
    }
  }

  getSelectedGroup() {
    const { competencegroups, selectedCompetencegroupId } = this.props;
    if (!selectedCompetencegroupId || !Array.isArray(competencegroups.data)) {
      return null;
    }
    return competencegroups.data.find(
      (g) => g.id === selectedCompetencegroupId
    );
  }

  getSelectedSubGroup() {
    const { selectedSubcompetencegroupId } = this.props;
    const group = this.getSelectedGroup();
    if (!group || !Array.isArray(group.children)) {
      return null;
    }
    return group.children.find((sg) => sg.id === selectedSubcompetencegroupId);
  }

  // eslint-disable-next-line react/sort-comp
  startCourse(cid, course_type_id) {
    const { coursesStartCourse } = this.props;
    coursesStartCourse({ cid, type: course_type_id });
  }

  getMetadataFromCourseCategory = (category) => {
    if (!category) {
      return {};
    }
    const metadataEntries = category.split(';');
    const out = {};
    metadataEntries.forEach((e) => {
      const parts = e.split('=');
      // eslint-disable-next-line prefer-destructuring
      out[parts[0]] = parts[1];
    });
    out.authorImage = getImageUrl(out.img);
    out.authorName = out.name;
    return out;
  };

  getCourseCardProps(course) {
    const {
      configObject,
      selectedCompetencegroupId,
      passedCompetences,
      configForCurrMapAndLang,
    } = this.props;

    const defaultCardBackgroundImage = configObject.getProperty(
      'params.default-img'
    );

    const defaultCardBackgroundImageQuiz = configObject.getProperty(
      'params.default-img-quiz'
    );

    const courseKindPropertiesByCourseKind =
      configForCurrMapAndLang.courseCatalog.courseKindProperties;
    const {
      durations,
      durationType,
      metric,
      title,
      cover,
      short_description,
      teaser,
      competence_type,
      competence_type_id,
      url,
      id,
      category,
    } = course;

    const getImgSrc = (isQuiz) =>
      (cover && cover.url) ||
      (isQuiz ? defaultCardBackgroundImageQuiz : defaultCardBackgroundImage);
    const duration =
      durationType ||
      (durations && durations.length && durations[0] && durations[0].type);

    const metadataFromCourseTitle = this.getMetadataFromCourseCategory(
      category
    );

    const authorImage =
      (course.cmsData && course.cmsData.authorImage) ||
      metadataFromCourseTitle.authorImage;
    const authorName =
      (course.cmsData && course.cmsData.authorName) ||
      metadataFromCourseTitle.authorName;

    const teaserForListView =
      course.cmsData && course.cmsData.teaserForListView;

    const competenceForCourse =
      passedCompetences.data &&
      passedCompetences.data.find((c) => c.competence_id === id);

    const propsByCourseType = {
      elearning: {
        mainText: { content: title },
        image: {
          src: getImgSrc(),
        },
        metaInformation: duration
          ? [
              {
                icon: ['far', 'clock'],
                location: 'text-part',
                text: `${duration} ${getDurationName(metric, duration !== 1)}`,
              },
            ]
          : null,
      },
      videos: {
        mainText: { content: title },
        secondaryText: { content: short_description || teaser },
        image: {
          src: getImgSrc(),
          icons: [
            {
              name: ['fas', 'play'],
              placement: 'bottom-left',
              color: 'black',
              fontSize: '0.8em',
              surroundByCircle: {
                background: 'white',
                padding: '0.55em 0.55em 0.55em 0.6em',
              },
            },
          ],
        },
      },
      articles: {
        mainText: {
          content: title,
        },
        secondaryText: {
          content: short_description || teaser,
          showReadMore: true,
        },
        image: {
          src: getImgSrc(),
        },
      },
      quiz: {
        mainText: {
          content: title,
          css: css`
            font-weight: bold;
            margin-top: 0.5em;
          `,
        },
        secondaryText: { content: short_description || teaser },
        showActionButton: {
          text: 'Velg',
        },
        image: {
          src: getImgSrc(true),
          aspectRatio: 1.5,
        },
        metaInformation:
          competence_type === 'Nano Speed Course'
            ? [
                {
                  location: 'image-part',
                  icon: ['far', 'stopwatch-20'],
                  element: (
                    <QuizPointsCardLine
                      points={
                        (competenceForCourse && competenceForCourse.points) ||
                        '0'
                      }
                    />
                  ),
                },
              ]
            : null,
      },
      trainertips: {
        mainText: { content: title },
        secondaryText: { content: short_description || teaserForListView },
        image: {
          src: getImgSrc(),
          secondaryImage: authorImage
            ? {
                src: authorImage,
                placement: 'bottom-left',
                alt: authorName,
              }
            : null,
        },
      },
    };

    const keyPosition = 0;
    const currCardType = Object.entries(courseKindPropertiesByCourseKind).find(
      ([_, v]) => v.competenceGroupId === selectedCompetencegroupId
    )[keyPosition];

    // courses from this group should be started when clicking on them
    const autostartCourseForCompetenceGroupIds = configObject.getProperty(
      'routes.course-catalog.autostartCourseForCompetenceGroupIds'
    );

    return {
      ...(propsByCourseType[currCardType] || propsByCourseType.elearning),
      id,
      competence_type_id,
      href: getUrlForCourse(url, id, competence_type),
      onAutostartCourse: this.startCourse,
      shouldAutostartOnClick:
        autostartCourseForCompetenceGroupIds &&
        autostartCourseForCompetenceGroupIds.includes(
          selectedCompetencegroupId
        ) &&
        !url,
    };
  }

  getThemeForGroupBaseColor(groupColor) {
    const { isMobileCourseCatalog } = this.props;

    if (!isMobileCourseCatalog || !groupColor) {
      return 'dark';
    }

    return lightOrDark(groupColor) === 'dark' ? 'light' : 'dark';
  }

  sortDuration(a, b) {
    const typeA = a.durationType;
    const typeB = b.durationType;
    let durationA = a.durations;
    let durationB = b.durations;

    if (!durationA) {
      durationA = 1000000;
    } else if (typeA === 'hours') {
      durationA *= 60;
    } else if (typeA === 'days') {
      durationA *= 60 * 24;
    }

    if (!durationB) {
      durationB = 1000000;
    } else if (typeB === 'hours') {
      durationB *= 60;
    } else if (typeB === 'days') {
      durationB *= 60 * 24;
    }
    if (durationA < durationB) {
      return 1;
    }
    return -1;
  }

  handleShowMore() {
    const { coursesExpand } = this.props;
    coursesExpand();
  }

  handleSorting(list) {
    const { sorting } = this.props;

    switch (sorting[0]) {
      case 'type':
        return list.sort((a, b) => a.competence_type_id - b.competence_type_id);
      case 'date':
        return list.sort((a, b) => {
          return (
            new Date(a.modified).getTime() - new Date(b.modified).getTime()
          );
        });
      case 'alphabetical':
        return list.sort((a, b) => a.title - b.title);
      case 'duration':
        // Reverse list so longest courses come first
        return list.sort(this.sortDuration).reverse();
      default:
        return list;
    }
  }

  navigateToLink(link) {
    const { history } = this.props;
    history.push(link);
  }

  getParamsForCustomTopPart() {
    const {
      configObject,
      featuredCompetences,
      configForCurrMapAndLang,
      isMobile,
      isMobileCourseCatalog,
    } = this.props;
    const customTopPartPrefix = 'routes.course-catalog.customToplevelSelector';

    const { featuredTiles } = configForCurrMapAndLang.courseCatalog;

    return {
      tiles: configObject.getProperty(`${customTopPartPrefix}.tiles`),
      fullwidthTile: configObject.getProperty(
        `${customTopPartPrefix}.fullwidthTile`
      ),
      courseKindMap: configForCurrMapAndLang.courseCatalog.courseKindProperties,
      featuredTiles: featuredTiles
        ? {
            ...featuredCompetences,

            data:
              featuredCompetences.data &&
              featuredCompetences.data.map((fc) => ({
                ...fc,
                ...fc.cmsData,
                ...this.getMetadataFromCourseCategory(fc.category),
                title: fc.cmsData.title || fc.description || fc.title,
                route: fc.contentRoute,
              })),
          }
        : null,
      featuredTilesConfig: configObject.getProperty(
        `${customTopPartPrefix}.featuredTiles`
      ),
      onClickCourseGroupSelectorTile: this
        .onChoosedCompetenceGroupFromCustomTopPart,
      onClickLinkTile: this.navigateToLink,
      onClickCourseTile: this.startCourse,
      isMobile: isMobileCourseCatalog,
    };
  }

  renderCalendar = (list, events) => {
    list.sort((a, b) => {
      return new Date(a.modified).getTime() - new Date(b.modified).getTime();
    });
    let currentMonth = null;
    return list.reduce((list, item) => {
      let my_events = [];
      if (events && events.data) {
        my_events = events.data.filter(
          (e) => e.competence_id === item.competence_id
        );
      }
      const itemComponent = (
        <div className="course-catalog__course-calendar-card-wrapper">
          <CourseCalendarCard
            event={item}
            href={getRouteWithParams(courseCatalogRoutes.coursePreview.path, {
              cid: item.competence_id,
            })}
            usersCourseEvent={my_events.filter((me) => me.id === item.id)}
          />
        </div>
      );
      const month = moment(item.startdate).format('MMMM');
      if (month !== currentMonth) {
        currentMonth = month;
        return [
          ...list,
          <h2 className="course-catalog__calendar-title-month">{month}</h2>,
          itemComponent,
        ];
      }
      return [...list, itemComponent];
    }, []);
  };

  render() {
    const {
      competences,
      competencegroups,
      catalogView,
      events,
      courseEvents,
      isMobile,
      isMobileCourseCatalog,
      selectedCompetencegroupId,
      searchTerm,
      configObject,
      match,
      location,
      displayMapInsteadOfCourseCatalog,
      featuredCompetences,
      featuresEnabled,
      coursesFiltersSetSubcompetencegroup,
    } = this.props;

    const { isExpanded } = this.state;
    const useCustomTopPart = configObject.getProperty(
      'routes.course-catalog.customToplevelSelector'
    );

    const groupsMode = useCustomTopPart ? 'subgroups' : 'groups';

    /*
    If we have a custom top part, we want to show it top  of the course catalog for desktop.
    For mobile, we want to show it in fullscreen so that when a course-catagory,
    is selected from it, show the course catalog for that category.
    */

    const mobileCurrPathIsForCourseCatalog =
      match.params.groupId &&
      location.pathname !== courseCatalogRoutes.main.path;

    const showCourseCatalogContent =
      !useCustomTopPart ||
      !isMobileCourseCatalog ||
      mobileCurrPathIsForCourseCatalog;

    const showCustomTopPart =
      useCustomTopPart &&
      (!mobileCurrPathIsForCourseCatalog || !isMobileCourseCatalog);

    const showCourseTypeFilter = featuresEnabled.courseTypeFilter;
    const showSearchbar = featuresEnabled.searchbar;

    const groupsForMode = getCompeteceGroupsForMode(
      competencegroups,
      selectedCompetencegroupId,
      groupsMode
    );

    const hasAnySidebarElementsToDisplay =
      showSearchbar ||
      showCourseTypeFilter ||
      (Array.isArray(groupsForMode) && groupsForMode.length > 0) ||
      isMobileCourseCatalog;

    const extraLargeTilesForGrouops =
      configObject.getProperty(
        'routes.course-catalog.useExtraLargeTilesForGroups'
      ) || [];

    const colorConfigForGroup = getColorConfigForGroup({
      courseGroupId: selectedCompetencegroupId,
      pathname: location.pathname,
      isMobile: isMobileCourseCatalog,
      configObject,
    });

    const theme = this.getThemeForGroupBaseColor(colorConfigForGroup.color);
    const selectedGroup = this.getSelectedGroup();
    const selectedSubGroup = this.getSelectedSubGroup();

    return (
      <div
        className={`course-catalog-wrapper ${
          isMobileCourseCatalog ? 'mobile' : 'desktop'
        }`}>
        {displayMapInsteadOfCourseCatalog && (
          <Redirect to={atlasRoutes.main.path} />
        )}

        {showCustomTopPart && (
          <TileGrid
            {...this.getParamsForCustomTopPart()}
            wrapperStyle={tileGridWrapperstyle}
            wrapperStyleMobile={tileGridWrapperstyleMobile}
            contentWrapperStyle={courseCatalogPageElement}
          />
        )}

        {showCourseCatalogContent && (
          <CourseCatalogContentWrapper isMobile={isMobileCourseCatalog}>
            <div
              className="course-catalog"
              css={
                isMobileCourseCatalog
                  ? css``
                  : css`
                      ${courseCatalogPageElement};
                      width: calc(100% - 12px);
                    `
              }>
              {isMobileCourseCatalog && (
                <Divider
                  color={colorConfigForGroup.color}
                  darkenWith={0.2}
                  height={1}
                />
              )}

              {hasAnySidebarElementsToDisplay && (
                <div
                  className={isMobileCourseCatalog ? 'top-menu' : 'sidebar'}
                  css={[
                    isMobileCourseCatalog
                      ? {
                          ...(colorConfigForGroup.createGradient
                            ? linearGradientWithAdjustableColorStop(
                                colorConfigForGroup.color,
                                0.4,
                                colorConfigForGroup.getTopPartColorFromCourseGroup
                                  ? 2
                                  : 0,
                                0.1
                              )
                            : { background: colorConfigForGroup.color }),
                        }
                      : {
                          borderRight: '1px solid #eaeaea',
                          marginRight: '50px',
                        },
                    { color: 'white' },
                  ]}>
                  {featuresEnabled.titleOfCategory &&
                    selectedGroup &&
                    isMobileCourseCatalog && (
                      <div
                        css={css`
                          margin: 0;
                          margin-top: 1em;
                          padding-left: 20px;
                          margin-bottom: 1.2em;
                        `}>
                        <CourseGroupHeader theme={theme}>
                          {selectedGroup.title}
                        </CourseGroupHeader>
                      </div>
                    )}
                  <CompetenceGroupSidebar
                    showSearchbar={showSearchbar}
                    showCourseTypeFilter={showCourseTypeFilter}
                    isMobile={isMobileCourseCatalog}
                    theme={this.getThemeForGroupBaseColor(
                      colorConfigForGroup.color
                    )}
                    mode={groupsMode}
                  />
                </div>
              )}
              <div className="course-catalog__courses-wrapper">
                {catalogView.tab === 'competences' &&
                  !isMobileCourseCatalog && <CoursesMenu isMobile={false} />}
                {!isMobileCourseCatalog && selectedGroup && (
                  <div
                    css={css`
                      margin-top: 34px;
                      margin-bottom: 0.1em;
                      margin-left: 0.5em;
                    `}>
                    {(selectedSubGroup && (
                      <>
                        <CourseGroupHeader
                          theme={theme}
                          breadcrumb
                          onClick={() =>
                            coursesFiltersSetSubcompetencegroup({
                              subcompetencegroupId: null,
                            })
                          }>
                          {selectedGroup.title}
                        </CourseGroupHeader>
                        <CourseGroupHeader theme={theme}>
                          {selectedSubGroup.title}
                        </CourseGroupHeader>
                      </>
                    )) || (
                      <CourseGroupHeader theme={theme}>
                        {selectedGroup.title}
                      </CourseGroupHeader>
                    )}
                  </div>
                )}
                {competences.data && competences.data.length === 0 && (
                  <NoCoursesAvailable>
                    <p>Fant ingen kurs i denne kategorien.</p>
                  </NoCoursesAvailable>
                )}

                {(catalogView.tab === 'competences' && (
                  <>
                    <div
                      className={`course-catalog__courses ${
                        extraLargeTilesForGrouops.includes(
                          selectedCompetencegroupId
                        )
                          ? 'extra_large_tiles'
                          : ''
                      } ${
                        hasAnySidebarElementsToDisplay
                          ? 'courses_with_sidebar'
                          : ''
                      }`}>
                      {(competences.isFetching &&
                        Array(5)
                          .fill()
                          .map((_, i) => (
                            <div
                              key={i}
                              className="course-catalog__course-card-loader-wrapper"
                              css={css`
                                display: flex;
                                position: relative;
                                top: -0.5em;
                              `}>
                              <CourseCardLoader />
                            </div>
                          ))) ||
                        this.handleSorting(competences.data || [])
                          .slice(0, competences.isExpanded ? 100 : 9)
                          .map((course) => (
                            <div
                              className="course-catalog__course-card-wrapper"
                              key={course.id}>
                              <CourseCard
                                {...this.getCourseCardProps(course)}
                              />
                            </div>
                          ))}
                      {(competences.data.length > 9 &&
                        !competences.isExpanded && (
                          <div className="info__chip">
                            <div className="grid-x">
                              <div className="cell small-12">
                                <button
                                  type="button"
                                  className={classnames('chip', 'show-more', {
                                    'chip--active': isExpanded,
                                  })}
                                  onClick={this.handleShowMore}>
                                  ...
                                </button>
                              </div>
                            </div>
                          </div>
                        )) ||
                        (!competences.isFetching &&
                          !competences.data.length &&
                          searchTerm && (
                            <div className="course-catalog__no-results">
                              Søket{' '}
                              <span className="course-catalog__no-results__term">
                                {searchTerm}
                              </span>{' '}
                              ga ingen treff
                            </div>
                          ))}
                    </div>
                  </>
                )) ||
                  (catalogView.tab === 'calendar' && (
                    <div className="course-catalog__courseevents-wrapper">
                      {(courseEvents.isFetching && (
                        <div className="course-catalog__course-calendar-card-wrapper">
                          <h2 className="course-catalog__calendar-title-month">
                            &nbsp;
                          </h2>
                          <CourseCalendarCardLoading />
                          <CourseCalendarCardLoading />
                          <CourseCalendarCardLoading />
                        </div>
                      )) ||
                        this.renderCalendar(courseEvents.data || [], events)}
                    </div>
                  ))}
              </div>
            </div>
          </CourseCatalogContentWrapper>
        )}
      </div>
    );
  }
}

CourseCatalogContainer.propTypes = {
  competences: fetchedDataWrapperShape(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.bool,
    ])
  ).isRequired,
  featuredCompetences: fetchedDataWrapperShape(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.bool,
    ])
  ).isRequired,
  competencegroups: fetchedDataWrapperShape(
    PropTypes.arrayOf(PropTypes.shape({}))
  ).isRequired,
  courseEvents: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  coursesFiltersSetSubcompetencegroup: PropTypes.func.isRequired,
  coursesExpand: PropTypes.func.isRequired,
  sorting: PropTypes.string.isRequired,
  catalogView: PropTypes.shape({}).isRequired,
  events: PropTypes.shape({}).isRequired,
  coursesInitializeMyCoursesView: PropTypes.func.isRequired,
  coursesGetFeaturedCompetences: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedCompetencegroupId: PropTypes.number,
  selectedSubcompetencegroupId: PropTypes.object,
  searchTerm: PropTypes.string.isRequired,
  configObject: PropTypes.shape({}).isRequired,
  coursesSetCompetencegroup: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  featuresEnabled: courseCatalogFeaturesShape,
  coursesStartCourse: PropTypes.func.isRequired,
  isMobileCourseCatalog: PropTypes.bool.isRequired,
  displayMapInsteadOfCourseCatalog: PropTypes.bool.isRequired,
  passedCompetences: PropTypes.shape({}).isRequired,
  configForCurrMapAndLang: PropTypes.shape({}).isRequired,
};

CourseCatalogContainer.defaultProps = {
  selectedCompetencegroupId: null,
  selectedSubcompetencegroupId: null,
  featuresEnabled: {},
};

const mapStateToProps = (state) => ({
  sorting: getCoursesSorting(state),
  catalogView: getSelectedCatalogView(state),
  competences: getCompetences(state),
  competencegroups: getCompetencegroups(state),
  courseEvents: getCourseEvents(state),
  isMobile: getIsMobile(state),
  events: getEvents(state),
  selectedCompetencegroupId: getSelectedCompetencegroupId(state),
  selectedSubcompetencegroupId: getSelectedSubcompetencegroupId(state),
  searchTerm: getCompetencesSearchTerm(state),
  configObject: getConfigObject(state),
  displayMapInsteadOfCourseCatalog: getShouldShowMapInsteadOfCourseCatalog(
    state
  ),
  isMobileCourseCatalog: getIsMobileCourseCatalog(state),
  featuredCompetences: getFeeaturedCompetences(state),
  featuresEnabled: getCourseCatalogFeatures(state),
  passedCompetences: getPassed(state),
  configForCurrMapAndLang: getPropertiesForCurrLangAndTrack(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...coursesActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCatalogContainer);
