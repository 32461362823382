import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { RoleShape } from '@types/role';

import FormSelect from '@components/form-select';
import FormInput from '@components/form-input';
import { getOrganisationId } from '@selectors/profile.selectors';

import './person-onthejob-form.scss';
import axios from 'axios';
import moment from 'moment';
import FormDatePicker from '@components/form-datepicker';
import { backendUrl } from '@config';

const validateDate = dateStr => {
  if (!dateStr) {
    return undefined;
  }

  try {
    if (moment(dateStr, 'DD.MM.YYYY', true).isValid()) {
      return undefined;
    }
  } catch {
    //
  }

  return 'Ugyldig dato';
};

const validateRequiredDate = dateStr => {
  if (!dateStr) {
    return 'Dato ikke valgt';
  }

  return undefined;
};

const maskDate = value => value && moment(value, 'DD.MM.YYYY').toDate();

class PersonOnTheJobForm extends Component {
  state = {
    verifications: {
      isFetching: true,
      data: null,
    },
  };

  componentDidMount() {
    const { personId, orgId, onGotData } = this.props;

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/persons/verify/${personId}/${orgId}?format=json`,
        params: {
          role_meta_types: 'position,role',
        },
        withCredentials: true,
      })
      .then(({ data }) => {
        this.setState({
          verifications: {
            isFetching: false,
            data,
          },
        });

        if (onGotData !== null) {
          onGotData();
        }
      });
  }

  handleRolesOnChange = ({ values }) => {
    const { change } = this.props;
    change('roles', values);
  };

  render() {
    const { handleSubmit, onCancel } = this.props;
    const {
      verifications: { data, isFetching: isRolesFetching },
    } = this.state;

    const dataVerifications = data
      ? data.map(title => ({
          title: title[1],
          id: title[0],
        }))
      : [];

    return (
      <div className="grid-x">
        {!isRolesFetching && (
          <form onSubmit={handleSubmit} className="person-onthejob-form">
            <div className="grid-x">
              <div className="small-12 medium-12 cell">
                <Field
                  component={FormSelect}
                  defaultValue=""
                  options={dataVerifications}
                  className="employee-add__input"
                  name="verifycourse_ids"
                  label="Kompetanse"
                  type="select"
                />
              </div>
              <div className="small-12 medium-6 cell">
                <Field
                  component={FormDatePicker}
                  className="employee-add__input"
                  name="verifieddate"
                  label="Fullført/registrert *"
                  type="text"
                  format={maskDate}
                  validate={[validateDate, validateRequiredDate]}
                />
              </div>
              <div className="small-12 medium-6 cell">
                <Field
                  component={FormDatePicker}
                  className="employee-add__input"
                  name="valid_until"
                  label="Gyldig til"
                  type="text"
                  format={maskDate}
                  validate={validateDate}
                />
              </div>
              <div className="small-12 medium-12 cell">
                <Field
                  component={FormInput}
                  className="employee-add__input"
                  name="comments"
                  label="Kommentar"
                  type="text"
                />
              </div>
            </div>
            <div className="person-onthejob-form__submit-button-wrapper">
              <button
                type="button"
                onClick={onCancel}
                className="btn btn--flat edit-pass__button">
                AVBRYT
              </button>
              <button className="btn" type="submit">
                Lagre
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

PersonOnTheJobForm.propTypes = {
  personId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  verifications: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGotData: PropTypes.func,
  change: PropTypes.func.isRequired,
};

PersonOnTheJobForm.defaultProps = {
  onGotData: null,
};

const mapStateToProps = state => ({
  orgId: getOrganisationId(state),
});

export default connect(mapStateToProps)(
  reduxForm({ form: 'onthejob' })(PersonOnTheJobForm)
);
