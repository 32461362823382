import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './competence.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProfile } from '@selectors/profile.selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import {
  fetchCompetencesChildren,
  profileToggleCompetence,
} from '@actions/profile.actions';
import CompetencesList from '@components/competences/competence-list';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DEFAULT_BG from '../../../assets/images/default_customer.jpg';

const createSubtitleContent = content => ({
  __html: content,
});

class CompetenceEquivalents extends Component {
  state = {
    isExpanded: false,
  };

  constructor(props) {
    super(props);
    this.handleChildrenUpdate = this.handleChildrenUpdate.bind(this);
  }

  handleChildrenUpdate(values) {
    const { course, root } = this.props;
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
    if (!isExpanded && course.children.length === 0) {
      const { fetchCompetencesChildren } = this.props;
      fetchCompetencesChildren({
        courseId: values,
        root,
      });
    }
  }

  removePersonalCompetence(id) {
    const { removeRequirement } = this.props;
    removeRequirement(id);
  }

  render() {
    const {
      size,
      course,
      roleName,
      isFetching,
      roleId,
      removeRequirement,
      profileToggleCompetence,
    } = this.props;
    const { isExpanded } = this.state;

    return (
      <>
        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}>
              <div className="cell auto">
                <div className="small-header">
                  {course.competence_type.title}
                  {roleName && <span> ({roleName})</span>}
                </div>
                {course.competence_title}
              </div>

              <div className="cell shrink button">
                {(!isExpanded && <FontAwesomeIcon icon={faChevronDown} />) || (
                  <>
                    {(course.children &&
                      course.children.length === 0 &&
                      isFetching && <span>...</span>) || (
                      <FontAwesomeIcon icon={faChevronUp} />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="small-12 cell">
              {isExpanded && course.children && course.children.length !== 0 && (
                <div className="sub equals">
                  <h3>Sjekklistepunkter:</h3>
                  <CompetencesList courses={course.children} />
                </div>
              )}
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}>
              <div className="cell auto">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}>
                  <div className="roles-list__competence-title">
                    <div
                      className={classNames([
                        'roles-list__competence-status',
                        {
                          'roles-list__competence-status--not-started':
                            course.passed === 0,
                        },
                        {
                          'roles-list__competence-status--in-progress':
                            course.passed > 0 && course.passed < 100,
                        },
                        {
                          'roles-list__competence-status--completed':
                            course.passed === 100,
                        },
                      ])}
                    />
                    <div>
                      <div className="small-header">
                        {course.competence_type.title}
                        {roleId === -2 &&
                          course.passed !== 100 &&
                          removeRequirement && (
                            <div className="removeThis hassub">
                              <button
                                type="button"
                                onClick={() =>
                                  this.removePersonalCompetence(
                                    course.competence_id
                                  )
                                }>
                                <i className="far fa-trash-alt" />
                              </button>
                            </div>
                          )}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon="calendar-alt" />
                            &nbsp;&nbsp;{course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon="user-check" />
                            &nbsp;&nbsp;{course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>

              <div className="cell shrink button">
                {(!isExpanded && <FontAwesomeIcon icon={faChevronDown} />) || (
                  <>
                    {(course.children &&
                      course.children.length === 0 &&
                      isFetching && <span>...</span>) || (
                      <FontAwesomeIcon icon={faChevronUp} />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="small-12 cell">
              {isExpanded && course.children && course.children.length !== 0 && (
                <div className="sub equals">
                  <h3>Sjekklistepunkter:</h3>
                  <CompetencesList size={size} courses={course.children} />
                </div>
              )}
            </div>
          </div>
        )}
        {size === 'big' && (
          <>
            <div className="cell small-4 medium-5">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${(course.cover && course.cover.url) ||
                    DEFAULT_BG})`,
                }}
              />
            </div>
            <div className="content cell small-8 medium-7">
              <div className="__title">
                <h2>{course.competence_title}</h2>
              </div>
              {course.competence_type.competence_type && (
                <div>{course.competence_type.title}</div>
              )}
              <div
                className="desc"
                dangerouslySetInnerHTML={createSubtitleContent(
                  course.short_description
                )}
              />
              {course.files && !!course.files.length && (
                <div className="course-preview__files">
                  {course.files.map(
                    ({ title, url }) =>
                      title !== 'cover' && (
                        <>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn course-preview__file-button">
                            <i className="fas fa-file-alt" /> {title}
                          </a>
                        </>
                      )
                  )}
                </div>
              )}

              <div
                className="button"
                role="button"
                tabIndex={0}
                onKeyPress={() =>
                  this.handleChildrenUpdate(course.competence_id)
                }
                onClick={() => this.handleChildrenUpdate(course.competence_id)}>
                {(!isExpanded && (
                  <>
                    <br />
                    Vis alle <FontAwesomeIcon icon={faChevronDown} />
                  </>
                )) ||
                  (course.children && (
                    <>
                      {(course.children.length === 0 && isFetching && (
                        <span>...</span>
                      )) || (
                        <>
                          <br />
                          Skjul <FontAwesomeIcon icon={faChevronUp} />
                        </>
                      )}
                    </>
                  ))}
              </div>
              <div className="small-12 cell">
                {isExpanded && course.children && course.children.length !== 0 && (
                  <div className="sub equals">
                    <h3>Sjekklistepunkter:</h3>
                    <CompetencesList
                      courses={course.children}
                      size="children"
                      onToggleCompetence={profileToggleCompetence}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

CompetenceEquivalents.defaultProps = {
  roleName: '',
  size: 'normal',
  root: 'std',
  roleId: -2,
  removeRequirement: undefined,
};

CompetenceEquivalents.propTypes = {
  course: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  size: PropTypes.string,
  root: PropTypes.string,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
  fetchCompetencesChildren: PropTypes.func.isRequired,
  profileToggleCompetence: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompetencesChildren,
      profileToggleCompetence,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompetenceEquivalents)
);
