import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

import { PersonShape } from '@types/person';

import Progress from '@components/progress/progress';

import PersonAvatar from '@components/person-avatar/person-avatar';

import { getIsMobile } from '@selectors/global.selectors';

import '@routes/employees/styles/emplyee-card.scss';
import { getMainOrganisations } from '../../../store/selectors/profile.selectors';

class EmployeeCard extends Component {
  state = {
    open: false,
  };

  handleAvatarClick = () => {
    const { employee, onAvatarClick } = this.props;
    onAvatarClick(employee);
  };

  handleEditClick = () => {
    const { employee, onEditClick } = this.props;
    onEditClick(employee);
  };

  handleMessageClick = () => {
    const { employee, onMessageClick } = this.props;
    onMessageClick(employee);
  };

  render() {
    const {
      onProfileClick,
      employee: { fullname, positions, mobile, phone, email, competencelevel },
      employee,
      isExpanded,
      mainOrganisation,
      page,
      taskList,
    } = this.props;
    const { isMobile } = this.props;

    const { open } = this.state;

    const cardProps =
      (!isExpanded && {
        role: 'button',
        onClick: this.handleAvatarClick,
        onKeyPress: this.handleAvatarClick,
        tabIndex: 0,
      }) ||
      {};

    const TaskList = taskList;

    if (isExpanded && isMobile) {
      return (
        <div className="employee-card employee-card--mobile" {...cardProps}>
          <div className="employee-card__top-section">
            <div className="employee-card__avatar-wrapper">
              <PersonAvatar
                person={employee}
                notificationCount={employee.pending_checklists}
                className="employee-card__avatar"
              />
            </div>
            <div className="employee-card__name-wrapper">
              <div className="employee-card__name">{fullname}</div>
              <div className="employee-card__progress-wrapper">
                <Progress progress={competencelevel} fullWidth barOnly />
              </div>
            </div>
          </div>
          <div className="employee-card__actions">
            {['message', 'edit'].indexOf(page) !== -1 && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={onProfileClick}>
                <i className="fas fa-user" />
              </button>
            )}
            {page !== 'message' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleMessageClick}>
                <i className="fas fa-envelope" />
              </button>
            )}
            {page !== 'edit' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleEditClick}>
                <i className="fas fa-pencil-alt" />
              </button>
            )}
            {taskList && (
              <>
                <button
                  className={classNames('btn btn--icon', {
                    'btn--expanded': open,
                  })}
                  type="button"
                  onClick={() => this.setState({ open: !open })}
                  onKeyPress={() => this.setState({ open: !open })}
                  onBlur={() => this.setState({ open: false })}>
                  <i className="fas fa-ellipsis-h" />
                </button>
                {open && (
                  <>
                    <div className="btn__dropdown">
                      <TaskList
                        onClick={() => this.setState({ open: false })}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames([
          'employee-card',
          {
            'employee-card--expanded': isExpanded,
            'employee-card--clickable': !isExpanded,
          },
        ])}
        {...cardProps}>
        <div
          className={classNames([
            'employee-card__top-section',
            { 'employee-card__top-section--vertical': isExpanded },
          ])}>
          <div className="employee-card__avatar-wrapper">
            <PersonAvatar
              person={employee}
              notificationCount={employee.pending_checklists}
              className={classNames([
                'employee-card__avatar',
                { 'employee-card__avatar--centered': isExpanded },
              ])}
            />
          </div>
          <div
            className={classNames([
              'employee-card__name-wrapper',
              { 'employee-card__name-wrapper--centered': isExpanded },
            ])}>
            <div className="employee-card__name">{fullname}</div>
            {positions && positions.length !== 0 && (
              <div className="employee-card__position">
                {positions.filter(p => p.organisation_id === mainOrganisation.id).length === 1 && (
                  <div>{positions.filter(p => p.organisation_id === mainOrganisation.id)[0].title}</div>
                )
                }
              </div>
            )}

            {email && (
              <div className="employee-card__email">
                {isExpanded ? <a href={`mailto:${email}`}>{email}</a> : email}
              </div>
            )}
            {phone && (
              <div className="employee-card__phone">
                {isExpanded ? <a href={`tel:${phone}`}>{phone}</a> : phone}
              </div>
            )}
            {mobile && (
              <div className="employee-card__phone">
                {isExpanded ? <a href={`tel:${mobile}`}>{mobile}</a> : mobile}
              </div>
            )}
          </div>
        </div>
        {isExpanded && (
          <>
            <div className="employee-card__progress-wrapper">
              <Progress progress={competencelevel} fullWidth />
            </div>
            <div className="employee-card__actions">
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleMessageClick}>
                <i className="fas fa-envelope" />
              </button>
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleEditClick}>
                <i className="fas fa-pencil-alt" />
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

EmployeeCard.propTypes = {
  employee: PersonShape().isRequired,
  onProfileClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onMessageClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  taskList: PropTypes.node,
  page: PropTypes.string,
};

EmployeeCard.defaultProps = {
  isExpanded: false,
  onProfileClick: () => {},
  onAvatarClick: () => {},
  onEditClick: () => {},
  onMessageClick: () => {},
  taskList: null,
  page: '',
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  mainOrganisation: getMainOrganisations(state),
});

export default connect(mapStateToProps)(EmployeeCard);

export const EmployeeCardLoading = ({ isExpanded, isCentered, isMobile }) =>
  isExpanded ? (
    (isMobile && (
      <div className="employee-card employee-card__loader--mobile">
        <div className="employee-card__top-section">
          <ContentLoader
            title="Laster inn..."
            height={52}
            width={409}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="26" cy="26" r="25" />
            <rect x="70" y="10" rx="4" ry="4" width="120" height="20" />
            <rect x="10" y="85" rx="3" ry="3" width="70" height="5" />
          </ContentLoader>
        </div>
        <div className="employee-card__actions">
          <ContentLoader
            title="Laster inn..."
            height={40}
            width={150}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="30" cy="20" r="20" />
            <circle cx="80" cy="20" r="20" />
            <circle cx="130" cy="20" r="20" />
          </ContentLoader>
        </div>
      </div>
    )) || (
      <div
        className={classNames([
          'employee-card__loader--expanded',
          { 'employee-card__loader--centered': isCentered },
        ])}>
        <ContentLoader
          title="Laster inn..."
          height={122}
          width={90}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="0" y="70" rx="4" ry="4" width="90" height="5" />
          <rect x="10" y="85" rx="3" ry="3" width="70" height="5" />
          <circle cx="45" cy="30" r="23" />
        </ContentLoader>
      </div>
    )
  ) : (
    <div
      className={classNames([
        'employee-card__loader',
        { 'employee-card__loader--centered': isCentered },
      ])}>
      <ContentLoader
        title="Laster inn..."
        height={40}
        width={180}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="58" y="8" rx="4" ry="4" width="117" height="6" />
        <rect x="58" y="24" rx="3" ry="3" width="85" height="6" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    </div>
  );

EmployeeCardLoading.propTypes = {
  isCentered: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isMobile: PropTypes.bool,
};

EmployeeCardLoading.defaultProps = {
  isCentered: false,
  isExpanded: false,
  isMobile: false,
};
