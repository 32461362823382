import './course-card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import AspectRatio from '@components/common/aspect-ratio';
import { css, jsx } from '@emotion/core';
import CourseDuration from '@components/course/course-duration';
import { highlightOnKeyboardFocusMixin } from '@styles/mixins';
import { backendUrl } from '@config';

import DEFAULT_BG from '../../../../../../assets/images/default_customer.jpg';
import { TrainertipsCard } from '../trainertips-card/trainertips-card';
import CourseCardV2 from '../course-card-v2/course-card-v2';
import { wrapperStyle as cardV2WrapperStyle } from '../course-card-v2/styles';

const createSubtitleContent = (content) => ({
  __html: content,
});

export const CourseCardLoader = ({
  customCssImagePart,
  customCssTextPart,
  customCssWrapper,
  customCssImagePartContent,
  ascpectRatioImagePart,
}) => (
  <div
    className="course-card course-card-loading"
    css={css`
      ${customCssWrapper}
    `}>
    <div
      className="course-card__cover"
      css={css`
        ${customCssImagePart}
      `}>
      <AspectRatio
        ratio={ascpectRatioImagePart}
        style={customCssImagePartContent}>
        <div
          className="course-card__cover--image"
          style={{
            backgroundColor: '#f3f3f3',
          }}
        />
      </AspectRatio>
    </div>
    <div
      className="course-card__content"
      css={css`
        ${customCssTextPart}
      `}>
      <ContentLoader
        height="150"
        width="250"
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="4" y="20" rx="4" ry="4" width="225" height="25" />
        <rect x="4" y="65" rx="4" ry="4" width="180" height="18" />
      </ContentLoader>
    </div>
  </div>
);

CourseCardLoader.propTypes = {
  customCssImagePart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  customCssWrapper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  customCssTextPart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  ascpectRatioImagePart: PropTypes.number,
  customCssImagePartContent: PropTypes.shape({}),
};

CourseCardLoader.defaultProps = {
  customCssTextPart: '',
  customCssImagePart: '',
  customCssWrapper: '',
  ascpectRatioImagePart: 1.7,
  customCssImagePartContent: {},
};

const AutoStartCourseWrapper = ({ children, ...props }) => (
  <div
    role="button"
    tabIndex="0"
    onKeyDown={(evt) => {
      if (evt.key === 'Enter' && props.onClick) {
        props.onClick();
      }
    }}
    css={css`
      &:hover {
        cursor: pointer;
      }
      ${highlightOnKeyboardFocusMixin};
    `}
    {...props}>
    {children}
  </div>
);

const CourseCard = ({
  href,
  shouldAutostartOnClick,
  onAutostartCourse,
  mainText,
  secondaryText,
  image,
  metaInformation,
  showActionButton,
  id,
  competence_type_id,
}) => {
  const WrapperComponent = shouldAutostartOnClick
    ? AutoStartCourseWrapper
    : Link;

  return (
    <>
      <WrapperComponent
        href="/"
        tabIndex="0"
        role="button"
        className="course-card"
        css={[cardV2WrapperStyle, highlightOnKeyboardFocusMixin]}
        to={href}
        onClick={() => {
          if (shouldAutostartOnClick) {
            onAutostartCourse(id, competence_type_id);
          }
        }}>
        <CourseCardV2
          mainText={mainText}
          secondaryText={secondaryText}
          image={image}
          metaInformation={metaInformation}
          showActionButton={showActionButton}
        />
      </WrapperComponent>
    </>
  );
};

CourseCard.propTypes = {
  cover: PropTypes.shape({
    url: PropTypes.string,
  }),
};

CourseCard.defaultProps = {
  cover: null,
};

export default CourseCard;
