const prefix = 'courses';

export const COURSES_SET_SORTING = `${prefix} set sorting`;
export const COURSES_SET_EXPAND = `${prefix}/set/expand`;
export const coursesExpand = () => ({
  type: COURSES_SET_EXPAND,
});

export const coursesSetSorting = (payload) => ({
  type: COURSES_SET_SORTING,
  payload,
});

export const COURSES_SET_SEARCHTERM = `${prefix} set search term`;

export const coursesSetSearchTerm = (payload) => ({
  type: COURSES_SET_SEARCHTERM,
  payload,
});

export const COURSES_INITIALIZE_MY_COURSES_VIEW = `${prefix} initialize my courses view`;
export const COURSES_INITIALIZE_MY_COURSES_VIEW_REQUEST = `${prefix} initialize my courses view request`;
export const COURSES_INITIALIZE_MY_COURSES_VIEW_SUCCESS = `${prefix} initialize my courses view success`;
export const COURSES_INITIALIZE_MY_COURSES_VIEW_FAILURE = `${prefix} initialize my courses view failure`;

export const coursesInitializeMyCoursesView = (payload) => ({
  type: COURSES_INITIALIZE_MY_COURSES_VIEW,
  payload,
});

export const coursesInitializeMyCoursesViewRequest = () => ({
  type: COURSES_INITIALIZE_MY_COURSES_VIEW_REQUEST,
});

export const coursesInitializeMyCoursesViewSuccess = () => ({
  type: COURSES_INITIALIZE_MY_COURSES_VIEW_SUCCESS,
});

export const coursesInitializeMyCoursesViewFailure = () => ({
  type: COURSES_INITIALIZE_MY_COURSES_VIEW_FAILURE,
});

export const COURSES_GET_COMPETENCES = `${prefix} get competences`;
export const COURSES_GET_COMPETENCES_REQUEST = `${prefix} get competences request`;
export const COURSES_GET_COMPETENCES_SUCCESS = `${prefix} get competences success`;
export const COURSES_GET_COMPETENCES_FAILURE = `${prefix} get competences failure`;

export const coursesGetCompetences = (payload) => ({
  type: COURSES_GET_COMPETENCES,
  payload,
});

export const coursesGetCompetencesRequest = () => ({
  type: COURSES_GET_COMPETENCES_REQUEST,
});

export const coursesGetCompetencesSuccess = (payload) => ({
  type: COURSES_GET_COMPETENCES_SUCCESS,
  payload,
});

export const coursesGetCompetencesFailure = (payload) => ({
  type: COURSES_GET_COMPETENCES_FAILURE,
  payload,
});

export const COURSES_GET_FEATURED_COMPETENCES = `${prefix} get featured competences`;
export const COURSES_GET_FEATURED_COMPETENCES_REQUEST = `${prefix} get featured competences request`;
export const COURSES_GET_FEATURED_COMPETENCES_SUCCESS = `${prefix} get featured competences success`;
export const COURSES_GET_FEATURED_COMPETENCES_FAILURE = `${prefix} get featured competences failure`;

export const coursesGetFeaturedCompetences = (payload) => ({
  type: COURSES_GET_FEATURED_COMPETENCES,
  payload,
});

export const coursesGetFeaturedCompetencesRequest = () => ({
  type: COURSES_GET_FEATURED_COMPETENCES_REQUEST,
});

export const coursesGetFeaturedCompetencesSuccess = (payload) => ({
  type: COURSES_GET_FEATURED_COMPETENCES_SUCCESS,
  payload,
});

export const coursesGetFeaturedCompetencesFailure = (payload) => ({
  type: COURSES_GET_FEATURED_COMPETENCES_FAILURE,
  payload,
});

export const COURSES_GET_COURSEEVENTS = `${prefix} get courseevents`;
export const COURSES_GET_COURSEEVENTS_REQUEST = `${prefix} get courseevents request`;
export const COURSES_GET_COURSEEVENTS_SUCCESS = `${prefix} get courseevents success`;
export const COURSES_GET_COURSEEVENTS_FAILURE = `${prefix} get courseevents failure`;

export const coursesGetCourseEvents = () => ({
  type: COURSES_GET_COURSEEVENTS,
});

export const coursesGetCourseEventsRequest = () => ({
  type: COURSES_GET_COURSEEVENTS_REQUEST,
});

export const coursesGetCourseEventsSuccess = (payload) => ({
  type: COURSES_GET_COURSEEVENTS_SUCCESS,
  payload,
});

export const coursesGetCourseEventsFailure = (payload) => ({
  type: COURSES_GET_COURSEEVENTS_FAILURE,
  payload,
});

export const COURSES_BEGIN_SIGNATURE = `${prefix} begin signature`;

export const coursesBeginSignature = (payload) => ({
  type: COURSES_BEGIN_SIGNATURE,
  payload,
});

export const COURSES_GET_COMPETENCEGROUPS = `${prefix} get competencegroups`;
export const COURSES_GET_COMPETENCEGROUPS_REQUEST = `${prefix} get competencegroups request`;
export const COURSES_GET_COMPETENCEGROUPS_SUCCESS = `${prefix} get competencegroups success`;
export const COURSES_GET_COMPETENCEGROUPS_FAILURE = `${prefix} get competencegroups failure`;

export const coursesGetCompetencegroups = (payload) => ({
  type: COURSES_GET_COMPETENCEGROUPS,
  payload,
});

export const coursesGetCompetencegroupsRequest = () => ({
  type: COURSES_GET_COMPETENCEGROUPS_REQUEST,
});

export const coursesGetCompetencegroupsSuccess = (payload) => ({
  type: COURSES_GET_COMPETENCEGROUPS_SUCCESS,
  payload,
});

export const coursesGetCompetencegroupsFailure = (payload) => ({
  type: COURSES_GET_COMPETENCEGROUPS_FAILURE,
  payload,
});

export const COURSES_FILTERS_SET_FILTERS = `${prefix} filters set filters`;
export const COURSES_SET_ACTIVE_COURSE = `${prefix} filters set active course`;
export const COURSES_FILTERS_SET_COMPETENCEGROUP = `${prefix} filters set competencegroup`;
export const COURSES_FILTERS_SET_SUBCOMPETENCEGROUP = `${prefix} filters toggle subcompetencegroup`;
export const COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP = `${prefix} filters toggle subsubcompetencegroup`;

export const coursesSetFilters = (payload) => ({
  type: COURSES_FILTERS_SET_FILTERS,
  payload,
});

export const coursesSetActiveCourse = (payload) => ({
  type: COURSES_SET_ACTIVE_COURSE,
  payload,
});

export const coursesSetCompetencegroup = (payload) => ({
  type: COURSES_FILTERS_SET_COMPETENCEGROUP,
  payload,
});

export const coursesFiltersSetSubcompetencegroup = (payload) => ({
  type: COURSES_FILTERS_SET_SUBCOMPETENCEGROUP,
  payload,
});

export const coursesFiltersSetSubSubcompetencegroup = (payload) => ({
  type: COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP,
  payload,
});

export const COURSES_GET_COMPETENCETYPES = `${prefix} get competencetypes`;
export const COURSES_GET_COMPETENCETYPES_REQUEST = `${prefix} get competencetypes request`;
export const COURSES_GET_COMPETENCETYPES_SUCCESS = `${prefix} get competencetypes success`;
export const COURSES_GET_COMPETENCETYPES_FAILURE = `${prefix} get competencetypes failure`;

export const coursesGetCompetencetypes = () => ({
  type: COURSES_GET_COMPETENCETYPES,
});

export const coursesGetCompetencetypesRequest = () => ({
  type: COURSES_GET_COMPETENCETYPES_REQUEST,
});

export const coursesGetCompetencetypesSuccess = (payload) => ({
  type: COURSES_GET_COMPETENCETYPES_SUCCESS,
  payload,
});

export const coursesGetCompetencetypesFailure = (payload) => ({
  type: COURSES_GET_COMPETENCETYPES_FAILURE,
  payload,
});

export const COURSES_FILTERS_TOGGLE_COMPETENCETYPE = `${prefix} filters toggle competencetype`;

export const coursesFiltersToggleCompetencetype = (payload) => ({
  type: COURSES_FILTERS_TOGGLE_COMPETENCETYPE,
  payload,
});

export const COURSES_GET_COMPETENCE_DETAILS = `${prefix} get competence details`;
export const COURSES_GET_COMPETENCE_DETAILS_REQUEST = `${prefix} get competence details request`;
export const COURSES_GET_COMPETENCE_DETAILS_SUCCESS = `${prefix} get competence details success`;
export const COURSES_GET_COMPETENCE_DETAILS_FAILURE = `${prefix} get competence details failure`;

export const coursesFetchCompetenceDetails = (payload) => ({
  type: COURSES_GET_COMPETENCE_DETAILS,
  payload,
});

export const coursesFetchCompetenceDetailsGetRequest = () => ({
  type: COURSES_GET_COMPETENCE_DETAILS_REQUEST,
});

export const coursesFetchCompetenceDetailsGetSuccess = (payload) => ({
  type: COURSES_GET_COMPETENCE_DETAILS_SUCCESS,
  payload,
});

export const coursesFetchCompetenceDetailsGetFailure = (payload) => ({
  type: COURSES_GET_COMPETENCE_DETAILS_FAILURE,
  payload,
});

export const COURSES_SIGN_COURSE = `${prefix} SIGN COURSE`;
export const COURSES_SIGN_COURSE_SUCCESS = `${prefix} SIGN COURSE SUCCESS`;
export const COURSES_SIGN_COURSE_ERROR = `${prefix} SIGN COURSE ERROR`;

export const coursesSignCourse = (payload) => ({
  type: COURSES_SIGN_COURSE,
  payload,
});

export const coursesSignCourseSuccess = (payload) => ({
  type: COURSES_SIGN_COURSE_SUCCESS,
  payload,
});

export const coursesSignCourseError = (payload) => ({
  type: COURSES_SIGN_COURSE_ERROR,
  payload,
});

export const COURSES_START_COURSE = `${prefix} START COURSE`;
export const COURSES_RUN_COURSE = `${prefix} RUN COURSE`;
export const COURSES_RUN_NANO_COURSE = `${prefix} RUN NANO COURSE`;
export const COURSES_CLOSE_COURSE = `${prefix} CLOSE COURSE`;
export const COURSES_ERROR_COURSE = `${prefix} ERROR COURSE`;
export const COURSES_COURSE_FINISHED = `${prefix} COURSE FINISHED`;
export const COURSES_COURSE_FAILURE = `${prefix} COURSE FAILURE`;

export const coursesStartCourse = (payload) => ({
  type: COURSES_START_COURSE,
  payload,
});

export const coursesEndCourse = () => ({
  type: COURSES_CLOSE_COURSE,
});

export const coursesLmsError = () => ({
  type: COURSES_ERROR_COURSE,
});

export const coursesLmsRunning = (payload) => ({
  type: COURSES_RUN_COURSE,
  payload,
});

export const coursesRunNanoCourse = (payload) => ({
  type: COURSES_RUN_NANO_COURSE,
  payload,
});

export const coursesCourseFinished = (payload) => ({
  type: COURSES_COURSE_FINISHED,
  payload,
});

export const coursesCourseFailure = (payload) => ({
  type: COURSES_COURSE_FAILURE,
  payload,
});

export const COURSE_SIGNON = `${prefix} course sign on`;
export const COURSE_SIGNON_REQUEST = `${prefix} course sign on request`;
export const COURSE_SIGNON_SUCCESS = `${prefix} course sign on success`;
export const COURSE_SIGNON_FAILURE = `${prefix} course sign on failure`;
export const COURSE_SIGNON_RESULTS = `${prefix} course sign on results`;

export const courseSignOn = (payload) => ({
  type: COURSE_SIGNON,
  payload,
});

export const courseSignOnRequest = (payload) => ({
  type: COURSE_SIGNON_REQUEST,
  payload,
});

export const courseSignOnSuccess = (payload) => ({
  type: COURSE_SIGNON_SUCCESS,
  payload,
});

export const courseSignOnFailure = (payload) => ({
  type: COURSE_SIGNON_FAILURE,
  payload,
});

export const courseSignOnResults = (payload) => ({
  type: COURSE_SIGNON_RESULTS,
  payload,
});

export const COURSE_SIGNOFF = `${prefix}/course/sign/off`;
export const COURSE_SIGNOFF_REQUEST = `${prefix}/course/sign/off/request`;
export const COURSE_SIGNOFF_SUCCESS = `${prefix}/course/sign/off/success`;
export const COURSE_SIGNOFF_FAILURE = `${prefix}/course/sign/off/failure`;
export const COURSE_SIGNOFF_RESULTS = `${prefix}/course/sign/off/results`;

export const courseSignOff = (payload) => ({
  type: COURSE_SIGNOFF,
  payload,
});

export const courseSignOffRequest = (payload) => ({
  type: COURSE_SIGNOFF_REQUEST,
  payload,
});

export const courseSignOffSuccess = (payload) => ({
  type: COURSE_SIGNOFF_SUCCESS,
  payload,
});

export const courseSignOffFailure = (payload) => ({
  type: COURSE_SIGNOFF_FAILURE,
  payload,
});

export const courseSignOffResults = (payload) => ({
  type: COURSE_SIGNON_RESULTS,
  payload,
});

export const SET_MAP_COURSES_DATA = `${prefix} SET MAP COURSES DATA`;

export const setMapCoursesData = (payload) => ({
  type: SET_MAP_COURSES_DATA,
  payload,
});

export const FETCH_MAP_COURSES = `${prefix} FETCH MAP COURSES`;
export const FETCH_MAP_COURSES_REQUEST = `${prefix} FETCH MAP COURSES REQUEST`;
export const FETCH_MAP_COURSES_SUCCEEDED = `${prefix} FETCH MAP COURSES REQUEST SUCCEEDED`;
export const FETCH_MAP_COURSES_FAILED = `${prefix} FETCH MAP COURSES REQUEST FAILED`;

export const fetchMapCourses = (payload) => ({
  type: FETCH_MAP_COURSES,
  payload,
});

export const fetchMapCoursesRequest = () => ({
  type: FETCH_MAP_COURSES_REQUEST,
});

export const fetchMapCoursesSucceeded = (payload) => ({
  type: FETCH_MAP_COURSES_SUCCEEDED,
  payload,
});

export const fetchMapCoursesFailed = (payload) => ({
  type: FETCH_MAP_COURSES_FAILED,
  payload,
});

export const SELECT_MAP_COURSE = `${prefix} SELECT MAP COURSE`;

export const selectMapCourse = (payload) => ({
  type: SELECT_MAP_COURSE,
  payload,
});

export const SET_MAP_IS_VERIFIED = `${prefix} SET MAP IS VERIFIED`;

export const setMapIsVerified = (payload) => ({
  type: SET_MAP_IS_VERIFIED,
  payload,
});

export const SET_OUTRO_IS_COMPLETED = `${prefix} SET OUTRO IS COMPLETED`;

export const setOutroIsCompleted = (payload) => ({
  type: SET_OUTRO_IS_COMPLETED,
  payload,
});

export const FETCH_MAP_COURSES_INFO = `${prefix} FETCH MAP COURSES INFO`;
export const FETCH_MAP_COURSES_INFO_REQUEST = `${prefix} FETCH MAP COURSES INFO REQUEST`;
export const FETCH_MAP_COURSES_INFO_SUCCEEDED = `${prefix} FETCH MAP COURSES INFO SUCCEEDED`;
export const FETCH_MAP_COURSES_INFO_FAILED = `${prefix} FETCH MAP COURSES INFO FAILED`;

export const fetchMapCoursesInfo = (payload) => ({
  type: FETCH_MAP_COURSES_INFO,
  payload,
});
export const fetchMapCoursesInfoRequest = (payload) => ({
  type: FETCH_MAP_COURSES_INFO_REQUEST,
  payload,
});
export const fetchMapCoursesInfoSucceeded = (payload) => ({
  type: FETCH_MAP_COURSES_INFO_SUCCEEDED,
  payload,
});
export const fetchMapCoursesInfoFailed = (payload) => ({
  type: FETCH_MAP_COURSES_INFO_FAILED,
  payload,
});

export const COURSES_FILTERS_SET_COMPETENCETYPES_NO_REFETCH = `${prefix} course set competencetypes filters no refetch`;

export const coursesFiltersSetCompetencetypesNoRefetch = (payload) => ({
  type: COURSES_FILTERS_SET_COMPETENCETYPES_NO_REFETCH,
  payload,
});
