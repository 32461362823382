import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './breadcrumbs.scss';

const UserBreadcrumb = ({ match }) => <span>{match.params.userName}</span>;

UserBreadcrumb.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className="breadcrumbs">
    {breadcrumbs.map((breadcrumb, index) => (
      <span key={breadcrumb.key}>
        <NavLink to={breadcrumb.props.match.url}>{breadcrumb}</NavLink>
        {index < breadcrumbs.length - 1 && <i> / </i>}
      </span>
    ))}
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Breadcrumbs;
