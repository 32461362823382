/** @jsx jsx */

import React, { useCallback, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMapCoursesInfo,
  coursesStartCourse,
  selectMapCourse,
} from '@actions/courses.actions';
import {
  getMapCourses,
  getMapCoursesInfo,
  getIsAllMapDotsCompleted,
  getIsFirstMapDotCompleted,
  getIsMapVerified,
  getSelectedMapCourse,
  getOutroIsCompleted,
} from '@selectors/courses.selectors';
import { getIsMobile } from '@selectors/global.selectors';
import { Map } from '@routes/atlas/components/Map/Map';
import HorizontalSplitPane from '@routes/atlas/layouts/HorizontalSplitPane/HorizontalSplitPane';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import { getPassed } from '@selectors/profile.selectors';
import { competencesToggle } from '@actions/competences.actions';
import { CompleteCourse } from '@routes/atlas/components/CompleteCourse';
import { CourseInformation } from '@routes/atlas/components/CourseInformation/course-information';
import ArticleLoader from '@routes/content/components/loader/loader';
import { withRouter } from 'react-router-dom';
import { getImageUrl } from '@utils/misc.utils';
import { getLoading } from '@selectors/alert.selectors';
import { MapBounding } from '../components/MapBounding/MapBounding';

/**
 * Displays the map, consisting of courses.
 *
 * Also handles playing of intro, verification and outro
 */
// eslint-disable-next-line react/prop-types
const AtlasContainer = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const courses = useSelector(getMapCourses);

  const trackIndex = 0;
  const track = courses.data || null;

  const coursesInfo = useSelector(getMapCoursesInfo);

  const isAllMapDotsCompleted = useSelector(getIsAllMapDotsCompleted);
  const isFirstMapDotCompleted = useSelector(getIsFirstMapDotCompleted);
  const configObject = useSelector(getConfigObject);
  const configForCurrMap = useSelector(getPropertiesForCurrLangAndTrack);

  const isMobile = useSelector(getIsMobile);
  const passed = useSelector(getPassed);
  const selectedCourse = useSelector(getSelectedMapCourse);
  const isMapVerified = useSelector(getIsMapVerified);
  const isOutroDone = useSelector(getOutroIsCompleted);

  const shouldShowOutro = isMapVerified && isOutroDone === false;

  const startSelectedCourse = useCallback(() => {
    if (selectedCourse.id) {
      dispatch(
        coursesStartCourse({
          cid: selectedCourse.id,
          type: selectedCourse.type,
        })
      );
    }
  }, [selectedCourse, dispatch]);

  const findInitialActiveCourse = useCallback(() => {
    if (track) {
      const result = track.tracks[trackIndex].dotts.find(
        ({ status }) => status === 'OPEN'
      );
      return result;
    }
    return null;
  }, [track]);

  const findLastCourse = useCallback(() => {
    const numDotsInFristTrack = track.tracks[trackIndex].dotts.length;
    if (track) {
      const result = track.tracks[trackIndex].dotts[numDotsInFristTrack - 1];
      return result;
    }
    return null;
  }, [track]);

  const findCourseIndex = useCallback(
    (val) => {
      if (track && typeof val === 'string') {
        const result = track.tracks[trackIndex].dotts.findIndex(
          ({ status }) => status === val
        );
        return result;
      }

      if (track && typeof val === 'number') {
        const result = track.tracks[trackIndex].dotts.findIndex(
          ({ id }) => id === val
        );
        return result + 1;
      }

      return null;
    },
    [track]
  );

  /**
   * Gets the initial active course, either the first open one,
   * or the last one if all is completed
   */
  useEffect(() => {
    if (isAllMapDotsCompleted) {
      const lastCourse = findLastCourse();
      dispatch(selectMapCourse(lastCourse));
    } else {
      const initialActiveCourse = findInitialActiveCourse();
      dispatch(selectMapCourse(initialActiveCourse));
      if (!isFirstMapDotCompleted) {
        dispatch(
          coursesStartCourse({
            cid: initialActiveCourse.id,
            type: 24,
          })
        );
      }
    }
  }, [
    dispatch,
    findInitialActiveCourse,
    findLastCourse,
    isAllMapDotsCompleted,
    isFirstMapDotCompleted,
  ]);

  /**
   * Fetches the active course info
   */

  useEffect(() => {
    if (
      selectedCourse != null &&
      isFirstMapDotCompleted &&
      !shouldShowOutro &&
      !coursesInfo
    ) {
      dispatch(fetchMapCoursesInfo({ trackId: configForCurrMap.atlas.mapId }));
    }
  }, [
    selectedCourse,
    dispatch,
    shouldShowOutro,
    isFirstMapDotCompleted,
    coursesInfo,
    configForCurrMap.atlas.mapId,
  ]);

  useEffect(() => {
    if (shouldShowOutro) {
      dispatch(
        coursesStartCourse({
          cid: configForCurrMap.atlas.outroId,
          type: 24,
        })
      );
    }
  }, [configForCurrMap.atlas.outroId, dispatch, shouldShowOutro]);

  const completedCourses = useMemo(
    () =>
      track.tracks[trackIndex].dotts.filter(({ status }) => status === 'DONE')
        .length,
    [track, trackIndex]
  );

  const mapData = track && track.tracks[trackIndex].dotts;

  useEffect(() => {
    const onKeyPress = ({ key }) => {
      if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) {
        const course = mapData[Number(key) - 1];
        if (course && course.status !== 'LOCKED') {
          dispatch(selectMapCourse(course));
        }
      }
    };

    window.addEventListener('keypress', onKeyPress, false);

    return () => {
      window.removeEventListener('keypress', onKeyPress, false);
    };
  }, [dispatch, mapData]);

  const courseIdToCourseInfoMap =
    coursesInfo &&
    Object.fromEntries(
      coursesInfo.competences[0].children.map((c) => [c.id, c])
    );

  const shouldShowCongratsAndVerifyPage = !!(
    isAllMapDotsCompleted &&
    !isMapVerified &&
    !courses.isFetching &&
    mapData
  );

  const mapProps = {
    track,
    hideDotsAndPath: !courseIdToCourseInfoMap,
    data: mapData.map((course) => ({
      ...course,
      ...(courseIdToCourseInfoMap || {})[course.id],
    })),
    activeCourseID:
      !shouldShowCongratsAndVerifyPage && selectedCourse && selectedCourse.id,
    completedCourses,
    allCompleted: isAllMapDotsCompleted,
    hide: !isFirstMapDotCompleted || passed.isFetching,
    backgroundImage:
      coursesInfo &&
      coursesInfo.competences[0].files.find((f) => f.title === 'cover').url,
    backgrondPosition:
      configObject.getProperty('params.map-img.backgroundPosition') ||
      '80% 50%',
    finishedWithFirstMapRuntrough: isOutroDone,
  };

  const courseInProgres = selectedCourse && selectedCourse.status === 1;

  const setCourseAsPassed = useCallback(() => {
    dispatch(
      competencesToggle({
        cid: configForCurrMap.atlas.verifyId,
        disableNotifications: true,
        disableStatusUpdateCheck: true,
        noRefetchCompetencesAfterPosting: true,
      })
    );
  }, [configForCurrMap.atlas.verifyId, dispatch]);

  // start loading the map image right away
  useEffect(() => {
    const { backgroundImage } = mapProps;
    if (!backgroundImage) {
      return;
    }

    const img = new Image();
    img.src = getImageUrl(backgroundImage);
  }, [mapProps, mapProps.mapImg]);

  const onVerifiedAndDone = useCallback(() => {
    if (configForCurrMap.atlas.verifyId) {
      setCourseAsPassed();
    }
  }, [configForCurrMap.atlas.verifyId, setCourseAsPassed]);

  if (!mapData || !selectedCourse || courseInProgres) {
    return null;
  }

  // map is verified, we wait for the outro to start
  if (
    loading ||
    (isMapVerified && !isOutroDone) ||
    (!courseIdToCourseInfoMap && !(isMapVerified && !isOutroDone))
  ) {
    return (
      <ArticleLoader
        wrapperstyle={css`
          height: 100vh;
          position: relative;
          top: -3em;
        `}
      />
    );
  }

  return (
    <div
      css={css(
        css`
          position: relative;
          height: 100%;
        `,
        !isMobile &&
          css`
            padding: 3em;
          `
      )}>
      {!isMobile && (
        <HorizontalSplitPane
          left={
            !!mapData && (
              <Map
                key={track.id}
                {...mapProps}
                disableInteraction={shouldShowCongratsAndVerifyPage}
              />
            )
          }
          right={
            (shouldShowCongratsAndVerifyPage && (
              <CompleteCourse onVerifiedAndDone={onVerifiedAndDone} />
            )) ||
            (coursesInfo && selectedCourse && (
              <CourseInformation
                coursesInfo={courseIdToCourseInfoMap[selectedCourse.id]}
                startSelectedCourse={startSelectedCourse}
                selectedCourse={selectedCourse}
                findCourseIndex={findCourseIndex}
              />
            )) ||
            null
          }
        />
      )}

      {!!(isMobile && !shouldShowCongratsAndVerifyPage && mapData) && (
        <Map key={track.id} {...mapProps} />
      )}
      {isMobile && shouldShowCongratsAndVerifyPage && (
        <MapBounding {...mapProps} name="map">
          <div
            css={css(
              css`
                position: relative;
                color: #fff;
                label {
                  color: white;
                }
              `
            )}>
            <CompleteCourse onVerifiedAndDone={onVerifiedAndDone} />
          </div>
        </MapBounding>
      )}
    </div>
  );
};

export default withRouter(AtlasContainer);
