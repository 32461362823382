import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './modal.scss';
import { getIsMobile } from '@selectors/global.selectors';

const Modal = connect(state => ({
  isMobile: getIsMobile(state),
}))(
  ({
    isMobile,
    children,
    onBackdropClick,
    className,
    classNameContent,
    dataLoaded,
    waitForLoadedDataWithAnimation,
  }) =>
    createPortal(
      <div
        className={classNames('modal', className, {
          'modal--mobile': isMobile,
        })}>
        {/* eslint-disable-next-line */}
        <div className="modal__backdrop" onClick={onBackdropClick} />
        {waitForLoadedDataWithAnimation && !dataLoaded && <LoadingIndicator />}
        <div
          className={classNames('modal__content', classNameContent)}
          style={
            waitForLoadedDataWithAnimation && !dataLoaded
              ? { display: 'none' }
              : {}
          }>
          {children}
        </div>
      </div>,
      document.getElementById('modal')
    )
);

const LoadingIndicator = () => {
  const maxLoadingState = 3;
  const loadingChar = '.';
  const [loadingState, setLoadingState] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState(loadingState =>
        loadingState >= maxLoadingState ? 0 : loadingState + 1
      );
    }, 500);

    return () => clearInterval(interval);
  }, []);
  return (
    <p className="modal__loading-text">
      Laster {loadingChar.repeat(loadingState)}
    </p>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  onBackdropClick: PropTypes.func,

  // if the data for the modal is loaded
  dataLoaded: PropTypes.bool,

  // if true - displays a loading animation untill dataLoaded is true and then opens the modal
  waitForLoadedDataWithAnimation: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  classNameContent: null,
  onBackdropClick: () => {},
  dataLoaded: false,
  waitForLoadedDataWithAnimation: false,
};

export default Modal;
