/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import { mq, or } from '@styles/facepaint';
import { highlightOnKeyboardFocusMixin } from '@styles/mixins';

const cellSpacing = '0.7em';

export const ContentWrapper = styled.div``;

export const Wrapper = styled.div`
  background-color: #f4f2f2;

  @media (max-width: 600px) {
    [name='featuredTile'] {
      grid-column-end: span 2;
    }
  }

  .featured-articles-carousel {
    height: 100%;

    .carousel-status {
      display: none;
    }

    .slide {
      background: transparent;
    }

    .control-dots .dot {
      background: gray;
      box-shadow: none;
    }

    .control-dots .dot.selected {
      background: gray;
      box-shadow: none;
    }

    .control-arrow.control-prev,
    .control-arrow.control-next {
      &.focus-visible {
        outline: 2px solid black;
        border: 5px solid green;
      }
    }

    .control-dots li.focus-visible {
      border: 6px solid black;
      background: black;
    }

    .carousel.carousel-slider {
      height: 100%;

      .slider-wrapper.axis-horizontal {
        height: 100%;

        .slider {
          height: 100%;

          .avatar-img {
          }
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileContentWrapper = styled.div`
  display: flex;
`;
export const DesktopContentWrapper = styled.div`
  padding: 3em;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeaturedTileActionButton = styled.button`
  color: white;
  :focus:not(:hover),
  :focus:active:not(:hover) {
    outline: 1px solid gray;
  }
`;

export const MobileTileWrapper = styled.section`
  display: flex;
  flex-direction: column;
  grid-row: ${(props) => props.rowIdx + 1};
  grid-column: ${(props) => props.gridColumn};
  /*Text does create a feeling of space, so add extra space for this row if we dont have text bellow*/
  margin-bottom: ${(props) => (!props.textOutside ? '0.5em' : '')};
`;

export const MobileTilesWrapper = styled.div`
  display: grid;
  grid-row-gap: 1.5em;
  grid-column-gap: 1.2em;
  margin: 0 auto;
`;

export const DesktopTilesWrapper = styled.section`
  display: grid;
  grid-column-gap: 5em;
  grid-row-gap: 2.5em;
`;

export const DesktopTileWrapper = styled.div`
  display: flex;
  grid-row: ${(props) => props.gridRow};
`;

export const DesktopTextOutside = styled.p`
  align-self: center;
  cursor: pointer;
`;
export const MobileTextOutside = styled.p`
  text-align: center;
  margin-bottom: 0;
  margin-top: 0.45em;
`;
