/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { Bucket } from '@components/Bucket';

/* A action-icon which can be used in dashboards eg
A image or FontAwesomeIcon-competible icon-name/array can be used to provide the graphic.

*/
const DashboardTile = ({
  active,
  textConfig,
  imageConfig,
  mobileBreakpoint,
  fullwidth,
}) => {
  return (
    <Bucket
      active={active}
      fullwidth={fullwidth}
      mobileBreakpoint={mobileBreakpoint}
      background={textConfig.background}
      secondaryText={textConfig.secondaryText}
      primaryText={textConfig.primaryText}
      imageSrc={imageConfig.src}
      imageOverlayColor={imageConfig.overlay}
      imagePosition={imageConfig.position}
    />
  );
};

DashboardTile.propTypes = {
  textConfig: PropTypes.shape({
    colorScheme: PropTypes.oneOf(['light', 'dark']),
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    background: PropTypes.string,
  }),
  imageConfig: PropTypes.shape({
    src: PropTypes.string,
    background: PropTypes.string,
    backgroundOpacity: PropTypes.number,
  }),
  active: PropTypes.bool,
  fullwidth: PropTypes.bool,
  mobileBreakpoint: PropTypes.string,
};

DashboardTile.defaultProps = {
  textConfig: {
    colorScheme: 'light',
  },
  imageConfig: {
    background: null,
    backgroundOpacity: 0.5,
  },
  active: false,
  fullwidth: false,
  mobileBreakpoint: 0,
};

export default DashboardTile;
