/* eslint-disable no-debugger */
/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import { css, jsx } from '@emotion/core';
import {
  Header,
  Wrapper,
  Teaser,
  Body,
  TeaserImg,
  TeaserImgCaption,
  TeaserImgContainer,
  GradientOverlay,
  animateVisibleHiddenVariants,
  AuthorHeader,
  AuthorAvatar,
  AuthorInfoWrapper,
  TeaserImageAnimationWrapper,
  ArticleContentWrapper,
  VideoPlayerInnerWrapper,
  VideoPlayerOuterWrapper,
} from '@routes/content/components/contentViewer/styles';

import * as styles from '@routes/content/components/contentViewer/styles';
import { getImageUrl } from '@utils/misc.utils';
import ArticleLoader from '../loader/loader';

/*
Views content from CMS
Plays a loading animation for the main image, if present
The animtion can be disabled with disableImageLoadingAnimation

If image animation is to be displayed, the image is loaded before the content is shown.
This is so that we know the size of the image, to make the animation work

*/

const ContentViewer = ({
  content,
  isLoading,
  isMobile,
  disableImageLoadingAnimation,
  disableVideoLoadingAnimation,
  gradientOverlay,
}) => {
  const [hideTeaserImage, setHideTeaserImage] = useState(isLoading);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const teaserImgRef = useRef();
  const videoPlayerRef = useRef();

  const displayContent =
    !isLoading &&
    (!hideTeaserImage || disableImageLoadingAnimation || !content.image) &&
    ((content.video && videoLoaded) || !content.video);

  const hasAuthorInfo = content && content.authorImage && content.authorText;

  /* Ensure that when we open a article, we are always scrolled to the top */
  useEffect(() => {
    const scrollContainer = document.querySelector('.app-wrapper__content');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, []);

  return (
    <>
      {gradientOverlay && (
        <GradientOverlay
          class="gradient-overlay"
          aria-hidden="true"
          gradient={gradientOverlay.gradient}
          height={gradientOverlay.height}
        />
      )}
      <Wrapper className="content-wrapper">
        {!displayContent && (
          <ArticleLoader
            isMobile={isMobile}
            wrapperstyle={css`
              margin-top: -4em;
            `}
            imagePartStyleMobile={css`
              margin-top: 6.3em;
            `}
            wrapperstyleMobile={css`
              position: relative;
              top: 0em;
              padding-left: 1em;
              padding-right: 1em;
            `}
          />
        )}
        {!isLoading && (
          <div
            aria-hidden={!displayContent}
            css={css`
              visibility: ${!displayContent ? 'hidden' : 'visible'};
            `}>
            {content.image && (
              <TeaserImgContainer>
                <TeaserImageAnimationWrapper
                  initial={!disableImageLoadingAnimation ? 'hidden' : false}
                  animate={
                    hideTeaserImage && !disableImageLoadingAnimation
                      ? 'hidden'
                      : 'visible'
                  }
                  variants={{
                    ...animateVisibleHiddenVariants,

                    hidden: {
                      ...animateVisibleHiddenVariants.hidden,
                      height:
                        teaserImgRef.current &&
                        `${teaserImgRef.offsetHeight}px`,
                    },
                  }}>
                  <TeaserImg
                    ref={teaserImgRef}
                    className="teaser-img"
                    onLoad={() => setHideTeaserImage(false)}
                    src={getImageUrl(content.image)}
                    alt={content.imageAltText}
                  />
                </TeaserImageAnimationWrapper>
                {content.imageCaption && (
                  <TeaserImgCaption>{content.imageCaption}</TeaserImgCaption>
                )}
              </TeaserImgContainer>
            )}

            {/* outer wrapper contols what size the video can stretch to
              inner wrapper controls the size of the video
              */}
            {content.video && (
              <VideoPlayerOuterWrapper
                initial={!disableVideoLoadingAnimation ? 'hidden' : false}
                animate={
                  !videoLoaded && !disableVideoLoadingAnimation
                    ? 'hidden'
                    : 'visible'
                }
                variants={{
                  ...animateVisibleHiddenVariants,

                  hidden: {
                    ...animateVisibleHiddenVariants.hidden,
                    height:
                      videoPlayerRef.current &&
                      `${videoPlayerRef.offsetHeight}px`,
                  },
                }}>
                <VideoPlayerInnerWrapper hide={!videoLoaded}>
                  <ReactPlayer
                    ref={videoPlayerRef}
                    onError={(e) => {
                      console.log('error loading video', e);
                    }}
                    onReady={() => {
                      setVideoLoaded(true);
                    }}
                    className="player"
                    controls
                    width="100%"
                    height="100%"
                    style={{
                      borderRadius: 10,
                    }}
                    url={content.video}
                  />
                </VideoPlayerInnerWrapper>
              </VideoPlayerOuterWrapper>
            )}
            <ArticleContentWrapper className="article-content">
              {hasAuthorInfo && (
                <AuthorInfoWrapper>
                  <AuthorAvatar src={getImageUrl(content.authorImage)} />
                  <AuthorHeader>{content.authorText}</AuthorHeader>
                </AuthorInfoWrapper>
              )}
              <Header className="header" hasAuthorInfo={hasAuthorInfo}>
                {content.title}
              </Header>
              {content.teaser && (
                <Teaser
                  className="teaser"
                  dangerouslySetInnerHTML={{ __html: content.teaser }}
                />
              )}
              <Body
                className="body"
                dangerouslySetInnerHTML={{
                  __html: content.body,
                }}
              />
            </ArticleContentWrapper>
          </div>
        )}
      </Wrapper>
    </>
  );
};

ContentViewer.defaultProps = {
  isLoading: false,
  isMobile: false,
  disableImageLoadingAnimation: false,
};

export default ContentViewer;
