import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PersonAvatar from '@components/person-avatar/person-avatar';

import storeLevel1 from '../../assets/images/store-level-1.png';
import storeLevel2 from '../../assets/images/store-level-2.png';
import storeLevel3 from '../../assets/images/store-level-3.png';
import storeLevel4 from '../../assets/images/store-level-4.png';

import './highscore-list.scss';
import { getOrganisations } from '../../store/selectors/roles.selectors';

const getStoreIcon = progress => {
  if (progress >= 90) {
    return storeLevel4;
  }
  if (progress >= 70) {
    return storeLevel3;
  }
  if (progress >= 40) {
    return storeLevel2;
  }
  return storeLevel1;
};

const HighscoreList = ({
  expanded,
  type,
  data,
  title,
  organisations,
  children,
}) => {
  const isAdmin =
    (organisations.data &&
      organisations.data.find(org => org.id === 70056) &&
      true) ||
    false;

  return (
    <div className="highscore-list">
      <div className="highscore-list__title__wrapper">
        <h3 className="highscore-list__title">{title}</h3>
      </div>
      {data && !!data.length && (
        <div className="highscore-list__content">
          {((expanded && data) || data.slice(0, 5)).map((item, key) => {
            let { avatar, name, parentName, completion } = {};

            if (type === 'persons') {
              avatar = (
                <PersonAvatar
                  size="55px"
                  avatar
                  person={item}
                  href={
                    isAdmin
                      ? `https://coop.snappergrape.no/organisations/organisation/${
                          item.butikk_id
                        }#!pid=${item.id}&view=person`
                      : undefined
                  }
                />
              );
              name = item.fullname;
              parentName = item.butikk_name;
              completion = `${item.points} poeng`;
            } else {
              avatar = (
                <PersonAvatar
                  size="55px"
                  imageUrl={getStoreIcon(item.completion)}
                  noBackground
                  href={
                    isAdmin
                      ? `https://coop.snappergrape.no/organisations/organisation/${
                          item.id
                        }`
                      : undefined
                  }
                />
              );
              ({ name } = item);
              completion = `Gjennomføringsgrad ${Math.round(item.completion)}%`;
              if (type === 'butikker') {
                parentName = item.samvirkelag;
              }
            }

            return (
              <div key={key} className="highscore-list__content__item__wrapper">
                <div className="highscore-list__content__item__avatar">
                  {avatar}
                </div>
                <div className="highscore-list__content__item">
                  <div className="highscore-list__content__item__labels">
                    {name && (
                      <div className="highscore-list__content__item__name">
                        {name}
                      </div>
                    )}
                    {completion && (
                      <div className="highscore-list__content__item__score">
                        {completion}
                      </div>
                    )}
                    {parentName && (
                      <div className="highscore-list__content__item__details">
                        {parentName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {children}
    </div>
  );
};

HighscoreList.propTypes = {
  expanded: PropTypes.bool,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  organisations: PropTypes.shape({}),
  type: PropTypes.oneOf(['persons', 'butikker', 'samvirkelag']).isRequired,
  children: PropTypes.node,
};

HighscoreList.defaultProps = {
  expanded: null,
  title: '',
  organisations: null,
  children: null,
};

const mapStateToProps = state => ({
  organisations: getOrganisations(state),
});

export default connect(mapStateToProps)(HighscoreList);
