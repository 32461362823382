import styled from '@emotion/styled';

import { css, jsx } from '@emotion/core';

import { motion, AnimatePresence } from 'framer-motion';

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 17em;
  height: 100%;
  ${(props) => (props.wrapperStyle ? props.wrapperStyle : '')};

  :focus {
    border: 1px solid gray;
  }
`;

export const ToplevelListWrapper = styled.ul`
  list-style-type: none;
  align-items: start;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isMobile
      ? css`
          margin-top: 0.3em;
        `
      : css``};
`;

export const SelectItemSecondaryText = styled.div`
  margin-left: auto;
  color: ${(props) => (props.theme === 'dark' ? 'gray' : 'white')};
`;

export const SelectOptionContent = styled.div`
  width: 100%;
  display: flex;
  padding: 0.9em 0.7em;
  margin-top: 0.4em;
  align-self: flex-start;
  color: ${(props) => (props.theme === 'dark' ? '#4f4f4f' : 'white')};

  ${(props) =>
    props.isMobile
      ? css`
          border-radius: 8px;
          width: auto;
          padding: 0.4em;
          margin-left: 2.7em;
        `
      : css`
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        `}

  ${(props) =>
    props.highlight
      ? css`
          background: ${props.theme === 'dark' ? '#eeeeee' : '#dfdfdf30'};
        `
      : ''}

  /*non-touch*/
  @media (hover: hover) {
    :hover,
    &.focus-visible {
      ${(props) =>
        props.highlightOnHover
          ? css`
              background: ${props.theme === 'dark' ? '#eeeeee' : '#dfdfdf30'};
            `
          : ''}
    }
  }

  /*touch*/
  @media (hover: none) {
    :hover {
      ${(props) =>
        props.highlightOnHover
          ? css`
              background: ${props.theme === 'dark' ? '#eeeeee' : '#dfdfdf30'};
            `
          : ''}
    }
  }
`;

export const SelectOptionWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  :hover {
    cursor: pointer;
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
`;

export const SelectItemText = styled.div`
  margin-left: 1em;
  margin-right: 1em;
`;

export const SelectChildren = styled(AnimatePresence)``;
