/* eslint-disable react/prop-types */
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import React from 'react';

import BGIMG from '@src/assets/images/bg1.jpg';

import CourseTrail from '@src/routes/my-education/components/course-trail';

import { device } from '@src/common/styles/device';

const Wrapper = styled.section`
  background: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  font-size: 12px; /* Sets the size of the Path */
  padding: 3em 0;
  min-width: 330px;
  max-width: 1024px;
  border-radius: 10px;
  border: 0.1px solid black;
  margin: 10px;

  @media ${device.mobileL} {
    font-size: 16px;
    margin: auto;
  }
`;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  width: 21em;
  height: 100%;
`;

const Trail = () => {
  return (
    <Wrapper img={BGIMG}>
      <Map>
        <CourseTrail />
      </Map>
    </Wrapper>
  );
};

export default Trail;
