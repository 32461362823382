import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FormInput } from './FormInput';
import { Button, box, stack, baseSize, mt, ButtonLink } from './styles';

const ChangePasswordForm = ({ handleSubmit, handleCancel }) => (
  <form onSubmit={handleSubmit} css={[box, stack]}>
    <div>
      <Field
        autoComplete="username"
        component={FormInput}
        name="username"
        type="text"
      />
    </div>

    <div css={[baseSize, mt]}>
      <Button type="submit">Få nytt passord</Button>
      <ButtonLink css={mt} type="button" onClick={handleCancel}>
        Avbryt
      </ButtonLink>
    </div>
  </form>
);

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'changepwd',
})(ChangePasswordForm);
